import React, { Fragment, useRef, useState } from "react";
import { Col, Container, OverlayTrigger, Row, Tooltip } from "react-bootstrap";
import { FormattedMessage, defineMessages, useIntl } from "react-intl";
import { useParams, Link, useHistory } from 'react-router-dom';
import { EstimateStatus, PaymentStatus, useDeclineRepairEstimateMutation, useGetRepairEstimateQuery } from "shared/types/__generated__/graphql-type";
import "./AccidentAssignmentsView.css";
import AccidentAssignmentsSubmissionModal from "AccidentManagement/AccidentAssignments/AddEditView/AccidentAssignmentsSubmissionModal";
import FormWrapper from "shared/components/FormWrapper";
import Spinner from "shared/components/Spinner";
import { AccidentAssignmentAtaCodes } from "AccidentManagement/common/AccidentAssignmentAtaCodes";
import AccidentAssignmentsEtaModal from "AccidentManagement/AccidentAssignments/AddEditView/AccidentAssignmentsEtaModal";
import * as constants from "shared/constants/App";
import configuration from "shared/configuration";
import ConfirmMessageBox from "shared/AddEditView/ConfirmMessageBox";
import AccidentAssignmentsImageSubmissionModal from "AccidentManagement/AccidentAssignments/AddEditView/AccidentAssignmentsImageSubmissionModal";
//#endregion
//#region Component
const AccidentAssignmentsView = () => {
    var _a, _b, _c, _d;
    // #region State
    const [showEstimateModal, setShowEstimateModal] = useState(false);
    const [showImageModal, setShowImageModal] = useState(false);
    const [showEtaModal, setShowEtaModal] = useState(false);
    const [etaDate, setEtaDate] = useState(null);
    const [etaNoteValue, setEtaNoteValue] = useState("");
    const [errorMessages, setErrorMessages] = useState([]);
    // #endregion
    // #region Refs
    const historyDetails = useRef();
    const declineClaimRef = useRef();
    // #endregion
    //#region Hooks
    const { id } = useParams();
    const history = useHistory();
    const { data: repairEstimateData, loading: repairEstimateLoading, error: repairEstimateError, refetch } = useGetRepairEstimateQuery({
        notifyOnNetworkStatusChange: true,
        variables: {
            id: parseInt(id)
        },
        pollInterval: showEstimateModal || showEtaModal || showImageModal ? undefined : configuration.accidentAssignmentsViewRefreshFrequencyInMinutes * 60 * 1000
    });
    const [declineRepairEstimateMutation, { data: declineRepairEstimateData, loading: declineRepairEstimateLoading, error: declineRepairEstimateError }] = useDeclineRepairEstimateMutation({
        variables: {
            repairEstimateId: parseInt(id)
        },
    });
    const intl = useIntl();
    //#endregion
    // #region Event handlers
    const toggleShowEstimateModal = () => {
        setShowEstimateModal(!showEstimateModal);
    };
    const toggleShowImageModal = () => {
        setShowImageModal(!showImageModal);
    };
    const toggleShowEtaModal = () => {
        if (!showEtaModal) {
            setEtaDate(repairEstimate.currentEstimatedCompletionDate ? new Date(repairEstimate.currentEstimatedCompletionDate) : null);
            setEtaNoteValue("");
        }
        setShowEtaModal(!showEtaModal);
    };
    const toggleShowDeclineAssignmentConfirmation = () => {
        declineClaimRef.current.showModal();
    };
    const onRepairDocumentClicked = (pdfUrl) => {
        const pdfWindow = window.open(pdfUrl, null, 'noopener, noreferrer');
        if (pdfWindow)
            pdfWindow.opener = null;
    };
    const onHistoryDetailsClicked = () => {
        historyDetails.current.scrollIntoView();
    };
    const onDeclineRepairAssignmentClicked = () => {
        declineClaimRef.current.showModal();
        setErrorMessages(errorMessages => []);
        declineRepairEstimateMutation().then((result) => {
            if (result.data.declineRepairEstimate.successPayload.success) {
                history.replace("/AccidentManagement/AccidentAssignments");
            }
            else {
                setErrorMessages(errorMessages => [...errorMessages, { message: constants.GENERIC_ERROR_MESSAGE }]);
            }
        });
    };
    // #endregion
    //#region International messages
    const messages = defineMessages({
        accident_assignments_view__claim_header: {
            id: "accident_assignments_view__claim_header",
            description: "Claim {claimId}",
            defaultMessage: "Claim {claimId}"
        },
        accident_assignments_history_details: {
            id: "accident_assignments_history_details",
            description: "Show history details",
            defaultMessage: "Show history details"
        },
        accident_assignments_decline_assignment: {
            id: "accident_assignments_decline_assignment",
            description: "Decline accident assignment",
            defaultMessage: "Decline accident assignment"
        },
        accident_assignments_initial_submission_header: {
            id: "accident_assignments_initial_submission_header",
            description: "Submit initial images, estimate and pricing",
            defaultMessage: "Submit initial images, estimate and pricing"
        },
        accident_assignments_initial_submission_subheader_1: {
            id: "accident_assignments_initial_submission_subheader_1",
            description: "To expedite the review of this accident assignment, please be sure to upload images, copy of the repair estimate, and input your pricing. All three items are needed to submit your repair estimate to Holman's Accident Management team.",
            defaultMessage: "To expedite the review of this accident assignment, please be sure to upload images, copy of the repair estimate, and input your pricing. All three items are needed to submit your repair estimate to Holman's Accident Management team."
        },
        accident_assignments_initial_submission_subheader_2: {
            id: "accident_assignments_initial_submission_subheader_2",
            description: "After the initial repair estimate is approved, you can upload any additional photos here.",
            defaultMessage: "After the initial repair estimate is approved, you can upload any additional photos here."
        },
        accident_assignments_submit_initial_estimate: {
            id: "accident_assignments_submit_initial_estimate",
            description: "Submit initial estimate",
            defaultMessage: "Submit initial estimate"
        },
        accident_assignments_additional_images_header: {
            id: "accident_assignments_additional_images_header",
            description: "Submit additional images",
            defaultMessage: "Submit additional images"
        },
        accident_assignments_submit_additional_images: {
            id: "accident_assignments_submit_additional_images",
            description: "Submit images",
            defaultMessage: "Submit images"
        },
        accident_assignments_additional_images_subheader: {
            id: "accident_assignments_additional_images_subheader",
            description: "Upload images here",
            defaultMessage: "Upload images here"
        },
        accident_assignments_history_header: {
            id: "accident_assignments_history_header",
            description: "History",
            defaultMessage: "History"
        },
        accident_assignments_eta_header: {
            id: "accident_assignments_eta_header",
            description: "Update repair ETA",
            defaultMessage: "Update repair ETA"
        },
        accident_assignments_eta_subheader: {
            id: "accident_assignments_eta_subheader",
            description: "Please enter the estimated completion date for these repairs.",
            defaultMessage: "Please enter the estimated completion date for these repairs."
        },
        accident_assignments_supplement_header: {
            id: "accident_assignments_supplement_header",
            description: "Submit supplemental repair estimate",
            defaultMessage: "Submit supplemental repair estimate"
        },
        accident_assignments_supplement_subheader: {
            id: "accident_assignments_supplement_subheader",
            description: "Upload your supplemental repair estimate here",
            defaultMessage: "Upload your supplemental repair estimate here"
        },
        accident_assignments_supplement_button: {
            id: "accident_assignments_supplement_button",
            description: "Submit supplemental repair",
            defaultMessage: "Submit supplemental repair"
        },
        accident_assignments_close_header: {
            id: "accident_assignments_close_header",
            description: "Close PO",
            defaultMessage: "Close PO"
        },
        accident_assignments_close_subheader: {
            id: "accident_assignments_close_subheader",
            description: "Once all repairs are complete, including supplemental repairs, you can close the repair",
            defaultMessage: "Once all repairs are complete, including supplemental repairs, you can close the repair"
        },
        accident_assignments_history_subheader: {
            id: "accident_assignments_history_subheader",
            description: "Estimates/Supplements",
            defaultMessage: "Estimates/Supplements"
        },
        accident_assignments_driver_contact_title: {
            id: "accident_assignments_driver_contact_title",
            description: "Driver Contact",
            defaultMessage: "Driver Contact"
        },
        accident_assignments_driver_contact_caption: {
            id: "accident_assignments_driver_contact_caption",
            description: "Contact",
            defaultMessage: "Contact"
        },
        accident_assignments_driver_phone_caption: {
            id: "accident_assignments_driver_phone_caption",
            description: "Phone",
            defaultMessage: "Phone"
        },
        accident_assignments_driver_email_caption: {
            id: "accident_assignments_driver_email_caption",
            description: "Email",
            defaultMessage: "Email"
        },
        accident_assignments_unavailable_caption: {
            id: "accident_assignments_unavailable_caption",
            description: "Unavailable",
            defaultMessage: "Unavailable"
        },
        original_estimate: {
            id: "original_estimate",
            description: "original estimate",
            defaultMessage: "original estimate"
        },
        supplement: {
            id: "supplement",
            description: "supplement {submissionNumber}",
            defaultMessage: "supplement {submissionNumber}"
        },
        accident_assignments_decline_claim_confirmation_no: {
            id: 'cancel_po_confirmation_no',
            description: 'No',
            defaultMessage: 'No'
        },
        accident_assignments_decline_claim_confirmation_yes: {
            id: 'cancel_po_confirmation_yes',
            description: 'Yes',
            defaultMessage: 'Yes'
        },
        accident_assignments_decline_claim_confirmation_title: {
            id: 'accident_assignments_decline_claim_confirmation_title',
            description: 'Decline Assignment',
            defaultMessage: 'Decline Assignment'
        },
        accident_assignments_decline_claim_confirmation_message: {
            id: 'accident_assignments_decline_claim_confirmation_message',
            description: 'You are about to decline this accident repair assignment and remove it from Holman PartnerConnect. Are you sure you want to decline this assignment?',
            defaultMessage: 'You are about to decline this accident repair assignment and remove it from Holman PartnerConnect. Are you sure you want to decline this assignment?'
        },
        accident_assignments_close_po_disabled_message: {
            id: 'accident_assignments_close_po_disabled_message',
            description: 'Please update repair ETA before closing PO',
            defaultMessage: 'Please update repair ETA before closing PO'
        },
    });
    //#endregion
    //#region Render helpers
    const getTitle = (repairEstimate) => {
        let paymentStatus = repairEstimate.paymentStatus;
        let estimateStatus = repairEstimate.estimateStatus;
        const messages = defineMessages({
            accident_assignments_pending_estimate_title: {
                id: "accident_assignments_pending_estimate_title",
                description: "Pending images and estimate",
                defaultMessage: "Pending images and estimate"
            },
            accident_assignments_view__pending_approval_title: {
                id: "accident_assignments_view__pending_approval_title",
                description: "Pending Holman approval for {submissionNumber} estimate",
                defaultMessage: "Pending Holman approval for {submissionNumber} estimate"
            },
            accident_assignments_pending_eta_title: {
                id: "accident_assignments_pending_eta_title",
                description: "Repair approved - Please provide repair ETA",
                defaultMessage: "Repair approved - Please provide repair ETA"
            },
            accident_assignments_approved_title: {
                id: "accident_assignments_approved_title",
                description: "Repair approved",
                defaultMessage: "Repair approved"
            },
            accident_assignments_payment_in_progress_title: {
                id: "accident_assignments_payment_in_progress_title",
                description: "Payment in progress",
                defaultMessage: "Payment in progress"
            },
            accident_assignments_payment_submitted_title: {
                id: "accident_assignments_payment_submitted_title",
                description: "Payment submitted",
                defaultMessage: "Payment submitted"
            },
            accident_assignments_work_completed_title: {
                id: "accident_assignments_work_completed_title",
                description: "Work completed",
                defaultMessage: "Work completed"
            },
            accident_assignments_declined_title: {
                id: "accident_assignments_declined_title",
                description: "Assignment Declined",
                defaultMessage: "Assignment Declined"
            },
        });
        if (estimateStatus == EstimateStatus.WaitingForEstimateAndImages) {
            return (React.createElement(Row, { className: "justify-content-center accident_assignments__pending_estimate_title" },
                React.createElement(FormattedMessage, Object.assign({}, messages.accident_assignments_pending_estimate_title))));
        }
        else if (estimateStatus == EstimateStatus.DeclinedByShop) {
            return (React.createElement(Row, { className: "justify-content-center accident_assignments__pending_estimate_title" },
                React.createElement(FormattedMessage, Object.assign({}, messages.accident_assignments_declined_title))));
        }
        else if (paymentStatus == PaymentStatus.PaymentNotRequested) {
            if (estimateStatus == EstimateStatus.Approved) {
                return (React.createElement(Row, { className: "justify-content-center accident_assignments__title" },
                    React.createElement(FormattedMessage, Object.assign({}, messages.accident_assignments_approved_title))));
            }
            else if (estimateStatus == EstimateStatus.WaitingForRepairEta) {
                return (React.createElement(Row, { className: "justify-content-center accident_assignments__pending_estimate_title" },
                    React.createElement(FormattedMessage, Object.assign({}, messages.accident_assignments_pending_eta_title))));
            }
            else if (estimateStatus == EstimateStatus.WaitingForHolmanApproval) {
                let submissionText = repairEstimate.submissionNumber == 1 ? "original" : `supplement ${repairEstimate.submissionNumber - 1}`;
                return (React.createElement(Row, { className: "justify-content-center accident_assignments__title" },
                    React.createElement(FormattedMessage, Object.assign({}, messages.accident_assignments_view__pending_approval_title, { values: { submissionNumber: submissionText } }))));
            }
        }
        else if (paymentStatus == PaymentStatus.PaymentInProgress) {
            return (React.createElement(Row, { className: "justify-content-center accident_assignments__title" },
                React.createElement(FormattedMessage, Object.assign({}, messages.accident_assignments_payment_in_progress_title))));
        }
        else if (paymentStatus == PaymentStatus.Paid) {
            return (React.createElement(Row, { className: "justify-content-center accident_assignments__title" },
                React.createElement(FormattedMessage, Object.assign({}, messages.accident_assignments_payment_submitted_title))));
        }
        else if (paymentStatus == PaymentStatus.WorkCompleted) {
            return (React.createElement(Row, { className: "justify-content-center accident_assignments__title" },
                React.createElement(FormattedMessage, Object.assign({}, messages.accident_assignments_work_completed_title))));
        }
    };
    const getVehicleInfo = (vehicle, client, odometer) => {
        const messages = defineMessages({
            accident_assignments_vehicle_vin_caption: {
                id: "accident_assignments_vehicle_vin_caption",
                description: "VIN",
                defaultMessage: "VIN"
            },
            accident_assignments_vehicle_client_caption: {
                id: "accident_assignments_vehicle_client_caption",
                description: "Client",
                defaultMessage: "Client"
            },
            accident_assignments_vehicle_unit_number_caption: {
                id: "accident_assignments_vehicle_unit_number_caption",
                description: "Holman unit no.",
                defaultMessage: "Holman unit no."
            },
            accident_assignments_vehicle_engine_caption: {
                id: "accident_assignments_vehicle_engine_caption",
                description: "Engine type",
                defaultMessage: "Engine type"
            },
            accident_assignments_vehicle_vehicle_caption: {
                id: "accident_assignments_vehicle_vehicle_caption",
                description: "Vehicle",
                defaultMessage: "Vehicle"
            },
            accident_assignments_vehicle_plate_caption: {
                id: "accident_assignments_vehicle_plate_caption",
                description: "Plate",
                defaultMessage: "Plate"
            },
            accident_assignments_vehicle_color_caption: {
                id: "accident_assignments_vehicle_color_caption",
                description: "Color",
                defaultMessage: "Color"
            },
            accident_assignments_vehicle_mileage_caption: {
                id: "accident_assignments_vehicle_mileage_caption",
                description: "Mileage",
                defaultMessage: "Mileage"
            },
            accident_assignments_vehicle_other_caption: {
                id: "accident_assignments_vehicle_other_caption",
                description: "Other",
                defaultMessage: "Other"
            },
            vehicle_documents: {
                id: 'vehicle_documents',
                description: 'Vehicle Documents',
                defaultMessage: 'Vehicle Documents'
            },
            repair_history_of_vehicle: {
                id: 'repair_history_of_vehicle',
                description: 'Repair History',
                defaultMessage: 'Repair History'
            },
            client_parameters_of_vehicle: {
                id: 'client_parameters_of_vehicle',
                description: 'client general and vehicle parameters info',
                defaultMessage: 'Client Parameters'
            },
            accident_assignments_unavailable_caption: {
                id: "accident_assignments_unavailable_caption",
                description: "Unavailable",
                defaultMessage: "Unavailable"
            },
        });
        let vehicleText = ((vehicle === null || vehicle === void 0 ? void 0 : vehicle.year) ? (vehicle === null || vehicle === void 0 ? void 0 : vehicle.year) + " " : "")
            + ((vehicle === null || vehicle === void 0 ? void 0 : vehicle.make) ? (vehicle === null || vehicle === void 0 ? void 0 : vehicle.make) + " " : "")
            + ((vehicle === null || vehicle === void 0 ? void 0 : vehicle.model) ? (vehicle === null || vehicle === void 0 ? void 0 : vehicle.model) + " " : "");
        let vehicleVinFirst = '';
        let vehicleVinLast = '';
        if ((vehicle === null || vehicle === void 0 ? void 0 : vehicle.vin) != null) {
            vehicleVinFirst = vehicle.vin.slice(0, 9);
            vehicleVinLast = vehicle.vin.slice(9, vehicle.vin.length);
        }
        return (React.createElement(Row, { className: "accident_assignments__vehicle_info" },
            React.createElement(Col, null,
                React.createElement(Row, null,
                    React.createElement(Col, { md: 5, className: "accident_assignments__vehicle_info_label" },
                        React.createElement(FormattedMessage, Object.assign({}, messages.accident_assignments_vehicle_vin_caption))),
                    React.createElement(Col, { md: 7, className: "accident_assignments__val" }, (vehicle === null || vehicle === void 0 ? void 0 : vehicle.vin) ?
                        React.createElement(React.Fragment, null,
                            vehicleVinFirst,
                            React.createElement("span", { className: "car_wash_details__val_highlight" }, vehicleVinLast))
                        : React.createElement(FormattedMessage, Object.assign({}, messages.accident_assignments_unavailable_caption)))),
                React.createElement(Row, null,
                    React.createElement(Col, { md: 5, className: "accident_assignments__vehicle_info_label" },
                        React.createElement(FormattedMessage, Object.assign({}, messages.accident_assignments_vehicle_client_caption))),
                    React.createElement(Col, { md: 7, className: "accident_assignments__val" }, (client === null || client === void 0 ? void 0 : client.clientName) + `(${client === null || client === void 0 ? void 0 : client.id})`)),
                React.createElement(Row, null,
                    React.createElement(Col, { md: 5, className: "accident_assignments__vehicle_info_label" },
                        React.createElement(FormattedMessage, Object.assign({}, messages.accident_assignments_vehicle_unit_number_caption))),
                    React.createElement(Col, { md: 7, className: "accident_assignments__val" }, (vehicle === null || vehicle === void 0 ? void 0 : vehicle.holmanUnitNo) ? vehicle === null || vehicle === void 0 ? void 0 : vehicle.holmanUnitNo : React.createElement(FormattedMessage, Object.assign({}, messages.accident_assignments_unavailable_caption)))),
                React.createElement(Row, null,
                    React.createElement(Col, { md: 5, className: "accident_assignments__vehicle_info_label" },
                        React.createElement(FormattedMessage, Object.assign({}, messages.accident_assignments_vehicle_engine_caption))),
                    React.createElement(Col, { md: 7, className: "accident_assignments__val" }, (vehicle === null || vehicle === void 0 ? void 0 : vehicle.engineType) ? vehicle === null || vehicle === void 0 ? void 0 : vehicle.engineType : React.createElement(FormattedMessage, Object.assign({}, messages.accident_assignments_unavailable_caption))))),
            React.createElement(Col, null,
                React.createElement(Row, null,
                    React.createElement(Col, { md: 5, className: "accident_assignments__vehicle_info_label" },
                        React.createElement(FormattedMessage, Object.assign({}, messages.accident_assignments_vehicle_vehicle_caption))),
                    React.createElement(Col, { md: 7, className: "accident_assignments__val" }, vehicleText ? vehicleText : React.createElement(FormattedMessage, Object.assign({}, messages.accident_assignments_unavailable_caption)))),
                React.createElement(Row, null,
                    React.createElement(Col, { md: 5, className: "accident_assignments__vehicle_info_label" },
                        React.createElement(FormattedMessage, Object.assign({}, messages.accident_assignments_vehicle_plate_caption))),
                    React.createElement(Col, { md: 7, className: "accident_assignments__val" }, (vehicle === null || vehicle === void 0 ? void 0 : vehicle.licensePlate) ? vehicle === null || vehicle === void 0 ? void 0 : vehicle.licensePlate : React.createElement(FormattedMessage, Object.assign({}, messages.accident_assignments_unavailable_caption)))),
                React.createElement(Row, null,
                    React.createElement(Col, { md: 5, className: "accident_assignments__vehicle_info_label" },
                        React.createElement(FormattedMessage, Object.assign({}, messages.accident_assignments_vehicle_color_caption))),
                    React.createElement(Col, { md: 7, className: "accident_assignments__val" }, (vehicle === null || vehicle === void 0 ? void 0 : vehicle.color) ? vehicle === null || vehicle === void 0 ? void 0 : vehicle.color : React.createElement(FormattedMessage, Object.assign({}, messages.accident_assignments_unavailable_caption)))),
                React.createElement(Row, null,
                    React.createElement(Col, { md: 5, className: "accident_assignments__vehicle_info_label" },
                        React.createElement(FormattedMessage, Object.assign({}, messages.accident_assignments_vehicle_mileage_caption))),
                    React.createElement(Col, { md: 7, className: "accident_assignments__val" }, odometer ? odometer : React.createElement(FormattedMessage, Object.assign({}, messages.accident_assignments_unavailable_caption)))),
                React.createElement(Row, null,
                    React.createElement(Col, { md: 5, className: "accident_assignments__vehicle_info_label" },
                        React.createElement(FormattedMessage, Object.assign({}, messages.accident_assignments_vehicle_other_caption))),
                    React.createElement(Col, { md: 7, className: "accident_assignments__val" },
                        React.createElement(Link, { to: `/vehicle/${vehicle === null || vehicle === void 0 ? void 0 : vehicle.id}/documents`, id: "vehicle_doc_link" },
                            React.createElement(FormattedMessage, Object.assign({}, messages.vehicle_documents))),
                        React.createElement("div", null,
                            React.createElement(Link, { to: '/po/repairHistory/' + (vehicle === null || vehicle === void 0 ? void 0 : vehicle.id), id: "vehicle_repair_hist_link" },
                                React.createElement(FormattedMessage, Object.assign({}, messages.repair_history_of_vehicle)))),
                        React.createElement("div", null,
                            React.createElement(Link, { to: '/po/clientParameters/' + (vehicle === null || vehicle === void 0 ? void 0 : vehicle.id), id: "client_param_link" },
                                React.createElement(FormattedMessage, Object.assign({}, messages.client_parameters_of_vehicle)))))))));
    };
    const getFileName = (fileName) => {
        return (React.createElement(OverlayTrigger, { placement: "right", key: fileName, overlay: React.createElement(Tooltip, { id: "tooltop" }, fileName) },
            React.createElement("div", { key: fileName }, fileName.substring(0, 20) + "...")));
    };
    const getImages = (images) => {
        const messages = defineMessages({
            accident_assignments_image_upload_caption: {
                id: "accident_assignments_image_upload_caption",
                description: "Uploaded on {uploadDate}",
                defaultMessage: "Uploaded on {uploadDate}"
            },
            accident_assignments_image_title: {
                id: "accident_assignments_image_title",
                description: "Images",
                defaultMessage: "Images"
            },
            accident_assignments_images_submission: {
                id: "accident_assignments_images_submission",
                description: "Collected for {submissionNumber}",
                defaultMessage: "Collected for {submissionNumber}"
            },
            original_estimate: {
                id: "original_estimate",
                description: "original estimate",
                defaultMessage: "original estimate"
            },
            supplement: {
                id: "supplement",
                description: "supplement {submissionNumber}",
                defaultMessage: "supplement {submissionNumber}"
            },
        });
        //Create array of arrays for each submission
        let imagesBySubmission = new Array(repairEstimateData.repairEstimate.submissionNumber);
        for (let i = 0; i < repairEstimateData.repairEstimate.submissionNumber; i++) {
            imagesBySubmission[i] = new Array();
        }
        //Populate submisison arrays with submission images
        images.map(img => {
            if (img.submissionNumber <= repairEstimateData.repairEstimate.submissionNumber)
                imagesBySubmission[img.submissionNumber - 1].push(img);
        });
        return (React.createElement(Container, { fluid: true, className: "accident_assignments__estimate_subsection" },
            React.createElement(Row, { className: "justify-content-left accident_assignments__header" },
                React.createElement(FormattedMessage, Object.assign({}, messages.accident_assignments_image_title))),
            React.createElement(Row, { className: "accident_assignments_image_container" }, imagesBySubmission.map((submissions, index) => (React.createElement(Fragment, { key: `image_${index + 1}` },
                React.createElement("div", { className: "justify-content-left accident_assignments__subheader" }, index === 0 ? intl.formatMessage(messages.original_estimate).toLocaleUpperCase() : intl.formatMessage(messages.supplement, { submissionNumber: index }).toLocaleUpperCase()),
                submissions.map(image => (React.createElement("div", { className: "accident_assignments_image_item", key: image.fileName },
                    React.createElement("div", null,
                        React.createElement("img", { className: "accident_assignments_image", src: image.thumbnailUrl })),
                    React.createElement("div", { className: "accident_assignments_image_text" },
                        React.createElement("div", { style: { fontStyle: "italic" } }, image.fileName.length > 20 ? getFileName(image.fileName) : image.fileName),
                        React.createElement("div", null,
                            React.createElement(FormattedMessage, Object.assign({}, messages.accident_assignments_image_upload_caption, { values: { uploadDate: intl.formatDate(image.createdDate) } }))),
                        React.createElement("div", null,
                            React.createElement(FormattedMessage, Object.assign({}, messages.accident_assignments_images_submission, { values: { submissionNumber: image.submissionNumber == 1 ? intl.formatMessage(messages.original_estimate) : intl.formatMessage(messages.supplement, { submissionNumber: image.submissionNumber - 1 }) } }))))))),
                index > 0 && imagesBySubmission.length != index + 1 ? React.createElement("hr", null) : null))))));
    };
    const getPricing = (lineItems) => {
        const messages = defineMessages({
            accident_assignments_pricing_header: {
                id: "accident_assignments_pricing_header",
                description: "Pricing",
                defaultMessage: "Pricing"
            },
            accident_assignments_pricing_description: {
                id: "accident_assignments_pricing_description",
                description: "Description",
                defaultMessage: "Description"
            },
            accident_assignments_pricing_qty: {
                id: "accident_assignments_pricing_qty",
                description: "Qty",
                defaultMessage: "Qty"
            },
            accident_assignments_pricing_cost: {
                id: "accident_assignments_pricing_cost",
                description: "Cost",
                defaultMessage: "Cost"
            },
            accident_assignments_pricing_amount: {
                id: "line_item_view_amount",
                description: "Amount",
                defaultMessage: "Amount"
            },
            accident_assignments_pricing_subtotal: {
                id: "accident_assignments_pricing_subtotal",
                description: "SUBTOTAL",
                defaultMessage: "SUBTOTAL"
            },
            supplement: {
                id: "supplement",
                description: "supplement {submissionNumber}",
                defaultMessage: "supplement {submissionNumber}"
            },
            accident_assignments_pricing_damage_parts: {
                id: "accident_assignments_pricing_damage_parts",
                description: "VEHICLE DAMAGE PARTS",
                defaultMessage: "VEHICLE DAMAGE PARTS"
            },
            accident_assignments_pricing_damage_labor: {
                id: "accident_assignments_pricing_damage_labor",
                description: "VEHICLE DAMAGE LABOR",
                defaultMessage: "VEHICLE DAMAGE LABOR"
            },
            accident_assignments_pricing_paint_parts: {
                id: "accident_assignments_pricing_paint_parts",
                description: "PAINT SUPPLIES PARTS",
                defaultMessage: "PAINT SUPPLIES PARTS"
            },
            accident_assignments_pricing_paint_labor: {
                id: "accident_assignments_pricing_paint_labor",
                description: "PAINT LABOR",
                defaultMessage: "PAINT LABOR"
            },
            accident_assignments_pricing_mechanical: {
                id: "accident_assignments_pricing_mechanical",
                description: "MECHANICAL LABOR",
                defaultMessage: "MECHANICAL LABOR"
            },
            accident_assignments_pricing_other: {
                id: "accident_assignments_pricing_other",
                description: "OTHER",
                defaultMessage: "OTHER"
            },
        });
        //todo: get remaining ata codes
        const getInternationalDescription = (lineItem) => {
            switch (lineItem.productCode) {
                case AccidentAssignmentAtaCodes.DamageParts:
                    return intl.formatMessage(messages.accident_assignments_pricing_damage_parts);
                case AccidentAssignmentAtaCodes.DamageLabor:
                    return intl.formatMessage(messages.accident_assignments_pricing_damage_labor);
                case AccidentAssignmentAtaCodes.PaintParts:
                    return intl.formatMessage(messages.accident_assignments_pricing_paint_parts);
                case AccidentAssignmentAtaCodes.PaintLabor:
                    return intl.formatMessage(messages.accident_assignments_pricing_paint_labor);
                case AccidentAssignmentAtaCodes.MechLabor:
                    return intl.formatMessage(messages.accident_assignments_pricing_mechanical);
                case AccidentAssignmentAtaCodes.Other:
                    return intl.formatMessage(messages.accident_assignments_pricing_other);
            }
        };
        let validAccidentLineItems = lineItems.filter((lineItem) => { return lineItem.unitPrice > 0 && Object.values(AccidentAssignmentAtaCodes).includes(lineItem.productCode); });
        let subTotal = validAccidentLineItems.reduce((sum, l) => sum + l.extendedEstimate, 0);
        return (validAccidentLineItems.length > 0 ?
            React.createElement(Row, { className: "accident_assignments__estimate_subsection" },
                React.createElement(Row, { className: "justify-content-left accident_assignments__header" },
                    React.createElement(FormattedMessage, Object.assign({}, messages.accident_assignments_pricing_header))),
                React.createElement("table", { className: "accident_assignments_line_item__table" },
                    React.createElement("thead", null,
                        React.createElement("tr", { className: "container" },
                            React.createElement("th", { className: "col-md-5 accident_assignments_line_item__header" },
                                React.createElement(FormattedMessage, Object.assign({}, messages.accident_assignments_pricing_description))),
                            React.createElement("th", { className: "col-md-1 accident_assignments_line_item__header accident_assignments_line_item__currency" },
                                React.createElement(FormattedMessage, Object.assign({}, messages.accident_assignments_pricing_qty))),
                            React.createElement("th", { className: "col-md-2 accident_assignments_line_item__header accident_assignments_line_item__currency" },
                                React.createElement(FormattedMessage, Object.assign({}, messages.accident_assignments_pricing_cost))),
                            React.createElement("th", { className: "col-md-2 accident_assignments_line_item__header accident_assignments_line_item__currency" },
                                React.createElement(FormattedMessage, Object.assign({}, messages.accident_assignments_pricing_amount))))),
                    React.createElement("tbody", null, validAccidentLineItems.map((lineItem, i) => (React.createElement("tr", { className: "container", key: i + 1 + "-" + lineItem.productCode },
                        React.createElement("td", { className: "col-md-6 accident_assignments_line_item__item" }, getInternationalDescription(lineItem)),
                        React.createElement("td", { className: "col-md-1 accident_assignments_line_item__item accident_assignments_line_item__currency" }, lineItem.quantity),
                        React.createElement("td", { className: "col-md-1 accident_assignments_line_item__item accident_assignments_line_item__currency" }, intl.formatNumber(lineItem.unitPrice, { style: "currency", currency: "USD" })),
                        React.createElement("td", { className: "col-md-1 accident_assignments_line_item__item accident_assignments_line_item__currency" }, intl.formatNumber(lineItem.unitPrice, { style: "currency", currency: "USD" }))))))),
                React.createElement("table", null,
                    React.createElement("tbody", null,
                        React.createElement("tr", { className: "container" },
                            React.createElement("td", { className: "col-md-6 accident_assignments_line_item__subtotal" },
                                React.createElement(FormattedMessage, Object.assign({}, messages.accident_assignments_pricing_subtotal))),
                            React.createElement("td", { className: "col-md-1 accident_assignments_line_item__item accident_assignments_line_item__currency" }),
                            React.createElement("td", { className: "col-md-1 accident_assignments_line_item__item accident_assignments_line_item__currency" }),
                            React.createElement("td", { className: "col-md-1 accident_assignments_line_item__item accident_assignments_line_item__currency" }, intl.formatNumber(subTotal, { style: "currency", currency: "USD" }))))))
            : null);
    };
    //#endregion
    let repairEstimate = repairEstimateData === null || repairEstimateData === void 0 ? void 0 : repairEstimateData.repairEstimate;
    let isSupplementalEstimate = (repairEstimate === null || repairEstimate === void 0 ? void 0 : repairEstimate.estimateStatus) === EstimateStatus.Approved;
    //#region Render
    return (React.createElement(Container, { className: "accident_assignments__container" }, repairEstimateLoading || declineRepairEstimateLoading ? React.createElement(Spinner, null)
        : repairEstimateError ? React.createElement(FormWrapper, { id: "queryErrorMessages", key: "queryErrorMessages", formErrors: [{ message: constants.GENERIC_ERROR_MESSAGE }] })
            : React.createElement(React.Fragment, null,
                declineRepairEstimateError ?
                    React.createElement(FormWrapper, { id: "mutationErrorMessages", key: "queryErrorMessages", formErrors: [{ message: declineRepairEstimateError.message }] })
                    : null,
                errorMessages ?
                    React.createElement(FormWrapper, { id: "errorMessages", key: "errorMessages", formErrors: errorMessages })
                    : null,
                React.createElement(AccidentAssignmentsSubmissionModal, { showModal: showEstimateModal, toggleShowModal: toggleShowEstimateModal, submissionCount: repairEstimate === null || repairEstimate === void 0 ? void 0 : repairEstimate.submissionNumber, repairEstimateId: repairEstimate === null || repairEstimate === void 0 ? void 0 : repairEstimate.id, viewRefetch: refetch, isSupplementSubmission: isSupplementalEstimate }),
                React.createElement(AccidentAssignmentsImageSubmissionModal, { showModal: showImageModal, toggleShowModal: toggleShowImageModal, submissionCount: repairEstimate === null || repairEstimate === void 0 ? void 0 : repairEstimate.submissionNumber, repairEstimateId: repairEstimate === null || repairEstimate === void 0 ? void 0 : repairEstimate.id, viewRefetch: refetch }),
                React.createElement(AccidentAssignmentsEtaModal, { showModal: showEtaModal, toggleShowModal: toggleShowEtaModal, submissionCount: repairEstimate.submissionNumber, repairEstimateId: repairEstimate.id, viewRefetch: refetch, estimatedCompletionDate: repairEstimate.currentEstimatedCompletionDate, etaDate: etaDate, setEtaDate: setEtaDate, etaNoteValue: etaNoteValue, setEtaNoteValue: setEtaNoteValue }),
                React.createElement(ConfirmMessageBox, { ref: declineClaimRef, title: messages.accident_assignments_decline_claim_confirmation_title, message: messages.accident_assignments_decline_claim_confirmation_message, okButtonText: messages.accident_assignments_decline_claim_confirmation_yes, cancelButtonText: messages.accident_assignments_decline_claim_confirmation_no, onOKClickEvent: onDeclineRepairAssignmentClicked, onCancelClickEvent: null }),
                getTitle(repairEstimate),
                React.createElement(Row, { className: "justify-content-center accident_assignments__claim_header" },
                    React.createElement(FormattedMessage, Object.assign({ values: { claimId: repairEstimate === null || repairEstimate === void 0 ? void 0 : repairEstimate.claimId } }, messages.accident_assignments_view__claim_header)),
                    React.createElement("a", { onClick: onHistoryDetailsClicked, id: "estimate_hist_link" },
                        React.createElement(FormattedMessage, Object.assign({}, messages.accident_assignments_history_details)))),
                React.createElement(Row, { className: "accident_assignments__vehicle_row" }, getVehicleInfo(repairEstimateData.repairEstimate.vehicle, repairEstimateData.repairEstimate.client, repairEstimateData.repairEstimate.enteredOdometer)),
                React.createElement("hr", { style: { margin: "0 3rem 0 3rem" } }),
                React.createElement(Row, { className: "justify-content-left accident_assignments__driver_info row" },
                    React.createElement(Col, null,
                        React.createElement(Row, { className: "accident_assignments__vehicle_info_label" },
                            React.createElement(FormattedMessage, Object.assign({}, messages.accident_assignments_driver_contact_title))),
                        React.createElement(Row, null,
                            React.createElement(Col, { md: 3, className: "accident_assignments__vehicle_info_label" },
                                React.createElement(FormattedMessage, Object.assign({}, messages.accident_assignments_driver_contact_caption))),
                            React.createElement(Col, { md: 9, className: "accident_assignments__val" }, (_a = repairEstimate.driverContact) === null || _a === void 0 ? void 0 : _a.name.toUpperCase())),
                        React.createElement(Row, null,
                            React.createElement(Col, { md: 3, className: "accident_assignments__vehicle_info_label" },
                                React.createElement(FormattedMessage, Object.assign({}, messages.accident_assignments_driver_phone_caption))),
                            React.createElement(Col, { md: 9, className: "accident_assignments__val" }, ((_b = repairEstimate.driverContact) === null || _b === void 0 ? void 0 : _b.phone) == 'UNAVAILABLE' ? intl.formatMessage(messages.accident_assignments_unavailable_caption) : (_c = repairEstimate.driverContact) === null || _c === void 0 ? void 0 : _c.phone)),
                        React.createElement(Row, null,
                            React.createElement(Col, { md: 3, className: "accident_assignments__vehicle_info_label" },
                                React.createElement(FormattedMessage, Object.assign({}, messages.accident_assignments_driver_email_caption))),
                            React.createElement(Col, { md: 9, className: "accident_assignments__val" }, (_d = repairEstimate.driverContact) === null || _d === void 0 ? void 0 : _d.email.toUpperCase())))),
                repairEstimate.estimateStatus === EstimateStatus.WaitingForEstimateAndImages ?
                    React.createElement(Row, { className: "accident_assignments__estimate_subsection" },
                        React.createElement(Row, { className: "justify-content-left accident_assignments__header" },
                            React.createElement(FormattedMessage, Object.assign({}, messages.accident_assignments_initial_submission_header))),
                        React.createElement(Row, { lg: 16, style: { margin: "0" } },
                            React.createElement(Row, { className: "justify-content-left accident_assignments__body_text" },
                                React.createElement(FormattedMessage, Object.assign({}, messages.accident_assignments_initial_submission_subheader_1))),
                            React.createElement(Row, { className: "justify-content-left accident_assignments__body_text" },
                                React.createElement(FormattedMessage, Object.assign({}, messages.accident_assignments_initial_submission_subheader_2)))),
                        React.createElement(Row, { className: "justify-content-end accident_assignments__submit_estimate" },
                            React.createElement("a", { onClick: (e) => { toggleShowEstimateModal(); }, id: "estimate_subm_btn" },
                                React.createElement(FormattedMessage, Object.assign({}, messages.accident_assignments_submit_initial_estimate)),
                                ' ',
                                React.createElement("img", { src: "/assets/VMS_33button_go_bblue.png" }))))
                    : null,
                repairEstimate.paymentStatus === PaymentStatus.PaymentNotRequested && (repairEstimate.estimateStatus === EstimateStatus.WaitingForHolmanApproval || repairEstimate.estimateStatus === EstimateStatus.Approved || repairEstimate.estimateStatus === EstimateStatus.WaitingForRepairEta) ?
                    React.createElement(Row, { className: "accident_assignments__estimate_subsection" },
                        React.createElement(Row, { className: "justify-content-left accident_assignments__header" },
                            React.createElement(FormattedMessage, Object.assign({}, messages.accident_assignments_additional_images_header))),
                        React.createElement(Row, { lg: 16, style: { margin: "0" } },
                            React.createElement(FormattedMessage, Object.assign({}, messages.accident_assignments_additional_images_subheader))),
                        React.createElement(Row, { className: "justify-content-end accident_assignments__submit_estimate" },
                            React.createElement("a", { onClick: (e) => { toggleShowImageModal(); }, id: "estimate_subm_btn" },
                                React.createElement(FormattedMessage, Object.assign({}, messages.accident_assignments_submit_additional_images)),
                                ' ',
                                React.createElement("img", { src: "/assets/VMS_33button_go_blue.png" }))))
                    : null,
                (repairEstimate.estimateStatus === EstimateStatus.Approved || repairEstimate.estimateStatus === EstimateStatus.WaitingForRepairEta) && repairEstimate.paymentStatus === PaymentStatus.PaymentNotRequested ?
                    React.createElement(Row, { className: "accident_assignments__estimate_subsection" },
                        React.createElement(Row, { className: "justify-content-left accident_assignments__header" },
                            React.createElement(FormattedMessage, Object.assign({}, messages.accident_assignments_eta_header))),
                        React.createElement(Row, { className: "justify-content-left accident_assignments__body_text" },
                            React.createElement(FormattedMessage, Object.assign({}, messages.accident_assignments_eta_subheader))),
                        React.createElement(Row, { className: "justify-content-end accident_assignments__submit_estimate" },
                            React.createElement("a", { onClick: (e) => { toggleShowEtaModal(); }, id: "eta_subm_btn" },
                                React.createElement(FormattedMessage, Object.assign({}, messages.accident_assignments_eta_header)),
                                ' ',
                                repairEstimate.estimateStatus === EstimateStatus.WaitingForRepairEta ? React.createElement("img", { src: "/assets/VMS_33button_go_bblue.png" }) : React.createElement("img", { src: "/assets/VMS_33button_go_blue.png" }))))
                    : null,
                repairEstimate.estimateStatus === EstimateStatus.Approved && repairEstimate.paymentStatus === PaymentStatus.PaymentNotRequested ?
                    React.createElement(Row, { className: "accident_assignments__estimate_subsection" },
                        React.createElement(Row, { className: "justify-content-left accident_assignments__header" },
                            React.createElement(FormattedMessage, Object.assign({}, messages.accident_assignments_supplement_header))),
                        React.createElement(Row, { className: "justify-content-left accident_assignments__body_text" },
                            React.createElement(FormattedMessage, Object.assign({}, messages.accident_assignments_supplement_subheader))),
                        React.createElement(Row, { className: "justify-content-end accident_assignments__submit_estimate" },
                            React.createElement("a", { onClick: (e) => { toggleShowEstimateModal(); }, id: "supp_estimate_subm_btn" },
                                React.createElement(FormattedMessage, Object.assign({}, messages.accident_assignments_supplement_button)),
                                ' ',
                                React.createElement("img", { src: "/assets/VMS_33button_go_blue.png" }))))
                    : null,
                (repairEstimate.estimateStatus == EstimateStatus.Approved || repairEstimate.estimateStatus == EstimateStatus.WaitingForRepairEta) && repairEstimate.paymentStatus === PaymentStatus.PaymentNotRequested ?
                    React.createElement(Row, { className: "accident_assignments__estimate_subsection" },
                        React.createElement(Row, { className: "justify-content-left accident_assignments__header" },
                            React.createElement(FormattedMessage, Object.assign({}, messages.accident_assignments_close_header))),
                        React.createElement(Row, { className: "justify-content-left accident_assignments__body_text" },
                            React.createElement(FormattedMessage, Object.assign({}, messages.accident_assignments_close_subheader))),
                        React.createElement(Row, { className: "justify-content-end accident_assignments__submit_estimate" }, repairEstimate.estimateStatus == EstimateStatus.WaitingForRepairEta
                            ? React.createElement(OverlayTrigger, { placement: "top", key: "closePO", overlay: React.createElement(Tooltip, { id: "tooltop" },
                                    React.createElement(FormattedMessage, Object.assign({}, messages.accident_assignments_close_po_disabled_message))) },
                                React.createElement("div", { className: "accident_assignments_disabled_link" },
                                    React.createElement(FormattedMessage, Object.assign({}, messages.accident_assignments_close_header)),
                                    ' ',
                                    React.createElement("img", { src: "/assets/VMS_33button_go_blue.png" })))
                            : React.createElement(Link, { id: "close_po_btn", to: (location) => (Object.assign(Object.assign({}, location), { state: { claimId: repairEstimate.claimId, estimateId: repairEstimate.id }, pathname: `/PO/close/${repairEstimate.orderId}` })) },
                                React.createElement(FormattedMessage, Object.assign({}, messages.accident_assignments_close_header)),
                                ' ',
                                React.createElement("img", { src: "/assets/VMS_33button_go_bblue.png" }))))
                    : null,
                repairEstimate.vendorClaimNotes.length > 0 ?
                    React.createElement(Row, { className: "accident_assignments__estimate_subsection", ref: historyDetails },
                        React.createElement(Row, { className: "justify-content-left accident_assignments__header" },
                            React.createElement(FormattedMessage, Object.assign({}, messages.accident_assignments_history_header))),
                        repairEstimate.vendorClaimNotes.map((log, i) => React.createElement(Row, { className: "justify-content-left", key: `log_${i + 1}` },
                            React.createElement(Col, { className: "accident_assignments__body_text" }, intl.formatDate(log.createdDate) + '-' + intl.formatTime(log.createdDate, { hour12: true }) + ' - ' + log.notes))),
                        repairEstimate.estimateDocuments.length > 0 ?
                            React.createElement(React.Fragment, null,
                                React.createElement(Row, { className: "justify-content-left accident_assignments__subheader", style: { paddingTop: "45px" } },
                                    React.createElement(FormattedMessage, Object.assign({}, messages.accident_assignments_history_subheader))),
                                repairEstimate.estimateDocuments.map((doc, i) => React.createElement(Row, { className: "justify-content-left", key: `document_${i + 1}` },
                                    React.createElement(Col, { className: "accident_assignments__body_text" },
                                        React.createElement("i", { className: "fa fa-file-pdf-o fa-lg", "aria-hidden": "true", id: `estimate_doc_${i + 1}_btn`, onClick: () => onRepairDocumentClicked(doc.documentUrl) }),
                                        " ",
                                        intl.formatDate(doc.createdDate) + '-' + intl.formatTime(doc.createdDate, { hour12: true }),
                                        " - ",
                                        doc.submissionNumber === 1 ? intl.formatMessage(messages.original_estimate).toLocaleUpperCase() : intl.formatMessage(messages.supplement, { submissionNumber: doc.submissionNumber - 1 }).toLocaleUpperCase()))))
                            :
                                null)
                    : null,
                repairEstimate.images.length > 0 && repairEstimate.submissionNumber != 0 ? getImages(repairEstimateData.repairEstimate.images) : null,
                repairEstimate.lineItems.length > 0 ? getPricing(repairEstimateData.repairEstimate.lineItems) : null,
                repairEstimate.estimateStatus === EstimateStatus.WaitingForEstimateAndImages ?
                    React.createElement(Row, { className: "justify-content-left accident_assignments__decline_assignment" },
                        React.createElement("a", { onClick: (e) => { toggleShowDeclineAssignmentConfirmation(); }, id: "decline_btn" },
                            React.createElement("img", { src: "/assets/VMS_28button_delete_blue.png" }),
                            ' ',
                            React.createElement(FormattedMessage, Object.assign({}, messages.accident_assignments_decline_assignment))))
                    : null)));
    //#endregion
};
// #endregion
export default (AccidentAssignmentsView);
