export default {
  "skip_the_phone": "Omita el teléfono: cree un OC",

  "INVOICE_NO_mandatory":"Por favor proporcione el número de factura",
  "INVOICE_NO_min_length":"El número de la factura debe tener al menos {maxLength} caracteres de largo",   
  "INVOICE_NO_max_length":"El número de la factura no debe ser más de {maxLength} caracteres",
  "INVOICE_NO_min_max_length":"El número de la factura debe estar entre {minLength} y {maxLength} caracteres de largo",
  "INVOICE_NO_exact_length":"The invoice number must have exactly {exactLength} characters long",

  "YOUR_NAME_mandatory":"Por favor ingrese su nombre",
  "YOUR_NAME_min_length":"Your name should be at least {minLength} characters long",   
  "YOUR_NAME_max_length":"Your name should not be more than {maxLength} characters",
  "YOUR_NAME_min_max_length":"Your name should be between {minLength} and {maxLength} characters long",
  "YOUR_NAME_exact_length":"Your name should be exactly {exactLength} characters long",

  "REPAIR_START_DATE_mandatory":"Por favor proporcione la fecha de inicio de la reparación",
  "REPAIR_COMPLETION_DATE_mandatory":"Por favor proporcione la fecha de finalización de la reparación",
  "INVOICE_DATE_mandatory":"Por favor proporcione la fecha de la factura",
  "ODOMETER_mandatory":"Por favor proporcione odómetro",
  "TAX_mandatory":"Please provide tax or tax exemption reason",
  "CONFIRM_WORK_IS_COMPLETE_mandatory":"Please confirm tha the work is complete",
  "CONFIRM_BANK_ACCOUNT_mandatory":"Please confirm the account number",


  "repair_start_date":"Repare la fecha de inicio",
  "search_for_po_orders_by_vin8":"Ingrese los últimos 8 caracteres del VIN",
  "search_for_po_orders_by_plate":"Ingrese el placa #",
  "search_for_po_orders_by_client":"Cliente #",
  "search_for_po_orders_by_vehicle":"No. de Unidad",
  "search_for_po_orders_by_state":"Estado",
  "vin8_error":"Por favor introduce un vin8",
  "plate_error":"Por favor ingrese la Placa #",
  "state_error":"Por favor selecciona un estado",
  "tag_error":"Por favor ingrese la Placa #",    
  "client_error":"Por favor ingrese su Cliente #",
  "vehicle_error":"Por favor ingrese su No. de Unidad",
  "client_vehicle_label":"Cliente # y No. de Unidad", 
  "help_link": "Ayuda",
  "contact_link": "Contacto",
  "search_vehicle_by_vin8": "Buscar vehículo",
  "your_account": "Su cuenta",
  "sign_out": "Cerrar sesión",
  "help_link_2": "Ayuda",
  "contact_link_2": "Contacto",
  "open_pos_link_2": "Ver todas las órdenes de compra de mantenimiento",
  "open_pos_link_header": "Ver pedidos",
  "close": "Cerrar",
  "ok": "OK",
  "session_expiring_caption": "Sesión a punto de expirar!",
  "session_expiring_note": "Su sesión expirará en 30 minutos, presione OK para permanecer conectado. Si cierra la sesión, los cambios en esta página no se guardarán",
  "login_id": "ID",
  "password": "Contraseña",
  "rememberMe": "Recordarme",
  "selectCountry": "Seleccione su país",
  "headerCaption": "Inicio de sesión de proveedor",
  "add_another_complaint": "Agregar otra queja",
  "add_line_items_for_complaint": "Agregar partidas para {complaintType}",
  "add_line_items_for_services": "Agregar partidas para Servicios pendientes",
  "add_edit_view_complaint_view_complaint_type": "Tipo de queja",
  "add_edit_view_complaint_view_complaint_caption": "Queja",
  "line_item_view_approved": "Partidas aprobadas",
  "line_item_view_pending": "Partidas pendientes",
  "line_item_view_rejected_items": "Partidas rechazadas",
  "line_item_view_quantity": "Cant",
  "line_item_view_hour": "Hora",
  "line_item_view_cost": "Costo",
  "line_item_view_amount": "Monto",
  "po_totals_view_subtotals": "Subtotal",
  "po_totals_view_approved": "Aprobada",
  "po_totals_view_pending_approval": "Aprobación pendiente",
  "approved_pos_caption": "Aprobada para servicio",
  "waiting_for_ari_approval_caption": "En espera de aprobación de Holman",
  "waiting_for_ari_approval_caption_mex": "En espera de aprobación de ARIZA",
  "waiting_for_client_approval_caption": "En espera de aprobación del cliente",
  "waiting_for_driver_validation_caption": "En espera de validación del conductor",
  "purchase_order_payment_in_progress": "Pago en curso",
  "your_action_required": "Se requiere su intervención",
  "edit_purchase_order": "Orden de compra No.",
  "edit_purchase_order_edit_mode": "Orden de compra No. {poNumber}",
  "close_purchase_order": "Cerrar orden de compra No.",
  "purchase_order_no": "Orden de compra No. {poNumber}",
  "approved_po_total": "Total aprobado:",
  "bank_account_no": "Últimos cuatro dígitos de número de cuenta bancaria: {bankAccountNo}",
  "close_dialog": "Cerrar",
  "add_line_item_to_po": "Agregar partidas a OC",
  "adjust_hours": "Horas",
  "adjust_rate": "Tarifa",
  "adjust_amount": "Monto",
  "pm_qty": "Cant.",
  "pm_cost": "Costo",
  "pm_amount": "Monto",
  "repair_hours": "Horas",
  "repair_rate": "Tarifa",
  "repair_amount": "Monto",
  "replace_hours": "Horas",
  "replace_rate": "Tarifa",
  "replace_qty": "Cant.",
  "replace_cost": "Costo de parte",
  "replace_amount": "Monto",
  "freq_used_ata_code": "O elija entre partes que se ingresan con frecuencia:",
  "save_and_add_caption": "Guardar y agregar partidas adicionales",
  "close_without_save": "Cerrar sin guardar",
  "save": "Guardar",
  "add_pm_caption": "Agregar servicios vencidos",
  "service_header_caption": "Servicio",
  "total_part_header_caption": "Partes",
  "total_labor_header_caption": "Mano de obra",
  "total_amount_header_caption": "Total",
  "parameter_caption": "Parámetros",
  "problem_with_close_po": "¿Algún problema? Edite la OC antes de cerrar",
  "total": "Total",
  "tax_required": "Marque aquí para verificar que esta OC no requiera impuestos",
  "invoice_number": "No. de factura",
  "invoice_date": "Fecha de la factura",
  "invoice_name": "Su nombre",
  "subtotal": "Subtotal",
  "repair_completion_date": "Fecha de terminación de reparación",
  "tax_exempted": "¿Exenta de impuestos?",
  "enter_tax": "Ingresar impuesto",
  "tax_exempted_yes": "Sí",
  "tax_exempted_no": "No",
  "payment_confirmation_text": "Confirme que el pago se acreditará a la cuenta que termina en {bankAccountNo}",
  "work_details_text": "Confirme que el trabajo que se detalla en la orden de compra esté completamente terminado",
  "no_tax_reason": "Motivo por el que es libre de impuestos",
  "no_tax_reason_repair_type": "El tipo de reparación está exenta de impuestos",
  "no_tax_reason_interstate": "Vehículo de comercio interestatal exento",
  "close_po_caption": "Solicitar pago de ${total} y cerrar OC",
  "error_invoice_no": "Ingrese un número de factura con más de 3 caracteres y elimine los espacios vacíos",
  "error_invoice_date": "Ingrese la fecha de la factura",
  "error_invoice_name": "Ingrese su nombre y apellido",
  "error_invoice_completion_date": "Ingrese la fecha de terminación de la reparación",
  "error_invoice_tax": "Ingrese el impuesto",
  "error_exempted_reason": "Proporcione motivo por el que está exento de impuestos",
  "error_payment_confirmation": "Confirme el número de cuenta",
  "error_work_details": "Confirme que el trabajo esté terminado",
  "page_title": "Cerrar orden de compra No.",
  "close_confirmation_title": "Ajustar OC",
  "close_confirmation_message": "¿Se requiere hacer ajustes en esta OC?",
  "close_confirmation_yes": "Sí",
  "close_confirmation_no": "No",    
  "add_complaint_caption": "Queja 1",
  "goto_next_link_caption": "Ir al siguiente paso",
  "add_services_caption": "Este vehículo tiene servicios pendientes",
  "add_services_caption_note": "SELECCIONAR PARA AGREGAR A LA ORDEN DE COMPRA",
  "schedule_header": "Calendario",
  "due_header": "Pendiente",
  "odometer_details_caption": "Actualización requerida",
  "odometer_details_odometer": "Odómetro",
  "odometer_details_last_reading": "Última lectura",
  "odometer_details_engine_hours": "Contador de horas",
  "odometer_details_select_one": "Seleccionar una opción",
  "odometer_details_at_shop": "El vehículo está en el taller",
  "odometer_details_not_at_shop": "El vehículo no está en el taller",
  "odometer_details_save_update": "Confirmar vehículo e ir al siguiente paso",
  "odometer_details_unavailable": "No disponibles",
  "ododmeter_Validation_Message_1": "Advertencia: la lectura ingresada del contador {newOdometer} difiere de la lectura estimada del contador por: {odometerDifference}",
  "ododmeter_Validation_Message_2": "Advertencia: la lectura ingresada del contador excede la lectura estimada del contador por: {newOdometer}.",
  "ododmeter_Validation_Message_3": "Advertencia: la lectura ingresada del contador {newOdometer} difiere de la lectura esperada del contador {estimatedOdometer} de {currentDate} por: {estimatedOdometerDiff}",
  "ododmeter_Validation_Message_4": "Advertencia: la lectura ingresada del contador {newOdometer} difiere de la lectura estimada del contador por:  {odometerDifference}",
  "ododmeter_Validation_Message_5": "Advertencia: Esta unidad está identificada como EQUIPO. La lectura del cuentakilómetros se copiará a la lectura del medidor de horas según las necesidades del cliente.",
  "hourmeter_Validation_Message": "Advertencia: la lectura del contador de horas no puede ser menor que el último valor registrado",
  "action_required_issue_note": "En caso de dudas respecto a estos ajustes, llame al \n1-866-274-1025 para hablar con un técnico de Holman",
  "action_required_issue_note_can": "En caso de dudas respecto a estos ajustes, llame al \n1-800-363-7676 para hablar con un técnico de Holman",
  'action_required_submitted_description':'Presentado',
  'action_required_title': "Se requiere acción de su parte",
  "need_more_notes_submit_description":" Presentar ",
  "action_required_note_from_ari_description":"Nota de Holman",
  "action_required_note_from_ari_description_client":"Nota de la clienta",
  "action_required_provide_note_description":"Por favor, proporcione su respuesta a continuación",
  "action_required_submit_note_all_rejected":"Esta orden de compra se cancelará una vez que revise y acepte las líneas rechazadas, y haga clic en Enviar",
  "action_required_submit_note_client_auth":"Esta orden de compra se enviará a la Autorización del cliente/Holman una vez que revise y acepte los cambios, y haga clic en Enviar",
  "action_required_submit_note_need_notes":"Esta orden de compra se enviará a Holman Approval una vez que revise y acepte los cambios, y haga clic en Enviar",
  "action_required_submit_note_po_approved":"Esta orden de compra se aprobará una vez que revise y acepte los cambios, y haga clic en enviar" ,
  "action_required_line_item_review":"Revisión ",
  "action_required_line_item_review_complete":"La revisión está completa",
  "action_required_line_item_review_pending":"Revisión pendiente",
  "action_required_line_status_need_notes":"Notas requeridas",
  "action_required_line_status_adjusted":"Equilibrado",
  "action_required_line_status_rejected":"Rechazado",
  "action_required_line_status_created":"Creado por Holman",   
  "action_required_po_submit":"Enviar",
  "action_required_line_item_view_note":"Revisa las siguientes líneas",   
  "action_required_po_edit": "Editar OC",
  "action_required_forward_for_further_review": "Adelante para revisión adicional",
  "adjusted_line_window_title":"Por favor revise",
  "need_more_notes_window_title":"Por favor revise",
  "rejected_line_window_title":"Por favor revise",
  "new_line_window_title":"Por favor revise", 
  "note_show_more_description":'mostrar más',
  "note_show_less_description":'mostrar menos',
  "complaint_notes_readonly_show_less":"...mostrar menos",
  "complaint_notes_readonly_show_more":"...mostrar más",  
  "repair_notes_show_less":"...mostrar menos",
  "repair_notes_show_more":"...mostrar más",
  "need_more_notes_vendor_note_error_message":"Por favor ingrese una respuesta válida",
  "adjusted_line_standard_ari_note_cost":"Hemos realizado el ajuste mencionado anteriormente para esta orden de compra.",
  "adjusted_line_standard_ari_note_labor":"Hemos realizado el ajuste mencionado anteriormente para esta orden de compra.",
  "approval_request_unsaved_changes_prompt":"Esta orden de compra tiene cambios no guardados. Haga clic en CANCELAR para permanecer en esta pantalla y revisar los cambios. Luego haga clic en Enviar en la parte inferior de la pantalla para enviar los cambios a Holman.",
  "need_more_notes_line_item_related_line_description":"La línea {associatedLineType} asociada no se aprobará hasta que proporcione más notas para esta línea.",
  "need_more_notes_line_item_status_description":"Holman necesita más información sobre {productDescription}",
  "need_more_notes_line_item_reviewed_description":"Has revisado esta línea",
  "new_line_item_created_by_ari":"Creado por Holman",
  "new_line_item_reviewed_description":"Has revisado esta línea",    
  "new_line_item_accept_description":" Aceptar ",    
  "new_line_item_status_description":"{productDescription} fue creado por Holman",
  "rejected_line_item_status_description":"{productDescription} fue rechazado por Holman",
  "rejected_line_item_status_description_client":"{productDescription} fue rechazado por la clienta",
  "rejected_line_payment_not_authorized_description":"NINGÚN PAGO SERÝ AUTORIZADO PARA ESTA LÝNEA",
  "rejected_line_item_accept_description":" Aceptar ",
  "rejected_line_item_reviewed_description":"Has revisado esta línea",
  "adjusted_line_item_reviewed_description":"Has revisado esta línea",    
  "adjusted_line_item_related_line_description":"La línea {associatedLineType} asociada no se aprobará hasta que acepte este cambio.",
  "adjusted_line_item_status_description":"{productDescription} fue ajustado por Holman",
  "adjusted_line_item_status_description_client":"{productDescription} fue ajustada por la clienta",
  "adjusted_line_item_accept_description":" Aceptar ",
  "adjusted_line_item_view_quantity": "Cant.",
  "adjusted_line_item_view_hour": "Hora",
  "adjusted_line_item_view_cost": "Costo",
  "adjusted_line_item_view_amount": "Monto",
  "adjusted_line_item_view_submitted": "Enviado",
  "adjusted_line_item_view_ari_adjustment": "Ajuste de Holman",
  "adjusted_line_item_view_ari_adjustment_client": "Ajuste del cliente",
  "adjusted_line_item_view_ari_created": "Creado por Holman",
  "adjusted_line_item_view_reject_intial_adjustment_message":"Rechazar ajuste y reenviar para revisión adicional",
  "reject_intial_adjustment_message": "Rechazar el ajuste y enviar para su revisión",
  "adjusted_line_item_view_accept_adjustments": "Aceptar el ajuste y continuar la reparación",
  "adjusted_line_item_view_accept_adjustments_client_auth": "Aceptar el ajuste para iniciar el proceso de autorización del cliente",
  "dri_val_complaint_title": "Queja",
  "dri_val_complaint_select": "Seleccionar tipos de quejas pendientes de validar por conductor",
  "complaint_view_complaint_caption": "Queja",
  "po_actions_diagnosis_view_caption": "Diagnóstico",
  "po_actions_line_item_view_quantity": "Cant.",
  "po_actions_line_item_view_hour": "Hora",
  "po_actions_line_item_view_cost": "Costo",
  "po_actions_line_item_view_amount": "Monto",
  "rejected_line_item_view_quantity": "Cant.",
  "rejected_line_item_view_hour": "Hora",
  "rejected_line_item_view_cost": "Tarifa",
  "rejected_line_item_view_amount": "Monto",
  "rejected_line_item_view_submitted": "Enviado",
  "rejected_line_item_view_acknowledge_lines": "Confirmar partida no autorizada {lineTotal, plural, one {item} other {items}} y continuar la reparación",
  "rejected_line_item_view_acknowledge_lines_client_auth": "Confirmar partida no autorizada {lineTotal, plural, one {item} other {items}} para iniciar el proceso de autorización del cliente",
  "rejected_line_item_view_acknowledge_lines_and_cancel": "Confirmar partida no autorizada {lineTotal, plural, one {item} other {items}} y cancelar la reparación",
  "rejected_line_item_view_no_payment_authorized": "No se autorizará ningún pago para {lineTotal, plural, one {this line item} otras {these line items}}",
  "authorization_status_reason": "Nota del técnico de Holman",
  "authorization_status_reason_client": "Nota del cliente",
  "rejected_line_item_view_no_payment_authorized_lines": "",
  "services_view_caption_2": "Mantenimiento",
  "adjustment_required": "Ajuste requerido",
  "not_authorized": "No autorizado",
  "adjust_reject_purchase_order_number": "Orden de compra No.",
  "shopview_approved_pos_caption": "Aprobada para servicio",
  "shopview_waiting_for_driver_validation_caption": "En espera de validación del conductor",
  "shopview_waiting_for_ari_approval_caption": "En espera de aprobación de Holman",
  "shopview_waiting_for_ari_approval_caption_mex":"En espera de aprobación de ARIZA",
  "shopview_waiting_for_client_approval_caption": "En espera de aprobación del cliente",
  "shopview_your_action_required": "Se requiere acción de su parte",
  "active_po_vehicle_column_header": "Vehículo",
  "active_po_vin_column_header": "VIN",
  "active_po_po_number_column_header": "No. OC",
  "active_po_po_total_amount_column_header": "Monto",
  "active_po_po_approved_amount_column_header": "Aprobada",
  "active_po_po_pending_amount_column_header": "Pendiente",
  "po_edit_link_caption": "EDITAR",
  "po_view_link_caption": "VER",
  "po_close_link_caption": "CERRAR",
  "po_reply_link_caption": "Respuesta",
  "today": "Hoy",
  "none": "Ninguna",
  "no_pos_caption": "Ninguna en este momento",
  "show_all_waiting_pos": "Mostrar todas las {poCount} órdenes de compra",
  "hide_older_pos": "Ocultar órdenes de compra anteriores",
  "payment_in_progress_caption": "Pago en curso",
  "payment_in_progress_vehicle_column_header": "Vehículo",
  "payment_in_progress_vin_column_header": "VIN",
  "payment_in_progress_po_number_column_header": "No. OC",
  "payment_in_progress_po_date_column_header": "Fecha OC",
  "payment_in_progress_po_total_amount_column_header": "Monto",
  "payment_in_progress_po_view_link_caption": "VER",
  "payment_in_progress_no_pos_caption": "Ninguna en este momento",
  "payment_in_progress_today": "Hoy",
  "shopview_no_pos_caption": "No hay órdenes de compra abiertas",
  "diagnosis_view_caption": "Diagnóstico",
  "diagnosis_view_caption1": "Solicitar aprobación para cargo de diagnóstico:",
  "diagnosis_view_caption2": "hora(s) en",
  "estimated_completion_description": "Plazo estimado para terminación de todas las reparaciones y servicios",
  "Estimated_Completion_Date_Morning": "Mañana",
  "Estimated_Completion_Date_Afternoon": "Tarde",
  "Estimated_Completion_Date_Evening": "Noche",
  "user_action_add_caption": "Solicitar aprobación",
  "user_action_view_caption": "Cerrar OC",
  "tax_edit_text": "Se puede agregar/editar el impuesto al cerrar la OC",
  "preventive_maintenance_caption": "Mantenimiento",
  "vehicle_details_vin_no": "VIN",
  "vehicle_details_client": "Cliente",
  "vehicle_details_ari_unit_no": "No. de Unidad Holman",
  "vehicle_details_engin_type": "Tipo de Motor",
  "vehicle_details_engin_hours": "Contador de horas",
  "vehicle_details_vehicle": "Vehículo",
  "vehicle_details_plate": "Placas",
  "vehicle_details_color": "Color",
  "vehicle_details_mileage": "Kilometraje",
  "vehicle_details_other": "Otros",
  "vehicle_details_documents": "Documentos del vehículo",
  "vehicle_details_parameters": "Documentos del cliente",
  "vehicle_details_po_number": "No. OC",
  "vehicle_details_unavailable": "No disponibles",
  "approved_items": "Partidas aprobadas",
  "line_items": "Partidas",
  "po_actions_po_totals_view_subtotals": "Subtotal",
  "po_actions_po_totals_view_tax": "Impuesto",
  "po_actions_po_totals_view_total": "Total",
  "po_actions_vehicle_details_client": "Cliente",
  "po_actions_vehicle_details_ari_unit_no": "No. de Unidad Holman",
  "po_actions_vehicle_details_vehicle": "Vehículo",
  "po_actions_vehicle_details_mileage": "Kilometraje",
  "estimated_completion_morning": "Mañana",
  "estimated_completion_afternoon": "Tarde",
  "estimated_completion_evening": "Noche",
  "estimated_completion_unavailable": "No disponible",
  "purchase_order_title_unavailable": "No disponible",
  "po_actions_vehicle_details_vin_no": "VIN",
  "close_po_submit_1": "Solicitar pago de ",
  "close_po_submit_2": " y cerrar OC",
  "user_action_cancel_po": "Cancelar OC",
  "cancel_po_confirmation_no": "No",
  "cancel_po_confirmation_yes": "Sí",
  "cancel_po_confirmation_title": "Cancelar OC",
  "cancel_po_confirmation_message": "Está a punto de anular esta orden de compra y eliminarla de Holman PartnerConnect. ¿Estás seguro de que quieres anular el pedido?",
  "add_line_items_for_maintenance": "Agregar partidas para Mantenimiento",
  "signed_out": "Has terminado la sesión correctamente",
  "user_action_edit_caption": "Cerrar OC",
  "search_po_caption": "Buscar por",
  "search_po_service_in_progress_caption": "Servicio en curso",
  "search_po_payment_in_progress_caption": "Pago en curso",
  "search_po_paid_caption": "Pagado",
  "search_po_po_number_column_header": "No. OC",
  "search_po_po_date_column_header": "Fecha OC",
  "search_po_po_status_column_header": "Estado",
  "search_po_invoice_no_column_header": "No. de factura",
  "search_po_po_payment_requested_column_header": "Pago solicitado",
  "search_po_po_payment_date_column_header": "Fecha de pago",
  "search_po_po_total_amount_column_header": "Monto",
  "search_po_none": "Ninguna",
  "search_po_po_view_link_caption": "VER",
  "search_po_today": "Hoy",
  "search_po_vin8_no_pos_caption": "Ninguna en este momento",
  "search_po_no_pos_caption": "Ninguna en este momento",
  "search_po_waiting_for_ari_approval": "En espera de aprobación de Holman",
  "search_po_waiting_for_ari_approval_mex": "En espera de aprobación de ARIZA",
  "search_po_waiting_for_client_approval": "En espera de aprobación del cliente",
  "search_po_waiting_for_vendor_approval": "En espera de aprobación del proveedor",
  "search_po_approved": "Aprobada",
  "close_po_confirmation_title": "Cerrar OC",
  "close_po_has_diagnosis_lines_only_message": "Las órdenes de compra no se pueden cerrar solo por diagnóstico. Corrija la (s) línea (s) para indicar las reparaciones realizadas y vuelva a enviarlas o llame al 1-866-274-1025 para obtener ayuda.",
  "close_po_confirmation_ok": "OK",
  "close_po__accident_claim": "Para reclamo {claimId}",
  "repair_hide_labor": "La mano de obra para {newProductDescription} debe incluirse en {existingProductDescription}",
  "adjust_hide_labor": "La mano de obra para {newProductDescription} debe incluirse en {existingProductDescription}",
  "replace_hide_labor": "La mano de obra para {newProductDescription} debe incluirse en {existingProductDescription}",
  "add_edit_view_confirmation_title": "Editar OC",
  "add_edit_view_confirmation_message": "Si está intentando editar la cantidad o el precio del artículo, elimine las partes y la mano de obra y vuelva a enviar los nuevos valores. ¿Estás seguro de que quieres eliminar este elemento?    ",
  "add_edit_view_po_confirmation_no": "No",
  "add_edit_view_confirmation_yes": "Sí",
  "complaint_code_MA99": "Mantenimiento",
  "complaint_code_RI99": "Insp. de Seguridad/Gob.",
  "complaint_code_TI99": "Llantas / Rines",
  "complaint_code_BR99": "Sistema de frenos",
  "complaint_code_LI99": "Luces / Cableado",
  "complaint_code_CH99": "Carga / Marcha",
  "complaint_code_EN99": "Sistema del motor",
  "complaint_code_DR99": "Facilidad de manejo / Sensores",
  "complaint_code_CO99": "Sistema de enfriamiento",
  "complaint_code_FU99": "Sistema de combustible",
  "complaint_code_ST99": "Sistema de dirección",
  "complaint_code_SU99": "Suspensión",
  "complaint_code_EX99": "Sistema de escape",
  "complaint_code_AC99": "Sistema de calefacción / AC",
  "complaint_code_UN99": "Otro problema del sistema",
  "complaint_error": "Agregue tipo de queja para continuar",
  "complaint_type_header": "SELECCIONE UN TIPO DE QUEJA",
  "complaint_type": "Tipo de queja",
  "change_complaint_type": "Cambiar tipo de queja",
  "add_complaint_type": "Agregar tipo de queja",
  "warranty": "Garantía:",
  "warranty_months": "Meses",
  "warranty_miles": "Kilómetros",
  "warranty_hours": "Horas",
  "contact_footer": " <a href='/contact/FeedbackForm'> Haga clic aquí </a>envíenos un correo electrónico con su pregunta y le garantizamos una respuesta rápida",
  "return": "Regresar",
  "help_title": "Ayuda",
  "documents_browse_button": "Buscar archivo",
  "documents_cancel_button": "Cancelar",
  "documents_hide": "Ocultar documentos",
  "documents_return_button": "Regresar",
  "documents_save_button": "Guardar nuevo documento",
  "documents_upload": "Cargar nuevo documento",
  "documents_header_name": "Nombre",
  "documents_header_upload_date": "Cargar fecha",
  "documents_header_document_type": "Tipo de documento",
  "documents_show_documents": "Mostrar documentos",
  "documents_title": "Documentos",
  "documents_title_for_vehicle": "Documentos para VIN {vin8}",
  "documents_file_name": "Nombre de archivo seleccionado",
  "documents_name": "Nombre",
  "documents_document_type": "Tipo de documento",
  "documents_date_received": "Fecha de recepción",
  "documents_name_required": "Ingrese un nombre",
  "documents_document_type_required": "Seleccione un tipo de documento",
  "documents_drag_drop": "Arrastre y suelte su archivo aquí o haga clic para explorar",
  "documents_none": "Ninguna en este momento",
  "help_caption": "Preguntas frecuentes",
  "faq_enrollment_title": "Inscripción/Registro",
  "faq_purchaseOrder_title": "Orden de Compra /Pago/Facturación",
  "faq_bank_title": "Cuentas bancarias",
  "faq_profile_title": "Información de perfil",
  "faq_troubleshoot_title": "Solución de problemas",
  "faq_general_operations_title": "Operación general del sistema",
  "faq_general_information_title": "Información general",
  "faq_enroll_question1": "¿Cómo puedo ser un Proveedor de Servicios de Holman?",
  "faq_enroll_answer1": "Simplemente haga clic en la palabra Inscribirse en la página de inicio. Este es un proceso de 2 partes. Recibirá una notificación por correo electrónico cuando sea aceptado. Luego puede mantener su cuenta en línea.",
  "faq_enroll_question54": "¿Cómo puedo ser un Proveedor de Servicios de Holman?",
  "faq_enroll_answer54":"Por favor, llamas a Holman en el número gratuito proporcionado",
  "faq_enroll_question2": "Cuando intento registrarme, me pide mi número de RFC",
  "faq_enroll_answer2": "Su número de RFC es de 9 dígitos",
  "faq_purchase_question3": "¿Qué significa orden de compra aprobada?",
  "faq_purchase_answer3": "Las Ordenes de Compra Aprobadas son reparaciones que han sido autorizadas y están pendientes de procesamiento de pago al finalizar las reparaciones.",
  "faq_purchase_question4": "¿Qué significa la 'Orden de Compra Procesada'?",
  "faq_purchase_answer4": "Las 'Ordenes de Compra Procesadas' (PO) son las órdenes de compra (POs) que se han cerrado para pago. Puede verificar el estado de estos pagamientos, seleccionando  la fecha procesada para la factura seleccionada.",
  "faq_purchase_question5": "¿Cómo encuentro actividad anterior en las páginas de Órdenes de Compra?",
  "faq_purchase_answer5": "Las páginas de Órdenes de Compra comienzan mostrando la actividad más reciente de su cuenta. Use la flecha de Mes Anterior para ver las órdenes de compra de fechas anteriores. Use la flecha de Mes Siguiente para ir a una fecha posterior. También puede seleccionar una fecha directamente de la lista desplegable. Solo las fechas que tienen actividad de cuenta están disponibles para su visualización.",
  "faq_purchase_question6": "¿Es posible encontrar una factura sin tener que navegar a través de los meses de actividad?",
  "faq_purchase_answer6": "Si conoce la Factura, Orden de Compra o Número de Pago, puede usar la página de Búsqueda de Facturas y la función de Buscar por Número. Introduzca el número correspondiente y oprima el botón Buscar. También puede seleccionar elementos escaneando la sección de Buscar por Agrupamiento de la página.",
  "faq_purchase_question7": "¿Puedo organizar los elementos en la página de Orden de Compra?",
  "faq_purchase_answer7": "Sí, seleccionando cualquier encabezado de la tabla de orden de compra, se ordenarán los elementos en esa columna. Si selecciona el mismo encabezado por segunda vez, se invertirá el orden de los elementos",
  "faq_purchase_question8": "¿Puedo ver información adicional de una orden de compra?",
  "faq_purchase_answer8": "Sí, puede seleccionar el número de OC de cualquier orden de compra. Esto mostrará las partidas detalladas de la OC. En la página Procesadas también puede seleccionar la fecha de Procesamiento. Esto mostrará los detalles de pago de ese elemento.",
  "faq_purchase_question9": "¿Por qué el monto de mi pago es diferente al monto que envié?",
  "faq_purchase_answer9": "Existe una comisión bancaria por gastos de manejo del 3% que se deduce del total de cada factura, independientemente de los servicios prestados. P.Ej.: ubicación fuera de las instalaciones (LOF), inspecciones, servicios, impuestos, etc.",
  "faq_purchase_question10": "¿Cómo agilizó mi llamada al centro de llamadas de ARIZA?",
  "faq_purchase_answer10": "1. ID de cliente y número de vehículo o últimos 8 dígitos del VIN. <br></br> 2. Kilometraje actual del vehículo. <br></br> 3. Capaz de explicar las reparaciones o servicios necesarios/sugeridos.  <br></br> 4. Precios desglosados por Partes/Mano de obra e Impuestos. <br></br>",
  "faq_bank_question11": "¿Qué sucede si no recibo mi correo electrónico de notificación de pago?",
  "faq_bank_answer11": "Inicie sesión en el sitio web y vaya a sus OCs procesadas, luego ubique la factura en cuestión y haga clic en .... Recibirá entonces la información del pago procesado en esa fecha. Tenga en cuenta que estas notificaciones no pueden ser reenviadas.",
  "faq_enroll_question12": "¿Cómo recibiré mi notificación de pago?",
  "faq_enroll_answer12": "Su correo electrónico de notificación de pago proviene de Fleet-Alert@holman.com. No se trata de correo no deseado o spam y no puede recrearse. Agregue esta dirección a su libreta de contactos, ya que estas alertas se envían de manera masiva y fácilmente se pueden confundir con correo no deseado (spam).",
  "faq_bank_question13": "¿Por qué no siempre recibo un correo electrónico cada vez que se acreditan fondos a mi cuenta de cheques?",
  "faq_bank_answer13": "Si usted no permite que nuestra dirección Fleet-Alert@holman.com acceda a su correo electrónico puede que se esté reconociendo como correo no deseado o spam. Usted puede siempre verificar los depósitos de la OC de un vehículo particular usando los últimos 8 dígitos del VIN en la sección de búsqueda de pie de página ('Buscar mis órdenes de compra por los últimos 8 caracteres de VIN').",
  "faq_bank_question14": "¿Por qué no puedo depositar a una cuenta de ahorros?",
  "faq_bank_answer14": "Por el momento sólo podemos aceptar una cuenta de cheques para transferencias a través de la Cámara de Compensación Automatizada (CCA). Dado que la gran mayoría de las compañías utilizan cuentas de cheques, esta fue la mejor opción que pudimos ofrecer a nuestros proveedores de servicios.",
  "faq_bank_question15": "La información de mi cuenta de cheques ha cambiado pero cerré OCs",
  "faq_bank_answer15": "Si el archivo contiene información de cuenta no válida, los pagos se retrasarán por al menos una semana. Vaya a Servicios a Afiliados y luego a Mantenimiento de cuenta de pago para actualizar la información de su cuenta de cheques, a menos que ARIZA ya se haya comunicado con usted para informarle del error.",
  "faq_bank_question16": "¿Puedo cambiar la información de mi cuenta de cheques en línea?",
  "faq_bank_answer16": "Sí, vaya a Servicios a Afiliados: Mantenimiento de cuenta de pago: Registre su nueva cuenta de cheques a ser utilizada para el pago futuro de servicios. Ingrese tanto su número de ruta bancaria como el número de cuenta",
  "faq_bank_question17": "Ya me inscribí y registré, pero me salté el paso en el que se agrega la información de mi cuenta de cheques",
  "faq_enroll_answer17": "Inicie sesión en su cuenta y vaya a Mantenimiento de Cuenta de Pago en Servicios a Afiliados y agregue la información de su cuenta de cheques, con esto completará la configuración de su cuenta",
  "faq_profile_question18": "¿Cómo puedo cambiar mi información de perfil?",
  "faq_profile_answer18": "En 'SU CUENTA', puede cambiar su contraseña, dirección de Email y perfil de servicios, actualizar la información de contacto, direcciones, etc.",
  "faq_profile_question19": "¿Qué sucede si olvido mi contraseña?",
  "faq_profile_answer19": "Vaya a iniciar sesión y haga clic en Enviarme correo electrónico. Se le pedirá que ingrese su número de RFC (# RFC) y su número de proveedor. Una vez que ingrese toda la información, haga clic en Enviarme correo electrónico y su contraseña le será enviada por correo electrónico a la dirección que tenemos registrada",
  "faq_profile_question20": "¿Cómo actualizo mi dirección de correo electrónico de Autorización de Cliente?",
  "faq_profile_answer20": "Inicie sesión en su cuenta con su número de proveedor y contraseña, vaya a Servicios a Afiliados en el lado izquierdo de la página y haga clic en Autorización de Cliente, tendrá que ingresar su contraseña nuevamente, luego ingrese la información actualizada y presione enviar",
  "faq_troubleshoot_question21": "Estoy usando un navegador más nuevo, pero no puedo registrarme",
  "faq_troubleshoot_answer21": "Hay dos posibilidades. Verifique si su navegador tiene la opción de Scripting habilitada. Esta es una opción de navegador requerida que le permite a su computadora procesar algunas características de páginas web. El resultado final es una interacción enriquecida y más rápida con la Web. Además, verifique que su navegador acepte cookies del navegador. Esta opción del navegador es necesaria para proporcionarle un diálogo personalizado",
  "faq_troubleshoot_question22": "¿Es usted una oficina corporativa con ubicaciones vinculadas que están intentando iniciar sesión en su cuenta?",
  "faq_troubleshoot_answer22": "Hemos realizado mejoras para que sus ubicaciones vinculadas puedan ahora mantener sus propios perfiles de servicio. Como resultado, agregamos un segundo nivel de protección con contraseña. Llame a Relaciones con Proveedores al #888-683-8327 y #1 para obtener ayuda. Lamentamos cualquier inconveniente.",
  "faq_troubleshoot_question23": "Necesito acceder a una cuenta existente",
  "faq_troubleshoot_answer23": "Para su seguridad, llame a Relaciones con Proveedores al 1-866-274-1025 #1 para verificar información específica de la cuenta. Se le proporcionará una contraseña temporal, si es necesario.",
  "faq_general_operations_question24": "¿Hay algún cargo o tarifa adicional por usar Holman Partner Connect?",
  "faq_general_operations_answer24": "NO, igual que nuestro Website anterior, no hay tarifas/cargos adicionales por el uso de Holman Partner Connect. Los servicios de Holman Partner Connect son un valor agregado par ti.",
  "faq_general_operations_question25": "¿Cada usuario en mi tienda necesita una contraseña individual?",
  "faq_general_operations_answer25": "NO, Holman Partner Connect usa un concepto de inicio de sesión único, que consiste en su identificación de usuario y contraseña actuales.",
  "faq_general_operations_question26": "¿Puedo comenzar a crear una orden de compra y enviarla en otro momento?",
  "faq_general_operations_answer26": "Sí, las OCs iniciadas y no enviadas para su aprobación aparecerán en la sección "+ ' "Se requiere acción de su parte" ' ,
  "faq_general_operations_question27": "¿Es posible hacer un cambio una vez que se envía una OC?",
  "faq_general_operations_answer27": "Sí, los usuarios pueden editar OCs aprobadas o pendientes, y volverlas a enviar para su aprobación.",
  "faq_general_operations_question28": "¿Puedo todavía procesar el pago de órdenes de compra abiertas autorizadas en la nueva?" +  '<divFAQ> <br></br> </divFAQ>',
  "faq_general_operations_answer28": "Sí, Holman PartnerConnect le permite procesar pagos en línea, además de ver el estado del pago",
  "faq_general_operations_question29": "¿Pude cambiar la información de mi tienda (correo electrónico, perfil de servicio) en Holman Partner Connect?" + '<divFAQ> <br></br> </divFAQ>',
  "faq_general_operations_answer29": "SÝ, Holman Partner Connect permitirá a los usuarios actualizar la información crítica de su cuenta, como los contactos de Email y del teléfono, servicios completados, etc. Esta infomation se puede encontrar presionando el boton 'SU CUENTA' en la parte superior de la página principal.",
  "faq_general_operations_question30": "¿Cómo me ayudará ARIZA a hacer crecer mi negocio?",
  "faq_general_operations_answer30": "Nuestro sitio Holman PartnerConnect mostrará los vehículos que se encuentran en su área de servicio y los clasificará en activos que necesitan servicio ahora, vehículos en los que usted trabajó anteriormente y en los que no lo ha hecho, como ejemplo",
  "faq_general_operations_question31": "¿ARI PartnerConnect está disponible en español?",
  "faq_general_operations_answer31": "general system operation answer 31",
  "faq_general_information_question31": "¿Qué es Holman Partner Connect?",
  "faq_general_information_answer31": "Holman Partner Connect es una systema nuevo de negocios bidireccional, diseñado para ahorrarle tiempo y dinero, al mismo tiempo proporcionándote una manera fácil de manejar la mayoria de las interacciones comerciales de Holman. Este nuevo sitio reemplazará nuestra Supplier Webpage ",
  "faq_general_information_question32": "¿Cómo me beneficiará Holman PartnerConnect?",
  "faq_general_information_answer32": "Holman PartnerConnect le permitirá crear órdenes de compra en línea, para la mayoría de las operaciones diarias comunes que normalmente se realizan en su taller. En la mayoría de los casos, usted puede omitir el teléfono creando una solicitud de orden de compra a través de Internet. Además de crear OCs en línea, nuestro nuevo sistema también le proporcionará el estado de la orden de compra de un vistazo, mientras que permite ediciones de la OC sobre la marcha Una vez que se hayan completado los trabajos en el vehículo, podrá revisar y cerrar rápida y fácilmente la orden de compra. Todas estas funciones están diseñadas para ahorrarle tiempo y aumentar la productividad, lo que se traduce en ahorros reales y tangibles a medida que transcurre su ajetreado día",
  "feedback_form_header": "Formulario de comentarios",
  "faq_footer": "Puede encontrar respuestas a sus preguntas e inquietudes en nuestra sección <a href='/contact/HelpFAQ'>Frequently Asked Questions.</a>",
  "thanks_title": "Muchas gracias",
  "thanks_sub": "Su mensaje ha sido enviado correctamente.",
  "suggestion_dropdown_question": "¿Qué tiene que decir?",
  "section_dropdown_question": "¿Para qué sección son tus comentarios?",
  "comment_title": "Comentario/Sugerencia",
  "criticism_title": "Crítica",
  "praise_title": "Felicitación",
  "question_title": "Pregunta",
  "problem_title": "Tuve un problema",
  "section_question_title": "Página web General",
  "create_po_title": "Crear OC",
  "close_po_title": "Cerrar OC",
  "edit_po_title": "Editar OC",
  "search_po_title": "Buscar OC",
  "po_dashboard_title": "Tablero de control de OC",
  "other_title": "Otros",
  "radio_title": "Proveedor potencial",
  "name_header": "Nombre",
  "company_header": "Companía (opcional)",
  "email_header": "Correo electrónico",
  "phone_header": "Teléfono",
  "ext_header": "Ext. (opcional)",
  "send_message_header": "Enviar un mensaje",
  "send": "Enviar",
  "mexican_active_address_header":"Boulevard Manuel Avila Camacho #685 <br></br> Fraccionamiento Industrial Alce Blanco <br></br>    Naucalpan México 53370 ",
  "active_address_header": "Sede de Holman Global <br></br> 4001 Leadenhall Road <br></br> Mount Laurel, NJ 08054",
  "car_care_description": "Si tiene problemas para visualizar, <br></br> Crear, Editar o <br></br> Cerrar órdenes de compra",
  "car_care_contact_number": "1-866-274-1025",
  "website_assistance_header": "Asistencia con el sitio web",
  "website_assistance_contact_number": "1-866-274-1025",
  "canadian_active_address_header": "Holman Financial Services Inc. <br></br> 1270 Central Parkway W. <br></br> Suite 600 <br></br> Mississauga, Ontario L5C 4P4 <br></br> 800-361-5882",
  "canadian_car_care_description": "Si tiene problemas para visualizar, <br></br> Crear, Editar o <br></br> Cerrar órdenes de compra",
  "canadian_car_care_contact_number": "1-866-274-1025",
  "canadian_website_assistance_header": "Asistencia con el sitio web",
  "canadian_website_assistance_contact_number": "1-866-416-6542",
  "shop_select": "Seleccione una opción",
  "parameters_header": "Parámetros",
  "parameters_header_services": "Reglas de servicio para vehículo de cliente",
  "empty_parameters": "Ninguna en este momento",
  "client_parameter_header": "Parámetros de cliente",
  "general_parameter_header": "Parámetros generales",
  "vin_parameter_header": "Parámetros para VIN",
  "repair_history_of_vehicle": "Historial de reparaciones",
  "client_parameters_of_vehicle": "Parámetros de cliente",
  "tax": "Impuesto",
  "pm_sheets": "Información de MP",
  "recent_service": "Servicio reciente",
  "line_item_view_date": "Fecha",
  "line_item_view_service": "Servicio",
  "line_item_view_odometer": "Odómetro",
  "line_item_view_supplier": "Proveedor",
  "line_view_link_caption": "VER",
  "add_no_services_caption": "Este vehículo no tiene servicios pendientes",
  "no_records_message": "No hay servicios recientes para este vehículo",
  "show_more": "Mostrar más",
  "learn_more_caption": "Más información",
  "payment_status_bulletin_title": "Información del estado del pago",
  "marketing_message": "Holman ha revolucionado la administración de flotas con tecnología que permite a organizaciones en todo el mundo alcanzar nuevos niveles de eficiencia y valor aprovechando el poder de los datos y el análisis",
  "driver_validation_text": "Esta orden de compra requiere validación del conductor. Notifique al conductor para que valide las reparaciones con ARIZA antes de realizar cualquier trabajo.",
  "line_items_error_message": "Agregue al menos una partida",
  "existing_po_link": "{po} ya existe. ¿Está seguro que desea continuar con una nueva OC {yes} | {no}?",
  "vehicle_search_message": "{vehicleSearchMessage}",
  "recover_password_text": "¿Olvidó su contraseña?",
  "email_notification_title": "Solicite su contraseña",
  "id_issued_ari_input": "ID emitido por Holman (obligatorio)",
  "email_me_button": "Envíenme un correo electrónico",
  "thank_you_recover_password_title": "Restablecer su contraseña",
  "thank_you_recover_password_subtext": "Si la información que proporcionó coincide con la información de la cuenta que tenemos registrada, le enviaremos un correo electrónico dentro de los próximos 10 minutos a la dirección que figura en el archivo con los detalles para restablecer su contraseña.",
  "error_message_recover_id": "El número de ID emitido por ARIZA no debe dejarse en blanco",
  "error_message_contact_name": "Por favor ingrese un nombre de contacto válido",
  "error_message_recover_identification": "Ingrese información del impuesto.",
  "error_message_incorrect_identification": "Número de ID no válido. Ingréselo conforme al siguiente formato:  (XXX-XX-XXXX) o (XX-XXXXXX)",
  "online_status__internet_down": "Parece que hay un problema con su conexión a Internet.",    
  "identification_ssn": "Últimos 4 dígitos de identificación EIN / contribuyente",
  "identification_ein": "Número de ID de Empleado",
  "return_login": "Regresar a la página principal",
  "driver_reference_number_title": "Su referencia#",
  "driver_reference_number_subTitle": "Factura#/RO#/WO#",
  "active_po_reference_number_column_header": "Su Ref.#",
  "odometer_details_optional": "(Si está equipado)",
  "po_requester_title": "Su nombre",
  "generic_error_message": "Ha ocurrido un error inesperado. Inténtelo de nuevo más tarde o haga clic en <a href='/contact/FeedbackForm'>here</a> para reportar un problema.",
  "generic_error_message_1": "Ha ocurrido un error inesperado. Inténtelo de nuevo más tarde o",
  "generic_error_message_2": " Haga clic aquí ",
  "generic_error_message_3": " para reportar un problema",
  "error_tax_amount": "OC excede el umbral de impuestos, llame al Centro de Atención al 1-866-274-1025",
  "add_new_line_item_caption": "Agregar partidas de {complaint_description}",
  "coming_soon_title": "Próximamente",
  "coming_soon__gamify_business_title": "Su negocio ARIZA",
  "coming_soon_gamify_performance_title": "Su rendimiento ARIZA",
  "coming_soon_setting_title": "Configuración",
  "coming_soon_business_growth_title": "Crecimiento empresarial",
  "contact_information_title": "Información de contacto",
  "vendor_business_phone_number": "Oficina",
  "vendor_extension_number": "Ext.",
  "vendor_extension_number_edit": "Ext. (opcional)",
  "vendor_fax_number": "Fax",
  "vendor_fax_number_edit": "Fax (opcional)",
  "settings_Cancel": "Cancelar",
  "save_contact_information": "Guardar configuración de información de contacto",
  "name_information_title": "Nombre de la empresa",
  "position_information_title": "Puesto",
  "settings_view_caption": "Configuración",
  "vendor_street_address_title": "Calle",
  "vendor_city_title": "Ciudad",
  "vendor_state_title": "Estado",
  "vendor_zip_title": "Código Postal",
  "changePassword_view_caption": "Contraseña",
  "changePassword_change_password": "Cambiar contraseña",
  "edit_contact_information": "Editar configuración de información de contacto",
  "vendor_name_error_message": "Ingrese nombre y apellido del contacto principal.",
  "vendor_street_address_error_message": "Ingrese la calle de su domicilio",
  "vendor_city_address_error_message": "Ingrese la ciudad de su domicilio",
  "vendor_zip_address_error_message": "Ingrese un código postal válido",
  "vendor_business_phone_error_message": "Formato de número de teléfono no válido. Ingréselo conforme al siguiente formato: (XXX-XXX-XXXX)",
  "vendor_fax_phone_error_message": "Formato de número de fax no válido. Ingréselo conforme al siguiente formato: (XXX-XXX-XXXX)",
  "vendor_street_address2_title": "Domicilio (renglón adicional)",
  "change_password_ari_id": "Número de ID emitido por ARIZA",
  "change_password_current": "Actual",
  "change_password_new": "Nuevo",
  "change_password_confirm_new": "Confirmar nuevo número",
  "changePassword_save_password": "Guardar contraseña",
  "change_password_ariId_error_mesg": "Ingrese el número de ID emitido por ARIZA",
  "change_password_new_pwd_error_mesg": "Ingrese la nueva contraseña",
  "change_password_ariId_error_mesg1": "El número de ID emitido por ARIZA es incorrecto",
  "change_password_current_pwd_error_mesg": "Ingrese la contraseña actual",
  "change_password_new_pwd_error_mesg1": "La nueva contraseña no puede ser la misma que la contraseña actual",
  "change_password_current_pwd_error_mesg1": "La contraseña actual es incorrecta",
  "change_password_confirm_pwd_error_mesg": "Confirme la nueva contraseña",
  "change_password_confirm_pwd_error_mesg1": "La contraseña no coincide",
  "password_successfully_changed": "Su contraseña ha sido modificada correctamente",
  "location_title": "Ubicación",
  "contact_title": "Nombre de contacto",
  "email_recipient_title": "Receptor de correo electrónico de pago",
  "account_view_caption": "Información de la cuenta", 
  "bank_account_caption": "Cuenta bancaria",
  "checking_account_caption": "Número de cuenta de cheques",
  "edit_account_information": "Editar información de cuenta bancaria",
  "account_submission_success": "Su actualización bancaria ha sido enviada para su procesamiento. Espere un día hábil completo para que el cambio bancario quede finalizado. Recuerde: para garantizar el pago de los servicios, verifique siempre su cuenta de pago al enviar facturación en línea",
  "tax_information_caption": "Información de impuestos",
  "federal_tax_caption": "Regimen fiscal federal",
  "ssn_caption": "No. de Seguro Social/Número de ID de Empleado",
  "checking_account_match_error_message": "Ambos números de cuenta deben ser idénticos. Vuelva a ingresar el número de cuenta de cheques",
  "checking_account_empty_error_message": "Ingrese número de cuenta de cheques",
  "routing_empty_error_message": "Ingrese un número válido de ruta y tránsito",
  "invalid_routing_error_message": "El primer dígito debe comenzar con 0,1,2 o 3. Número de ruta no válido",
  "vendor_email_recipient_error_message": "Formato de dirección de correo electrónico no válido",
  "edit_routing_caption": "Ingrese el Número de Ruta y Tránsito de 9 dígitos:",
  "edit_checking_caption": "Ingrese el número de cuenta de cheques: <br></br> (Do not include check number)",
  "re_enter_caption": "Vuelva a ingresar el número de cuenta de cheques:",
  "cancel__edit_caption": "Cancelar",
  "save_account_caption": "Guardar cambios en la información de la cuenta bancaria",
  "account_verify_caption": "Se requiere verificación de cuenta",
  "account_verify_sub_caption": "Todos los cambios en el registro de una cuenta bancaria requieren volver a ingresar su nombre y RFC. Espere un día hábil completo para que un cambio bancario quede concluido y verifique siempre su número de cuenta antes de enviar una factura en línea.",
  "contact_name_caption": "Nombre de contacto",
  "social_security_caption": "No. de Seguro Social",
  "employer_id_no_caption": "Número de ID de Empleado",
  "terms_caption": "Términos y Condiciones",
  "radio_button_agree_caption": "He leído y acepto los términos y condiciones arriba indicados",
  "cancel_verify_caption": "Cancelar",
  "continue_verify_caption": "Continuar",
  "terms_and_condition_error_message": "Acepte los términos y condiciones para continuar",
  "services_view_caption": "Servicios",
  "minority_vendor_caption": "Proveedor Minoritario",
  "save_services_caption": "Guardar cambios en los servicios",
  "services_Cancel": "Cancelar",
  "edit_services_caption": "Editar servicios",
  "vendor_account_confirmation_no": "No",
  "vendor_account_confirmation_yes": "Sí",
  "vendor_account_confirmation_title": "Cuenta de proveedor",
  "vendor_account_confirmation_message": "Esta página tiene cambios no guardados. Haga clic en CANCELAR para permanecer en esta pantalla.",
  "grow_business_caption": "Haga que su empresa crezca",
  "my_units_caption": "Consiga más negocios hoy",
  "total_vehicle_count_caption": "Total de vehículos ARIZA asignados a usted",
  "services_due_now_caption": "Vehículos ARIZA en los que ha trabajado anteriormente con servicios actualmente pendientes",
  "additional_services_due_count_caption": "Vehículos adicionales ARIZA en su área con servicios actualmente pendientes",
  "your_units_title": "Sus Unidades",
  "units_due_caption": "Clientes con vehículos ARIZA a los que les ha dado servicio en el pasado",
  "units_due_sub_caption": "Los Proveedores de Alto Rendimiento de ARIZA tienen visibilidad de los vehículos que están en sus áreas respectivas, y cuentan con herramientas para ayudar a administrar los servicios preventivos a esos activos. Esta página contiene información, como por ejemplo qué vehículos tienen inspecciones o servicios pendientes o vencidos. Holman proporciona esta información solo a nuestros Proveedores de Alto Rendimiento.",
  "my_units_service_due": "Servicios pendientes",
  "my_units_email_driver": "Correo electrónico del conductor",
  "my_units_client_name": "Nombre del cliente",
  "my_units_vehicle": "Vehículo",
  "my_units_vin8": "Número de VIN",
  "my_units_reject_unit": "Unidad rechazada",
  "other_units_caption": "Otras unidades",
  "confirm_reject_title": "¿Está rechazando la unidad?",
  "confirm_reject_message": "¿Está seguro que desea rechazar la unidad?",
  "confirm_reject_yes": "Sí",
  "confirm_reject_no": "No",
  "close_without_save_caption": "Cerrar",
  "due_services_caption": "Este vehículo tiene servicio pendiente",
  "create_po_caption": "Crear una OC",
  "service_caption": "Servicios",
  "schedule_caption": "Calendario",
  "due_caption": "Pendiente",
  "company_name_title": "Nombre de la compañía",
  "phone_title": "Teléfono",
  "ari_id_title": "Número de ID emitido por ARIZA",
  "search_by_title": "Buscar por:",
  "sign_in_caption1": "Registrado",
  "sign_in_caption2": "Iniciar sesión",
  "register_caption": "Regístrese",
  "linked_account_title": "Para acceder a su cuenta vinculada",
  "select_caption": "Seleccionar ubicación",
  "corporate_account_link": "Cuenta corporativa",
  "or_caption": "O",
  "other_units_due_sub_caption": "Oportunidades comerciales adicionales, estas unidades no han estado en su taller",
  "add_services_sub_caption": "El administrador de la flota o el conductor de este vehículo han sido notificados de este servicio pendiente.",
  "units_due_sub_caption_non_hp": "Los Proveedores de Alto Rendimiento de ARIZA tienen visibilidad de los vehículos que están en sus áreas respectivas, y cuentan con herramientas para ayudar a administrar los servicios preventivos a esos activos. Esta página contiene información, como por ejemplo qué vehículos tienen inspecciones o servicios pendientes o vencidos.",
  "faq_purchase_question100": "¿Por qué el monto de mi pago es diferente al monto que envié?",
  "faq_purchase_answer100": "Existe una comisión bancaria por gastos de manejo del 3% que se deduce del total de cada factura.",
  "faq_general_operations_question101": "¿Podré actualizar la información de mi taller (Datos bancarios, correo electrónico) en" + '<divFAQ> <br></br> </divFAQ>' +  "Holman PartnerConnect?",
  "faq_general_operations_answer101": "Sí, Holman PartnerConnect permitirá a los usuarios actualizar información crítica de la cuenta, como por ej., cuenta bancaria, teléfono y contactos de correo electrónico. Esto se puede encontrar haciendo clic en 'SU CUENTA' en la parte superior de la página principal.",
  "faq_general_information_question102": "¿Cómo me beneficiará Holman PartnerConnect?",
  "faq_general_information_answer102": "Holman PartnerConnect le permitirá crear órdenes de compra en línea para lavados y detallado, operaciones que normalmente se realizan en su taller. En la mayoría de los casos, usted puede omitir el teléfono creando una solicitud de orden de compra a través de Internet. Además de crear OCs en línea, nuestro nuevo sistema también le proporcionará el estado de la orden de compra de un vistazo, mientras que permite ediciones de la OC sobre la marcha Una vez que se hayan completado los trabajos en el vehículo, podrá revisar y cerrar rápida y fácilmente la orden de compra. Todas estas funciones están diseñadas para ahorrarle tiempo y aumentar la productividad, lo que se traduce en ahorros reales y tangibles a medida que transcurre su ajetreado día",
  "vehicle_search_error": "{vehicleSearchError}",
  "po_creation_error": "{poCreationError}",
  "vin8_label": "VIN8",
  "purchase_order_label": "Orden de Compra",
  "invoice_label": "Factura",
  "payment_label": "Pago",
  "search_duration": "Duración",
  "last_60_days": "Últimos 60 días",
  "last_120_days": "Últimos 120 días",
  "car_wash_title": "Seleccione el Cliente e ingrese el número de vehículo para crear una OC",
  "car_wash_plate": "Placa #",
  "car_wash_state": "Estado",
  "car_wash_or": "O",
  "car_wash_client_no": "Número de cliente",
  "car_wash_ari_unit_no": "No. de Unidad Holman",
  "client_number_error": "Seleccione un número de cliente",
  "ari_unit_no_error": "Ingrese su número de unidad ARIZA",
  "vendor_details_title_car_wash": "Servicio de lavado de automóvil",
  "payment_in_progress_total_amount_column_header": "Total",
  "payment_in_progress_plate_number_column_header": "No. de placa",
  "active_po_invoice_number_column_header": "No. de factura",
  "car_wash_service_date_label": "Fecha de servicio",
  "car_wash_invoice_no_label": "No. de factura",
  "car_wash_repair_selection": "Lavado",
  "detail_car_wash_repair_selection": "Lavado al Detalle",
  "car_wash_create_po": "Solicitar pago de",
  "total_info_label_car_wash": "Total",
  "tax_info_label_car_wash": "Impuesto",
  "search_po_payment_no_column_header": "Número de pago",
  "search_po_vehicle_column_header": "Vehículo",
  "vendor_details_title_request_no": "Solicitud de cortesía No. {requestNo}",
  "dealer_draft_confirm_payment": "Confirme que el pago se acreditará a la cuenta que termina en {checkingAccount}",
  "dealer_draft_add_edit_view_confirmation_title": "Borrar solicitud",
  "request_payment":"Request Payment ",
  "restore_request": "Restablecer solicitud",
  "vehicle_details_issue_date": "Fecha de emisión",
  "vehicle_details_status": "Estado",
  "optional_information_description": "Proporcione la siguiente información si cuenta con ella:",
  "optional_information_sub_description": " NOTA: No ingrese placa o registro temporales",
  "dealer_draft_delivery_date_description": "Fecha de entrega",
  "dealer_draft_license_plate_description": "Placa #",
  "plate_state_label": "Placa # y Estado",
  "dealer_draft_registration_date_description": "Vencimiento del registro",
  "dealer_draft_Registration_state_description": "Estado de registro",
  "dealer_draft_payment_accepted_error": "Confirme el pago antes de solicitar la aprobación.",
  "dealer_draft_delivery_date_invalid_error_label": "Proporcione una fecha anterior",
  "dealer_draft_reg_date_invalid_error_label": "Proporcione una fecha futura",
  "add_new_service_item_caption": "Agregar partidas de Servicio",
  "cost_details": "Costo",
  "request_no_subtotal_view": "Subtotal",
  "request_no_tax_rate_view": "Tasa de impuesto",
  "request_location_tax_rate_view":"Location",
  "show_all_waiting_requests": "Mostrar todas las solicitudes de {poCount}",
  "hide_older_requests": "Ocultar solicitudes anteriores",
  "dealer_draft_active_requests_title": "Solicitudes activas",
  "dealer_draft_active_requests_client_no": "Número de cliente",
  "dealer_draft_active_requests_vehicle_no": "Número de Unidad ARIZA",
  "dealer_draft_active_requests_requests_no": "Número de solicitud",
  "dealer_draft_active_requests_vin8": "Vin8",
  "dealer_draft_active_requests_no_requests_caption": "Ninguna en este momento",
  "quick_start": "Inicio rápido",
  "username_and_password": "Nombre de usuario y Contraseña",
  "forgot_password": "Olvidaste tu contraseña",
  "explanation_of_po_types": "Breve explicación de tipos de orden de compra",
  "your_action_is_required": "Se requiere acción de su parte",
  "approved_for_service": "Aprobada para servicio",
  "waiting_for_ari_client_approval": "En espera de aprobación de ARIZA/Cliente",
  "payment_in_progress": "Pago en curso",
  "search_for_my_pos": "Buscar mis Órdenes de Compra",
  "searching_for_vehicle": "Ingresar el número de serie de un vehículo y lo que arroja",
  "close_po": "Cerrar una Orden de Compra",
  "build_po": "Crear una Orden de Compra",
  "edit_po": "Editar una Orden de Compra",
  "help": "Ayuda",
  "contact": "Contacto",
  "cancel_po": "Cancelar una Orden de Compra",
  "search_tips": "Consejos de búsqueda",
  "aged_pos": "Órdenes de compra antiguas",
  "vehicle_information": "Información del vehículo, historial, parámetros, calendarios de MP",
  "old_browser_warning": "Esta función puede no funcionar correctamente ya que su navegador parece un poco desactualizado. Le recomendamos que actualice a la última versión de Chrome, Firefox o Internet Explorer.",
  "help_videos": "Videos Tutoriales",
  "service_classification": "Regimen comercial",
  "maintenance_general_classification": "Reparación y Mantenimiento General",
  "other_classification": "Lavado de Auto o al Detalle-Solamente",
  "corporate_vendor_details_title": "Cuenta Corporativa",
  "corporate_vendor_details_sub_title": "Para acceder a su cuenta corporativa principal",
  "corporate_vendor_login_text_2": `aquí`,
"corporate_vendor_forgot_pwd_text_1": `¿Olvidó su contraseña? `,
"corporate_vendor_forgot_pwd_text_2": "enviarme un correo electrónico",
"help_faq_return": "Regresar",
"supplier_enrollment_subheader":"Already a Holman Supplier? Regrese a la página principal para iniciar sesión con su ID y Contraseña ",
"not_enroll_text":"¿No es Socio ARIZA? ",
"register_here":"Regístrese aquí",
"waiting_for_client_caption":"En espera de aprobación del cliente",
"enroll_link":"Inscribirse",
"recently_enrolled":"¿Inscrito recientemente?",
"create_new_account":"Paso 1 de 5: Crear una nueva cuenta",
'notification_email':'Paso 5 de 5: Configuración del correo electrónico de autorización',
"ari_id":"Número de ID emitido por ARIZA",
"ssn":"Número de Seguro Social",
"employer_id":"Número de ID de Empleado",
"create_pwd":"Crear Contraseña",
"confirm_pwd":"Confirmar Contraseña",
"go_to_next_step":"Ir al siguiente paso",
"bank_info":"Paso 3 de 5: Información bancaria",
"routing_number":"Ingrese el Número de Ruta y Tránsito de 9 dígitos",
"checking_acc_number": "Ingrese el número de cuenta de cheques (No incluya el número de cheque)",
"re_enter_checking_acc_number":"Vuelva a ingresar el número de cuenta de cheques",
"payment_email":"Dirección de correo electrónico de pago",
"services":"Paso 4 de 5: Perfil de Servicios",
"note": "Marcar Registro indica su aceptación del programa de autorización.<br></br> Los correos electrónicos de notificación se enviarán a la siguiente dirección que proporcionó.<br></br> Además, también se le avisará inmediatamente sobre la notificación cuando inicie sesión en este sitio web.<br></br>",
"register":"Registrarse",
"onlineusage_caption": "Uso en línea",
"onlineusage_description": "Órdenes de Compra creadas en línea.",
"repairvalue_caption": "Precisión de la estimación",
"repairvalue_description": "Los costos de reparación se calculan con los precios publicados disponibles, así como ...",
"pmcompletion_caption": "Terminación de MP",
"pmcompletion_description": "Vehículos ARIZA asignados a usted con MP terminado a tiempo.",
"workretention_caption": "Retención de trabajo",
"workretention_description": "Vehículos ARIZA asignados a usted a los que se les retuvo trabajo.",
"auth_notification_title": "Configuración de correo electrónico de autorización",
"currentRegistrationStatus": "Estado de registro actual",
"notRegistered": "No registrado",
"edit_configuration_caption":"Editar configuración de correo electrónico de autorización",
"no_services_caption": "Ninguno",
"faq_footer1": "Puede encontrar respuestas a sus preguntas e inquietudes en ",
"faq_footer2":"Preguntas Frecuentes.",
"faq_title_help": "Ayuda",
"faq_caption_help":"Preguntas Frecuentes",
"contact_footer_1": "Haga clic aquí",
"contact_footer_2":" para enviarnos un correo electrónico con su pregunta y le garantizamos una respuesta rápida",
"contact_footer_3":'Si necesita ayuda con un idioma alternativo, contáctese con <br></br>uno de nuestros centros de llamadas y estaremos encantados de ayudarle. <br></br>En los EE.UU  - 800-227-2273 <br></br>En Canadá - 800-363-7676 <br></br>En México - 800-002-7492',
"registered":"Registrados",
"cancel":"Cancelar",
"save_contacts": "Guardar cambios en la configuración de información de contacto",
"header": "Solicitud de inscripción de proveedor",
"physical_address": "Domicilio físico",
"business_name": "Nombre de la compañía",
"address_1":"Domicilio, renglón 1",
"address_2":"Domicilio, renglón 2",
"city":"Ciudad",
"state":"Estado",
"zip":"Código Postal",
"country":"País",
"contact_information": "Información de contacto",
"name":"Nombre",
"phone":"Teléfono",
"ext":"Ext.",
"email":"Correo electrónico",
"reenter_email": "Reingresar correo electrónico",
"fax":"Fax (opcional)",
"referral_code": "Código de referencia",
"referral_code_note": "Si tiene un código de referencia, ingréselo aquí",
"submit":"Enviar",
"business_name_required": "Ingrese el nombre de la compañía",
"address1_required": "Introduzca 1er renglón del domicilio",
"address2_required": "Introduzca 2o renglón del domicilio",
"city_required": "Introduzca la Ciudad",
"state_required": "Seleccione un Estado",
"country_required":"Seleccione un País",
"contact_name_required":"Ingrese nombre de Contacto",
"phone_area_required":"Ingrese el Teléfono",
"phone_prefix_required":"Ingrese el Teléfono",
"phone_suffix_required":"Ingrese número de teléfono",
"email_required": "Introduzca una dirección de correo electrónico (ejemplo: email@dominio.com)",
"re_enter_email_required": "Vuelva a ingresar la dirección de correo electrónico correcta",
"supplier_enrollment":"Solicitud de inscripción de proveedor",
"terms_and_conditions":"Términos y Condiciones",
"all_officials_sub_heading": "Todas las instalaciones oficiales de servicio ARIZA:",
"read_and_agree":"He leído y acepto los términos y condiciones arriba indicados.",
'tc_initials' : 'Por favor ingrese las iniciales del nombre y apellido',
'sign_in' : 'Tus iniciales',
'accept_terms_and_condition': 'Por favor acepte Términos y Condiciones',
"accepted_by": "Aceptado por:",
"tax_information":"Información fiscal",
"legal_name":"Razón social",
"legal_name_note":"(como se detalla en los registros fiscales)",
"contact_name": "Nombre de contacto",
"business_dba_required": "Nombre comercial",
"tax_classification":"Regimen fiscal federal",
"client_referral": "Si uno de nuestros clientes fue el que lo remitió a ARIZA, proporcione su nombre",
"liability_tax_classification": "Ingrese el régimen tributario (C = Sociedad anónima C, S = Sociedad anónima S, P = Sociedad en comandita)",
"term_must_be_selected": "Lea y acepte los Términos y Condiciones",
"terms_accepted_by_required": "Se requieren las iniciales",
"tax_classification_required": "Favor de elegir un régimen fiscal federal",
"liability_tax_invalid_value": "Ingrese un valor válido para el Régimen de Responsabilidad Fiscal",
"ein_required": "Proporcione ya sea el número de Seguro Social o el número de ID del patrón",
//"legal_name_required": "Proporcione un valor para la Razón Social",
"legal_name_required": "Proporcione un valor para el Nombre de Contacto",
"approval_not_requested": "Aprobación no solicitada",
"approval_request_not_submitted": "Ha creado/actualizado una Orden de Compra pero nunca se solicitó aprobación para ella. Verifique si la reparación es necesaria y solicite aprobación. De lo contrario, cancele la OC",
"approval_request_or_cancel_po": "Solicite aprobación o cancele la OC",
"terms_conditions_text": "1) Autoriza a ARIZA a realizar pagos directamente a la cuenta bancaria que usted proporcionará <br/> 2) Acepta pagar la comisión bancaria por gastos de manejo como acordado con ARIZA durante su proceso de inscripción <br/> 3) Reconoce que la originación de todas las transacciones en la cuenta debe cumplir con las leyes de EE.UU. <br/> 4) **Informará a ARIZA de cualquier cambio, incluida la cuenta y ubicación de la empresa al menos 5 días hábiles antes del cambio**<br/> 5) Reconoce que ARIZA podrá reproducir una factura que haga constar transacciones conforme al presente acuerdo",
"quick_start_guide": "Haga clic aquí para obtener una guía rápida de capacitación en video",
"confirmation_for_detail_submission": 'Su número de solicitud de inscripción es {requestId}. <br></br><br></br> Su solicitud de inscripción se ha enviado para su procesamiento. Recibirá un correo electrónico con el estado de su inscripción dentro de tres y cinco días hábiles. <br></br><br></br> Si es aprobado, recibirá instrucciones por correo electrónico para completar el proceso de registro lo antes posible haciendo clic en el hipervínculo para regresar al Sitio Web de Proveedores y proporcionará la información de su cuenta de cheques. <br></br><br></br> Una vez aprobado, se le asignará un número de ID de Socio ARIZA permanente. Use su número de solicitud de inscripción temporal para cualquier correspondencia con ARIZA. <br></br><br></br>POR FAVOR, DIRIGA CUALQUIER SOLICITUD DE INSCRIPCIÓN O DOCUMENTOS SOLICITADOS A <a>enrollments@holman.com</a>.  <br></br><br></br> Gracias por enviar su solicitud de inscripción',
"thank_you":"Gracias",
"terms_info":"Paso 2 de 5: Términos y Condiciones de Holman Partner Connect",
"select_one": "Seleccione una opcion:",
"optOut": "Exclusión voluntaria",
"name_auth_email": "Nombre",
"email_auth":"Dirección de correo electrónico",
"add_new_contact":"Agregar nuevo contacto",
"termsHeader": "Términos y Condiciones Generales ("+'"Términos ARIZA")',
"search_for_po_orders_by_vin": "Buscar órden(es) de compra por VIN8 ...",
"search_for_po_orders_by_po": "Buscar órden(es) de compra por OC #...",
"search_for_po_orders_by_payment": "Buscar órden(es) de compra por Pago #...",
"search_for_po_orders_by_invoice": "Buscar órden(es) de compra por Factura #...",
"search_for_po_orders": "Buscar OC...",
"or_by_caption": "O por",
"new_work_assignment": "Nueva asignación de trabajo",
"model": "Modelo",
"search_zip": "Buscar Código Postal...",
"reason_for_repair": "Ingrese un motivo para la reparación",
"engine_hours": "Cronómetro (si está equipado)",
"odometer_caption": "Odómetro",
"view_all_courtesy": "Ver todas las Solicitudes de Cortesía abiertas",
"register_header": "Registro",
"contact_header":"Nombre de contacto",
"new_password_header": "Nueva contraseña",
"confirm_password_header":"Confirme la nueva contraseña",
"new_password_required":"Introduzca la nueva contraseña",
"re_enter_password_required":"Vuelva a introducir la contraseña correcta",
"tax__classification_I":"Empresa individual/Propietario único",
"tax__classification_C":"Sociedad Anónima tipo C",
"tax__classification_S":"Sociedad Anónima tipo S",
"tax__classification_P":"Sociedad en comandita",
"tax__classification_L":"Sociedad Anónima de Capital Variable",
"correctionTypes_REPLACE":"REEMPLAZAR",
"correctionTypes_REPAIR":"REPARAR",
"correctionTypes_ADJUST":"AJUSTAR",
"correctionTypes_PM":"MP",
"complaint_code_DG99"	 : 	 "Diagnóstico",
"complaint_code_CL99"	 : 	 "Problema con el clutch",
"complaint_code_FR99"	 : 	 "Problema con el chasis",
"complaint_code_GA99"	 : 	 "Problema con la instrumentación",
"complaint_code_GL99"	 : 	 "Problema con el cristal",
"complaint_code_HE99"	 : 	 "Problema con la calefacción",
"complaint_code_IN99"	 : 	 "Problema con el seguro",
"complaint_code_RA99"	 : 	 "Problema con el radio",
"complaint_code_TR99"	 : 	 "Problema con la transmisión",
"complaint_code_UP99"	 : 	 "Problema con equipo modificado",
"complaint_code_WI99"	 : 	 "Problema con el cableado",
"complaint_code_XX99"	 : 	 "Otros",
"complaint_code_SA01"	 : 	 "Impuesto Sobre las Ventas",
"complaint_code_RE01"	 : 	 "Renta",
"complaint_code_MA01"	 : 	 "MA01",
"complaint_code_1224"	 : 	 "Solicitud de soporte debido a error del conductor",
"complaint_code_1024"	 : 	 "Solicitud de soporte para un desglose mecánico",
"complaint_code_1324"	 : 	 "Solicitud de soporte debido a abuso del conductor",
"complaint_code_3124"	 : 	 "Accidente no reportado",
"complaint_code_3214"	 : 	 "Accidente reportado",
"complaint_code_3824"	 : 	 "Reparación en garantía",
"complaint_code_4224"	 : 	 "Solicitud de soporte debido a una reparación en garantía",
"complaint_code_3811"	 : 	 "Código de CD obsoleto de Fdx",
"complaint_code_3814"	 : 	 "Directiva de campaña",
"complaint_code_4114"	 : 	 "Mantenimiento de carrocería",
"complaint_code_RC01"	 : 	 "Problema en el camino",
"complaint_code_FL99"	 : 	 "Montacargas",
"complaint_code_HR99"	 : 	 "Hyrail",
"complaint_code_AL99"	 : 	 "Aéreo / Elevador",
"complaint_code_RE99"	 : 	 "Refrigeración",
"complaint_code_RC99"	 : 	 "Llamada de carretera",
"complaint_code_TL99"	 : 	 "Trailer (caja de remolque)",
"complaint_code_DL99"	 : 	 "Problema con la tracción",
"complaint_code_CA99"	 : 	 "Problema con la cabina",
"complaint_code_UN01"	 : 	 "No incluido",
"performance"	 : 	"Rendimiento",
"chart_legend"	 : 	 "Leyenda del gráfico",
"best_in_class"	 : 	 "La mejor en su clase",
"above_average"	 : 	 "Por encima de la media",
"avg"	 : 	"Promedio",
"needs_improvement": "Necesita mejorar",
"items_added": "Partidas agregadas a la OC:",
"web_assistance_header": "Para cuestiones relacionadas con su cuenta existente, incluidos pagos, u obtener ayuda con el sitio web PartnerConnect llame al <b>888-683-8327</b> y seleccione la opción #1",
"po_assistance_header": "Para obtener ayuda con una orden de compra existente o para comunicarse con un técnico del Centro de Atención de ARIZA, llame al <b>1-866-274-1025</b>",
"canadian_po_assistance_header": "Para obtener ayuda con una orden de compra existente o para comunicarse con un técnico del Centro de Atención de ARIZA, llame al <b>1-866-274-1025</b>",
"contact_form_header": "Para crear una cuenta de proveedor nueva, use el enlace que aparece en la página web principal",
"terms_and_conditions_subheader_login": "Estamos actualizando los términos de su cuenta",
"terms_and_conditions_header_login":"Términos y Condiciones",
"cancel_po_footer_message":"En caso de dudas respecto a estos ajustes, llame al <br/>1-866-274-1025 para hablar con un técnico de ARIZA",
"enrollement":"Inscripción",
"new_supplier_registration" : "Registro",
"my_purchase_order" : "Mis Órdenes de Compra",
"my_purchase_order_dynamic" : "Mis {ordersTypeText} durante los últimos {days} días",
"my_purchase_order_dynamic_search" : "Mis {ordersTypeText} de {searchText}",
"ododmeter_Validation_Message_6" : "Ingrese el valor del odómetro",
"tires_replaced" : "Haga clic en las llantas que vayan a ser reemplazadas",
"tires_tread" : "Profundidad del dibujo o banda de rodadura",
"inches_caption" : "Centímetros",
"unit_confirmation_box_title" : "Confirmar cambio de la unidad de medida",
"unit_confirmation_message_for_tires" : "Se aplicará cambio de unidad de medida a todas las llantas. Presione Ok si desea cambiarla a ",
"unit_confirmation_message_for_brakes" : "Se aplicará cambio de unidad de medida a todos los componentes de los frenos. Presione Ok si desea cambiarla a ",
"unit_confirmation_yes" : "Sí, cambiar la unidad de medida",
"brake_rotor" : "Se requieren medidas del rotor del freno",
"brake_drum" : "Se requieren medidas del tambor del freno",
"brake_lining" : "Se requiere espesor de la pastilla de freno",
"front_brakes" : "Delanteros",
"left_brakes" : "Izquierdos",
"right_brakes" : "Derechos",
"milli_meter":"milímetro",
"add_axle" : "Agregar eje",
"remove_axle" : "Retirar eje",
"measurements_in" : "Medidas en:",
"continue_link":"Continuar",
"spare_tire" : "LLanta de refacción",
"add_wheels" : "Agregar rines",
"remove_wheels" : "Retirar rines",
"car_wash_error_1" : "El odómetro no está dentro de nuestro rango de kilometraje esperado para este vehículo",
"car_wash_error_2" : "Seleccione una fecha de servicio.",
"car_wash_error_3" : "Ingrese un número de factura con más de 3 caracteres y elimine los espacios vacíos",
"car_wash_error_4" : "Ingrese el costo del Lavado de Automovil",
"car_wash_error_5" : "El artículo excede los costos de servicio permitidos, comuníquese con ARIZA al 800-227-2273",
"car_wash_error_6" : "Ingrese el costo del Lavado al Detalle",
"car_wash_error_7" : "El costo del Lavado al Detalle excede el precio máximo",
"car_wash_error_8" : "El impuesto excede el umbral de impuesto permitido",
"car_wash_error_9" : "El odómetro no está dentro de nuestro rango de kilometraje esperado para este vehículo",
"car_wash_error_10" : "El impuesto excede el umbral de impuesto permitido",
"unsaved_data" : "Tiene cambios no guardados que se perderán si decide continuar. Haga clic en Aceptar para continuar o en Cancelar para permanecer en la misma página.",
"car_wash_select" : "Seleccionar",
"car_wash_confirmation_message" : "Está a punto de anular esta orden de compra y eliminarla de Holman PartnerConnect. ¿Estás seguro de que quieres anular el pedido?",
"reason_T29" : "DESGASTADO",
"reason_T30" : "SOLICITUD DE CLIENTE",
"reason_T03" : "REVENTADO",
"reason_T04" : "COBRADO/SUSPENDIDO POR EL CLIENTE",
"reason_T31" : "DESGASTE IRREGULAR",
"reason_T13" : "PERFORADA",
"reason_T15" : "DAÑO LATERAL / CHIPOTE",
"reason_T21" : "DIBUJO NO COINCIDE",
"select_country" : "Seleccionar País",
"select_country_usa" : "EE.UU.",
"select_country_Mexico" : "México",
"select_state" : "Seleccionar Estado",
"select_providence" : "Seleccionar Provincia",
"new_tires" : "Detalles de llantas nuevas",
"wheel_tread_depth_new_tooltip":"Nueva profundidad de dibujo",
"wheel_tread_depth_old_tooltip":"Profundidad de la banda de rodadura antigua",
"tire_width_tooltip":"Ancho del neumático",
"tire_aspect_ratio_tooltip":"Relación de aspecto",
"tire_rim_diameter_tooltip":"Diámetro de la llanta",
"tire_manufacturer_tooltip":"Fabricante de neumáticos",
"tire_model_tooltip":"Modelo de neumático",
"tire_reason_for_replacement_tooltip":"Motivo del reemplazo",
"onlineUsage_month" : "Uso en línea %, por mes",
"repairValue_month" : "Valor de reparación %, por mes",
"pmCompletion_month" : "Terminación de MP %, por mes",
"workRetention_month" : "Retención de trabajo %, por mes",
"invalid_email_format" : "El formato del correo electrónico no es válido",
"terms_and_conditions_required" : "Acepte los Términos y Condiciones",
"routing_number_required" : "Ingrese número de Ruta",
"institute_id_required" : "Ingrese número de ID del Instituto de 3 dígitos",
"transit_id_required" : "Ingrese número de ID de Tránsito de 5 dígitos",
"check_number_required" : "Ingrese el número de cuenta de cheques",
"re_enter_check_number_rquired" : "Vuelva a ingresar el número de cuenta de cheques",
"check_numbers_should_match" : "Ambos números de cuenta deben ser idénticos. Vuelva a ingresar el número de cuenta de cheques",
"payment_email_required" : "Ingrese la dirección de correo electrónico de pago",
"invalid_routing_number" : "El primer dígito debe comenzar con 0,1,2 o 3",
"routing_number_should_be_9_digits" : "El número de ruta debe ser de 9 dígitos",
"atleast_one_email_required" : "Introduzca al menos un correo electrónico",
"name_email_required" : "Se requieren nombre y correo electrónico",
"invalid_is_registered" : "Regístrese para recibir correos electrónicos de notificación o  elija la exclusión voluntaria",
"ari_required" : "Ingrese el número de ID correcto proporcionado por ARIZA",
"tax_id_required" :	 "Ingrese ya sea el número de Seguro Social o el número de ID del patrón",
"password_required" : 	"Introduzca la contraseña",
"confirm_password_required" : "Confirme la contraseña",
"password_should_match" : "La contraseña no coincide",
"max_min_spec_not_met":"El valor ingresado excede las especificaciones min/max",
"max_min_spec_required":"Se requiere la especificación mín./máx.",
"front_axle_spec_required" : "Se debe llenar al menos un valor de eje delantero",
"rear_axle_spec_required" : "Se debe llenar al menos un valor de eje trasero",
"front_axle" : "Eje delantero",
"brake_title" : "Se requiere {brake_component} de frenos",
"rear_brakes" :	"Traseros",
"fee_message_national_account" : "El cargo por cuenta nacional se calculó al {feePercent}%.",
"fee_message_insurance_po_intellipay" : "Orden de compra del seguro, cargo por Intellipay se calculó al {feePercent}%.",
"fee_message_po_accessed_online" : "Orden de compra accesada, iniciada, editada o cerrada en línea, el cargo por Intellipay se calculó al {feePercent}%.",
"fee_message_po_not_accessed_online" : "Orden de compra no accesada, iniciada, editada o cerrada en línea, el cargo por Intellipay se calculó al {feePercent}%.",
"recover_too_attempts_title": "Restablecer su contraseña",
"recover_too_attempts_subtext": "Ha excedido el número de intentos exitosos de restablecer la contraseña que pueden completarse en una hora y su cuenta ha sido bloqueada.",
"recover_too_attempts_subtext_2": "Si tiene alguna pregunta, comuníquese con el equipo de Relaciones con proveedores de EE. UU. Al 1-888-683-8327, el equipo de Relaciones con proveedores de Canadá al 1-866-416-6542 o el servicio de atención al cliente de ARIZA al 01-800-00-27492.",
"po_assistance_header_anony":"Para obtener ayuda con un pedido de compra existente o para comunicarse con un técnico del centro de llamadas de Holman, llame a uno de los siguientes: <Row><div>EE.UU.<b>  1-866-274-1025</b></div> <div>Canadá<b>  1-800-363-7676</b></div> <div>México<b>  1-800-002-7492</b></div></Row>",
"po_assistance_header_anony_MEX": "Para obtener ayuda con un pedido de compra existente o para comunicarse con un técnico del centro de llamadas de Holman, llame al : 1-800-002-7492",
"old_supplier_redirect_caption":"Puede acceder al antiguo sitio del proveedor haciendo clic aquí.",
"feedback_required":"Por favor rellene este campo",
"return_button": "Regresar",
"login_button": "Iniciar sesión",
"recover_password_subtitle": "Además de uno de los siguientes:",
"identification_email": "Dirección de correo electrónico",
"businessNumber_last4digits":"Últimos 4 dígitos del número comercial",
"business_number_required":"Número de negocios inválido Por favor ingrese el siguiente formato: (XXXX)",
"invalid_payment_email_format": "El formato de correo electrónico de pago no es válido",
"error_message_incorrect_identification_last_4_digits": "Identificación invalida. Por favor ingrese el siguiente formato: (XXXX)",
"federal_tax_id_last4digits": "Últimos 4 dígitos de Federal Tax Id",
"federal_tax_id_required": "Id. De impuesto federal no válido Por favor ingrese el siguiente formato: (XXXX)",
"forgot_password_button": "Se te olvidó tu contraseña",
"reset_password_result_title": "Restablecer su contraseña",
"reset_password_result_subtext": "El enlace de restablecimiento de contraseña que ha seleccionado se ha utilizado o ha caducado. Para su seguridad, complete el proceso de restablecimiento de contraseña nuevamente.",
"reset_password_result_subtext_2": "Si necesita asistencia adicional, marque 01-800-00-27492.",
"reset_password_success_title": "Restablecer su contraseña",
"reset_password_success_subtext": "Su contraseña ha sido cambiada exitosamente. Puede iniciar sesión en Holman PartnerConnect ahora.",
"confirm_password_field_required": "Confirmar contraseña no puede vaciarse",
"confirm_password_field_does_not_match": "Confirmar que la contraseña no coincide",
"reset_password_title": "Restablecer su contraseña",
"password_field_required": "La contraseña debe cumplir con los criterios de complejidad a continuación",
"confirm_password_field": "Confirmar contraseña: ",
"reset_password_button": "Restablecer la contraseña",
"reset_password_home_button": "Casa" ,
"recover_password_or_seperator": "o",
"new_password_field": "Contraseña Nueva: ",
"mex_tax_id" : "ID de impuestos federales",
"contact_name_required_details":"Por favor, proporcione un Nombre de Contacto",
"corporate_vendor_login_text_1":"Registrarse? De clic en",
"redirect_sso_user":"Redirigiendo al tablero ...",
"signout_sso_user":"Se ha desconectado de Holman PartnerConnect. Ahora es seguro cerrar su navegador",
"zip_required":"Por favor, introduzca el código postal de 5 o 9 dígitos",
"adjusted_line_item_view_reject_intial_adjustment_message":"Rechazar el ajuste y reenviar a una revisión adicional",
"all_dropdown":"Todas",
"ari_standard_rate":"Tarifa Holman estándar (requerido)",
"body_shop_rate":"Tarifa de Hojalatería y Pintura",
"businessNumber":"Número de negocio",
"businessNumber_required":"Por favor, proporcione un valor para el número de negocio",
"car_wash_confirmation_no":"No",
"car_wash_confirmation_title":"Cancelar solicitud",
"car_wash_confirmation_yes":"Sí",
"check_number_required_canada":"Por favor, introduzca el número de cuenta bancaria",
"checking_acc_no_canada":"Número de cuenta de la institución bancaria",
"close_po__non_intellipay_vendor":"Está PO no puede ser procesada en línea. Por favor, envíe está factura para el pago.",
"confirmation_for_request_submission":"Gracias por tomarse el tiempo para ponerse en contacto . Por el momento no estamos aceptando nuevas solicitudes de proveedores en este momento. Gracias por llenar su información. Su solicitud quedará en la lista de espera en caso de surgir la necesidad de un centro de reparación en su área.",
"continue_to_brake_spec":"Continuar",
"continue_to_tire_spec":"Continuar",
"correction_labor_subTitle":"(SOLAMENTE MANO DE OBRA)",
"correction_parts_labor_subTitle":"(Piezas y / o mano de obra)",
"correction_pm_subTitle":"(MENÚ precio de artículos)",
"custom_error_message":"No se encontraron órdenes de compra que cumplan este criterio de búsqueda.",
"dealer_draft_processed_requests_title":"Solicitudes procesadas",
"dealer_submitted_request_error":"Esto no es una solicitud de cortesía válida",
"dealerdraft_submit_label":"Solicitud de cortesía",
"contact_customer_directly": "Esta orden de compra es para un cliente de Holman Small Business Solutions. Para esta transacción, tratará directamente con el cliente en lugar de Holman. Si tiene alguna pregunta sobre esta orden de compra, llame al cliente directamente al número proporcionado una vez que se haya creado la orden de compra.",
"dot_inspection__attest_required":"Por favor, dar fe de esta afirmación",
"dot_inspection__edit_form":"Ver / Editar la forma DOT de inspección de vehículos",
"dot_inspection__fail":"Fallo",
"dot_inspection__has_inspection_passed_components_invalid":"Todos los componentes de los vehículos tienen que ser marcados como OK o tener una fecha reparación para aprobar la inspección",
"dot_inspection__has_inspection_passed_no_failure_specified":"Especifique los componentes con fallos",
"dot_inspection__has_inspection_passed_required":"Por favor, seleccione APROBADO o CON FALLO",
"dot_inspection__has_inspection_passed_unsafe_conditions":"La inspección no puede ser aprobada con condiciones inseguras enlistadas.",
"dot_inspection__inspector_attest":"Doy fe de que estoy calificado para llevar a cabo una inspección del DOT y que este vehículo ha pasado todos los elementos para la inspección anual de vehículos de acuerdo con 49 CFR Parte 396.",
"dot_inspection__inspector_name_required":"Por favor, introduzca Nombre de los inspectores",
"dot_inspection__inspector_qualified_required":"El inspector debe estar calificado para marcar la inspección como aprobada",
"dot_inspection__inspector_signature":"firma de los inspectores",
"dot_inspection__pass":"APROBAR",
"dot_inspection__inspection_date":"Fecha de inspección",
"dot_inspection__inspection_date_invalid":"Fecha no válida / formateada incorrectamente, ingrese una fecha válida",
"dot_inspection__inspection_date_disabled":"Por favor introduzca una fecha valida", 
"dot_inspection__save_continue":"Guardar y continuar",
"dot_inspection__signature_required":"Por favor, introduzca su firma",
"dot_inspection__title":"inspección anual de vehículos DOT",
"dot_inspection_confirmation__fail_confirm_no":"No y especificar los componentes con fallos antes de continuar",
"dot_inspection_confirmation__fail_confirm_question":"¿Se van a reparar los componentes del vehículo con fallos en esta factura?",
"dot_inspection_confirmation__fail_confirm_title":"PO de la reparación?",
"dot_inspection_confirmation__fail_confirm_yes":"Sí y editar PO",
"dot_inspection_form__all_ok":"Todo bien",
"dot_inspection_form__date":"Fecha",
"dot_inspection_form__errors":"Por favor, corrija los errores que se muestran abajo.",
"dot_inspection_form__fail_confirm_no":"No y continuar",
"dot_inspection_form__fail_confirm_question":"¿Se van a reparar los componentes del vehículo con fallos en esta factura?",
"dot_inspection_form__fail_confirm_title":"Reparar PO?",
"dot_inspection_form__fail_confirm_yes":"Sí y editar PO",
"dot_inspection_form__fleet_unit_number":"Número de vehículo",
"dot_inspection_form__inspector_name":"nombre de los inspectores",
"dot_inspection_form__inspector_qualified_statement":"Este inspector cumple con los requisitos de calificación en la sección 396.19.",
"dot_inspection_form__inspector_qualified_yes":"SÝ",
"dot_inspection_form__motor_carrier":"Operador del autotrasnporte",
"dot_inspection_form__motor_carrier_address":"Dirección, ciudad, estado, código postal",
"dot_inspection_form__needs_repair":"Necesita <br></br> reparación",
"dot_inspection_form__ok":"DE ACUERDO",
"dot_inspection_form__other":"Otro",
"dot_inspection_form__other_failure_reasons":"Enliste cualquier otra condición que pueda evitar el funcionamiento seguro de este vehículo.",
"dot_inspection_form__repaired_date":"Fecha  <br></br> reparación",
"dot_inspection_form__repairing_vendor":"Proveedor que reparó",
"dot_inspection_form__report_number":"Numero de reporte",
"dot_inspection_form__save":"Guardar y continuar",
"dot_inspection_form__title":"formulario de inspección anual de vehículos DOT",
"dot_inspection_form__tractor":"Tractor",
"dot_inspection_form__trailer":"Remolque",
"dot_inspection_form__truck":"Camión",
"dot_inspection_form__vehicle_component_1":"a. Servicios de Frenos",
"dot_inspection_form__vehicle_component_10":"j. Frenos hidráulicos",
"dot_inspection_form__vehicle_component_11":"k. Sistemas de vacío",
"dot_inspection_form__vehicle_component_12":"a. Quintas Ruedas",
"dot_inspection_form__vehicle_component_13":"b. Ganchos Tipo Muela",
"dot_inspection_form__vehicle_component_14":"c. La barra de tracción / barra de remolque de ojos",
"dot_inspection_form__vehicle_component_15":"d. Barra de enganche / barra de remolque",
"dot_inspection_form__vehicle_component_16":"e. Dispositivos de seguridad",
"dot_inspection_form__vehicle_component_17":"f. Monturas de silla",
"dot_inspection_form__vehicle_component_18":"a. Cualquier sistema de escape con fugas en un punto delantero o directamente debajo del compartimiento de descanso del conductor",
"dot_inspection_form__vehicle_component_19":"b. Sistema de escape del autobús o descarga a la atmósfera en violación de las normas (1) (2) o (3).",
"dot_inspection_form__vehicle_component_2":"b. Sistema de frenos de estacionamiento",
"dot_inspection_form__vehicle_component_20":"c. Ninguna parte del sistema de escape de cualquier vehículo de motor deberá estar situado de modo que pueda ser probable de dañar, quemar o carbonizar el cableado eléctrico del suministro de combustible o cualquier parte del sistema de combustible del vehículo.",
"dot_inspection_form__vehicle_component_21":"a. fugas visibles",
"dot_inspection_form__vehicle_component_22":"b. Falta tapón del depósito de combustible",
"dot_inspection_form__vehicle_component_23":"c. Depósito de combustible suejetado seguramente.",
"dot_inspection_form__vehicle_component_24":"Deberán ser operables todos los dispositivos de iluminación y reflectores requeridos por la Sección 393.",
"dot_inspection_form__vehicle_component_25":"a. La(s) parte(s) del vehículo o la condición de carga de tal manera que la rueda de repuesto o cualquier parte de la carga o material de relleno pueden caer sobre la calzada.",
"dot_inspection_form__vehicle_component_26":"b. Protección contra desplazamiento de la carga",
"dot_inspection_form__vehicle_component_27":"a. Libre manejo del volante",
"dot_inspection_form__vehicle_component_28":"b. Columna de dirección",
"dot_inspection_form__vehicle_component_29":"c. Eje delantero y y todos los componentes de la dirección así como la columna de dirección",
"dot_inspection_form__vehicle_component_3":"c. Rotores o Tambores de Frenos",
"dot_inspection_form__vehicle_component_30":"d. Caja de dirección",
"dot_inspection_form__vehicle_component_31":"e. Brazo Pitman",
"dot_inspection_form__vehicle_component_32":"F. Caja dirección asistida",
"dot_inspection_form__vehicle_component_33":"g. Articulaciones de rótula/Terminal de direcicón",
"dot_inspection_form__vehicle_component_34":"h. Tirantes y barras de acoplamiento,",
"dot_inspection_form__vehicle_component_35":"i. Tuercas",
"dot_inspection_form__vehicle_component_36":"j. Sistema de dirección",
"dot_inspection_form__vehicle_component_37":"a. Cualquier perno de suspensión, de resorte tipo U o bien,  otra(s) parte(s) de posicionamiento del eje que esté agrietado, roto, suelto o faltante que resulte en el desplazamiento de un eje de su posición normal.",
"dot_inspection_form__vehicle_component_38":"b. Conjunto de resortes/muelles",
"dot_inspection_form__vehicle_component_39":"c. Radio de torque o componentes de seguimiento.",
"dot_inspection_form__vehicle_component_4":"d. Manguera de freno",
"dot_inspection_form__vehicle_component_40":"a. Componentes de Armazón",
"dot_inspection_form__vehicle_component_41":"b. Neumático y libramiento de rueda",
"dot_inspection_form__vehicle_component_42":"c. Conjuntos de eje ajustable (Subbastidores Deslizantes)",
"dot_inspection_form__vehicle_component_43":"a. Neumáticos en cualquier eje de dirección de una unidad de potencia.",
"dot_inspection_form__vehicle_component_44":"b. Todos los otros neumáticos.",
"dot_inspection_form__vehicle_component_45":"a. Chapa o Anillo Lateral",
"dot_inspection_form__vehicle_component_46":"b. Rin y Llanta",
"dot_inspection_form__vehicle_component_47":"c. Sujetadores",
"dot_inspection_form__vehicle_component_48":"d. Soldaduras",
"dot_inspection_form__vehicle_component_49":"Requisitos y excepciones como se indican en relación  a cualquier decoloración por cuarteadura, o elemento que reduzca la visión (393.60 referencia para las excepciones)",
"dot_inspection_form__vehicle_component_5":"e. Mangueras de frenos",
"dot_inspection_form__vehicle_component_50":"Cualquier componente de energía que tiene un limpiador que no funciona o partes faltantes o dañados que provoquen un mal funcionamiento.",
"dot_inspection_form__vehicle_component_6":"F. Dispositivo de aviso de presión baja",
"dot_inspection_form__vehicle_component_7":"g. Válvula de protección del tractor",
"dot_inspection_form__vehicle_component_8":"h. Compresor de aire",
"dot_inspection_form__vehicle_component_9":"i. Frenos eléctricos",
"dot_inspection_form__vehicle_components_header_1":"1. Sistema de frenos",
"dot_inspection_form__vehicle_components_header_10":"10. Neumáticos",
"dot_inspection_form__vehicle_components_header_11":"11. Llantas y ruedas",
"dot_inspection_form__vehicle_components_header_12":"12. vidrio de Parabrisas",
"dot_inspection_form__vehicle_components_header_13":"13. Limpiaparabrisas",
"dot_inspection_form__vehicle_components_header_2":"2. Dispositivos de acoplamiento",
"dot_inspection_form__vehicle_components_header_3":"3. Sistema de escape",
"dot_inspection_form__vehicle_components_header_4":"4. Sistema de Combustible",
"dot_inspection_form__vehicle_components_header_5":"5. Sistema de Luces",
"dot_inspection_form__vehicle_components_header_6":"6. Carga segura",
"dot_inspection_form__vehicle_components_header_7":"7. Mecánismo de Dirección",
"dot_inspection_form__vehicle_components_header_8":"8. Suspensión",
"dot_inspection_form__vehicle_components_header_9":"9. Marco",
"dot_inspection_form__vehicle_components_title":"componentes del vehículo inspeccionados",
"dot_inspection_form__vehicle_history":"Historial del vehículo",
"dot_inspection_form__vehicle_identification":"Identificación del vehículo",
"dot_inspection_form__vehicle_type":"Tipo de vehiculo",
"dot_inspection_form__vendor_address":"Dirección, ciudad, estado, código postal",
"edit_routing_caption_canada":"Introducir ID del instituto seguido del ID de Tránsito:",
"employer_id_no_caption_CAN":"Número de negocio",
"equipment_add_rate":"Tarifa de Equipamiento/Adicionales",
"error_invoice_start_date":"Por favor, introduzca la Fecha de inicio de reparación.",
"error_invoice_tax_canada":"Impuestos sólo se aplica a {applicationTaxType} favor de corregir.",
"error_message_incorrect_identification_canada":"Identificación invalida. Por favor, introduzca con siguiente formato: (XX-XXXXXX)",
"exceptions_Base_Intellipay_Fee":"Tarifa base de pago a Holman",
"exceptions_Canada":"Canadá",
"exceptions_Escalator":"Escalera mecánica para uso diferente a PartnerConnect",
"exceptions_gov_canada":"Gobierno Federal de Canadá (RCMP)",
"exceptions_intellipay_3":"3% comisión de pago a Holman",
"exceptions_intellipay_4":"4% comisión de pago a Holman",
"exceptions_Name":"Nombre",
"exceptions_no_escalator":"sin escaleras mecánicas",
"exceptions_Other_Notes":"Otras notas",
"exceptions_other_notes1":"Permitir la flexibilidad de pago existente",
"exceptions_other_notes2":"Puede obligar a APC e IP",
"exceptions_prov_1":"Provincia de Territorios del Noroeste",
"exceptions_prov_2":"Provincia de Columbia Británica",
"exceptions_prov_3":"Provincia de Nueva Escocia",
"exceptions_prov_4":"Provincia de Alberta",
"exceptions_prov_5":"Provincia de Ontario",
"exceptions_prov_6":"BC Hydro",
"exceptions_prov_7":"Hydro One",
"exceptions_state_1":"Estado de Connecticut",
"exceptions_state_2":"Estado de Georgia",
"exceptions_state_3":"Estado de Minnesota",
"exceptions_state_4":"Estado de Nueva York",
"exceptions_state_5":"Estado de Carolina del Norte",
"exceptions_state_6":"Estado de Virginia Occidental",
"exceptions_state_7":"Estado de Wisconsin",
"exceptions_Supplier":"Proveedor:",
"exceptions_Supplier_sub_header":"Las siguientes entidades gubernamentales tienen términos diferentes a nuestros términos y condiciones estándar (a <a> https://partnerconnect.holman.com/terms </a>). Tenga en cuenta que las operaciones para estas entidades específicas serán procesadas ​​de acuerdo a la siguiente tabla. Las transacciones para todos los demás clientes de Holman seguirán los términos y condiciones estándar.",
"exceptions_us":"Estados Unidos",
"existing_po_error_selection_no":"No",
"existing_po_error_selection_po":"Orden de compra",
"existing_po_error_selection_yes":"Sí",
"feedback_required_email":"Por favor, incluir @ en la dirección de correo electrónico.",
"gst_caption_canada":"Numero de negocio",
"heavy_duty":"Uso pesado",
"institute_id":"ID del Instituto",
"invoices":"Facturas",
"labor_rate":"tarifas de mano de obra",
"laborRate_required":"Por favor, introduzca la tarifa de mano de obra de $ 9.99 o más",
"light_duty":"Uso ligero",
"medium_duty":"Uso mediano",
"mex_tax_id ":"ID de impuestos federales",
"mm_caption":"MM",
"mobile_onsite_rate":"Tasa móvil/in sitio",
"multi_client_name":"Nombre del cliente",
"multi_full_vin":"Número de Serie (VIN) completo",
"multi_make":"Marca",
"multi_model":"Modelo",
"multi_veh_title_carwash_po":"Su búsqueda ha producido varios vehículos con la misma información. Por favor, seleccione el vehículo que está trabajando de la lista siguiente para proceder a iniciar la PO de lavado de coches.",
"multi_veh_title_repair_po":"Su búsqueda ha producido varios vehículos con la misma información. Por favor, seleccione el vehículo que está trabajando de la siguiente lista con el fin de proceder a iniciar la creación de la PO.",
"multi_veh_title_search_vin":"Su búsqueda ha producido varios vehículos con la misma información. Por favor, seleccione el vehículo que está trabajando de la siguiente lista para proceder.",
"multi_year":"Año",
"non_intellipay_account_message":"Si necesita actualizar su información bancaria póngase en contacto con nuestro equipo de Relaciones con asociados <br></br> En los Estados Unidos - 1-888-683-8327 opción 1 <br></br> En Canadá - 1-866-416-6542",
"optional_rates":"tarifas opcionales",
"password_required":"Por favor, ingrese su contraseña",
"payments":"pagos",
"postalCode":"Código postal",
"postalCode_required":"Por favor, introduzca un código postal válido en el formato: XXXXX",
"providence_error":"Por favor, seleccione una Localidad",
"province":"Localidad",
"province_required":"Por favor, seleccione una Localidad",
"purchase_order":"Ordenes de compra",
"purchase_order_paid":"Número de Orden de Compra. {poNumber}",
"re_enter_check_number_rquired_canada":"Por favor vuelva a introducir el número de cuenta bancaria",
"rear_brakes":"Posterior",
"reenter_checking_acc_no_canada":"Confirmar Número de cuenta",
"REFERENCE_NO_exact_length":"El Número de referencia debe ser exactamente de {exactLength} caracteres",
"REFERENCE_NO_mandatory":"Por favor, indique el número de referencia",
"REFERENCE_NO_max_length":"El Número de referencia no debe ser mayor a {maxLength} caracteres.",
"REFERENCE_NO_min_length":"El Número de referencia debe ser de al menos {MINLENGTH} caracteres",
"REFERENCE_NO_min_max_length":"El Número de referencia debe estar entre {} y {minLength maxLength} caracteres",
"reference_number_validation_message":"Por favor, introduzca su número de referencia a 6 dígitos",
"save_brake_changes":"Guardar",
"save_tire_changes":"Guardar",
"search_for_dealerdraft_submit":"Buscar Solicitud de cortesía ...",
"search_for_po_orders_by_prov":"Localidad",
"search_for_po_orders_by_service_no":"Número de tarjeta de servicio",
"select_country_canada":"Canadá",
"service_card_label":"Tarjeta de servicio No.",
"service_error":"Por favor, introduzca su número de tarjeta de servicio",
"tag_prov_label":"Placas y Localidad de las Placas",
"tax_gst":"GST",
"tax_hst":"HST",
"tax_id_required":"Por favor, introduzca su Número de Seguridad social o Número de Identificación",
"tax_pst":"PST",
"tax_qst":"QST",
"trailer":"Remolque",
"transit_id":"Id tránsito",
"unit_confirmation_message_end_part":"?",
"unit_confirmation_no":"No",
"vendor_details_sub_title_processed":"Su solicitud ha sido aceptada y presentada para su pago. <a> Imprimir </a> y guardar esta página para sus registros.",
"view":"VER",
"recaptcha_title":"Por favor, haga clic en la casilla de verificación a continuación, esta es una medida de seguridad para evitar el spam.",
"password_policy_title" : "La nueva contraseña debe cumplir los siguientes criterios:",
"password_policy_8_chars" : "Contiene al menos 8 caracteres",
"password_policy_uppercase" : "Contiene al menos 1 letra mayúscula",
"password_policy_lowercase" : "Contiene al menos 1 letra minúscula",
"password_policy_digit" : "Contiene al menos 1 número",
"password_policy_special_char" : "Contiene al menos 1 símbolo de esta lista !#$%&\'()*+,-./:;<=>?@[\]^_{|}~",
"invalid_token_title": "Usuario Holman",
"invalid_token_subtext": "El token de usuario Holman no es válido.",
"invalid_token_subtext_2": "Póngase en contacto con HPCITsupport@holman.com para obtener ayuda.",
"vendor_search_label": "Ingrese un ID de proveedor para acceder a la cuenta",
"vendor_search_placeholder": "Ingrese la identificación del vendedor",
"vendor_search_invalid": "Contenedor de búsqueda de vendedor no válido",
"vendor_search_permission_error": "El usuario Holman no tiene permiso para ver este proveedor",
"vendor_search_empty": "La identificación del vendedor no puede estar vacía",
"vendor_search_error": "Vendedor no encontrado",
"vendor_search_suffix_missing": "Introduzca el sufijo del país al final de cada ID de proveedor (UA, MM, CC)",
"change_password_title": "Cambia tu contraseña",
"current_password_field": "Contraseña actual",
"already_used_in_last_5_passwords": "La contraseña que ha ingresado no es válida porque coincide con uno de los últimos cinco que ha utilizado. Por favor crea una nueva contraseña.",
"current_password_field_required": "La contraseña actual no se puede vaciar",
"change_password_sub_title": "Nuestros registros indican que su contraseña actual no cumple con los requisitos de complejidad. Para su seguridad, por favor configure una nueva contraseña.",
"tls_warning_title": "Advertencia de seguridad del navegador",
"tls_warning_message_line1": "Este sitio web no permite que los navegadores antiguos se utilicen por motivos de seguridad. Para acceder a este sitio web, deberá actualizar a la última versión de Microsoft Internet Explorer, Google Chrome, Apple Safari o Mozilla Firefox.",
"tls_warning_message_line3": "Si tiene alguna pregunta, consulte a su departamento de TI o proveedor de seguridad de Internet.",
"tls_warning_message_line4": "Para comunicarse con Holman con respecto a esta actualización de seguridad, comuníquese con la mesa de ayuda de Holman PartnerConnect en <a>partnerconnect@holman.com</a>",
"tls_warning_learn_more_button": "Aprende más",
"tls_learn_more_title": "Se ha detectado un navegador obsoleto o no compatible",
"tls_learn_more_message": "<p><p>Este sitio web no permite que los navegadores antiguos se utilicen por motivos de seguridad. Para acceder a este sitio web, deberá actualizar a la última versión de Internet Explorer (IE), Chrome, Safari o Firefox.</p><p>Información para su servicio de asistencia de TI: TLS 1.0 y TLS 1.1. Los protocolos están deshabilitados en este sitio web para cumplir con los estándares de seguridad de mejores prácticas para la protección de datos.</p>Deberá instalar un navegador web que sea compatible con el protocolo TLS 1.2. Las siguientes versiones del navegador son compatibles con TLS 1.2:</p><ul><li>Google Chrome versión 30 o superior</li><li>Mozilla Firefox versión 27 o superior</li><li>Microsoft Internet Explorer 11 o superior</li><li>Microsoft Edge</li><li>Apple Safari 7 o superior</li></ul><br />Si tiene alguna pregunta, comuníquese con Holman al <a>partnerconnect@holman.com</a>.</p>",

  // Crane inspection
  "crane_inspection__title": 'Inspección de seguridad de grúas móviles',
  "crane_inspection__edit_form": 'Ver / Editar formulario de inspección de la grúa',
  "crane_inspection__save_continue": 'Guardar y continuar',
  "crane_inspection__pass": 'PASS',
  "crane_inspection__fail": 'FAIL',
  "crane_inspection__inspection_date":"Fecha de inspección",
  "crane_inspection__manufacturer": "Fabricante",
  "crane_inspection__manufacturer_required": "Proveer fabricante",
  "crane_inspection__capacity": "Capacity",
  "crane_inspection__capacity_required": "Proporcionar capacidad",
  "crane_inspection_form__serial_number": "Número de serie",
  "crane_inspection_form__serial_number_required": "Proporcionar número de serie",
  'crane_inspection__serial_number': "número de serie",
  'crane_inspection__model_number': "Número de modelo",
  'crane_inspection__hoistSystemBrakesType': "Frenos del sistema de elevación",
  'crane_inspection__hoistSystemBrakesType_Hydraulic': "Hydraulic",
  'crane_inspection__hoistSystemBrakesType_Air': "Air",
  'crane_inspection__boomJibBrakesType': "Frenos de pluma / pluma",
  'crane_inspection__boomJibBrakesType_Hydraulic': "Hydraulic",
  'crane_inspection__boomJibBrakesType_Air': "Air",
  'crane_inspection__interval': "Tipo",
  'crane_inspection__interval_annual': "Anual",
  'crane_inspection__interval_quadrennial': "Quadrennial",
  'crane_inspection__interval_other': "Otro",
  'crane_inspection_form__deficiencies': "Deficiencias",
  'crane_inspection__serial_number_required': "Proporcionar el número de serie",
  'crane_inspection__model_number_required': "Proporcionar número de modelo",
  'crane_inspection__hoistSystemBrakesType_required': "Proporcionar frenos del sistema de elevación",
  'crane_inspection__boomJibBrakesType_required': "Proporcionar frenos de pluma / brazo",
  'crane_inspection__interval_required': "Proporcionar el tipo",
  'crane_inspection__interval_other_required': "Proporcionar otro tipo",
  "crane_inspection__inspection_date_invalid":"Fecha no válida / formateada incorrectamente, ingrese una fecha válida",
  "crane_inspection__inspection_date_disabled":"Por favor introduzca una fecha valida",
  "crane_inspection__has_inspection_passed_required": 'Por favor, seleccione PASS o FAIL',
  "crane_inspection__has_inspection_passed_components_invalid": 'Todos los componentes del vehículo deben marcarse como Sat. o N / A para pasar la inspección ',
  "crane_inspection__has_inspection_passed_unsafe_conditions": 'La inspección no puede pasar con las condiciones inseguras enumeradas',
  "crane_inspection__has_inspection_passed_no_failure_specified": 'Por favor, especifique los componentes y deficiencias del vehículo que fallaron',
  "crane_inspection__has_inspection_passed_no_failure_unsat_deficiencies": 'Componentes del vehículo marcados como Sat. o N / A no puede tener deficiencias en la lista',
  "crane_inspection__has_inspection_fail_deficiencies_without_unsat": 'Por favor, especifique las deficiencias de Unsat. componentes del vehículo',
  "crane_inspection__has_inspection_failed_all_components_required": 'Todos los componentes deben especificar Sat./Unsat./NA.',
  "crane_inspection__inspector_attest": 'Certifico que estoy calificado para realizar una inspección ANSI y / o OSHA y que esta unidad ha superado todos los requisitos de inspección ANSI y / o OSHA',
  "crane_inspection__attest_required": 'Por favor, confirme esta declaración',
  "crane_inspection__inspector_signature": "Firma del inspector",
  "crane_inspection__inspector_signature_note": "Al escribir su nombre, acepta que esto será la representación electrónica de su firma.",
  "crane_inspection__signature_required": 'Por favor ingrese su firma',
  "crane_inspection_confirmation__fail_confirm_title": 'Inspección móvil de grúa ANSI',
  "crane_inspection_confirmation__fail_confirm_question": '¿Va a reparar los componentes defectuosos del vehículo en esta factura de reparación?',
  "crane_inspection_confirmation__fail_confirm_yes": 'Sí y editar PO',
  "crane_inspection_confirmation__fail_confirm_no": 'No y especifique los componentes fallidos',
  "crane_inspection_form__title": 'Formulario de inspección de seguridad de grúa móvil',
  "crane_inspection_form__component_subtext_line1": '* Importante para mantener una copia actualizada del informe de inspección en este vehículo.',
  "crane_inspection_form__component_subtext_line2": '* Las leyes locales del estado pueden solicitar inspecciones con más frecuencia. Investiga todos los estados en los que viajas.',
  "crane_inspection_form__component_subtext_line3": 'Todo cable de alambre insatisfactorio deberá reemplazarse según las recomendaciones del fabricante original.',
  "crane_inspection_form__customer": 'Cliente',
  "crane_inspection_form__address": 'Dirección',
  "crane_inspection_form__unit_number": 'Número de unidad',
  "crane_inspection_form__year": 'Year',
  "crane_inspection_form__make": 'Make',
  "crane_inspection_form__license_plate": 'matrícula',
  "crane_inspection_form__date": 'Date',
  "crane_inspection_form__save": 'Guardar y continuar',
  'crane_inspection_form__all_ok': 'All OK',
  'crane_inspection_form__item': 'Elementos de acción',
  'crane_inspection_form__pass': 'Sat.',
  'crane_inspection_form__fail': 'Unsat.',
  'crane_inspection_form__na': 'N / A',
  'crane_inspection_form__fail_confirm_title': 'Repair PO?',
  'crane_inspection_form__fail_confirm_question': '¿Va a reparar los componentes defectuosos del vehículo en esta factura de reparación?',
  'crane_inspection_form__fail_confirm_yes': 'Sí y editar PO',
  'crane_inspection_form__fail_confirm_no': 'No and continue',
  'crane_inspection_form__rail_gear': "Equipo de riel",
  'crane_inspection_form__rail_gear_model': "Modelo de tren de rodaje",
  'crane_inspection_form__rail_gear_front_serial_number': "Número de serie frontal",
  'crane_inspection_form__rail_gear_rear_serial_number': "Número de serie trasero",
  'crane_inspection_form__component_300': "Publicación de certificación actual",
  'crane_inspection_form__component_301': "* Instrucciones para el operador",
  'crane_inspection_form__component_302': "* Registros de mantenimiento preventivo",
  'crane_inspection_form__component_303': "Gráficos de señales de mano",
  'crane_inspection_form__component_304': "* Gráficos de carga adecuados",
  'crane_inspection_form__component_305': "Controles, niveles, diagramas del operador debidamente marcados",
  'crane_inspection_form__component_306': "Calcomanías informativas / de advertencia requeridas",
  'crane_inspection_form__component_307': "Dispositivo (s) de advertencia / Alarma de respaldo",
  'crane_inspection_form__component_308': "Protecciones de seguridad y máquinas",
  'crane_inspection_form__component_309': "Provisiones de acceso (barandas, etc.)",
  'crane_inspection_form__component_310': "Extintores de incendios",
  'crane_inspection_form__component_311': "Anti-two block",
  'crane_inspection_form__component_312': "Celdas de carga e indicadores",
  'crane_inspection_form__component_313': "Componentes eléctricos / ensamblajes",
  'crane_inspection_form__component_314': "Interruptor de parada de emergencia",
  'crane_inspection_form__component_315': "Interruptor de sobrecarga",
  'crane_inspection_form__component_316': "Interruptores maestros o controlador de tambor",
  'crane_inspection_form__component_317': "Interruptores, contactores, relés",
  'crane_inspection_form__component_318': "* Estación de control principal",
  'crane_inspection_form__component_319': "Estación de control remoto y botones",
  'crane_inspection_form__component_320': "Cubo / controles de suelo",
  'crane_inspection_form__component_321': "Indicadores: nivel, ángulo / longitud de la pluma, rotación del tambor, carga.",
  'crane_inspection_form__component_322': "Motores",
  'crane_inspection_form__component_323': "* Ejes, engranajes, acoplamientos, guardas",
  'crane_inspection_form__component_324': "* Conjunto (s) de rodamientos",
  'crane_inspection_form__component_325': "Seals",
  'crane_inspection_form__component_326': "Conjuntos de polea",
  'crane_inspection_form__component_327': "* Polea y pin del ecualizador",
  'crane_inspection_form__component_328': "Frenos",
  'crane_inspection_form__component_329': "Interruptor (es) de límite",
  'crane_inspection_form__component_330': "Dispositivos de bloqueo",
  'crane_inspection_form__component_331': "* Tambores y anclajes",
  'crane_inspection_form__component_332': "* Carga los ensamblajes del bloque",
  'crane_inspection_form__component_333': "Ganchos y pernos de contrapeso",
  'crane_inspection_form__component_334': "* Hooks tranvía / garganta",
  'crane_inspection_form__component_335': "Hook NDT",
  'crane_inspection_form__component_336': "* Conjunto (s) de rodamientos",
  'crane_inspection_form__component_337': "* Seals",
  'crane_inspection_form__component_338': "Frenos",
  'crane_inspection_form__component_339': "Línea de conducción y batería (s)",
  'crane_inspection_form__component_340': "* Embragues de fricción, acoplamiento y pasadores",
  'crane_inspection_form__component_341': "* Caja de engranajes; todos los engranajes y piñones",
  'crane_inspection_form__component_342': "* Boom y accesorios",
  'crane_inspection_form__component_343': "* Conjunto de rotaciones de la pluma., bloqueos y frenos",
  'crane_inspection_form__component_344': "* Bloqueo o trinquete del polipasto de la pluma",
  'crane_inspection_form__component_345': "* Paradas traseras de pluma y brazo",
  'crane_inspection_form__component_346': "Barreras giratorias",
  'crane_inspection_form__component_347': "* kickout automático de la pluma",
  'crane_inspection_form__component_348': "* Ensamblaje (s) del estabilizador",
  'crane_inspection_form__component_349': "* Soldaduras, acordes, cordones",
  'crane_inspection_form__component_350': "* sockets de cuña",
  'crane_inspection_form__component_351': "Drums",
  'crane_inspection_form__component_352': "Sistemas de lubricación automáticos",
  'crane_inspection_form__component_353': "Lubricación manual / niveles de fluidos",
  'crane_inspection_form__component_354': "Gauge (aceite, etc.) y puertos gauge",
  'crane_inspection_form__component_355': "Superestructura (marco principal, vidrio)",
  'crane_inspection_form__component_356': "Pernos, tuercas, remaches, montajes, soportes",
  'crane_inspection_form__component_357': "* Aire / sistema hidráulico: cilindros, mangueras y conexiones",
  'crane_inspection_form__component_358': "Silenciador / sistema de escape",
  'crane_inspection_form__component_359': "Housekeeping",
  'crane_inspection_form__component_360': "Dirección",
  'crane_inspection_form__component_361': "Piezas soldadas",
  'crane_inspection_form__component_362': "Tornillos",
  'crane_inspection_form__component_363': "Tracks",
  'crane_inspection_form__component_364': "Track drive",
  'crane_inspection_form__component_365': "Elevador principal",
  'crane_inspection_form__component_366': "Aux. Hoist",
  'crane_inspection_form__component_367': "Aux. Hoist (2)",
  'crane_inspection_form__component_368': "Colgantes",
  'crane_inspection_form__component_369': "Colgantes (2)",
  'crane_inspection_form__component_370': "Jib Stays",
  'crane_inspection_form__component_371': "Boom Hoist",
  'crane_inspection_form__component_group_18': "Registros",
  'crane_inspection_form__component_group_19': "Equipo de seguridad",
  'crane_inspection_form__component_group_20': "Sistemas eléctricos y sistemas de control",
  'crane_inspection_form__component_group_21': "Sistemas de elevación",
  'crane_inspection_form__component_group_22': "Boom / jib",
  'crane_inspection_form__component_group_23': "Medidores de sistemas de lubricantes",
  'crane_inspection_form__component_group_24': "Misceláneo",
  'crane_inspection_form__component_group_25': "Carrocería de la oruga",
  'crane_inspection_form__component_group_26': "Informe de condición de cable y gancho",
  'crane_inspection__vehicle_history_title': "Registro del historial del vehículo",
  'crane_inspection__vehicle_history_report_number': "Número de informe",
  'crane_inspection__vehicle_history_unit_number': "Número de unidad de flota",
  'crane_inspection__vehicle_history_inspection_date': "Fecha",

  //BIT
  "bit_inspection__title": 'Inspección BIT',
  'bit_inspection__truck': 'camión',
  'bit_inspection__bus': 'bus',
  'bit_inspection__trailer': 'trailer',
  "bit_inspection__edit_form": 'Ver / Editar formulario de inspección BIT',
  "bit_inspection__save_continue": 'Guardar y continuar',
  "bit_inspection__pass": 'PASS',
  "bit_inspection__fail": 'FAIL',
  "bit_inspection__has_inspection_passed_required": 'Por favor, seleccione PASS o FAIL',
  "bit_inspection__has_inspection_passed_components_invalid": 'Todos los elementos deben marcarse como OK o N / A para pasar la inspección',
  "bit_inspection__has_inspection_passed_unsafe_conditions": 'La inspección no puede pasar con las condiciones inseguras enumeradas',
  "bit_inspection__has_inspection_passed_no_failure_specified": 'Por favor, especifique los elementos fallidos',
  "bit_inspection__has_inspection_failed_all_components_required": 'Todos los componentes deben especificar OK / NA / Def.',
  "bit_inspection__has_inspection_other_option_comments_required": 'Se requieren comentarios para "Otro" componente del vehículo.',
  "bit_inspection__inspection_date":"Fecha de inspección",
  "bit_inspection__inspection_date_invalid":"Fecha no válida / formateada incorrectamente, ingrese una fecha válida",
  "bit_inspection__inspection_date_disabled":"Por favor introduzca una fecha valida",
  "bit_inspection__inspector_attest": 'Certifico que estoy calificado para realizar la inspección solicitada y que este vehículo ha superado todos los elementos de inspección de acuerdo con los requisitos de BIT.',
  "bit_inspection__attest_required": 'Por favor, confirme esta declaración',
  "bit_inspection__inspector_signature": "Firma del inspector",
  "bit_inspection__inspector_signature_note": "Al escribir su nombre, acepta que esto servirá como la representación electrónica de su firma.",
  "bit_inspection__signature_required": 'Por favor ingrese su firma',
  "bit_inspection_confirmation__fail_confirm_title": 'inspección BIT',
  "bit_inspection_confirmation__fail_confirm_question": '¿Va a reparar los artículos fallidos en esta factura de reparación?',
  "bit_inspection_confirmation__fail_confirm_yes": 'Sí y editar PO',
  "bit_inspection_confirmation__fail_confirm_no": 'No y especifique los elementos fallidos',
  "bit_inspection_form__title": 'formulario de inspección BIT',
  "bit_inspection_form__carrier_name": 'Nombre del operador',
  "bit_inspection_form__unit_number": 'Número de unidad',
  "bit_inspection_form__year": 'Año',
  "bit_inspection_form__make": 'Make',
  "bit_inspection_form__license_plate": 'Matrícula',
  "bit_inspection_form__date": 'Date',
  "bit_inspection_form__save": 'Guardar y continuar',
  'bit_inspection_form__all_ok': 'Todo OK',
  'bit_inspection_form__item': 'Item',
  'bit_inspection_form__ok': 'OK',
  'bit_inspection_form__na': 'N / A',
  'bit_inspection_form__defective': 'Def',
  'bit_inspection_form__comments': 'Comentarios',

  // CAMION
  'bit_inspection_form__component_51': '1. Extintor de incendios y dispositivos reflectantes de alerta.',
  'bit_inspection_form__component_52': '2. Cuerno, descongelador, medidores, cuentakilómetros y velocímetro ',
  'bit_inspection_form__component_53': '3. Espejos y soportes ',
  'bit_inspection_form__component_54': '4. Limpiaparabrisas, grietas de ventanas ',
  'bit_inspection_form__component_55': '5. Todas las luces, señales, reflectores, guardabarros',
  'bit_inspection_form__component_56': '6. Condicionamiento eléctrico y protección ',
  'bit_inspection_form__component_57': '7. Baterías, nivel de agua, terminales y cables ',
  'bit_inspection_form__component_58': '8. Dispositivos de advertencia: aire, aceite, temperatura, antideslizamiento y / o vacío ',
  'bit_inspection_form__component_59': '9. Radiador y mangueras de agua: nivel de refrigerante, condición y / o fugas',
  'bit_inspection_form__component_60': '10. Correas-compresor, ventilador, bomba de agua y / o alternador ',
  'bit_inspection_form__component_61': '11. Mangueras de aire y fugas en las tuberías, condición y / o protección ',
  'bit_inspection_form__component_62': '12. Sistema de combustible: tanque, mangueras, tubería y / o bomba; fugas',
  'bit_inspection_form__component_63': '13. Sistema de escape, colectores, tuberías, silenciador; Fugas y / o condición ',
  'bit_inspection_form__component_64': '14. Montaje del motor, exceso de grasa y / o aceite ',
  'bit_inspection_form__component_65': '15. Juego sin ajuste de embrague ',
  'bit_inspection_form__component_66': '16. Filtro de aire, varillaje del acelerador ',
  'bit_inspection_form__component_67': '17. Sistema de arranque y carga ',
  'bit_inspection_form__component_68': '18. Válvula de protección del tractor ',
  'bit_inspection_form__component_69': '19. Sistema de freno hidráulico, ajuste, componentes y / o condición ',
  'bit_inspection_form__component_70': '20. Nivel del cilindro maestro hidráulico, fugas y / o condición ',
  'bit_inspection_form__component_71': '21. Mangueras y tubos de condición y protección ',
  'bit_inspection_form__component_72': '22. Sistema de frenos de aire: ajuste, componentes y / o condición ',
  'bit_inspection_form__component_73': '23. 1 minuto de prueba de pérdida de aire o vacío ',
  'bit_inspection_form__component_74': '24. Presiones de conexión y desconexión del regulador del compresor de aire (85-130) ',
  'bit_inspection_form__component_75': '25. Válvula de retención del tanque de aire primario y de prueba ',
  'bit_inspection_form__component_76': '26. Otro tanque de aire de drenaje y control de contaminación; aseguramiento',
  'bit_inspection_form__component_77': '27. Neumáticos-profundidad de la banda de rodadura, inflación y condición ',
  'bit_inspection_form__component_78': '28. Ruedas, tuercas y pernos, grietas, holguras y / o condiciones ',
  'bit_inspection_form__component_79': '29. Freno de estacionamiento, capaz de sostener el vehículo ',
  'bit_inspection_form__component_80': '30. Sistema de parada de emergencia etiquetado y operativo ',
  'bit_inspection_form__component_81': '31. Los frenos se sueltan después de una pérdida completa del aire de servicio ',
  'bit_inspection_form__component_82': '32. Sistema de dirección de montaje, pestañas libres y componentes ',
  'bit_inspection_form__component_83': '33. Los brazos de dirección, los enlaces de arrastre y / o los extremos de la barra de acoplamiento ',
  'bit_inspection_form__component_84': '34. Dispositivos de conexión: quinta rueda, enganche de remolque o dispositivos de seguridad ',
  'bit_inspection_form__component_85': '35. Sistema de suspensión: resortes, grilletes, pernos en U y / o barras de torsión ',
  'bit_inspection_form__component_86': '36. Marco y miembros cruzados-grietas y / o condición ',
  'bit_inspection_form__component_87': '37. Eje motor, juntas universales, y / o guardas ',
  'bit_inspection_form__component_88': '38. Transmisión y montaje diferencial, fugas y / o condición ',
  'bit_inspection_form__component_89': '39. Sellos de rueda-fugas y / o condición ',
  'bit_inspection_form__component_90': '40. Bajo carro limpio y seguro ',
      'bit_inspection_form__component_91': '41. Otro',
      
  // REMOLQUE
  'bit_inspection_form__component_100': '1. Todas las luces, señales, reflectores',
  'bit_inspection_form__component_101': '2. Mudflaps ',
  'bit_inspection_form__component_102': '3. Fugas de aire-sistema de frenos ',
  'bit_inspection_form__component_103': '4. Fugas de aire del sistema de descarga ',
  'bit_inspection_form__component_104': '5. Marco, subtrama y grietas en el cuerpo ',
  'bit_inspection_form__component_105': '6. Ajuste de freno ',
  'bit_inspection_form__component_106': '7. Sistema de frenos, tambores y componentes-condición ',
  'bit_inspection_form__component_107': '8. Sistema de suspensión: resortes, grilletes, pernos en U y / o barras de torsión ',
  'bit_inspection_form__component_108': '9. Conexión de dispositivos-pivote, barra de tiro, dispositivos de seguridad y / o ojos ',
  'bit_inspection_form__component_109': '10. Quinta rueda sobre remolque de arrastre ',
  'bit_inspection_form__component_110': '11. Mangueras de aire y tubos de fugas, condición y / o protección ',
  'bit_inspection_form__component_111': '12. Neumáticos-profundidad de la banda de rodadura, inflación y condición ',
  'bit_inspection_form__component_112': '13. Ruedas, tuercas y pernos, grietas, holguras y / o condiciones ',
  'bit_inspection_form__component_113': '14. Mangueras y tubos de condición, protección ',
  'bit_inspection_form__component_114': '15. Maestro hidráulico nivel de cilindro, fugas y / o condición ',
  'bit_inspection_form__component_115': '16. Freno de estacionamiento, capaz de sostener el vehículo ',
  'bit_inspection_form__component_116': '17. Sistema de frenado de emergencia',
  'bit_inspection_form__component_117': '18. Válvulas de relé de aire y montaje en tanque ',
  'bit_inspection_form__component_118': '19. Los frenos se sueltan después de una pérdida completa del aire de servicio ',
  'bit_inspection_form__component_119': '20. Sellos de rueda-fugas y / o condición ',
  'bit_inspection_form__component_120': '21. Bajo carro limpio y seguro ',
  'bit_inspection_form__component_121': '22. Otro',
      
  // BUS
  'bit_inspection_form__component_150': '1. Extintor de incendios, botiquín de primeros auxilios y dispositivos de advertencia reflectiva ',
  'bit_inspection_form__component_151': '2. Cuerno, descongelador, medidores, cuentakilómetros y velocímetro ',
  'bit_inspection_form__component_152': '3. Asiento del conductor, asientos de pasajeros, acolchado, interior y condición del piso ',
  'bit_inspection_form__component_153': '4. Limpiaparabrisas, ventanas, espejos y soportes ',
  'bit_inspection_form__component_154': '5. Todas las luces interiores y exteriores, señales, reflectores',
  'bit_inspection_form__component_155': '6. Condicionamiento eléctrico y protección ',
  'bit_inspection_form__component_156': '7. Baterías, nivel de agua, terminales y cables ',
  'bit_inspection_form__component_157': '8. Dispositivos de advertencia: aire, aceite, temperatura, salida y / o vacío ',
  'bit_inspection_form__component_158': '9. Calentadores, descongeladores, interruptores, y ventilaciones ',
  'bit_inspection_form__component_159': '10. Puertas, exteriores, pintura, y marcaje ',
  'bit_inspection_form__component_160': '11. Radiador y mangueras de agua, nivel de refrigerante, condición y / o fugas ',
  'bit_inspection_form__component_161': '12. Correas-compresor, ventilador, agua y / o alternador ',
  'bit_inspection_form__component_162': '13. Mangueras de aire y tubos de fugas, condición y / o protección ',
  'bit_inspection_form__component_163': '14. Sistema de combustible: tanque, mangueras, tuberías y / o fugas de la bomba ',
  'bit_inspection_form__component_164': '15. Sistema de escape, colectores, tuberías, fugas de silenciador y / o condición ',
  'bit_inspection_form__component_165': '16. Montaje del motor, exceso de grasa y / o aceite ',
  'bit_inspection_form__component_166': '17. Juego sin ajuste de embrague ',
  'bit_inspection_form__component_167': '18. Filtro de aire, varillaje del acelerador ',
  'bit_inspection_form__component_168': '19. Sistema de arranque y carga ',
  'bit_inspection_form__component_169': '20. Sistema de freno hidráulico, ajuste, componentes y / o condición ',
  'bit_inspection_form__component_170': '21. Maestro hidráulico nivel de cilindro, fugas y / o condición ',
  'bit_inspection_form__component_171': '22. Mangueras y tubos de condición, protección ',
  'bit_inspection_form__component_172': '23. Ajuste del sistema de frenos de aire, compartimentos y / o condición ',
  'bit_inspection_form__component_173': '24. 1 minuto de prueba de pérdida de aire o vacío ',
  'bit_inspection_form__component_174': '25. Presión de corte y desconexión del regulador del compresor de aire (85-130) ',
  'bit_inspection_form__component_175': '26. Depósito de aire primario y función de prueba de la válvula de retención ',
  'bit_inspection_form__component_176': '27. Otros tanques de aire-drenaje y control de contaminación ',
  'bit_inspection_form__component_177': '28. Neumáticos-profundidad de rodadura, inflado, condición ',
  'bit_inspection_form__component_178': '29. Ruedas, tuercas y pernos, grietas, holguras y / o condiciones ',
  'bit_inspection_form__component_179': '30. Freno de estacionamiento, capaz de sostener el vehículo ',
  'bit_inspection_form__component_180': '31. Sistema de parada de emergencia etiquetado, operativo ',
  'bit_inspection_form__component_181': '32. Los frenos no se sueltan después de una pérdida completa del aire de servicio ',
  'bit_inspection_form__component_182': '33. Sistema de dirección de montaje, pestañas libres y componentes ',
  'bit_inspection_form__component_183': '34. Los brazos de dirección, los enlaces de arrastre y / o los extremos de la barra de acoplamiento ',
  'bit_inspection_form__component_184': '35. Sistema de suspensión: resortes, grilletes, pernos en U y / o barras de torsión ',
  'bit_inspection_form__component_185': '36. Marco y miembros cruzados-grietas y / o condición ',
  'bit_inspection_form__component_186': '37. Eje motor, juntas universales, y / o guardas ',
  'bit_inspection_form__component_187': '38. Transmisión y montaje diferencial, fugas y / o condición ',
  'bit_inspection_form__component_188': '39. Sellos de rueda-fugas y / o condición ',
  'bit_inspection_form__component_189': '40. Bajo carro limpio y seguro ',
      'bit_inspection_form__component_190': '41. Otro',
      
  'bit_inspection_form__fail_confirm_title': 'Repair PO?',
  'bit_inspection_form__fail_confirm_question': '¿Vas a reparar los artículos fallidos en esta factura de reparación?',
  'bit_inspection_form__fail_confirm_yes': 'Sí y editar PO',
  'bit_inspection_form__fail_confirm_no': 'No y continuar',

  // inspección FRA
  "fra_inspection__title": 'inspección FRA',
  "fra_inspection__edit_form": 'Ver / Editar formulario de inspección FRA',
  "fra_inspection__save_continue": 'Guardar y continuar',
  "fra_inspection__pass": 'PASS',
  "fra_inspection__fail": 'FAIL',
  "fra_inspection__has_inspection_passed_required": 'Por favor, seleccione PASS o FAIL',
  "fra_inspection__has_inspection_passed_components_invalid": 'Todos los elementos deben marcarse como OK para pasar la inspección',
  "fra_inspection__has_inspection_passed_unsafe_conditions": 'La inspección no puede pasar con las condiciones inseguras enumeradas',
  "fra_inspection__has_inspection_passed_no_failure_specified": 'Por favor, especifique los elementos con error',
  "fra_inspection__inspection_date":"Fecha de inspección",
  "fra_inspection__inspection_date_invalid":"Fecha no válida / formateada incorrectamente, ingrese una fecha válida",
  "fra_inspection__inspection_date_disabled":"Por favor introduzca una fecha valida",
  "fra_inspection__inspector_attest": 'Certifico que estoy calificado para realizar la inspección solicitada y que este vehículo ha superado todos los elementos de inspección de acuerdo con los requisitos de FRA.',
  "fra_inspection__attest_required": 'Por favor, confirme esta declaración',
  "fra_inspection__inspector_signature": "Firma del inspector",
  "fra_inspection__inspector_signature_note": "Al escribir su nombre, acepta que esto será la representación electrónica de su firma.",
  "fra_inspection__signature_required": 'Por favor ingrese su firma',
  "fra_inspection_confirmation__fail_confirm_title": 'inspección FRA',
  "fra_inspection_confirmation__fail_confirm_question": '¿Va a reparar los artículos fallidos en esta factura de reparación?',
  "fra_inspection_confirmation__fail_confirm_yes": 'Sí y editar PO',
  "fra_inspection_confirmation__fail_confirm_no": 'No y especifique los elementos fallidos',
  "fra_inspection_form__title": 'Formulario de inspección FRA',
  "fra_inspection_form__carrier_name": 'Nombre del operador',
  "fra_inspection_form__unit_number": 'Número de unidad',
  "fra_inspection_form__year": 'Año',
  "fra_inspection_form__make": 'Make',
  "fra_inspection_form__license_plate": 'Matrícula',
  "fra_inspection_form__date": 'Date',
  "fra_inspection_form__save": 'Guardar y continuar',
  'fra_inspection_form__all_ok': 'Todo OK',
  'fra_inspection_form__item': 'Elementos de acción',
  'fra_inspection_form__pass': 'PASS',
  'fra_inspection_form__fail': 'FAIL',
  'fra_inspection_form__fail_confirm_title': 'Repair PO?',
  'fra_inspection_form__fail_confirm_question': '¿Vas a reparar los artículos fallidos en esta factura de reparación?',
  'fra_inspection_form__fail_confirm_yes': 'Sí y editar PO',
  'fra_inspection_form__fail_confirm_no': 'No y continuar',
  'fra_inspection_form__rail_gear': "Equipo de riel",
  'fra_inspection_form__rail_gear_model': "Modelo de tren de rodaje",
  'fra_inspection_form__rail_gear_front_serial_number': "Número de serie frontal",
  'fra_inspection_form__rail_gear_rear_serial_number': "Número de serie trasero",
  'fra_inspection_form__guide_wheel_load_lf': "L / F",
  'fra_inspection_form__guide_wheel_load_rf': "R / F",
  'fra_inspection_form__guide_wheel_load_lr': "L / R",
  'fra_inspection_form__guide_wheel_load_rr': "R / R",
  'fra_inspection_form__component_201': "Inspeccione visualmente el engranaje del riel en busca de daños y componentes faltantes. Revise todos los accesorios de montaje. Apriete los accesorios de montaje sueltos. Revise los cojinetes de pivote para ver si están desgastados",
  'fra_inspection_form__component_202': "Inspeccione el mecanismo de bloqueo del volante para ver si está instalado y funciona correctamente.",
  'fra_inspection_form__component_203': "Inspeccione las ruedas guía. Asegúrese de que el desgaste de la brida esté dentro de la tolerancia. Revise los cojinetes de las ruedas para ver si están jugados, desgastados y ruidos. Avise si es necesario revisar los cojinetes de las ruedas guía.",
  'fra_inspection_form__component_204': "Verifique la carga de la rueda guía según las recomendaciones del fabricante. Avise si las alturas y los pesos requieren ajustes. Compruebe si se encuentran dentro de las especificaciones del OEM.",
  'fra_inspection_form__component_205': "Inspeccione los mecanismos de bloqueo del engranaje del riel y los pasadores de bloqueo para un funcionamiento correcto.",
  'fra_inspection_form__component_206': "Revise los neumáticos del camión para ver si están inflados correctamente. Reemplace las tapas faltantes del vástago de la válvula. Infle los neumáticos a la presión recomendada en la calcomanía de la jamba de la puerta.",
  'fra_inspection_form__component_207': "Compruebe los topes del volante en los vehículos donde estén instalados. Inspeccione: el cableado del sensor ABS delantero, los brazos de control superiores, las mangueras del freno y las abrazaderas de retención de la manguera del freno en busca de daños. Reportar cualquier deficiencia.",
  'fra_inspection_form__component_208': "Inspeccione visualmente las ruedas, los pernos, las tuercas y los neumáticos modificados de la carretilla para detectar equipos faltantes, desgastados o dañados. Inspeccione visualmente los espaciadores de las ruedas. No retire las ruedas de la carretilla. Apriete las tuercas de las ruedas para corregir el torque",
  'fra_inspection_form__component_209': "Inspeccione la instalación del engranaje del riel para ver si está correctamente alineado. Pinte las bridas de las ruedas guía y la prueba de la pista. Avise si el vehículo requiere alineación del engranaje del riel.",
  'fra_inspection_form__component_210': "Inspeccione el sistema de derivación para detectar daños y el funcionamiento correcto. Ajuste los cepillos de derivación según sea necesario.",
  'fra_inspection_form__component_211': "Verifique el funcionamiento correcto de la luz estroboscópica. Requerido en 49CFR214.",
  'fra_inspection_form__component_212': "Verifique el funcionamiento correcto de la alarma de respaldo. Requerido en 49CFR214.",
  'fra_inspection_form__component_213': "Lubrique todos los puntos de engrase en el engranaje del riel. Lubrique otras partes móviles con aceite ligero.",
  'fra_inspection_form__component_214': "Asegúrese de que el tren de rodaje cumpla con todos los boletines de seguridad y servicio del fabricante.",
  'fra_inspection_form__component_215': "Registre cualquier daño anormal al sistema de engranajes de riel que indicaría un descarrilamiento o daño por colisión.",
  'fra_inspection_form__component_216': "Revise las válvulas hidráulicas, mangueras y cilindros como equipados para detectar daños, fugas y enrutamiento. Avise de cualquier deficiencia.",
  "insurance_edit_check_message":"Si necesita revisar una estimación de eventos relacionados con reclamaciones, envíe un suplemento y documentación de respaldo a <a>{emailAddress}</a>",
  "insurance_edit_check_ok":"De acuerdo",
  "work_completed_tax_deferred_header":"Importe del impuesto que se añadirá más adelante.",
  "work_completed_tax_deferred_subtext":"Si cree que está viendo este mensaje por error, llame al 1-866-274-1025 para hablar con un técnico de Holman.",
  "work_completed_tax_deferred_subtext_can":"Si cree que está viendo este mensaje por error, llame al 1-800-363-7676 para hablar con un técnico de Holman.",
 
  /*User Account Menu */
  'user_account_menu_your_account':	'Su Cuenta',
  'user_account_corporate_account_link':	'Cuenta corporativa',
  'user_account_menu_manage_stores':	'Administrar tiendas',
  'user_account_menu_manage_users':	'Gestionar usuarios',
  'user_account_menu_access_stores':	'Tiendas de acceso',
  'user_account_menu_sign_out':	'Cerrar sesión',

 'all_stores__title':'Todas las tiendas',
 'all_stores_ari_vendor_id_store_id':'ID Holman/Núm de tienda',
 'all_stores_address':'Dirección de la tienda',
 'all_stores__manage_stores':'Tienda de acceso',
 'all_stores__pagination_results_caption':'Mostrando resultados {first}-{last} de {storeCount}',
 'all_stores__search_text_place_holder':'buscar tienda',
 'all_stores__search_previous':'anterior',
 'all_stores__search_next':'siguiente',
 'all_stores__search_no_stores_text':'No se encontraron resultados para este criterio de búsqueda.',

 'manage_stores__title':'Administrar Tiendas',
 'manage_stores_ari_vendor_id_store_id':'ID Holman/Núm de tienda',
 'manage_stores_address':'Dirección de la tienda',
 'manage_stores__manage_stores':'Administrar Tienda',
 'manage_stores__pagination_results_caption':'Mostrando resultados {first}-{last} de {storeCount}',
 'manage_stores__search_text_place_holder':'buscar tienda',
 'manage_stores__search_previous':'anterior',
 'manage_stores__search_next':'siguiente',
 'manage_stores__search_no_stores_text':'No se encontraron resultados para este criterio de búsqueda.',

 'user_profile__title':'Perfil del usuario',
 'user_profile_resend_invite_link':'Enviar invitación',
 'add_stores__title':'Añadir tienda (s)',
 'user_profile__edit_permissions':'Editar permisos',
 'user_profile__remove_access':'Eliminar acceso',
 'user_profile__remove_access_all':'Eliminar el acceso a todas las tiendas',
 'user_profile__back':'Volver a todos los usuarios',
 'user_profile_no_stores_text':'El usuario no tiene acceso a ninguna tienda.',

 'manage_users__title':'Gestionar usuarios',
 'manage_users_name':'Nombre',
 'manage_users_email':'Email',
 'manage_users_resend_invite_btn':'Enviar invitación',
 'manage_users_remove_all_user_access_btn':'Eliminar todo el acceso',
 'manage_users__no_search_results':'No se encontraron usuarios que coincidan con los criterios de búsqueda',
 'manage_users__pagination_results_caption':'Mostrando resultados {first}-{last} de {userCount}',
 'manage_users__pagination_results_search_caption':' para el término de búsqueda',
 'manage_users__clear_search_results_caption':'Resultados de búsqueda claros',
 'manage_users__search_text_place_holder':'buscar usuario',
 'manage_users__search_previous':'anterior',
 'manage_users__search_next':'siguiente',
 'add_user__title':' Añadir usuario (s)',
 'manage_users__search_no_users_text':'No se encontraron resultados para este criterio de búsqueda.',
 'manage_users__no_users_to_manage': 'Actualmente no estás administrando ningún usuario.',

 'remove_access__title':"Eliminar acceso de usuario",
 'remove_access_message':"Está seguro de que desea eliminar el acceso de {firstName} {lastName} para {vendorId} en {address}? Haga clic en Sí para continuar. Haga clic en no para cancelar.",
 'remove_all_access_message':"Estás seguro de que deseas eliminar el acceso de {firstName} {lastName} a todas sus tiendas? Haga clic en Sí para continuar. Haga clic en no para cancelar.",
 'remove_access_yes':'Sí',
 'remove_access_no':'No',
 'delete_user':'Borrar usuario',

 'recover_password_sub_heading'   :  'Por favor haga clic en la bandera apropiada',
 'recover_password_email_address' : 'ID de dirección de correo electrónico ',  
 'recover_password_vendorId':'ID emitido por Holman ',
 'recover_password_ARI_access_error_message':'Por favor, seleccione cómo acceder Holman Partner Connect',

 'reset_password_persona_user_registration_mesg':'Introduzca una contraseña para registrar su cuenta con Holman Partner Connect',
 'reset_password_persona_user_registration_title':'Configurar la clave',

 'invalid_user_email_format':'Por favor, introduce una dirección de correo electrónico válida',  
 'persona_header_text':'Holman PartnerConnect',
 'store_profile__title':'Perfil de la tienda',
 'email_confirmation_title':'confirmación de correo electrónico',
 'email_confirmation_message':'Se ha enviado un enlace de invitación a {firstName} {lastName}.',
 'email_confirmation_ok':'De acuerdo',

 'store_profile_name':'Nombre',
 'store_profile_email_address':'Dirección de correo electrónico',
 'store_profile__pagination_results_caption':'Mostrando resultados {first}-{last} de {userCount}',
 'store_profile__search_previous':'anterior',
 'store_profile__search_next':'siguiente',
 'store_profile__admin':'Administración',
 'store_profile__remove_access':'Quitar acceso',
 'store_profile__back':'Volver a gestionar tiendas',
 'store_profile_no_users_text':'No hay usuarios que tengan acceso a esta tienda.',

  // Holman Redirect Screen
  'holman_redirect__holman_button1': 'Ir al nuevo sitio',
  'holman_redirect__ari_button1': 'Permanecer en el sitio antiguo',
  'holman_redirect__holman_redirect__holman_button2': 'Ir al nuevo sitio web de Holman PartnerConnect',
  'holman_redirect__ari_button2': 'Permanecer en el antiguo sitio web de ARI PartnerConnect',
  'holman_redirect__holman_redirect__holman_button3': 'Ir al nuevo sitio web de Holman PartnerConnect',
  'holman_redirect__instructions_new_site': `<p>
  Presione en el botón a continuación para navegar a la nueva página web de Holman PartnerConnect. Si llegara a tener alguna dificultad después de navegar, siga las instrucciones a continuación:
</p>

<ul>
  <li>
    El cortafuegos corporativo bloquea la nueva página web: partnerconnect.holman.com
    <ul>
      <li>Presione el <b>botón atrás</b> para volver a la pantalla anterior y luego presione en <b>Permanecer en el sitio anterior.</b> </li>
      <li>Trabaje con su equipo de apoyo de TI para desbloquear el acceso a partnerconnect.holman.com</li>
    </ul>
  </li>
  <li>
    No puede iniciar sesión porque su nombre de usuario y contraseña se almacenaron en la página antigua.
    <ul>
      <li>Use el enlace de contraseña olvidada en la nueva página para restablecer su contraseña.</li>                  
    </ul>
  </li>
  <li>
    Si las soluciones anteriores no resuelven sus problemas...
    <ul>
      <li>Contactar a Holman por correo electrónico: <Mailto>aripartnerconnect@arifleet.com</Mailto></li>
    </ul>
  </li>
</ul>`,
  'holman_redirect__instructions_old_site': `<p>
  Puede continuar usando la página vieja hasta el <Date>30 de abril</Date>. Durante ese tiempo, intente usar la nueva página web y resuelva cualquier problema. A continuación, se presentan problemas comunes y sus soluciones:
</p>

<ul>
  <li>
    El cortafuegos corporativo bloquea el nuevo sitio web: partnerconnect.holman.com
    <ul>
      <li>Presione en el <b>botón Atrás</b> para volver a la pantalla anterior y luego presione en <b>Permanecer en el sitio anterior</b>.</li>                    
      <li>Trabaje con su equipo de soporte de TI para desbloquear el acceso a partnerconnect.holman.com</li>
    </ul>
  </li>
  <li>
    No puede iniciar sesión porque su nombre de usuario y contraseña se almacenaron en la página antigua.
    <ul>
      <li>Use el enlace de contraseña olvidada en la nueva página para restablecer su contraseña.</li>                  
    </ul>
  </li>
  <li>
    Si las soluciones anteriores no resuelven sus problemas...
    <ul>
      <li>Contactar a Holman por correo electrónico: <Mailto>aripartnerconnect@arifleet.com</Mailto></li>
    </ul>
  </li>
</ul>`,

 'add_users_to_store_search_caption':'Búsqueda de usuarios',
 'search_users_caption':'Buscar y seleccionar usuarios para otorgar acceso.',
 'search_users_add_user_text1':'Usuario no encontrado? No hay problema.',
 'search_users_add_user_text2':'Puedes configurar un nuevo usuario aquí',
 'select_users_caption':'Usuarios seleccionados',
 'select_users_none':'Ningún usuario seleccionado',
 'add_users_to_store_continue':'Termina de seleccionar usuarios y continúa',
 'add_users_to_store__no_user_found':'Usuario no encontrado',
 'add_users_to_store_grant_permissions_title':'Conceder permisos',
 'permissions_step__permissions_caption':'Otorgar permisos a',   
 'permissions_return':'Regreso',
 'permissions_save_changes':'Guarda los cambios',
 'permissions__grant_delegate_access_title':'Otorgar acceso de delegado',
 'permissions__grant_delegate_access_instructions':'Los administradores pueden crear nuevos usuarios, establecer permisos y editar permisos. Los administradores solo pueden otorgar o editar permisos para los que ya tienen acceso.',
 'permissions_yes':'Sí',
 'permissions_no':'No',
 'permissions_features':'Características de los permisos',
 'permissions_orders':'ORDENES DE COMPRA',
 'permissions_delivery_requests':'PEDIDOS DE ENTREGA DE CORTESÝA',
 'permissions_your_account_settings_contact_details':'AJUSTES Y DETALLES DE CONTACTO',
 'permissions_bank':'DETALLES BANCARIOS',
 'permissions_tax':'DETALLES DE IMPUESTOS',
 'permissions_documents':'SUBIR DOCUMENTOS',
 'permissions_auth_email':'ACTUALIZAR AUTORIZACIÓN CONFIGURACIÓN DE CORREO ELECTRÓNICO',
 'permissions_services':'SERVICIOS',
 'permissions_view':'Ver',
 'permissions_search_view':'Búsqueda / Vista',
 'permissions_create':'Crear',
 'permissions_edit':'Editar',
 'permissions_close_view':'Cerrar / Ver',
 'permissions_void':'Cancelar (nulo)',
 'permissions_upload':'Subir',
 'add_users_to_store__add_user_title':'Agregar usuario a esta tienda',
 'message_title': 'Actualización de detalles de pago en progreso',
 'permissions__car_wash_vendor_po_note': 'Para los vendedores de lavado de autos, se requieren todos los permisos de orden de compra',
 'payment_status_bulletin_body' : 'Los cambios recientes en la información de su cuenta bancaria han suspendido temporalmente todos los pagos. Esté preparado para recibir una llamada telefónica de Holman para verificar estos cambios y permitir que se reanuden los pagos.',
 'account_verify_sub_caption_for_payment_status':'Cualquier cambio en la información de su cuenta bancaria suspenderá temporalmente todos los pagos. <alert>Por razones de seguridad</alert>, esté preparado para <b>recibir</b> una llamada telefónica de Holman para verificar estos cambios.',
 'account_verify_sub_caption_for_payment_email':'Todos los cambios en el campo Destinatario de correo electrónico requieren que se vuelva a ingresar su nombre e identificación fiscal.',
 'edit_payment_email_caption':'Pago por correo electrónico del destinatario',
 'edit_payment_email_validation_error_message':'Por favor ingrese un correo electrónico de pago válido',
 'edit_payment_email_cancel__edit_caption':'Cancelar',
 'edit_payment_email_save__caption':'Guardar pago destinatario de correo electrónico',

 'payment_email_caption':'Pago por correo electrónico del destinatario',
 'edit_payment_email_information':'Editar el destinatario del correo electrónico de pago',

 'add_users_to_store_add_new_user_caption2': 'Para configurar un nuevo usuario, complete los campos a continuación y haga clic en crear usuario. Una vez creado el usuario, se enviará un correo electrónico con instrucciones para acceder a Holman PartnerConnect.',
 'manage_stores_admin':'Administración',
 'manage_stores_store_information':'Almacenar información',
 'store_profile__search_text_place_holder':'Buscar usuario',
 'store_profile__pagination_results_search_caption':' para el término de búsqueda',
 'add_users_to_store_add_new_users_caption':'Configurar nuevo usuario',
 'add_new_user_first_name_placeHolder':'Ingrese el nombre',
 'add_new_user_last_name_placeHolder':"Introduzca el apellido",
 'add_new_user_email_placeHolder':'Ingrese correo electrónico',
 'add_new_user_create_user':'Crear usuario',
 'add_new_user__email_invalid':'Formato de dirección de correo electrónico no válido',
 'add_new_user__email_invalid_required':'Se requiere E-mail',
 'add_users_to_store_return':'Regreso',
 'add_users_to_store_add_new_user__continue':'Continuar',
 'add_users_to_store_add_new_user__dirty_warning':"Esta pantalla tiene cambios sin guardar. Los nuevos usuarios que haya creado se guardarán, pero no los permisos. Haga clic en 'Cerrar' para descartar los cambios.",
 'add_users__back':'Volver a todos los usuarios',

 'add_stores_to_store_search_caption':'Buscar tiendas',
  'search_stores_caption':'Encuentra y selecciona tiendas',
  'select_stores_caption':'Tiendas seleccionadas',
  'add_stores_to_user__continue':'Termine de seleccionar tiendas y continúe',
  'add_stores_to_user__no_store_found':'No se ha encontrado ninguna tienda.',
  'add_stores_to_user__select':'Seleccionar',
  'add_stores_to_user__select_all_stores':'Seleccionar todas las tiendas',
  'add_stores_to_user__ari_vendor_id_store_id':'ID Holman / Tienda no.',
  'add_stores_to_user__address':'Dirección de la tienda',
  'add_stores_to_user__manage_stores':'Administrar tienda',
  'add_stores_to_user__pagination_results_caption':'Mostrando resultados {first}-{last} de {storeCount}',
  'add_stores_to_user__pagination_results_search_caption':' para el término de búsqueda',
  'add_stores_to_user__clear_search_results_caption':'. Borrar resultados de búsqueda',
  'add_stores_to_user__search_text_place_holder':'Buscar tienda',
  'add_stores_to_user__search_previous':'anterior',
  'add_stores_to_user__search_next':'próxima',
  'add_stores_to_user__search_no_stores_text':'No se encontraron resultados para este criterio de búsqueda.',
   'select_stores_none':'No hay tiendas seleccionadas',

  'modify_user_permissions__grant_permissions_title':'Modificar permisos',
  'modify_user_permissions__return':'Regreso',
  'modify_user_permissions__save_changes':'Guardar cambios',    
  'modify_user_permissions__store':'Tienda',

  'permissions__select_all':'Haga clic aquí para seleccionar todas las funciones de permisos',
  'permissions__deselect_all':'Haga clic aquí para anular la selección de todas las funciones de permisos',
  'permissions__bank_details':'Detalles del banco',
  'permissions__unable_to_edit_permission_level':'No puede editar esta función en su nivel de permiso actual.',
  'permissions__unable_to_edit_minimum':'El permiso de Búsqueda / Vista de PO no se puede deseleccionar porque es necesario para ver la pantalla de vista de tienda de PartnerConnect y buscar pedidos de compra.',
  'permissions__delivery_requests_us_only':'Solo disponible para usuarios de EE. UU.',
  'permissions_close_view_edit':'Cerrar / Ver / Editar',
  'user_profile_search_text_place_holder':'Buscar tienda',

  'user_profile__search_no_users_text':'No se encontraron resultados para este criterio de búsqueda.',
  'manage_stores__no_store_results':'El usuario no tiene acceso a ninguna tienda.',
  'all_stores__access_no_stores_text':'No tienes acceso a ninguna tienda.',
  'store_profile_search_no_users_text':'No se encontraron resultados para este criterio de búsqueda.',

  'user_profile':'Perfil del usuario',
  'reset_password_result_subtext3':'Este enlace ha expirado. Comuníquese con la persona que lo invitó y solicite que envíe un nuevo enlace.',
  'send_invite_result_title':'Invitación',
  'auto_negotiated_note_US':'Si no acepta estos cambios, debe llamar a Holman al \n1-866-274-1025',
  'auto_negotiated_note_CAN':'Si no acepta estos cambios, debe llamar a Holman al \n1-800-363-7676',
  'ari_issued_id':'ID emitido por Holman',
  'vendor_location':'Ubicación',
  'search_po_client_name_column_header':'Nombre del cliente',
  'search_po_full_vin_column_header':'Vin completo. No',
  'search_po_client_id_amount_column_header':'Identificación del cliente',
  'client_po_title':'Cliente PO#',
  'client_po_label':'PO del cliente',
  'search_for_client_po':'Buscar órdenes de compra por cliente PO# ...',
  'client_po_number_caption':'Cliente PO# (si está disponible)',
  'client_po_subtitle':'(si está disponible)',
  "existing_po_voided_link":"Los PO anulados por el usuario existen para este activo. Como resultado, esta orden de compra estará sujeta a una revisión adicional. {click}",
  "existing_po_voided_error_selection_continue":"Clic aquí para continuar",
  "existing_po_voided_error_selection_po":"PO",
  'recover_password_email_address_sub':'(Correo electrónico como ID de usuario)',
  'recover_password_vendorId_sub':'(i.e. 123456UA o 987654CC)',
  'recover_password_choice_sub':'¿Qué método utiliza para iniciar sesión en Holman PartnerConnect?',
  'recover_password_footer_sub':'Tenga en cuenta: la dirección de correo electrónico que ingrese DEBE coincidir con lo que está archivado con Holman.',
  "closed_po_driver_reference_number_title": "<br></br> tu referencia#",
  'coupon_header_services':'Horario de cupones',
  'pm_sheet_header':'Calendario',
  'complaint_notes_readonly_your_note':'Tu nota: ',
  'complaint_notes_readonly_ari_note':'Holman: ',
  "repair_notes_show_less":"...mostrar menos",
  "repair_notes_show_more":"...mostrar más",
  'repair_notes_your_note':'Tu Nota: ',
  'repair_notes_client_note':'Cliente: ',
  'action_required_line_items_title':'Solicitud de pedido de compra para VIN:',

  'find_part_for_unmapped_prod_code' : 'Este código de producto es incorrecto o necesita más detalles. Si es correcto, busque utilizando la búsqueda de piezas Holman anterior.',
  'search_product_code_caption': "Busque y seleccione el código de producto",
  'search_product_code_placeholder': "Buscar por código de producto o descripción",
  'product_code_search_continue': "Seguir",
  'product_code_search_none_found': "No se han encontrado resultados",

  
  'add_services_using_product_code_caption_note': "Agregue las siguientes líneas a la orden de compra utilizando los códigos de producto apropiados.",
  
'contract_pricing_cannot_be_edited_displayed_message': "Este código de producto tiene precio de contrato. El precio no se puede editar ni mostrar.",
  'contract_pricing_cannot_be_edited_message' : "Este código de producto tiene precio de contrato. El precio no se puede editar.",
  
  'repair_labor_qty': "Cant",
  'repair_labor_cost': "Lab Cost",

  'adjust_labor_cost': "Lab Cost",
  'adjust_labor_qty': "Cant",
  
  'replace_labor_cost': "Lab Cost",
  'replace_labor_qty': "Cant",

    'price_exceeds_error_message': "El campo resaltado a continuación tiene un costo demasiado alto. Verifique la pieza seleccionada y el tipo de corrección, edite el costo y luego haga clic en confirmar. Si el costo es correcto, haga clic en confirmar. El botón 'Agregar elemento de línea' se volverá a habilitar una vez que confirme su costo.",
    'price_exceeds_continue_error_message':"El campo resaltado a continuación tiene un costo demasiado alto. Verifique la pieza seleccionada y el tipo de corrección, edite el costo y luego haga clic en confirmar. Si el costo es correcto, haga clic en confirmar. El botón 'Continuar' se volverá a habilitar una vez que confirme su costo.",
    'service_exceeds_error_message': 'Los campos resaltados a continuación tienen un costo demasiado alto. Edite el costo y luego haga clic en confirmar. Si el costo es correcto, haga clic en confirmar. Los botones de guardar se volverán a habilitar una vez que confirme su costo.',
    'services_save_disabled_exceeds_tooltip_message': 'Los servicios vencidos no se pueden agregar hasta que confirme sus precios en las líneas resaltadas.',
    'save_disabled_exceeds_tooltip_message': 'No se puede agregar una línea hasta que confirme sus precios en las líneas resaltadas.',
    'save_disabled_exceeds_continue_tooltip_message': "El botón Continuar se volverá a habilitar una vez que confirme los precios en las líneas resaltadas.",
    'validation_link_message': "Confirmar {description} costo",
    'price_exceeds_confirmation': "Confirmar",

  'add_product_code_instruction': "Estas líneas han sido agregadas por un técnico de Holman, pero no tienen códigos de producto. Siga los pasos para agregar el código de producto.",
  'missing_product_code_icon_title': 'Haga clic para agregar el código de producto faltante',

  'delete_associated_line_confirmation_message': "También se eliminarán todas las líneas de piezas o mano de obra asociadas. ¿Está seguro de que desea eliminar esta línea?",
  'missing_product_code_step_continue': "Seguir",
  'missing_product_code_step_return': "Eliminar línea",
  'missing_product_code_information_1': "Nota de Holman: La línea de pedido para <line_description> </line_description> se agregó sin un código de producto. Agregue el código de producto y el precio correctos, si corresponde.",
  'missing_product_code_information_2': "Haga clic en Continuar para proporcionar la información del código de producto que falta.",
  'missing_product_code_information_3': "Haga clic en Eliminar línea para eliminar este artículo de la orden de compra.",
  'missing_product_code':' para <line_description></line_description>',
  'adjusted_line_window_tooltip':'Este código de producto tiene precio de contrato. El precio no se puede editar ni mostrar.',
  'missing_product_code_disabled_reason':'Hay líneas que faltan códigos de producto. Agregue los códigos de producto y los precios correctos, si corresponde.',
  'advanced_search_link':'BUSCAR ÓRDENES DE COMPRA',
  'advanced_search_link_2':'Buscar ordenes',
  'adv_search_ata':'Buscar piezas',

  'reject_system_adjustment': 'Negociación inicial rechazada por proveedor. Revise los precios de las piezas y el tiempo de mano de obra en consecuencia.',

  //Fleet Admin
  'fleet_admin_fallback_ari_contact_info': 'Si no acepta estos cambios, puede reenviar esta orden de compra para una revisión más detallada \no llamar al técnico de Holman.',
  'fleet_admin_fallback_contact_info': 'Si no acepta estos cambios, puede reenviar esta orden de compra para una revisión más detallada \no llamar al técnico de Holman.',
  'fleet_admin_contact_info': 'Si no acepta estos cambios, puede reenviar esta orden de compra para una revisión más detallada \no llamar a {fleetAdminName} al {fleetAdminPhone}.',
  'fleet_admin_contact_info_issue_note': 'Si tiene alguna pregunta con respecto a esta solicitud de cliente / notas, puede reenviar esta orden de compra para una revisión más detallada \no llamar a {fleetAdminName} al {fleetAdminPhone}.',

  'dealer_draft_reg_no_selection_label':'Ninguna de las anteriores',
  'dealer_draft_reg_label':'Información de Registro',
  'dealer_draft_temp_license_label':'Número de matrícula temporal',
  'dealer_draft_temp_exp_label':'Vencimiento del registro temporal',
  'dealer_draft_temp_state_label':'Estado de registro temporal',
  'dealer_draft_initial_license_label':'Licencia inicial emitida',
  'dealer_draft_temp_label':'Etiqueta temporal emitida',
  'dealer_draft_reg_details_label':'Detalles de registro',
  'dealer_draft_reg_details_error_label':'Seleccione uno de los campos siguientes',
  'dealer_draft_reg_details_select_label':'Seleccione uno',
  'dealer_draft_no_selection_error_label':'Nuestros registros indican que su concesionario debe completar la licencia inicial. Antes de continuar, comuníquese con nosotros a delivery@holman.com o al 800-257-7781 para obtener más ayuda.',
  'dealer_draft_delivery_date_error_label':'Por favor seleccione una fecha',

  //Aged POs
  'no_aged_pos_dialog':'Ninguno en este momento',
  'aged_po_title':'Tu atención necesaria',
  'aged_po_messaging':'Su tienda tiene órdenes de compra abiertas que tienen más de 30 días. Complete y cierre estas órdenes de compra lo antes posible. Holman no procesará ni pagará órdenes de compra que tengan más de 120 días de antigüedad.',
  'shopview_link':'Ir a la vista de tienda',
  'created_date_column':'Fecha de creación',
  'vin_column':'VIN',
  'po_column':'PO no.',
  'ref_column':'Su referencia #',
  'amount_column':'Monto',
  'edit_link':'EDITAR',
  'close_link':'CERCA',
  'aged_po_date':'hace días',
  'aged_po_link':'Ver pedidos antiguos',
  'aged_po_link2':'Ver pedidos antiguos',
  'holman_vendor':'HOLMAN VENDOR',

  'repair_type_change_step_continue':'Continuar',
  'repair_type_change_step_delete_line':'Eliminar línea',
  'repair_type_change_step_header':'Confirmar los detalles del trabajo para esta línea',
  'repair_type_change_step_info_1':'Nota de Holman: Un técnico de Holman agregó el elemento de línea para <line_description></line_description>, pero la estimación debe actualizarse.',
  'repair_type_change_step_info_2':'Haga clic en Continuar para actualizar la estimación.',
  'repair_type_change_step_info_3':'Haga clic en Eliminar línea para eliminar este artículo de la orden de compra',

  'ie_warning_title':"Advertencia de seguridad del navegador",
  'ie_warning_message_line1':"A partir del 31 de octubre de 2023, Holman PartnerConnect ya no será compatible con Microsoft Internet Explorer (IE) porque Microsoft ya no lo admite. Esto significa que IE ya no recibe actualizaciones de seguridad, por lo que su uso no es seguro para Holman, nuestros clientes o socios.",
  'ie_warning_message_line2':"Si desea obtener más información, consulte {articleLink} de Microsoft: ",
  'ie_warning_message_line3':"Preguntas comunes:",
  'ie_warning_message_question1':"¿Qué debería hacer después?",
  'ie_warning_message_question2':"¿Qué pasa si no sé mi nombre de usuario y contraseña porque están guardados en mi navegador?",
  'ie_warning_message_question3':"¿Qué pasa si no puedo descargar un nuevo navegador?",
  'ie_warning_message_answer1':"Debe descargar un navegador moderno como Microsoft Edge, Google Chrome o Mozilla Firefox.",
  'ie_warning_message_answer2':"Primero descargue un nuevo navegador.",
  'ie_warning_message_answer3':"Entonces puedes {resetpasswordLink}.",
  'ie_warning_message_answer4':"Póngase en contacto con su equipo de TI.",
  'ie_warning_message_answer5':"Si no tiene una persona de TI en su empresa, puede comunicarse con TI de Holman en {mailAddress}.",
  'ie_warning_article_text':"este artículo",
  'ie_warning_reset_password_text':"restablecer su contraseña",
  'ie_warning_learn_more_button':"aprende más",
  'ie_warning_signout_button':"Cerrar sesión y utilizar otro navegador",

  //Labor rate update
  'labor_rate_update_title':"Actualización de la tasa de mano de obra",
  'labor_rate_update_question_line1':"Ha cambiado la tarifa de mano de obra de ${currentRate} a ${newRate}. ¿Es esta su nueva tarifa laboral estándar?",
  'labor_rate_update_question_line2':"¿Es esta su nueva tarifa laboral estándar?",
  'labor_rate_update_yes':"Sí, solicitar nueva tarifa",
  'labor_rate_update_no':"No",
  'labor_rate_update_error':"Por favor selecciona una opcion",

  'tire_width_required':"Por favor ingrese el ancho del neumático",
  'aspect_ratio_required':"Por favor ingrese la relación de aspecto",
  'rim_diameter_required':"Por favor ingrese el diámetro de la llanta",
  'tire_manufacturer_required':"Por favor seleccione Fabricante de neumáticos",
  'tire_model_required':"Por favor introduzca el modelo de neumático",
  'tire_reason_required':"Por favor seleccione un motivo para el reemplazo",
  'old_tire_tread_depth_required':"Ingrese la profundidad de la banda de rodadura del neumático viejo para el neumático reemplazado.",
  'qty_must_match_with_tire_selection_counter_custom_message':"El número de neumáticos seleccionados para reemplazo no coincide con la cantidad definida en la línea de pedido. Por favor seleccione ",
  'qty_must_match_end_message':" llantas.",
  'qty_must_match_with_tire_selection_counter':"Debes HACER CLIC EN EL/LOS NEUMÁTICO/S del diagrama que corresponde a la posición que estás reemplazando. El neumático se volverá azul una vez que hayas hecho clic en él. Presione RETORNO para corregir su entrada.",

  'wheel_required':"Requerido",
  "account_submission_success": "Su actualización bancaria ha sido enviada para su procesamiento. Espere un día hábil completo para completar el cambio bancario. Recuerde: Para garantizar el pago de los servicios, verifique siempre su cuenta de pago al enviar la facturación en línea.",
  'account_submission_success_new_text': "Los cambios recientes en la información de su cuenta bancaria han suspendido temporalmente todos los pagos. Esté preparado para recibir una llamada telefónica de Holman para verificar estos cambios y permitir que se reanuden los pagos.",

  //Accident Management
  'images_and_estimates_requested': 'Imágenes y presupuestos solicitados.',
  'repair_approved_eta_pending': 'Reparación aprobada, se necesita ETA de reparación',
  'repair_approved': 'Reparación aprobada',
  'payment_submitted': 'Pago enviado',
  'pending_repair_approval': 'Pendiente de aprobación del {submission}',
  'original_estimate': 'presupuesto original',
  'supplement': 'suplemento {submissionNumber}',
  'show_all_waiting_claim': 'Mostrar todas las reclamaciones de {claimCount}',
  'hide_older_claim': 'Ocultar reclamos más antiguos',
  'accident_assignments_your_action_title': 'Tu acción requerida',
  'accident_assignments_approved_title': 'Aprobado',
  'accident_assignments_pending_approval_title': 'Aprobación pendiente',
  'accident_assignments_payment_in_progress_title': 'Pago en curso',
  'accident_assignments_vehicle_header': 'Vehículo',
  'accident_assignments_vin_header': 'VIN',
  'accident_assignments_claim_header': 'Afirmar #',
  'accident_assignments_status_header': 'Estado',
  'accident_assignments_view_caption': 'VISTA',
  'accident_assignments_no_claim': 'Ninguno en este momento',
  'repair_estimate_link': 'Ver todas las órdenes de compra de accidentes abiertas',
  "accident_assignments_eta_title": "Actualizar ETA de reparación",
  "accident_assignments_body_1": "Ingrese la fecha estimada de finalización de todas las reparaciones.",
  "update_eta_link": "Actualizar ETA de reparación",
  "accident_assignments_eta_note_body": "Ingrese el motivo del cambio en la ETA de reparación",
  "accident_assignments_eta_date_error": "Por favor ingrese una fecha estimada de finalización válida",
  "accident_assignments_eta_note_error": "Ingrese el motivo del cambio en la ETA de reparación",
  "accident_assignments_submission_continue_validate": "Sube {fileType} antes de continuar",
  "accident_assignments_submission_submit_validate": "Ingrese el precio antes de enviarlo para su aprobación.",
  "accident_assignments_submission_images_drag_drop": "Arrastra y suelta tus imágenes aquí o haz clic en el ícono para cargar una o más",
  "accident_assignments_submission_document_drag_drop": "Arrastre y suelte su presupuesto de reparación aquí o haga clic en el icono para cargar uno",
  'submit_for_approval_link': 'Someter para aprobacion',
  'submit_additional_images': 'Enviar imágenes adicionales',
  'return_link': 'Devolver',
  'browse_file': 'Buscar Archivo',
  'files_uploaded': '{fileCount} archivo(s) cargado(s)',
  'accident_assignments_submission_rejected_image': 'Algunos archivos no se pudieron cargar debido a un tipo de archivo incorrecto. Los tipos de imágenes aceptables incluyen: JPG PNG JPEG JPE BMP GIF JFIF',
  'accident_assignments_submission_rejected_document': 'Algunos archivos no se pudieron cargar debido a un tipo de archivo incorrecto. Los tipos de documentos aceptables incluyen: PDF',
  'accident_assignments_submission_rejected_max_files': 'La carga supera el número máximo de archivos: {fileCount} archivo(s)',
  "accident_assignments_submission_images_title": "Upload images",
  "accident_assignments_submission_estimate_title": "Cargar copia del presupuesto de reparación",
  "accident_assignments_submission_pricing_title": "Precios de reparación",
  "accident_assignments_submission_additional_images_title": "Sube imágenes adicionales",
  "accident_assignments_submission_images_body": "Envíe imágenes de las cuatro esquinas del vehículo, el odómetro, el número de placa, el VIN y cualquier área dañada. Estas imágenes serán utilizadas por el equipo de AccMan para revisar el presupuesto de reparación y estarán accesibles para el cliente y su compañía de seguros.",
  "accident_assignments_submission_estimate_body": "Para acelerar la revisión de esta asignación de accidente, asegúrese de cargar las imágenes y el presupuesto de reparación, ingrese su precio. Los tres elementos son necesarios para enviar su presupuesto de reparación al equipo de gestión de accidentes de Holman. \n\n Puede agregar imágenes adicionales aquí durante el proceso de reparación si es necesario.",
  "accident_assignments_submission_pricing_body": "Por último, ingrese el costo de la mano de obra y las piezas en las líneas siguientes.",
  "accident_assignments_submission_additional_images": "Haga clic a continuación para seleccionar imágenes adicionales para agregar a la estimación original.",
  "accident_assignments_pricing_description": "descripción",
  "accident_assignments_pricing_cost": "Costo",
  "accident_assignments_pricing_subtotal": "TOTAL PARCIAL",
  "accident_assignments_view__claim_header": "Reclamo {claimId}",
  "accident_assignments_history_details": "Mostrar detalles del historial",
  "accident_assignments_decline_assignment": "Asignación de accidentes de disminución",
  "accident_assignments_initial_submission_header": "Enviar imágenes iniciales, estimación y precios",
  "accident_assignments_initial_submission_subheader_1": "Para acelerar la revisión de esta asignación de accidentes, asegúrese de cargar imágenes, copiar la estimación de reparación e ingresar sus precios.Se necesitan los tres artículos para enviar su estimación de reparación al equipo de gestión de accidentes de Holman.",
  "accident_assignments_initial_submission_subheader_2": "Después de aprobar la estimación de reparación inicial, puede cargar cualquier foto adicional aquí.",
  "accident_assignments_submit_initial_estimate": "Enviar una estimación inicial",
  "accident_assignments_additional_images_header": "Enviar imágenes adicionales",
  "accident_assignments_submit_additional_images": "Enviar imágenes",
  "accident_assignments_additional_images_subheader": "Sube imágenes aquí",
  "accident_assignments_history_header": "Historia",
  "accident_assignments_eta_header": "Actualizar ETA de reparación",
  "accident_assignments_eta_subheader": "Ingrese la fecha de finalización estimada para estas reparaciones.",
  "accident_assignments_supplement_header": "Enviar una estimación de reparación suplementaria",
  "accident_assignments_supplement_subheader": "Cargue su estimación de reparación suplementaria aquí",
  "accident_assignments_supplement_button": "Enviar reparación suplementaria",
  "accident_assignments_history_subheader": "Estimaciones/suplementos",
  "accident_assignments_driver_contact_title": "Contacto del conductor",
  "accident_assignments_driver_contact_caption": "Contacto",
  "accident_assignments_driver_phone_caption": "Teléfono",
  "accident_assignments_driver_email_caption": "Correo electrónico",
  "accident_assignments_pending_estimate_title": "Imágenes pendientes y estimación",
  "accident_assignments_view__pending_approval_title": "Pendiente de la aprobación de Holman para la estimación {submissionNumber}",
  "accident_assignments_pending_eta_title": "Reparación aprobada: proporcione la ETA de reparación",
  "accident_assignments_approved_title": "Reparación aprobada",
  "accident_assignments_payment_in_progress_title": "Pago en progreso",
  "accident_assignments_payment_submitted_title": "Pago enviado",
  "accident_assignments_work_completed_title": "Trabajo completado",
  "accident_assignments_vehicle_vin_caption": "VIN",
  "accident_assignments_vehicle_client_caption": "Cliente",
  "accident_assignments_vehicle_unit_number_caption": "Unidad de Holman no.",
  "accident_assignments_vehicle_engine_caption": "Tipo de motor",
  "accident_assignments_vehicle_vehicle_caption": "Vehículo",
  "accident_assignments_vehicle_plate_caption": "Lámina",
  "accident_assignments_vehicle_color_caption": "color",
  "accident_assignments_vehicle_mileage_caption": "Kilometraje",
  "accident_assignments_vehicle_other_caption": "Otra",
  'vehicle_documents': 'Documentos del vehículo',
  'repair_history_of_vehicle': 'Historial de reparación del vehículo',
  "accident_assignments_unavailable_caption": "Indisponible",
  "accident_assignments_image_upload_caption": "Subido en {uploadDate}",
  "accident_assignments_image_title": "Imágenes",
  "accident_assignments_images_submission": "Recolectado para {submissionNumber}",
  "accident_assignments_pricing_header": "Fijación de precios",
  "accident_assignments_pricing_description": "Descripción",
  "accident_assignments_pricing_qty": "Cantidad",
  "accident_assignments_pricing_cost": "Costo",
  "accident_assignments_pricing_subtotal": "TOTAL PARCIAL",
  "accident_assignments_pricing_damage_parts": "PIEZAS DE DAÑO DEL VEHÍCULO",
  "accident_assignments_pricing_damage_labor": "DAÑO DEL VEHÍCULO DE PARTO",
  "accident_assignments_pricing_paint_parts": "PIEZAS DE SUMINISTROS DE PINTURA",
  "accident_assignments_pricing_paint_labor": "PINTAR TRABAJO",
  "accident_assignments_pricing_mechanical": "TRABAJO MECÁNICO",
  "accident_assignments_pricing_other": "OTRA",
  "accident_assignments_close_header": "Cerrar orden de compra No.",
  "accident_assignments_close_subheader": "Una vez que se hayan completado todas las reparaciones, incluidas las reparaciones complementarias, puede cerrar la reparación",
  "accident_assignments_eta_note_length_error": "Ingrese un motivo entre 3 y 900 caracteres",
  "claim_no_label": "Reclamo por accidente",
  'search_for_claim_no': 'Busque una estimación de accidentes por número de reclamo...',
  'search_claim_error': 'Número de reclamo no válido #{claimId}. Por favor reconfirma y vuelve a intentarlo.',
  "accident_assignments_submission_supplement_title": "Cargar copia complementaria",
  "accident_assignments_submission_supplement_pricing_body": "Envíe precios suplementarios en la línea siguiente",
  "accident_assignments_decline_claim_confirmation_title": "Rechazar tarea",
  'accident_assignments_decline_claim_confirmation_message': "Está a punto de rechazar esta tarea de reparación de accidentes y eliminarla de Holman PartnerConnect. ¿Está seguro de que desea rechazar esta tarea?",
  "accident_assignments_declined_title": "Asignación rechazada",
  'accident_assignments_close_po_disabled_message': "Actualice la ETA de reparación antes de cerrar la orden de compra",
  "accident_assignments_locked_po_message": "Actualmente, un técnico de Holman está revisando la orden de compra.",
  "accident_assignments_locked_po_2_message":"Actualmente, otro técnico de Holman está revisando la orden de compra.",
  "accident_po_link": "Ver órdenes de accidentes",
}
