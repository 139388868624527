export default {
	"skip_the_phone": "Passer le téléphone: Créer un bon de commande",
	"approved_for_service": "Approuvé pour service",
	"search_pos_by_vin8": "Search purchase orders by last 8 characters of VIN",
	"faq_enroll_question1": "Comment puis-je devenir un fournisseur de services Holman?",
    "faq_enroll_answer1": "Cliquez simplement sur le mot « Enroll » sur la page d’accueil.  Ceci est un processus à 2 étapes. Vous serez avisé via un courriel lorsque vous serez accepté. Vous pourrez par la suite entretenir votre compte en ligne.",
    "faq_enroll_question54": "Comment puis-je devenir un fournisseur de services Holman?",
    "faq_enroll_answer54": "S'il vous plaît contacter Holman au numéro sans frais indiqué",
	"faq_enroll_question2": "Lorsque j'essaie de m'inscrire on me demande mon # d'identification de taxe fédérale",
	"faq_enroll_answer2": "Votre numéro d'indentification pour la taxe fédérale comporte \"9\" chiffres; les entrer sans le tiret. Ex.: correct/123456789 Incorrect/12-3456789",
	"faq_purchase_question3": "Que veut dire bons de commandes approuvés?",
	"faq_purchase_answer3": "Les bons de commande approuvés sont vos factures en attente étant toujours en attente de paiement.",
	"faq_purchase_question4": "Que veut dire bons de commande traités?",
	"faq_purchase_answer4": "Les bons de commande traités sont vos bons de commande qui ont été fermés pour paiement. Vous pouvez vérifier l'état de ces paiements en cliquant  sur la date de traitement de la facture sélectionnée.",
	"faq_purchase_question9": "Pourquoi le montant du paiement est-il différent du montant que j'ai soumis?",
	"faq_purchase_answer9": "Il y a un montant Holman Payer appliqué au total de chaque facture ; peu importe quels services sont rendus. Par exemple : LOF, inspections, services, taxes, etc.",
	"faq_enroll_question12": "Comment vais-je recevoir mon avis de paiement?",
	"faq_enroll_answer12": "L'avis de paiement par courriel provient de Fleet-Alert@holman.com. Ceci n'est pas un pourriel et ne peut être recréé. Veuillez l'ajouter à votre carnet d'adresses car ces avis sont envoyés en vrac et peuvent facilement être pris pour des pourriels.",
	"faq_bank_question13": "Pourquoi je ne reçois pas toujours un courriel chaque fois que des fonds sont déposés dans mon compte chèque?",
	"faq_bank_answer13": "Si vous ne permettez pas à notre adresse Fleet-Alert@holman.com d'entrer dans votre courriel, elle pourrait être reconnue comme un pourriel. Vous pouvez toujours vérifier les dépôts sous \"Processed PO\" ou \"Invoice Search\" à l'aide du # ACH ou du # de bon de commande d'une facture spécifique.",
	"faq_bank_question14": "Pourquoi les dépôts ne peuvent-ils pas être faits dans un compte épargne?",
	"faq_bank_answer14": "Pour le moment, nous ne pouvons accepter qu'un compte chèque pour les transferts ACH. Étant donné que la grande majorité des entreprises utilisent un compte chèque, c'était le meilleur choix à offrir à nos fournisseurs de services.",
	"faq_profile_question18": "Comment puis-je mettre à jour les informations de mon profil?",
	"faq_profile_answer18": "Sous \"YOUR ACCOUNT\", vous pouvez changer votre mot de passe, votre adresse de courriel et les informations de votre compte chèque, mettre à jour les informations de votre contact, adresse etc.",
	"faq_general_operations_question24": "Y a-t-il des charges ou des frais additionnels lorsqu'on utilise \"Holman Partner Connect\"?",
	"faq_general_operations_answer24": "Non, \"Holman Partner Connect\" peut aider à réduire le besoin de faire des appels, à augmenter la productivité et la flexibilité de votre atelier. Il n'y a aucuns frais additionnels lorsque vous utilisez ce service à valeur ajoutée.",
	"faq_general_operations_question25": "Chacun des utilisateurs de mon atelier a-t-il besoin d'avoir un mot de passe individuel?",
	"faq_general_operations_answer25": "Non, \"Holman Partner Connect\" utilise un concept de connexion unique, composé de vos numéro d'identification d'utilisateur et mot de passe actuels.",
	"faq_general_operations_question26": "Puis-je commencer à compléter un bon de commande et le soumettre que plus tard?",
	"faq_general_operations_answer26": "Oui, les bons de commande ayant été commencés et non soumis pour approbation apparaîtront dans la section \"Your Action is Required\"",
	"faq_general_operations_question27": "Est-il possible de faire des changements après qu'un bon de commande a été soumis pour approbation?",
	"faq_general_operations_answer27": "Oui, les utilisateurs sont autorisés à effectuer des modifications sur les bons de commande approuvés ou en attente et les resoumettre pour approbation.",
	"faq_general_operations_question28": "Sur le nouveau site web, puis-je encore faire une demande de paiement sur les bons de commande autorisé et ouverts?",
	"faq_general_operations_answer28": "Oui, \"Holman Partner Connect\" vous permet de traiter les paiements en ligne, ainsi que de visualiser l'état des paiements.",
	"faq_general_operations_question29": "Avec \"Holman Partner Connect\" aurai-je la possibilité de mettre à jour les informations sur mon atelier (bancaire,courriel, profil de service)?",
	"faq_general_operations_answer29": "Oui, \"Holman Partner Connect\" vous permet de mettre à jour les informations importantes de votre compte comme le compte de banque, le téléphone, les contacts pour le courriel, les services offerts, etc. Ceci peut être trouvé en cliquant sur \"YOUR ACCOUNT\" au haut de la page principale d'accueil.",
	"faq_general_operations_question30": "Comment Holman m'aidera-t-elle à développer mon entreprise?",
	"faq_general_operations_answer30": "Notre site \"Holman Partner Connect\" affichera les véhicules se trouvant dans votre zone de service et les classera, par exemple, par catégories d'actifs ayant besoin d'entretien maintenant, de véhicules sur lesquels vous avez auparavant travaillé et ceux sur lesquels vous ne l'avez pas fait.",
	"faq_general_operations_question31": "Est-ce que Holman PartnerConnect est disponible en français ?",
	"faq_general_operations_answer31": "Non. Pour un service en français, veuillez nous appeler au 905-803-8000 ou 1-800-361-5882.",
	"faq_general_information_question31": "\"Holman Partner Connect\" c'est quoi?",
	"faq_general_information_answer31": "\"Holman Partner Connect\" est un tout nouveau portail d'affaires bidirectionnel conçu pour vous faire gagner du temps et de l'argent, tout en vous fournissant une façon facile de gérer la majeure partie de vos interactions commerciales avec Holman. Ce nouveau site remplacera notre page web pour fournisseur actuelle.",
	"faq_general_information_question32": "Comment \"Holman Partner Connect\" me profitera-t-il?",
	"faq_general_information_answer32": "\"Holman Partner  Connect\" vous permettra de créer des bons de commande en ligne pour la plupart des opérations quotidiennes courantes, étant généralement effectuées dans votre atelier. Dans la plupart des cas vous pouvez simplement ignorer le téléphone en créant un bon de commande sur Internet. En plus de créer des bons de commande en ligne, notre nouveau système vous fournira également un aperçu du statut d'un bon de commande, tout en permettant les modifications en direct. Une fois que les travaux sur le véhicule ont été complétés, vous pouvez facilement et rapidement réviser et fermer le bon de commande. Toutes ces fonctionnalités sont conçues pour vous faire sauver du temps et augmenter la productivité; ce qui se traduit par des économies réelles et tangibles au fur et à mesure que la journée avance.",
	"faq_title_help":"Aide",
	"faq_caption_help":"Foire aux questions",
	"faq_enrollment_title":"Inscription/Enregistrement",
	"faq_purchaseOrder_title":"Bons de commande / Paiement / Facturation",
	"faq_bank_title":"Comptes bancaires",
	"faq_profile_title":"Information sur le profil",
	"faq_general_operations_title":"Fonctionnement général du système",
	"faq_general_information_title":"Information générale",
	"contact_footer_1":"Cliquez ici",
	"contact_footer_2":" pour nous envoyer votre question par  courriel et nous vous répondrons sous peu",
	"help_faq_return":"Retourner",
	"faq_purchase_question100":"Pourquoi le paiement reçu est différent du montant qui a été  soumis ?",
	"faq_purchase_answer100":"Il y a un montant Holman Payer appliqué au total de chaque facture ; peu importe quels services sont rendus. Par exemple : LOF, inspections, services, taxes, etc.",
	"faq_general_operations_question101":"Est-ce que je pourrai encore mettre à jour l’information pour mon entreprise (information bancaire, contact) sur  Holman PartnerConnect?",
	"faq_general_operations_answer101":"Oui, Holman PartnerConnect permet aux utilisateurs de mettre à jour l’information essentielle telle que les informations bancaires ou adresses.  Vous trouverez l’onglet ``votre compte``  en haut de la page d’accueil.",
	"faq_general_information_question102":"Comment Holman PartnerConnect me sera profitable?",
	"faq_general_information_answer102":"Holman PartnerConnect  vous permet de créer des bons de commandes en ligne pour les nettoyages /lavages et autres services fournis par votre établissement. Dans la plupart des cas, il vous pouvez éviter l’appel à Holman en créant votre bon de commande en ligne. Notre système vous permet de faire le suivi rapide du  statut de votre bon de commande, tout en vous permettant d’effectuer les modifications nécessaires de votre bon de commande. Une fois le travail complété, il vous suffit de vérifier et fermer votre bon de commande. Tous ces changements ont été mis en place pour vous permettre des gains en temps et efficacité, cela signifie des économies tangibles et réelles dans vos journées déjà bien occupées.",
    "help_link":"Aide",
    "contact_link": "Contact",
    "search_vehicle_by_vin8": "Rechercher un véhicule",
    "your_account": "Votre compte",
    "sign_out": "Déconnexion",
    "help_link_2": "Aide",
    "contact_link_2": "Contact",
    "open_pos_link_2": "Voir tous les bons de commande de maintenance",
    "open_pos_link_header": "Afficher les commandes",
    "close": "Fermer",
    "ok": "OK",
    "session_expiring_caption": "Session arrive à terme!",
    "session_expiring_note": "Votre session se terminera dans 30 minutes, cliquez sur OK pour demeurer connecté. Si vous vous déconnectez, les changements effectués sur cette page ne seront pas sauvegardés.",
    "login_id": "ID",
    "password": "Mot de passe",
    "rememberMe": "Se souvenir de moi",
    "selectCountry": "Choisissez votre pays",
    "headerCaption": "Connection du fournisseur",
    "add_another_complaint": "Ajouter une autre réclamation",
    "add_line_items_for_complaint": "Ajouter des lignes d'items {complaintType}",
    "add_line_items_for_services": "Ajouter des lignes d'items pour Services dus",
    "add_edit_view_complaint_view_complaint_type": "Type de réclamation",
    "add_edit_view_complaint_view_complaint_caption": "Réclamation",
    "line_item_view_approved": "Lignes d'items approuvées",
    "line_item_view_pending": "Lignes d'items en attente",
    "line_item_view_rejected_items": "Lignes d'items rejetées",
    "line_item_view_quantity": "Qté",
    "line_item_view_hour": "Heure",
    "line_item_view_cost": "Coût",
    "line_item_view_amount": "Montant",
    "po_totals_view_subtotals": "Sous-total",
    "po_totals_view_approved": "Approuvé",
    "po_totals_view_pending_approval": "En attente d'approbation",
    "approved_pos_caption": "Approuvé pour service",
    "waiting_for_ari_approval_caption": "En attente de l'approbation d'Holman",
    "waiting_for_ari_approval_caption_mex":"En attente de l'approbation d'ARIZA",
    "waiting_for_client_approval_caption": "En attente de l'approbation du client",
    "waiting_for_driver_validation_caption": "En attente de confirmation du conducteur",
    "purchase_order_payment_in_progress": "Paiement en cours",
    "your_action_required": "Action requise de votre part",
    "edit_purchase_order": "No de bon de commande",
    "edit_purchase_order_edit_mode": "No de bon de commande {poNumber}",
    "close_purchase_order": "Fermer bon de commande",
    "purchase_order_no": "No de bon de commande {poNumber}",
    "approved_po_total": "Total approuvé:",
    "bank_account_no": "Derniers quatre chiffres du compte bancaire: {bankAccountNo}",
    "close_dialog": "Fermer",
    "add_line_item_to_po": "Ajouter une ligne d'item au bon de commande",
    "adjust_hours": "Heures",
    "adjust_rate": "Tarif",
    "adjust_amount": "Montant",
    "pm_qty": "Qté",
    "pm_cost": "Coût",
    "pm_amount": "Montant",
    "repair_hours": "Heures",
    "repair_rate": "Tarif",
    "repair_amount": "Montant",
    "replace_hours": "Heures",
    "replace_rate": "Tarif",
    "replace_qty": "Qté",
    "replace_cost": "Coût Pièce",
    "replace_amount": "Montant",
    "freq_used_ata_code": "Ou choisir parmi les pièces entrées fréquemment:",
    "save_and_add_caption": "Sauvegarder et ajouter des lignes d'items supplémentaires",
    "close_without_save": "Fermer sans sauvegarder",
    "save": "Sauvegarder",
    "add_pm_caption": "Ajouter des services dus",
    "service_header_caption": "Service",
    "total_part_header_caption": "Pièces",
    "total_labor_header_caption": "Main-d'œuvre",
    "total_amount_header_caption": "Total",
    "parameter_caption": "Paramètres",
    "problem_with_close_po": "Problème rencontré? Réviser le bon de commande avant de le fermer",
    "total": "Total",
    "tax_required": "Vérifiez ici que les taxes ne sont pas requises pour ce bon de commande",
    "invoice_number": "No Facture.",
    "invoice_date": "Date Facture",
    "invoice_name": "Votre nom",
    "subtotal": "Sous-total",
    "repair_completion_date": "Date de fin de réparation",
    "tax_exempted": "Exempt de taxes?",
    "enter_tax": "Entrer les taxes",
    "tax_exempted_yes": "Oui",
    "tax_exempted_no": "Non",
    "tax_gst": "TPS",
    "tax_pst": "TVP",
    "tax_hst": "TVH",
    "tax_qst": "TVQ",
    "payment_confirmation_text": "Confirmez que le paiement sera versé au compte se terminant par {bankAccountNo}",
    "work_details_text": "Confirmez que les travaux détaillés au bon de commande sont complètement achevés",
    "no_tax_reason": "Raison Aucune taxe",
    "no_tax_reason_repair_type": "Type de réparation exempte de taxe",
    "no_tax_reason_interstate": "Véhicule commerce inter-états exempt",
    "close_po_caption": "Demander le paiement de ${total} et fermer le bon de commande",
    "error_invoice_no": "Veuillez entrer un numéro de facture de plus de 3 caractères et supprimer les espaces vides.",
    "error_invoice_date": "Veuillez entrer la date de la facture.",
    "error_invoice_name": "Veuillez entrer le prénom et le nom de famille.",
    "error_invoice_completion_date": "Veuillez entrer la date d'achèvement de la réparation.",
    "error_invoice_tax": "Veuillez entrer les taxes.",
    "error_exempted_reason": "Veuillez fournir la raison pour aucune taxe.",
    "error_payment_confirmation": "Veuillez confirmer le numéro de compte.",
    "error_work_details": "Veuillez confirmer que le travail a été complété.",
    "page_title": "Fermer bon de commande.",
    "close_confirmation_title": "Ajuster Bon de commande",
    "close_confirmation_message": "Est-ce que des ajustements sont requis pour ce bon de commande?",
    "close_confirmation_yes": "Oui",
    "close_confirmation_no": "Non",
    "add_complaint_caption": "Réclamation 1",
    "goto_next_link_caption": "Aller à la prochaine étape",
    "add_services_caption": "Ce véhicule a des services dus",
    "add_services_caption_note": "CHOISIR POUR AJOUTER AU BON DE COMMANDE",
    "schedule_header": "Cédule",
    "due_header": "Dû",
    "odometer_details_caption": "Mise-à-jour requise",
    "odometer_details_odometer": "Odomètre",
    "odometer_details_last_reading": "Dernière lecture",
    "odometer_details_engine_hours": "Compteur horaire",
    "odometer_details_select_one": "Choisir un",
    "odometer_details_at_shop": "Véhicule est à l'atelier",
    "odometer_details_not_at_shop": "Véhicule n'est pas à l'atelier",
    "odometer_details_save_update": "Confirmez le véhicule et passez à l'étape suivante",
    "odometer_details_unavailable": "Non disponible",
    "ododmeter_Validation_Message_1": "Attention: L'odomètre entré {newOdometer} diffère de l'odomètre estimé de: {odometerDifference}.",
    "ododmeter_Validation_Message_2": "Attention: L'odomètre entré excède l'odomètre estimé de: {newOdometer}.",
    "ododmeter_Validation_Message_3": "Attention: L'odomètre entré {newOdometer} diffère de l'odomètre prévu {estimatedOdometer} pour le {currentDate} de: {estimatedOdometerDiff}.",
    "ododmeter_Validation_Message_4": "Attention: L'odomètre entré {newOdometer} diffère de l'odomètre estimé de: {odometerDifference}.",
    "ododmeter_Validation_Message_5": "Attention: Cette unité est identifiée en tant qu’ÉQUIPEMENT. La lecture du compteur kilométrique sera copiée dans la lecture du compteur horaire en fonction des besoins du client.",
    "hourmeter_Validation_Message": "Attention: Le compteur d'heures ne peut être inférieur à la dernière valeur enregistrée..",
    "action_required_issue_note": "Si vous avez des questions concernant ces ajustements, veuillez appeler au 1-866-274-1025 afin de parler avec un technicien d'Holman.",
    "action_required_issue_note_can": "Si vous avez des questions concernant ces ajustements, veuillez appeler au \n1-800-363-7676 afin de parler avec un technicien d'Holman.",
    'action_required_submitted_description':'soumis',
    "action_required_title": "Action requise de votre part",
    "action_required_note_from_ari_description":"Note de l'Holman",
    "action_required_note_from_ari_description_client":"Note du client",
    "action_required_provide_note_description":"S'il vous plaît fournir votre réponse ci-dessous",
    "action_required_submit_note_all_rejected":"Ce bon de commande sera annulé une fois que vous aurez examiné et accepté les lignes rejetées, puis cliqué sur Soumettre.",
    "action_required_submit_note_client_auth":"Ce bon de commande sera envoyé à l'autorisation du client/Holman une fois que vous aurez examiné et accepté les modifications, puis cliqué sur Soumettre.",
    "action_required_submit_note_need_notes":"Ce bon de commande sera envoyé à l’approbation Holman une fois que vous aurez examiné et accepté les modifications, puis cliqué sur Soumettre.",
    "action_required_submit_note_po_approved":"Ce bon de commande sera approuvé une fois que vous aurez examiné et accepté les modifications, puis cliqué sur Soumettre." ,
    "action_required_line_item_review":"La revue ",
    "action_required_line_item_review_complete":"Cette ligne est passée en revue",
    "action_required_line_item_review_pending":"Cette ligne n'est pas passée en revue",
    "action_required_line_status_need_notes":"Notes requises",
    "action_required_line_status_adjusted":"Ajusté",
    "action_required_line_status_rejected":"Rejeté",
    "action_required_line_status_created":"Créé par Holman",
    "action_required_line_item_view_note":"Revoir les lignes suivantes",
    "action_required_po_edit": "Modifier Bon de commande",
    "action_required_forward_for_further_review": "À transmettre pour un examen plus approfondi",
    "adjusted_line_window_title":"S'il-vous-plaît évaluez",
    "need_more_notes_window_title":"S'il-vous-plaît évaluez",
    "rejected_line_window_title":"S'il-vous-plaît évaluez",
    "new_line_window_title":"S'il-vous-plaît évaluez",    
    "action_required_po_submit":"Soumettre",
    "note_show_more_description":'montre plus',
    "note_show_less_description":'montre moins',
    "complaint_notes_readonly_show_less":"...montre moins",
    "complaint_notes_readonly_show_more":"...montre plus",   
    "repair_notes_show_less":"...montre moins",
    "repair_notes_show_more":"...montre plus",   
    "need_more_notes_submit_description":" Soumettre ", 
    "need_more_notes_vendor_note_error_message":"S'il vous plaît entrer une réponse valide",
    "adjusted_line_standard_ari_note_cost":"Nous avons apporté les ajustements mentionnés ci-dessus à ce bon de commande.",
    "adjusted_line_standard_ari_note_labor":"Nous avons apporté les ajustements mentionnés ci-dessus à ce bon de commande.",
    "approval_request_unsaved_changes_prompt":"Cette commande d'achat contient des modifications non enregistrées. Veuillez cliquer sur ANNULER pour rester sur cet écran et passer en revue les modifications. Cliquez ensuite sur Soumettre en bas de l'écran pour envoyer les modifications à Holman.",
    "need_more_notes_line_item_related_line_description":"La ligne associée {associatedLineType} ne sera approuvée que lorsque vous aurez fourni plus de notes pour cette ligne.",
    "need_more_notes_line_item_status_description":"Holman Besoin d'informations supplémentaires sur {productDescription}",
    "need_more_notes_line_item_reviewed_description":"Vous avez examiné cette ligne",
    "new_line_item_created_by_ari":"Créé par Holman",
    "new_line_item_reviewed_description":"Vous avez examiné cette ligne",
    "new_line_item_accept_description":" Acceptez ",
    "new_line_item_status_description":"{productDescription} a été créé par Holman",
    "rejected_line_item_status_description":"{productDescription} a été rejeté par Holman",
    "rejected_line_item_status_description_client":"{productDescription} a été rejeté par le client",
    "rejected_line_payment_not_authorized_description":"AUCUN PAIEMENT NE SERA AUTORISE POUR CETTE LIGNE",
    "rejected_line_item_accept_description":" Acceptez ",
    "rejected_line_item_reviewed_description":"Vous avez examiné cette ligne",
    "adjusted_line_item_reviewed_description":"Vous avez examiné cette ligne",
    "adjusted_line_item_related_line_description":"La ligne associée {associatedLineType} ne sera approuvée que lorsque vous aurez accepté ce changement.",
    "adjusted_line_item_status_description":"{productDescription} a été ajusté par Holman",
    "adjusted_line_item_status_description_client":"{productDescription} a été ajusté par le client",
    "adjusted_line_item_accept_description":" Acceptez ",
    "adjusted_line_item_view_quantity": "Qté",
    "adjusted_line_item_view_hour": "Heure",
    "adjusted_line_item_view_cost": "Coût",
    "adjusted_line_item_view_amount": "Montant",
    "adjusted_line_item_view_submitted": "Soumis",
    "adjusted_line_item_view_ari_adjustment": "Ajustement Holman",
    "adjusted_line_item_view_ari_adjustment_client": "Ajustement du client",
    "adjusted_line_item_view_ari_created": "Créé par Holman",
    "adjusted_line_item_view_accept_adjustments": "Accepter l'ajustement et continuer avec la réparation",
    "adjusted_line_item_view_reject_intial_adjustment_message":"Rejeter l'ajustement et transmettre pour un examen plus approfondi",
    "adjusted_line_item_view_accept_adjustments_client_auth": "Accepter l'ajustement et débuter le processus d'autorisation du client",
    "reject_intial_adjustment_message":"Rejeter l'ajustement et le transmettre pour un examen plus approfondi",
    "dri_val_complaint_title": "Réclamation",
    "dri_val_complaint_select": "Choisir les types de réclamations en attente de validation du conducteur",
    "complaint_view_complaint_caption": "Réclamation",
    "po_actions_diagnosis_view_caption": "Diagnostic",
    "po_actions_line_item_view_quantity": "Qté",
    "po_actions_line_item_view_hour": "Heure",
    "po_actions_line_item_view_cost": "Coût",
    "po_actions_line_item_view_amount": "Montant",
    "rejected_line_item_view_quantity": "Qté",
    "rejected_line_item_view_hour": "Heure",
    "rejected_line_item_view_cost": "Tarif",
    "rejected_line_item_view_amount": "Montant",
    "rejected_line_item_view_submitted": "Soumis",
    "rejected_line_item_view_acknowledge_lines": "   Accepter la ligne  {lineTotal, plural, one {item} other {items}} non autorisée et continuer avec la réparation",
    "rejected_line_item_view_acknowledge_lines_client_auth": "Accepter la ligne {lineTotal, plural, one {item} other {items}} non autorisée pour débuter le processus d'autorisation du client",
    "rejected_line_item_view_acknowledge_lines_and_cancel": "Accepter la ligne {lineTotal, plural, one {item} other {items}} non autorisée et annuler la réparation",
    "rejected_line_item_view_no_payment_authorized": "Le paiement ne sera pas autorisé pour {lineTotal, plural, one {ces éléments de campagne} other {ces éléments de campagne}}.",
    "authorization_status_reason": "Note du technicien d'Holman",
    "authorization_status_reason_client": "Note du client",
    "rejected_line_item_view_no_payment_authorized_lines": "",
    "services_view_caption_2": "Entretien",
    "adjustment_required": "Ajustement requis",
    "not_authorized": "Non autorisé",
    "adjust_reject_purchase_order_number": "No de bon de commande",
    "shopview_approved_pos_caption": "Approuvé pour service",
    "shopview_waiting_for_driver_validation_caption": "En attente de confirmation du conducteur",
    "shopview_waiting_for_ari_approval_caption": "En attente de l'approbation d'Holman",
    "shopview_waiting_for_ari_approval_caption_mex":"En attente de l'approbation d'ARIZA",
    "shopview_waiting_for_client_approval_caption": "En attente de l'approbation du client",
    "shopview_your_action_required": "Action requise de votre part",
    "active_po_vehicle_column_header": "Véhicule",
    "active_po_vin_column_header": "NS",
    "active_po_po_total_amount_column_header": "Montant",
    "active_po_po_approved_amount_column_header": "Approuvé",
    "active_po_po_pending_amount_column_header": "En attente",
    "po_edit_link_caption": "MODIFIER",
    "po_view_link_caption": "VOIR",
    "po_close_link_caption": "FERMER",
    "po_reply_link_caption": "Répondre",
    "today": "Aujourd'hui",
    "none": "None",
    "no_pos_caption": "Aucun pour le moment",
    "show_all_waiting_pos": "Montrer tous les {poCount} bons de commande",
    "hide_older_pos": "Masquer les vieux bons de commande",
    "payment_in_progress_caption": "Paiement en cours",
    "payment_in_progress_vehicle_column_header": "Véhicule",
    "payment_in_progress_vin_column_header": "NS",
    "payment_in_progress_po_number_column_header": "No bon de commande",
    "payment_in_progress_po_date_column_header": "Date Bon de commande",
    "payment_in_progress_po_total_amount_column_header": "Montant",
    "payment_in_progress_po_view_link_caption": "VOIR",
    "payment_in_progress_no_pos_caption": "Aucun pour le moment",
    "payment_in_progress_today": "Aujourd'hui",
    "shopview_no_pos_caption": "Aucun bon de commande ouvert",
    "diagnosis_view_caption": "Diagnostic",
    "diagnosis_view_caption1": "Demander autorisation pour les frais du diagnostic:",
    "diagnosis_view_caption2": "heure(s) à",
    "estimated_completion_description": "Estimation de l'achèvement de toutes les réparations et de tous les services",
    "Estimated_Completion_Date_Morning": "Matin",
    "Estimated_Completion_Date_Afternoon": "Après-midi",
    "Estimated_Completion_Date_Evening": "Soirée",
    "user_action_add_caption": "Demander autorisation",
    "user_action_view_caption": "Fermer bon de commande",
    "tax_edit_text": "Les taxes peuvent être ajoutées/édités lors de la fermeture du bon de commande",
    "preventive_maintenance_caption": "Entretien",
    "vehicle_details_vin_no": "NS",
    "vehicle_details_client": "Client",
    "vehicle_details_ari_unit_no": "No d'unité Holman",
    "vehicle_details_engin_type": "Type de moteur",
    "vehicle_details_engin_hours": "Compteur horaire",
    "vehicle_details_vehicle": "Véhicule",
    "vehicle_details_plate": "Plaque",
    "vehicle_details_color": "Couleur",
    "vehicle_details_mileage": "Mètre",
    "vehicle_details_other": "Autre",
    "vehicle_details_documents": "Documents du véhicule",
    "vehicle_details_parameters": "Documents du client",
    "vehicle_details_po_number": "No bon de commande",
    "vehicle_details_unavailable": "Non disponible",
    "approved_items": "Lignes d'items",
    "line_items": "Lignes d'items",
    "po_actions_po_totals_view_subtotals": "Sous-total",
    "po_actions_po_totals_view_tax": "Taxes",
    "po_actions_po_totals_view_total": "Total",
    "po_actions_vehicle_details_client": "Client",
    "po_actions_vehicle_details_ari_unit_no": "No d'unité Holman",
    "po_actions_vehicle_details_vehicle": "Véhicule",
    "po_actions_vehicle_details_mileage": "Mètre",
    "estimated_completion_morning": 'Matin',
    "estimated_completion_afternoon": 'Après-midi',
    "estimated_completion_evening": 'Soirée',
    "estimated_completion_unavailable": 'Non disponible',
    "purchase_order_title_unavailable": 'Non disponible',
    "po_actions_vehicle_details_vin_no": 'NS',
    "close_po_submit_1": 'Demander le paiement de ',
    "close_po_submit_2": ' et fermer le bon de commande',
    'user_action_cancel_po': 'Annuler le bon de commande',
    'cancel_po_confirmation_no': 'Non',
    'cancel_po_confirmation_yes': 'Oui ',
    'cancel_po_confirmation_title': 'Annuler le bon de commande',
    'cancel_po_confirmation_message': "Vous êtes sur le point d'annuler ce bon de commande et de le supprimer de Holman PartnerConnect. Êtes-vous sûr de vouloir annuler le bon de commande?",
    'add_line_items_for_maintenance': "Ajouter des lignes d'items pour Entretien",
    "signed_out": 'Déconnexion réussie.',
    'user_action_edit_caption': 'Fermer le bon de commande',
    "search_po_caption": "Rechercher par",
    "search_po_service_in_progress_caption": "Service en cours",
    "search_po_payment_in_progress_caption": "Paiement en cours",
    "search_po_paid_caption": "Payé",
    "search_po_po_number_column_header": "PO no.",
    "search_po_po_date_column_header": "Date Bon de commande",
    "search_po_po_status_column_header": "Statut",
    "search_po_invoice_no_column_header": "No Facture",
    "search_po_po_payment_requested_column_header": "Paiement demandé",
    "search_po_po_payment_date_column_header": "Date du paiement",
    "search_po_po_total_amount_column_header": "Montant",
    "search_po_none": "None",
    "search_po_po_view_link_caption": "VOIR",
    "search_po_today": "Aujourd'hui",
    "search_po_vin8_no_pos_caption": "Aucun pour le moment",
    "search_po_no_pos_caption": "Aucun pour le moment",
    "search_po_waiting_for_ari_approval": "En attente d'approbation d'Holman",
    "search_po_waiting_for_ari_approval_mex": "En attente d'approbation d'ARIZA",
    "search_po_waiting_for_client_approval": "En attente d'approbation du client",
    "search_po_waiting_for_vendor_approval": "En attente d'approbation du fournisseur",
    "search_po_approved": "Approuvé",
    'close_po_confirmation_title': `Fermer Bon de commande`,
'close_po_has_diagnosis_lines_only_message': "Les bons de commande ne peuvent être fermés que pour le diagnostic. Veuillez corriger les postes pour indiquer les réparations effectuées et les soumettre à nouveau ou contactez le 1-866-274-1025 pour obtenir de l'aide.",
'close_po_confirmation_ok': 'OK',
"close_po__accident_claim": "Pour la réclamation {claimId}",
'repair_hide_labor': "La main-d'œuvre pour {newProductDescription} devrait être incluse dans {existingProductDescription}",
'adjust_hide_labor':"La main-d'œuvre pour {newProductDescription} devrait être incluse dans {existingProductDescription}",
'replace_hide_labor': "La main-d'œuvre pour {newProductDescription} devrait être incluse dans {existingProductDescription}",
'add_edit_view_confirmation_title': 'Modifier Bon de commande',
'add_edit_view_confirmation_message': "Si vous essayez de modifier la quantité ou le prix d'un article, supprimez les pièces et la main-d'œuvre et soumettez à nouveau les nouvelles valeurs. Êtes-vous sûr de bien vouloir supprimer cet élément?",
'add_edit_view_po_confirmation_no': 'Non',
'add_edit_view_confirmation_yes': 'Oui',
'complaint_code_MA99': 'Entretien',
'complaint_code_RI99': "Sécurité / Insp. Gouv.",
'complaint_code_TI99': 'Pneus / Roues',
'complaint_code_BR99': 'Système de freinage',
'complaint_code_LI99': 'Éclairage / Filage',
'complaint_code_CH99': 'Recharge / Démarrage',
'complaint_code_EN99': 'Système moteur',
'complaint_code_DR99': 'Maniabilité / Capteurs',
'complaint_code_CO99': 'Système de refroidissement',
'complaint_code_FU99': 'Système de carburant',
'complaint_code_ST99': 'Système de direction',
'complaint_code_SU99': 'Suspension',
'complaint_code_EX99': "Système d'échappement",
'complaint_code_AC99': 'Système de chauffage / Climatisation',
'complaint_code_UN99': 'Autre problème de système',
"complaint_error": "Veuillez ajouter le type de réclamation afin de pouvoir continuer.",
"complaint_type_header": "CHOISIR UN TYPE DE RÉCLAMATION",
"complaint_type": "Type de réclamation:",
"change_complaint_type": "Modifier le type de réclamation",
"add_complaint_type": "Ajouter le type de réclamation",
"warranty": "Garantie:",
"warranty_months": "Mois",
"warranty_miles": "Kilomètres",
"warranty_hours": "Heures",
"contact_footer": "<a href='/contact/FeedbackForm'> Cliquez ici </a> pour nous envoyer votre question par courriel et nous vous répondrons sous peu",
"return": "Retour",
"help_title": "Aide",
"documents_browse_button": "Parcourir les fichierse",
"documents_cancel_button": "Annuler",
"documents_hide": "Masquer les documents",
"documents_return_button": "Retour",
"documents_save_button": "Sauvegarder nouveau document",
"documents_upload": "Télécharger nouveau document",
"documents_header_name": "Nom",
"documents_header_upload_date": "Télécharger date",
"documents_header_document_type": "Type de document",
"documents_show_documents": "Montrer les documents",
"documents_title": "Documents",
"documents_title_for_vehicle": "Documents pour NS {vin8}",
"documents_file_name": "Nom de fichier sélectionné",
"documents_name": "Nom",
"documents_document_type": "Type de document",
"documents_date_received": "Date reçue",
"documents_name_required": "Veuillez entrer un nom.",
"documents_document_type_required": "Veuillez sélectionner un type de document.",
"documents_drag_drop": "Glissez et déposez votre fichier ici ou cliquez pour parcourir les fichiers.",
"documents_none": "Aucun pour le moment",
"help_caption": "Foire aux questions",
"faq_troubleshoot_title": "Dépannage",
"faq_purchase_question5": "Comment puis-je trouver des activités antérieures sur les pages Bon de commande?",
"faq_purchase_answer5": "Les pages Bon de commande montre en premier les activités les plus récentes pour votre compte. Utilisez la flèche Mois précédent pour vois les bons de commande de dates antérieures. Utilisez la flèche Prochain mois pour vous déplacé à une date ultérieure. Vous pouvez aussi choisir une date directement de la liste déroulante. Seules les dates pour lesquelles il y a eu des activités s'affichent.",
"faq_purchase_question6": "Est-il possible de retracer une facture sans passer par les mois d'activités?",
"faq_purchase_answer6": "Si vous connaissez le numéro de la facture, du bon de commande ou du paiement, vous pouvez utilisez la page de recherche de facture et l'option Trouver par numéro. Entrez le numéro de l'item et cliquez sur le bouton Trouver. Vous pouvez aussi choisir des items en explorant la section de la page Rechercher par groupe.",
"faq_purchase_question7": "Puis-je arranger les items sur la page Bon de commande?",
"faq_purchase_answer7": "Oui, en sélectionnant n'importe quel entête du tableau des bons de commande, les items seront triées selon cette colonne. En sélectionnant le même entête une deuxième fois, l’ordre des items s'inversera.",
"faq_purchase_question8": "Puis-je voir des informations supplémentaires pour un bon de commande?",
"faq_purchase_answer8": "Oui, vous pouvez choisir le numéro du bon de commande de n'importe quel bon de commande. Ceci affichera le détail des lignes d'items du bon de commande. Sur la page Traité vous pouvez aussi choisir la Date traitée. Ceci affichera le détail du paiement pour cet item.",
"faq_purchase_question10": "Comment puis-ce accélérer mon appel au Centre d'appel d'Holman?",
"faq_purchase_answer10": "1. Identifiant du client & numéro du véhicule ou les 8 derniers caractères du N/S. <br></br> 2. Kilométrage actuel du véhicule.<br></br> 3. Être en mesure d'expliquer les Réparations ou les Services requis/suggérés.  <br></br> 4. Prix séparés des Pièces/Main-d'œuvre et taxes. <br></br>",
"faq_bank_question11": "Que dois-je faire si ne je reçois pas mon avis de paiement par courriel?",
"faq_bank_answer11": "Connectez-vous au site et allez à la page des bons de commande traités. Trouvez ensuite la facture pour laquelle vous avez des questions. Sur la page Traité vous obtiendrez les information du paiement et la date du traitement. Veuillez noter que ces avis ne peuvent pas être envoyés de nouveau.",
"faq_bank_question15": "Mes informations bancaires ont changés mais j'ai fermé des bons de commande.",
"faq_bank_answer15": "Si les informations bancaires en dossier sont invalides, le paiement pourrait être retardé d'au moins une semaine. Allez aux Services aux membres et ensuite Gestion du compte des paiements afin de mettre à jour vos informations bancaires à moins que vous ayez déjà été contacté par Holman concernant l'erreur.",
"faq_bank_question16": "Puis-je changer mes informations bancaires en ligne?",
"faq_bank_answer16": "Oui, allez aux Services aux membres: Gestion du compte des paiements; Enregistrer vos nouvelles informations bancaires pour les paiements futurs. Entrez votre numéro de banque ainsi que votre numéro de compte.",
"faq_bank_question17": "Je suis déjà inscrit mais j'ai omis l'étape d'ajouter mes informations bancaires.",
"faq_enroll_answer17": "Connectez-vous dans votre compte et allez aux Services aux membres, Gestion du compte des paiements pour ajouter vos informations bancaires; ceci terminera la configuration de votre compte.",
"faq_profile_question19": "Que dois-je faire si j'ai oublié mon mot de passe?",
"faq_profile_answer19": "Allez à la page de connexion et cliquez sur Envoyez-moi un courriel. Vous serez demandé d'entrer votre numéro de taxe fédérale et votre numéro d'identification de fournisseur. Une fois ces informations entrées, cliquez Envoyez-moi un courriel et vous recevez votre mot de passe à l'adresse courriel indiquée dans votre dossier.",
"faq_profile_question20": "Comment puis-je mettre à jours mon adresse courriel?",
"faq_profile_answer20": "Connectez-vous à votre compte avec votre identification de fournisseur et votre mot de passe, allez aux Services aux membres, du côté gauche de la page, et cliquez sur Autorisation du client; vous devrez entrer votre mot de passe à nouveau et vous pourrez ensuite mettre à jours vos informations et soumettre.",
"faq_troubleshoot_question21": "J'utiliser un navigateur récent mais je ne peux pas m'inscrire.",
"faq_troubleshoot_answer21": "Il y a deux possibilités. Vérifiez votre navigateur pour que les scripts soient activés. Ceci est une option requise afin de d'autoriser votre ordinateur à traiter certaines fonctionnalités de page Web avancées. Le résultat final est une interaction enrichie et plus rapide avec le Web. Vérifiez également que votre navigateur accepte les cookies de navigateur. Cette option de navigateur est requise afin de vous fournir un dialogue personnalisé.",
"faq_troubleshoot_question22": "Êtes-vous un siège corporatif ayant des emplacements liés essayant de se connecter à votre compte?",
"faq_troubleshoot_answer22": "Nous avons effectués des améliorations afin que vos emplacement liés puissent maintenant avec leurs propres profils. Nous avons ajouté un deuxième niveau de protection de mot de passe. Veuillez appeler notre département de Relations avec les fournisseurs au 1-888-683-8327 pour obtenir de l'aide. Nous sommes désolés de cet inconvénient.",
"faq_troubleshoot_question23": "Je doit accéder à un compte existant.",
"faq_troubleshoot_answer23": "Pour votre sécurité, veuillez appeler notre département de Relations avec les fournisseurs au 1-866-274-1025 , afin de confirmer les informations spécifiques de votre compte. Un mot de passe temporaire vous sera fourni, si nécessaire.",
"feedback_form_header": "Formulaire de commentaires",
"faq_footer": "Vous pouvez trouver des réponses à vos questions dans notre <a href='/contact/HelpFAQ'>Foire Aux Questions.</a>",
"thanks_title": "Merci!",
"thanks_sub": "Votre message a été envoyé avec succès.",
"suggestion_dropdown_question": "Que voulez-vous nous dire?",
"section_dropdown_question": "Vos commentaire touche quelle section?",
"comment_title": "Commentaire/Suggestion",
"criticism_title": "Critique",
"praise_title": "Éloges",
"question_title": "Question",
"problem_title": "Rencontré un problème",
"section_question_title": "Site Web en général",
"create_po_title": "Créer Bon de commande",
"close_po_title": "Fermer Bon de commande",
"edit_po_title": "Modifier Bon de commande",
"search_po_title": "Rechercher Bon de commande",
"po_dashboard_title": "Tableau de bord Bon de commande",
"other_title": "Autre",
"radio_title": "Futur fournisseur",
"name_header": "Nom",
"company_header": "Compagnie (optionnel)",
"email_header": "Courriel",
"phone_header": "Téléphone",
"ext_header": "Poste (optionnel)",
"send_message_header": "Envoyer un message",
"send": "Envoyer",
"active_address_header": "Holman Siège social <br></br> 4001 Leadenhall Road <br></br> Mount Laurel, NJ 08054",
"car_care_description": "Pour des problèmes d'affichage, <br></br> Création, Édition ou <br></br> Fermeture de Bons de commande",
"car_care_contact_number": "1-866-274-1025",
"website_assistance_header": "Pour des questions concernant votre compte actuel incluant les paiements ou pour de l'aide au site de PartnerConnect, veuillez appeler au <b>888-683-8327</b>",
"website_assistance_contact_number": "1-866-274-1025",
"canadian_active_address_header": "Services Financiers Holman Inc. <br></br> 1270 Central Parkway W. <br></br> Suite 600 <br></br> Mississauga, Ontario L5C 4P4 <br></br> 800-361-5882",
"canadian_car_care_description": "Pour des problèmes d'affichage, <br></br> Création, Édition ou <br></br> Fermeture de Bons de commande",
"canadian_car_care_contact_number": "1-866-274-1025",
"canadian_website_assistance_header": "Pour des questions concernant votre compte actuel incluant les paiements ou pour de l'aide au site de PartnerConnect, veuillez appeler au <b>1-866-416-6542</b>",
"canadian_website_assistance_contact_number": "1-866-416-6542",
"shop_select": "Veuillez en choisir un!",
"parameters_header": "Paramètres",
"parameters_header_services": "Règles de service des véhicules du client ",
"empty_parameters": "Aucun pour le moment",
"client_parameter_header": "Paramètres du client",
"general_parameter_header": "Paramètres généraux",
"vin_parameter_header": "Paramètres pour N/S",
"repair_history_of_vehicle": "Historique des réparations",
"client_parameters_of_vehicle": "Paramètres du client",
"tax": "Taxes",
"pm_sheets": "Information d'entretien préventif",
"recent_service": "Service récent",
"line_item_view_date": "Date reçue",
"line_item_view_service": "Service",
"line_item_view_odometer": "Odomètre",
"line_item_view_supplier": "Fournisseur",
"line_view_link_caption": "VOIR",
"add_no_services_caption": "Ce véhicule n'a pas de service dû",
"no_records_message": "Aucun service récent pour ce véhicule",
"show_more": "Montrer plus",
"learn_more_caption": "Pour en savoir plus",
"payment_status_bulletin_title": "Informations sur l'état du paiement",
"marketing_message": "Holman a révolutionné la gestion de parc avec de la technologie permettant aux entreprises dans le monde entier d’obtenir de nouveaux niveaux d’efficacité et de valeur, en tirant parti de la puissance des données et des analyses.",
"driver_validation_text": "Ce bon de commande nécessite la validation du conducteur. Avisez le conducteur de valider les réparations avec Holman avant d'effectuer les travaux.",
"line_items_error_message": "Veuillez ajouter au moins une ligne d'item",
"existing_po_link": "{po} existe déjà. Voulez-vous vraiment continuer avec un nouveau bon de commande {yes}|{no}?",
"vehicle_search_message": "{vehicleSearchMessage}",
"recover_password_text": "Vous avez oublié votre mot de passe?",
"email_notification_title": "Demander votre mot de passe",
"id_issued_ari_input": "ID émis par Holman(obligatoire)",
"email_me_button": "Envoyez-moi un courriel",
"thank_you_recover_password_title": "Réinitialisez votre mot de passe",
"thank_you_recover_password_subtext": "Si les informations que vous avez fournies correspondent aux informations que nous avons sur le compte, nous vous enverrons un e-mail dans les 10 prochaines minutes à l'adresse du dossier avec les détails pour réinitialiser votre mot de passe.",
"error_message_recover_id": "Identification émise par Holman ne doit pas être laissé vide",
"error_message_contact_name": "Veuillez saisir un nom de contact valide.",
"error_message_recover_identification": "Veuillez entrer les informations sur les taxes.",
"error_message_incorrect_identification": "Identification non valide. Veuillez l'entrer selon le format suivant:  (XXX-XX-XXXX) ou (XX-XXXXXX)",
"online_status__internet_down": "Il semble y avoir un problème avec votre connexion Internet.",
"identification_ssn": "Les 4 derniers chiffres de l'identification EIN / contribuable",
"identification_email": "Adresse e-mail",
"return_login": "Retourner à la page d'accueil",
"driver_reference_number_title": "Votre # de référence",
"driver_reference_number_subTitle": "# Facture/#RO/#WO",
"active_po_reference_number_column_header": "Votre # Réf.",
"odometer_details_optional": "(Si équipé)",
"po_requester_title": "Votre nom",
"generic_error_message": "Une erreur inattendue s'est produite. Veuillez essayer de nouveau plus tard ou cliquez <a href='/contact/FeedbackForm'>ici</a> pour rapporter le problème.",
"generic_error_message_1": "Une erreur inattendue s'est produite. Veuillez essayer de nouveau plus tard ou  ",
"generic_error_message_2": "Cliquez ici",
"generic_error_message_3": " pour rapporter le problème.",
'error_tax_amount': "Bon de commande dépasse le seuil fixé, veuillez appeler le centre d'appel au 1-866-274-1025",
'add_new_line_item_caption': 'Ajouter {complaint_description} items',
"coming_soon_title": "À venir",
"coming_soon__gamify_business_title": "Votre Commerce Holman",
"coming_soon_gamify_performance_title": "Votre Performance Holman",
"coming_soon_setting_title": "Réglages",
"coming_soon_business_growth_title": "Croissance de l'entreprise",
"contact_information_title": "Informations du contact",
"vendor_business_phone_number": "Commerce",
"vendor_extension_number": "Poste",
"vendor_extension_number_edit": "Poste (optionnel)",
"vendor_fax_number": "Télécopieur",
"vendor_fax_number_edit": "Télécopieur (optionnel)",
"settings_Cancel": "Annuler",
"save_contact_information": "Sauvegarder les réglages des informations du contact",
"name_information_title": "Non d'affaires",
"position_information_title": "Position",
"settings_view_caption": "Réglages",
"vendor_street_address_title": "Adresse",
"vendor_city_title": "Ville",
"vendor_state_title": "Province",
"vendor_zip_title": "Code postal",
"changePassword_view_caption": "Mot de passe",
"changePassword_change_password": "Changer le mot de passe",
"edit_contact_information": "Modifier les réglages des informations du contact",
"vendor_name_error_message": "Veuillez entrer les prénom et nom du contact primaire.",
"vendor_street_address_error_message": "Veuillez entrer votre rue.",
"vendor_city_address_error_message": "Veuillez entrer votre ville.",
"vendor_zip_address_error_message": "Veuillez entrer un code postal valide.",
"vendor_business_phone_error_message": "Format non valide du numéro de téléphone. Veuillez l'entrer selon le format suivant: (XXX-XXX-XXXX)",
"vendor_fax_phone_error_message": "Format non valide du numéro de télécopieur. Veuillez l'entrer selon le format suivant: (XXX-XXX-XXXX)",
"vendor_street_address2_title": "Adresse 2",
"change_password_ari_id": "Identification émise par Holman",
"change_password_current": "Actuel",
"change_password_new": "Nouveau",
"change_password_confirm_new": "Confirmer nouveau",
"changePassword_save_password": "Sauvegarder le mot de passe",
"change_password_ariId_error_mesg": "Veuillez entrer l'identification émise par Holman.",
"change_password_new_pwd_error_mesg": "Veuillez entrer le nouveau mot de passe.",
"change_password_ariId_error_mesg1": "Identification émise par Holman erronée",
"change_password_current_pwd_error_mesg": "Veuillez entrer le mot de passe actuel.",
"change_password_new_pwd_error_mesg1": "Le nouveau mot de passe ne peut être le même que le mot de passe actuel.",
"change_password_current_pwd_error_mesg1": "Le mot de passe actuel est erroné.",
"change_password_confirm_pwd_error_mesg": "Veuillez confirmer le nouveau mot de passe.",
"change_password_confirm_pwd_error_mesg1": "Le mot de passe ne correspond pas.",
"password_successfully_changed": "Votre mot de passe a été changé avec succès.",
"location_title": "Emplacement",
"contact_title": "Nom du contact",
"email_recipient_title": "Destinataire du courriel des paiements",
"account_view_caption": "Informations du compte",
"bank_account_caption": "Compte bancaire",
"checking_account_caption": "No du compte chèque",
"edit_account_information": "Modifier les informations du compte bancaire",
"account_submission_success": "La mise à jour de votre compte bancaire a été soumise pour traitement. Veuillez allouer un jour ouvrable pour que le changement soit complété. N'oubliez pas: Afin d'assurer le paiement des services, veuillez toujours vérifier les informations de votre compte bancaire lorsque vous soumettez une facture en ligne..",
"tax_information_caption": "Information sur les taxes",
"federal_tax_caption": "Classification de la taxe fédérale",
"ssn_caption": "No de sécurité sociale / Identification d'employeur",
"checking_account_match_error_message": "Les numéros des deux comptes doivent être identiques. Veuillez entrer de nouveau le numéro du compte chèque.",
"checking_account_empty_error_message": "Veuillez entrer le numéro du compte chèque.",
"routing_empty_error_message": "Veuillez entrer des numéros de succursale et de banque valides.",
"invalid_routing_error_message": "Le premier chiffre devrait débuter par 0,1,2 ou 3. Numéro non valide.",
"vendor_email_recipient_error_message": "Format non valide de l'adresse de courriel.",
"edit_routing_caption": "Entrez les 9 chiffres des numéros de succursale et de banque:",
"edit_checking_caption": "Entrez le numéro du compte chèque: <br></br> (Ne pas inclure le numéro du chèque)",
"re_enter_caption": "Entrez de nouveau le numéro du compte chèque:",
"cancel__edit_caption": "Annuler",
"save_account_caption": "Sauvegarder les modifications sur les informations du compte bancaire",
"account_verify_caption": "Vérification du compte requise",
"account_verify_sub_caption": "Toutes les modifications apportées à l'inscription du compte bancaire exige la rentrée de votre nom et de l'identifiant de taxe. Veuillez allouer un jour ouvrable pour que le changement soit complété et toujours vérifier votre numéro de compte avant de soumettre une facture en ligne.",
"contact_name_caption": "Nom du contact",
"social_security_caption": "No de sécurité sociale",
"employer_id_no_caption": "No d'identification d'employeur",
"terms_caption": "Termes et conditions",
"terms_conditions_text": "1) Autorise Holman à effectuer le paiement directement au compte bancaire que vous fournirez <br/> 2) Consent à payer les frais de gestion bancaires tel qu'entendu avec Holman lors du processus de votre inscription <br/> 3) Reconnait que l'origine de toutes les transactions au compte doit se conformer aux lois canadiennes <br/> 4) **Informerai Holman de tout changement, incluant le compte et l'emplacement d'affaires dans les 5 jours ouvrables précédant le changement**<br/> 5)  Reconnait qu'Holman peut reproduire une facture attestant des transaction en vertu de la présente entente",
"radio_button_agree_caption": "J'ai lu et accepte les termes et conditions indiqués ci-dessus.",
"cancel_verify_caption": "Annuler",
"continue_verify_caption": "Continuer",
"terms_and_condition_error_message": "Veuillez accepter les termes et conditions afin de pouvoir continuer.",
"services_view_caption": "Services",
"minority_vendor_caption": "Fournisseur minoritaire",
"save_services_caption": "Sauvegarder les modifications des services",
"services_Cancel": "Annuler",
"edit_services_caption": "Modifier les services",
"vendor_account_confirmation_no": "Non",
"vendor_account_confirmation_yes": "Oui",
"vendor_account_confirmation_title": "Compte fournisseur",
"vendor_account_confirmation_message": "Cette page contient des modifications qui n'ont pas été sauvegardées. Veuillez cliquer ANNULER pour rester sur cet écran.",
"grow_business_caption": "Faire croître votre entreprise",
"my_units_caption": "Augmentez votre chiffre d'affaires aujourd'hui",
"total_vehicle_count_caption": "Nombre total de véhicules Holman qui vous ont été assignés",
"services_due_now_caption": "Véhicules Holman pour lesquels vous avez effectués des travaux dans le passé ayant des services dus maintenant",
"additional_services_due_count_caption": "Véhicules Holman supplémentaires dans votre région pour lesquels des services sont dus maintenant",
"your_units_title": "Vos unités",
"units_due_caption": "Clients ayant des véhicules Holman pour lesquels vous avez effectué des services dans le passé",
"units_due_sub_caption": "Les fournisseurs de haute performance du Holman ont une visibilité sur les véhicules dans leur région respective et disposent d’outils leur aidant à gérer l’entretien préventif de ces actifs. Cette page contient des informations en particulier sur les véhicules dont l’entretien arrive à échéance ou sur  les véhicules dont les services ou les vérifications préventives sont en retard. Holman fournit ces informations uniquement à ses fournisseurs de haute performance.",
"my_units_service_due": "Services dus",
"my_units_email_driver": "Courriel du conducteur",
"my_units_client_name": "Nom du client",
"my_units_vehicle": "Véhicule",
"my_units_vin8": "N/S",
"my_units_reject_unit": "Rejeter l'unité",
"other_units_caption": "Autres unités",
"confirm_reject_title": "Rejeter l'unité?",
"confirm_reject_message": "Êtes-vous certain de vouloir rejeter l'unité?",
"confirm_reject_yes": "Oui",
"confirm_reject_no": "Non",
"close_without_save_caption": "Fermer",
"due_services_caption": "Ce véhicule a un service dû",
"create_po_caption": "Créer un bon de commande",
"service_caption": "Services",
"schedule_caption": "Cédule",
"due_caption": "Dû",
"company_name_title": "Nom de la compagnie",
"phone_title": "Téléphone",
"ari_id_title": "Holman Identification émise",
"search_by_title": "Rechercher par:",
"sign_in_caption1": "Enregistré ",
"sign_in_caption2": "Connexion",
"register_caption": "Enregistrer ",
"linked_account_title": "Pour accéder à votre compte lié",
"select_caption": "Choisir l'emplacement",
"corporate_account_link": "Compte corporatif ",
"or_caption": "Ou",
"other_units_due_sub_caption": "Opportunités d'affaires supplémentaires, ces unités n'ont jamais été dans votre atelier.",
"add_services_sub_caption": "Le gestionnaire du parc ou le conducteur du véhicule a été avisé de ce service en attente.",
"error_message_incorrect_identification_canada": "Identification non valide. Veuillez l'entrer selon le format suivant: (XX-XXXXXX)",    
"edit_routing_caption_canada": "Entrez l'identifiant de l'institution suivi de l'identifiant de la succursale:",    
"units_due_sub_caption_non_hp": "Les fournisseurs de haute performance d’ARI ont une visibilité sur les véhicules dans leur région respective et disposent d’outils leur aidant à gérer l’entretien préventif de ces actifs. Cette page contient des informations en particulier sur les véhicules dont l’entretien arrive à échéance ou sur  les véhicules dont les services ou les vérifications préventives sont en retard.",
"vehicle_search_error": "{vehicleSearchError}",
"po_creation_error": "{poCreationError}",
'vin8_label': 'N/S8',
'purchase_order_label': 'Bon de commande',
'invoice_label': 'Facture',
'payment_label': 'Paiement',
'search_duration': 'Durée',
'last_60_days': 'Derniers 60 jours',
'last_120_days': 'Derniers 120 jours',
"car_wash_title": "Choisir le client et entrer un numéro de véhicule pour créer un bon de commande",
"car_wash_plate": "# Plaque",
"car_wash_state": "Province",
"car_wash_or": "Ou",
"car_wash_client_no": "No Client",
"car_wash_ari_unit_no": "No unité Holman",
"client_number_error": "Veuillez choisir un numéro de client.",
"ari_unit_no_error": "Veuillez entrer votre numéro d'unité Holman.",
"vendor_details_title_car_wash": "Service de lavage d'auto",
"payment_in_progress_total_amount_column_header": "Total",
"payment_in_progress_plate_number_column_header": "No Plaque",
"active_po_invoice_number_column_header": "No Facture",
"car_wash_service_date_label": "Date du service",
"car_wash_invoice_no_label": "No Facture",
"car_wash_repair_selection": "Lavage d'auto",
"detail_car_wash_repair_selection": "Lavage d'auto esthétique",
"car_wash_create_po": "Demander le paiement de ",
"total_info_label_car_wash": "Total",
"tax_info_label_car_wash": "Taxes",
'search_po_payment_no_column_header': 'No Paiement',
'search_po_vehicle_column_header': 'Véhicule',
"vendor_details_title_request_no": "No Demande de courtoisie {requestNo}",
"dealer_draft_confirm_payment": "Confirmez que le paiement sera versé au compte se terminant par {bankAccountNo}",
"dealer_draft_add_edit_view_confirmation_title": "Supprimer la demande",
"request_payment":"Demander le paiement ",
"restore_request": "Restaurer la demande",
"vehicle_details_issue_date": "Date d'émission",
"vehicle_details_status": "Statut",
"optional_information_description": "Veuillez fournir l'information suivante, si disponible:",
"optional_information_sub_description": "NOTE: Ne pas entrer une plaque ou immatriculation temporaire.",
"dealer_draft_delivery_date_description": "Date de livraison",
"dealer_draft_license_plate_description": "# Plaque d'immatriculation",
"dealer_draft_registration_date_description": "Expiration immatriculation",
"dealer_draft_Registration_state_description": "Province d'immatriculation",
"dealer_draft_payment_accepted_error": "Veuillez confirmer le paiement avant de demande l'autorisation.",
"dealer_draft_delivery_date_invalid_error_label": "Veuillez indiquer une date passée",
"dealer_draft_reg_date_invalid_error_label": "Veuillez indiquer une date future",
"add_new_service_item_caption": "Ajouter des items de service",
"cost_details": "Coût",
"request_no_subtotal_view": "Sous-total",
"request_no_tax_rate_view": "Pourcentage Taxe",
"request_location_tax_rate_view":"Emplacement",
"show_all_waiting_requests": "Montrer toutes les {poCount} demandes",
"hide_older_requests": "Masquer les vieilles demandes",
"dealer_draft_active_requests_title": "Demandes actives",
"dealer_draft_active_requests_client_no": "No Client",
"dealer_draft_active_requests_vehicle_no": "No unité Holman",
"dealer_draft_active_requests_requests_no": "No Demande",
"dealer_draft_active_requests_vin8": "N/S8",
"dealer_draft_active_requests_no_requests_caption": "Aucun pour le moment",
"quick_start": "Démarrage rapide",
"username_and_password": "Nom d'utilisateur et Mot de passe",
"forgot_password": "Mot de passe oublié",
"explanation_of_po_types": "Brève explication des types de bon de commande",
"your_action_is_required": "Action requise de votre part",
"waiting_for_ari_client_approval": "En attente de l'approbation d'Holman / du Client",
"payment_in_progress": "Paiement en cours",
"search_for_my_pos": "Rechercher mes Bons de commande",
"searching_for_vehicle": "Entrez un numéro de série de véhicule et de quoi il retourne",
"close_po": "Fermer un Bon de commande",
"build_po": "Bâtir un Bon de commande",
"edit_po": "Modifier un Bon de commande",
"help": "Aide",
"contact": "Contact",
"cancel_po": "Annuler un Bon de commande",
"search_tips": "Astuces de recherche",
"aged_pos": "Bons de commande âgés",
"vehicle_information": "Information du véhicule, historique, paramètres, cédules d'entretien préventif",
"old_browser_warning": "Cette fonctionnalité peut ne pas fonctionner correctement car votre navigateur semble un peu désuet. Nous recommandons la mise à niveau vers la dernière version de Chrome, Firefox ou Internet Explorer.",
"help_videos": "Vidéos tutorielles",
"service_classification": "Classification d'affaires",
"maintenance_general_classification": "Réparation générale & Entretien",
"other_classification": "Lavage d'auto ou Esthétique seulement",
'corporate_vendor_details_title': 'Compte corporatif',
'corporate_vendor_details_sub_title': 'Pour accéder à votre compte corporatif principal',
'corporate_vendor_login_text_1': `Connexion ? Cliquez `,
'corporate_vendor_login_text_2': `ici`,
'corporate_vendor_forgot_pwd_text_1': `Vous avez oublié votre mot de passe? `,
'corporate_vendor_forgot_pwd_text_2': 'Envoyez-moi un courriel',
'supplier_enrollment_subheader':"Vous êtes déjà un Fournisseur d'Holman? Veuillez retourner à la page d'accueil pour vous connecter avec votre identifiant et votre mot de passe",
'not_enroll_text':"Vous n'êtes pas un Partenaire d'Holman? ",
'register_here':'Inscrivez-vous ici',
'waiting_for_client_caption':"En attente de l'approbation du client",
'enroll_link':"S'inscrire",
'recently_enrolled':'Récemment inscrit?',
'create_new_account':'Étape 1 de 5: Créer un nouveau compte',
'notification_email' : "Étape 5 sur 5 : Configuration de l'e-mail d'autorisation",
'ari_id':'Identification émise par Holman',
'ssn':'No sécurité sociale',
'employer_id':"No d'identification d'employeur",
'create_pwd':'Créer un mot de passe',
'confirm_pwd':'Confirmer le mot de passe',
'go_to_next_step':'Aller à la prochaine étape',
'bank_info':'Étape 3 de 5: Informations bancaires',
'routing_number':'Entrez les 9 chiffres des numéros de succursale et de banque',
'checking_acc_number':'Entrez le numéro du compte chèque (ne pas inclure le numéro du chèque)',
're_enter_checking_acc_number':'Entrez de nouveau le numéro du compte chèque',
'payment_email':'Adresse de courriel pour les paiements',
'services':'Étape 4 de 5: Profil des services',
'note':'Cocher Enregistrer indique votre acceptation du programme d\'autorisation.<br></br> Les avis courriels seront envoyés à l\'adresse fournie ci-dessous.<br></br> En outre, vous serez immédiatement informé des avis lorsque vous vous connecté à ce site.<br></br>',
'register':'Enregistrer',
'onlineusage_caption':'Utilisation en ligne',
'onlineusage_description':'Les commandes d\'achat créées ou fermées en ligne.',
'repairvalue_caption':'Estimer l\'exactitude',
'repairvalue_description':'Les coûts des réparations sont mesurés contre les prix publiés disponibles, ainsi que ...',
'pmcompletion_caption':'Entretien préventif complété',
'pmcompletion_description':"Véhicules Holman qui vous ont été assignés pour lesquels l'entretien préventif a été complété à temps.",
'workretention_caption':'Rétention du travail',
'workretention_description':'Véhicules Holman qui vous ont été assignés pour lesquels le travail a été retenu.',
'auth_notification_title':'Configuration Courriel d\'autorisation',
'currentRegistrationStatus':'Statut d\'enregistrement actuel',
'notRegistered':'Non enregistré',
'edit_configuration_caption':'Modifier la Configuration Courriel d\'autorisation',
'no_services_caption':'None',
'faq_footer1':'Vous pouvez trouver des réponses à vos questions et préoccupations dans notre ',
'faq_footer2':'Foire Aux Questions.',
'contact_footer_3':"Si vous avez besoin d'assistance dans une autre langue, veuillez contacter <br></br>un de nos centres d'appel et nous serons heureux de vous aider. <br></br>Aux USA  - 800-227-2273 <br></br>Au Canada - 800-363-7676<br></br>Au Mexico - 800-002-7492",
'registered':'Enregistré',
'cancel':'Annuler',
'save_contacts':'Sauvegarder les réglages des informations du contact',
'header':'Demande d\'inscription de Fournisseur',
'physical_address':'Adresse physique',
'business_name':'Nom d\'affaires',
'address_1':'Adresse 1',
'address_2':'Adresse 2',
'city':'Ville',
'state':'Province',
'zip':'Code postal',
'country':'Pays',
'contact_information':'Informations du contact',
'name':'Nom',
'phone':'Téléphone',
'ext':'Poste',
'email':'Courriel',
'reenter_email':'Entrez le courriel à nouveau',
'fax':'Télécopieur (optionnel)',
'referral_code':'Code de référence',
'referral_code_note':'Si vous avez un code de référence veuillez l\'entrer ici',
'submit':'Soumettre',
'business_name_required':"Veuillez entrer le Nom d'affaires",
'address1_required':"Veuillez entrer l'Adresse 1",
'address2_required':"Veuillez entrer l'Adresse 2",
'city_required':'Veuillez entrer la Ville',
'state_required':'Veuillez choisir une province',
'zip_required':'Veuillez entrer le Code postal de 6 caractères',
'country_required':'Veuillez choisir un Pays',
'contact_name_required':'Veuillez entrer le Nom du contact',
'phone_area_required':'Veuillez entrer le Téléphone',
'phone_prefix_required':'Veuillez entrer le Téléphone',
'phone_suffix_required':'Veuillez entrer le Numéro de téléphone',
'email_required':'Veuillez entrer une Adresse de courriel (exemple: courriel@domain.com)',
're_enter_email_required':'Veuillez entrer à nouveau une adresse de courriel valide',
'supplier_enrollment':"Demande d'inscription de Fournisseur",
'terms_and_conditions':'Modalités et conditions',
'all_officials_sub_heading':"Toutes les centres de services officiels d'Holman:",
'read_and_agree':"J'ai lu et accepte les termes et conditions indiqués ci-dessus.",
'tc_initials' : 'Veuillez saisir les initiales du prénom et du nom',
'sign_in' : 'Tes initiales',
'accept_terms_and_condition': 'Veuillez accepter les termes et conditions',
'accepted_by':'Accepté par:',
'tax_information':'Information sur les taxes',
'legal_name':"Nom d'affaires légal",
'legal_name_note':'(tel que détaillé dans le dossier des taxes)',
'contact_name':'Nom du contact',
'business_dba_required':"Nom d'affaires (DBA)",
'tax_classification':'Classification de la taxe fédérale',
'client_referral':"Si vous êtes référé à Holman par l'un de nos clients, veuillez nous donner leur nom.",
'liability_tax_classification':'Entrez la classification de la taxe (C=C corp, S=S corp, P=Partnership)',
'term_must_be_selected':"Prière de lire et d'accepter les Termes et conditions",
'terms_accepted_by_required':'Initiales requises',
'tax_classification_required':'Veuillez choisir une classification de la taxe fédérale',
'liability_tax_invalid_value':'Veuillez entrer une valeur valide pour Classification des taxes responsabilité',
'ein_required':"Veuillez fournir soit le NSS ou l'identification de l'employeur",
'legal_name_required':'Veuillez fournir une valeur pour Nom légal',
'contact_name_required_details':'Veuillez fournir une valeur pour Nom du contact',
'approval_not_requested':'Autorisation non requise',
'approval_request_not_submitted':"Vous avez créé/mis à jour un Bon de commande mais l'autorisation n'a jamais été demandée pour celui-ci. Veuillez vérifier si la réparation est requise et demander une autorisation. Sinon, annuler le Bon de commande.",
'approval_request_or_cancel_po':'Demander Autorisation ou Annuler Bon de commande',
"quick_start_guide":"Cliquez ici pour un guide de formation vidéo rapide",
"tax classifications ":"Classifications des taxes",
"confirmation_for_detail_submission":"Votre numéro de demande d'inscription est le {requestId}.<br/><br/> Votre demande d'inscription a été soumise pour traitement. Vous recevrez un courriel, dans les trois à cinq jours ouvrables, du statut de votre inscription. <br/><br/> Si vous être approuvé, vous serez invité par courriel à compléter le processus d'inscription le plus tôt possible en cliquant sur le lien hypertexte qui vous dirigera au site des Fournisseurs dans lequel vous pourrez fournir les renseignements sur votre compte-chèques.<br/><br/> Suite à l'approbation, un Identification permanent pour Holman Partner vous sera attribué. Utilisez votre numéro de demande d'inscription temporaire dans toutes vos correspondances avec Holman. <br/><br/> VEUILLEZ DIRECTER TOUTES LES DEMANDES D'INSCRIPTION OU LES DOCUMENTS DEMANDÉS À " +'<a href="mailto:enrollments@holman.com">enrollments@holman.com</a>.'+" <br/><br/> Merci d'avoir soumis votre demande d'inscription.",
"thank_you":"Merci",
"terms_info":"Étape 2 de 5: Holman Partner Connect Modalités et Conditions",
"select_one":"En choisir un:",
"optOut":"Choisir de ne pas participer",
"name_auth_email":"Nom",
"email_auth":"Courriel",
"add_new_contact":"Ajouter un nouveau contact",
"termsHeader":'Modalités et Conditions générales ("Modalités Holman")',
"search_for_po_orders_by_vin":"Rechercher pour Bon(s) de commande par N/S...",
"search_for_po_orders_by_po":"Rechercher pour Bon(s) de commande par # de bon...",
"search_for_po_orders_by_payment":"Rechercher pour Bon(s) de commande par # de paiement...",
"search_for_po_orders_by_invoice":"Rechercher pour Bon(s) de commande par # de facture...",
"search_for_po_orders":"Rechercher pour Bon de commande...",
"or_by_caption":"Ou par",
"new_work_assignment":"Nouvelle attribution de travail",
"model":"Modèle",
"search_zip":"Rechercher code postal...",
"reason_for_repair":"S'il vous plaît entrer la plainte, la cause et la correction ici.",
"engine_hours":"Compteur horaire (si équipé)",
"odometer_caption":"Odomètre",
"view_all_courtesy":"Afficher les demandes de courtoisie ouvertes",
"register_header":"Immatriculation",
"contact_header":"Nom du contact",
"new_password_header":"Nouveau mot de passe",
"confirm_password_header":"Confirmer le nouveau mot de passe",
"new_password_required":"Veuillez entrer un nouveau mot de passe",
"re_enter_password_required":"Veuillez entrer à nouveau le bon mot de passe",
"tax__classification_I":"Individu/Propriétaire unique",
"tax__classification_C":"C Corporation",
"tax__classification_S":"S Corporation",
"tax__classification_P":"Partenariat",
"tax__classification_L":"Société à responsabilité limitée",
"complaint_code_DG99":"Diagnostic",
"complaint_code_CL99":"Problème embrayage",
"complaint_code_FR99":"Problème châssis",
"complaint_code_GA99":"Problème instrumentation",
"complaint_code_GL99":"Problème vitres",
"complaint_code_HE99":"Problème chauffage",
"complaint_code_IN99":"Problème assurance",
"complaint_code_RA99":"Problème radio",
"complaint_code_TR99":"Problème transmission",
"complaint_code_UP99":"Problème équipement aménagé",
"complaint_code_WI99":"Problème filage",
"complaint_code_XX99":"Autre",
"complaint_code_SA01":"Taxes de vente",
"complaint_code_RE01":"Location",
"complaint_code_MA01":"MA01",
"complaint_code_1224":"Assistance routière suite à une erreur du conducteur",
"complaint_code_1024":"Assistance routière suite à un bri mécanique",
"complaint_code_1324":"Assistance routière suite à un abus du conducteur",
"complaint_code_3124":"Accident non rapporté",
"complaint_code_3214":"Accident rapporté",
"complaint_code_3824":"Réparation sur garantie",
"complaint_code_4224":"Assistance routière suite à une réparation sur garantie",
"complaint_code_3811": "Fdx code CD obsolète",
"complaint_code_3814":"Directive de campagne",
"complaint_code_4114":"Entretien carrosserie",
"complaint_code_RC01":"Problème assistance routière",
"complaint_code_FL99":"Chariot-élévateur",
"complaint_code_HR99":"Rail-route",
"complaint_code_AL99":"Équipement aérien / monte-charge",
"complaint_code_RE99":"Réfrigération",
"complaint_code_RC99":"Dépannage",
"complaint_code_TL99":"Remorque",
"complaint_code_DL99":"Problème arbre de transmission",
"complaint_code_CA99":"Problème en cabine",
"complaint_code_UN01":"Non fourni",
"correctionTypes_REPLACE":"REMPLACER",
"correctionTypes_REPAIR":"RÉPARATION",
"correctionTypes_ADJUST":"AJUSTER",
"correctionTypes_PM":"ENTRETIEN PRÉVENTIF",
"correction_parts_labor_subTitle":"(PIÈCES ET / OU MAIN D'OEUVRE)",
"correction_labor_subTitle":"(TRAVAIL SEULEMENT)",
"correction_pm_subTitle":"(MENU PRIX ARTICLES)",
"performance":"Performance",
"chart_legend":"Légende du tableau",
"best_in_class":"Meilleur de sa catégorie",
"above_average":"Au-dessus de la moyenne",
"avg":"Moyenne",
"needs_improvement":"Nécessite des améliorations",
"items_added":"Items ajoutés au PO:",
"active_po_po_number_column_header": "PO no.",
"po_assistance_header":"Pour obtenir de l'aide avec un bon de commande existant ou pour parler à un technicien du centre d'appels d'Holman, veuillez appeler au <b>1-866-274-1025</b>",
"po_assistance_header_anony":"Pour obtenir de l'aide avec un bon de commande existant ou pour contacter un technicien du centre d'appels Holman, veuillez appeler l'un des numéros suivants: <Row><div>USA<b>  1-866-274-1025</b></div> <div>Canada<b>  1-800-363-7676</b></div> <div>Mexico<b>  1-800-002-7492</b></div></Row>",
"canadian_po_assistance_header":"Pour obtenir de l'aide avec un bon de commande existant ou pour parler à un technicien du centre d'appels d'Holman, veuillez appeler au <b>1-866-274-1025</b>",
"contact_form_header":"Pour créer un nouveau compte fournisseur, veuillez utiliser le lien fourni sur la page Web principale.",
"enrollement":"Inscription",
"new_supplier_registration":"Enregistrement",
"my_purchase_order":"Mes Bons de commande",
"my_purchase_order_dynamic":"Mes {ordersTypeText} pour les derniers {days} jours",
"my_purchase_order_dynamic_search":"Mes {ordersTypeText} pour {searchText}",
"ododmeter_Validation_Message_6":"Entrez la valeur Odomètre",
"continue_link":"Continuer",
"unit_confirmation_no":"Non",
"province":"Province",
"postalCode":"Code postal",
    "province_required":"Veuillez choisir une province",
    "postalCode_required":"Veuillez entrer un Code postal valide de format: XXX XXX",
    "businessNumber":"Numéro d'entreprise",
    "institute_id":"l'identifiant de l'institution",
    "transit_id":"l'identifiant de la succursale",
    "checking_acc_no_canada":"le numéro du compte",
    "reenter_checking_acc_no_canada":"Entrez de nouveau le numéro du compte",
    "gst_caption_canada": "Numéro d'entreprise",
    "business_number_required":"Numéro d'entreprise invalide. Veuillez entrer le format suivant: (XXXX)",
    "businessNumber_required":"Veuillez fournir une valeur pour le numéro d'entreprise",
    "employer_id_no_caption_CAN": "Numéro d'entreprise",
    'existing_po_error_selection_no': 'Non',
    'existing_po_error_selection_yes': 'Oui',
    'existing_po_error_selection_po':"Bons de commande",
    "car_wash_error_1":"L'odomètre n'est pas dans notre rayon de kilométrage prévu pour ce véhicule.",
    "car_wash_error_2":"Veuillez sélectionner une date de service.",
    "car_wash_error_3":"Veuillez entrer un numéro de facture de plus de 3 caractères et supprimer les espaces vides.",
    "car_wash_error_4":"Veuillez entrer le coût du lavage de l'auto",
    "car_wash_error_5":"L'item dépasse les coûts de service admissibles veuillez contacter Holman au 1-800-227-2273",
    "car_wash_error_6":"Veuillez entrer le coût du lavage esthétique",
    "car_wash_error_7":"Le coût du lavage esthétique dépasse la tarification maximale permise",
    "car_wash_error_8":"La taxe dépasse le seuil de taxe fixé",
    "car_wash_error_9":"L'odomètre n'est pas dans notre rayon de kilométrage prévu pour ce véhicule.",
    "car_wash_error_10":"La taxe dépasse le seuil de taxe fixé",
    "unsaved_data":"Vous avez des modifications non sauvegardées qui seront perdues si vous décidez de continuer. Cliquez sur OK pour continuer ou annuler pour rester sur la même page.",
    "car_wash_select":"Sélectionner",
    "car_wash_confirmation_title":" Demande d'annulation",
    "car_wash_confirmation_message":"Vous êtes sur le point d'annuler ce bon de commande et de le supprimer de Holman PartnerConnect. Êtes-vous sûr de vouloir annuler le bon de commande?",
    "car_wash_confirmation_no":"Non",
    "car_wash_confirmation_yes":"Oui",
    "reason_T29":"USÉ",
    "reason_T30":"DEMANDE DU CLIENT",
    "reason_T03":"PNEU ÉCLATÉ",
    "reason_T04":"REMIS AU CLIENT",
    "reason_T31":"USURE INÉGALE",
    "reason_T13":"TRANSPERCÉ",
    "reason_T15":"DOMMAGE AU FLANC / BOSSELÉ",
    "reason_T21":"BANDE DE ROULEMENT NE CORRESPOND PAS",
    "select_country":"Sélectionner le pays",
    "select_country_usa":"USA",
    "select_country_canada":"Canada",
    "select_state":"Sélectionner l'État",
    "select_providence":"Sélectionner la Province",
    "terms_and_conditions_subheader_login":"Nous sommes à mettre à jour les termes de votre compte.",
    "terms_and_conditions_header_login":"Termes & Conditions",
    "cancel_po_footer_message":"Si vous avez des questions concernant ces modifications,<br/> veuillez composer le 1-866-274-1025 pour discuter avec un technicien d’ARI",
    "confirmation_for_request_submission":"Merci d'avoir pris le temps de contacter Holman. Nous n'acceptons pas les applications pour nouveaux fournisseurs pour le moment. Merci d'avoir rempli vos informations. Votre centre sera placé sur notre liste d'attente de fournisseur et nous vous contacterons si un besoin pour un centre de réparation dans votre secteur survient.",
    "new_tires":"Nouvelles informations sur les pneus",
    "wheel_tread_depth_new_tooltip":"Nouvelle profondeur de bande de roulement",
    "wheel_tread_depth_old_tooltip":"Profondeur de l'ancienne bande de roulement",
    "tire_width_tooltip":"Largeur de pneu",
    "tire_aspect_ratio_tooltip":"Ratio d'aspect",
    "tire_rim_diameter_tooltip":"Diamètre de jante",
    "tire_manufacturer_tooltip":"Fabricant de pneus",
    "tire_model_tooltip":"Modèle de pneu",
    "tire_reason_for_replacement_tooltip":"Raison du remplacement",
    "purchase_order_paid":"No de bon de commande {poNumber}",
    "tires_replaced":"Cliquez sur les pneus étant remplacés",
    "tires_tread":"Profondeur de la bande de roulement",
    "inches_caption":"Pouces",
    "mm_caption":"MM",
    "unit_confirmation_box_title":"Confirmez le changement d'unité de mesure",
    "unit_confirmation_message_for_tires":"Le changement d'unité de mesure sera appliqué à tous les pneus. Cliquez OK pour effectuer le changement",
    "unit_confirmation_message_for_brakes":"Le changement d'unité de mesure sera appliqué à toutes les pièces des freins. Cliquez OK pour effectuer le changement",
    "unit_confirmation_message_end_part":"?",
    "unit_confirmation_yes":"Oui, changer l'unité de mesure.",
    "brake_rotor":"Mesures des rotors des freins requises",
    "brake_drum":"Mesures des tambours des freins requises",
    "brake_lining":"Épaisseur de garniture de frein requise",
    "front_brakes":"Avant",
    "left_brakes":"Gauche",
    "right_brakes":"Droit",
    "milli_meter":"Millimètre",
    "add_axle":"Ajouter Essieu",
    "remove_axle":"Retirer Essieu",
    "measurements_in":"Mesures en:",
    "spare_tire":"Roue de secours",
    "add_wheels":"Ajouter Roues",
    "remove_wheels":"Retirer Roues",
    "onlineUsage_month":"% d'utilisation en ligne, par mois",
    "repairValue_month":"% de valeur de réparation, par mois",
    "pmCompletion_month":"% d'entretien préventif complété, par mois",
    "workRetention_month":"% de rétention de travail, par mois",
    "all_dropdown":"Toutes",
    "invalid_email_format":"Le format du courriel n'est pas valide",
    "terms_and_conditions_required":"Veuillez accepter les Conditions générales",
    "routing_number_required":"Veuillez entrer le numéro de route",
    "institute_id_required":"Veuillez entrer les 3 chiffres de l'identification de l'institution",
    "transit_id_required":"Veuillez entrer les 5 chiffres de l'identification du transit",
    "check_number_required":"Veuillez entrer le numéro du compte chèque",
    "check_number_required_canada":"Veuillez entrer le numéro de compte de l'institution financière",
    "re_enter_check_number_rquired":"Veuillez entrer à nouveau le numéro du compte chèque",
    "re_enter_check_number_rquired_canada":"Veuillez entrer à nouveau le numéro de compte de l'institution financière",
    "check_numbers_should_match":"Les deux numéros de compte doivent être identiques. Veuillez entrer à nouveau le numéro du compte chèque.",
    "payment_email_required":"Veuillez entrer l'adresse de courriel pour le paiement.",
    "invalid_routing_number":"Le premier chiffre devrait débuter par un 0, 1, 2 ou 3.",
    "routing_number_should_be_9_digits":"Le numéro de route doit être composé de 9 chiffres.",
    "atleast_one_email_required":"Veuillez entrer au moins un courriel.",
    "name_email_required":"Le nom et le courriel sont obligatoire.",
    "invalid_is_registered":"Vous devez soit vous inscrire pour recevoir des avis par courriel ou choisir de ne pas en recevoir.",
    "ari_required":"Veuillez entrer le bon ID fourni par Holman",
    "tax_id_required":"Entrez le SSN ou l'ID de l'employeur",
    "password_required":"Veuillez entrer le mot de passe",
    "confirm_password_required":"Veuillez confirmer le mot de passe",
    "password_should_match":"Le mot de passe ne correspond pas",
    "max_min_spec_not_met":"La valeur saisie dépasse les spécifications min/max",
    "max_min_spec_required":"La spécification Min/Max est requise",
    "front_axle_spec_required":"Au moins une valeur de l'essieu avant doit être remplie",
    "rear_axle_spec_required":"Au moins une valeur de l'essieu arrière doit être remplie",
    "front_axle":"Essieu avant",
    "save_tire_changes":"Sauvegarder",
    "continue_to_tire_spec":"Continuer",
    "save_brake_changes":"Sauvegarder",
    "brake_title":"Brakes {brake_component} requis",
    "continue_to_brake_spec":"Continuer",
    "rear_brakes":"freins arrière",
    "payments":"Paiements",
    "invoices":"Factures",
    "purchase_order":"Bons de commande",
    "fee_message_national_account":"Frais de compte national calculés à {feePercent}%.",
    "fee_message_insurance_po_intellipay":"Commande d'assurance, tarif Holman Payer calculé à {feePercent}%.",
    "fee_message_po_accessed_online":"Ordre d'achat initié, modifié ou fermé en ligne, frais Holman Payer calculés à {feePercent}%.",
    "fee_message_po_not_accessed_online":"Le bon de commande n'a pas été ouvert, modifié ou fermé en ligne, frais Holman Payer calculés à {feePercent}%.",
    "plate_state_label":"Plaque et Province",
    "tag_prov_label":"Plaque et Province",
    "service_card_label":"# carte service",
    "client_vehicle_label":"Id client et # unité",
    "search_for_po_orders_by_vin8":"VIN8",
    "search_for_po_orders_by_plate":"Assiette",
    "search_for_po_orders_by_state":"Etat",
    "search_for_po_orders_by_prov":"Province",
    "search_for_po_orders_by_client":"Identité du client",
    "search_for_po_orders_by_vehicle":"Numéro d'unité",
    "search_for_po_orders_by_service_no":"# carte service",
    "vin8_error":"Veuillez entrer un VIN8",
    "plate_error":"S'il vous plaît entrer votre plaque d'immatriculation",
    "state_error":"Veuillez sélectionner une province",
    "tag_error":"S'il vous plaît entrer votre assiette",
    "providence_error":"Veuillez sélectionner une province",
    "client_error":"Veuillez entrer votre numéro de client",
    "vehicle_error":"Veuillez entrer votre numéro de véhicule",
    "service_error":"Veuillez entrer votre numéro de carte de service",
    "reference_number_validation_message":"Veuillez entrer votre numéro de référence à 6 chiffres",
    "error_invoice_tax_canada":"Taxe applicable seulement pour {applicationTaxType}, veuillez corriger.",
    "error_invoice_start_date": "Veuillez entrer la date de début de réparation.",
    "view":"VOIR",
     "dealer_draft_processed_requests_title": "Demandes traitées",
    "labor_rate":"Taux de travail",
    "optional_rates":"Tarifs optionnels",
    "body_shop_rate":"Taux d'atelier",
    "mobile_onsite_rate":"Tarif mobile / sur place",
    "equipment_add_rate":"Équipement / Taux d'ajout",
    "light_duty":"Service léger",
    "medium_duty":"Service moyen",
    "heavy_duty":"À toute épreuve",
    "trailer":"Bande annonce",
    "ari_standard_rate":"Tarif Standard Holman (obligatoire)",
    "laborRate_required":"Veuillez entrer le taux de main d'oeuvre.",
    "multi_client_name":"Nom du client",
    "multi_full_vin":"Vin complet. No.",
    "multi_year":"An",
    "multi_make":"Faire",
    "multi_model":"Modèle",
    "custom_error_message":"Aucun bon de commande trouvé, correspondant à ce critère.",
    "multi_veh_title_repair_po":"Votre recherche a renvoyé plusieurs véhicules avec la même information. Veuillez sélectionner le véhicule sur lequel vous travaillez dans la liste ci-dessous afin de commencer votre création de po.",
    "multi_veh_title_carwash_po":"Votre recherche a renvoyé plusieurs véhicules avec la même information. Veuillez sélectionner le véhicule sur lequel vous travaillez dans la liste suivante afin de commencer la création de votre lave-auto.",
    "multi_veh_title_search_vin":"Votre recherche a renvoyé plusieurs véhicules avec la même information. Veuillez sélectionner le véhicule sur lequel vous travaillez dans la liste suivante afin de continuer.",

    "REFERENCE_NO_mandatory":"S'il vous plaît fournir le numéro de référence",
    "REFERENCE_NO_min_length":"Le numéro de référence doit avoir au moins {minLength} caractères",   
    "REFERENCE_NO_max_length":"Le numéro de référence ne doit pas dépasser {maxLength} caractères",
    "REFERENCE_NO_min_max_length":"Le numéro de référence doit être compris entre {minLength} et {maxLength} caractères",
    "REFERENCE_NO_exact_length":"Le numéro de référence doit être exactement {exactLength} caractères",

    "YOUR_NAME_mandatory":"S'il vous plaît fournir votre nom",
    "YOUR_NAME_min_length":"Votre nom doit contenir au moins {minLength} caractères",   
    "YOUR_NAME_max_length":"Votre nom ne doit pas dépasser les caractères {maxLength}",
    "YOUR_NAME_min_max_length":"Votre nom doit être compris entre {minLength} et {maxLength} caractères",
    "YOUR_NAME_exact_length":"Votre nom doit être exactement {exactLength} caractères",

    "INVOICE_NO_mandatory":"S'il vous plaît fournir le numéro de facture",
    "INVOICE_NO_min_length":"Le numéro de facture doit contenir au moins {minLength} caractères",   
    "INVOICE_NO_max_length":"Le numéro de facture ne doit pas dépasser {maxLength} caractères",
    "INVOICE_NO_min_max_length":"Le numéro de facture doit être compris entre {minLength} et {maxLength} caractères",
    "INVOICE_NO_exact_length":"Le numéro de facture doit être exactement {exactLength} caractères",

    "REPAIR_START_DATE_mandatory":"S'il vous plaît fournir la date de début de réparation",
    "REPAIR_COMPLETION_DATE_mandatory":"S'il vous plaît fournir la date d'achèvement de réparation",
    "INVOICE_DATE_mandatory":"Veuillez indiquer la date de la facture",
    "ODOMETER_mandatory":"S'il vous plaît fournir un odomètre",
    "TAX_mandatory":"S'il vous plaît fournir une raison d'exonération fiscale ou fiscale",
    "CONFIRM_WORK_IS_COMPLETE_mandatory":"S'il vous plaît confirmer que le travail est terminé",
    "CONFIRM_BANK_ACCOUNT_mandatory":"S'il vous plaît confirmer le numéro de compte",

    "vendor_details_sub_title_processed":"Votre demande a été acceptée et soumise pour paiement. S'il vous plaît <a>imprimer</a> et conserver cette page pour vos dossiers.",

    "businessNumber_last4digits":"Les 4 derniers chiffres du numéro d'entreprise",
    "recover_password_subtitle": "Plus l'un des suivants:",
    "error_message_incorrect_identification_last_4_digits": "ID invalide. Veuillez entrer le format suivant: (XXXX)",
    "invalid_payment_email_format": "Le format de l'email de paiement est invalide",
    "login_button": "S'identifier",
    "forgot_password_button": "Mot de passe oublié",
    "reset_password_result_title": "Réinitialisez votre mot de passe",
    "reset_password_result_subtext": "Le lien de réinitialisation du mot de passe que vous avez sélectionné a été utilisé ou a expiré. Pour votre sécurité, veuillez compléter à nouveau le processus de réinitialisation du mot de passe.",
    "reset_password_result_subtext_2": "Si vous avez des questions, veuillez communiquer avec l'équipe des Relations avec les fournisseurs des États-Unis au 1-888-683-8327 ou avec l'équipe des Relations avec les fournisseurs canadiens au 1-866-416-6542.",
    "reset_password_success_title": "Réinitialisez votre mot de passe",
    "reset_password_success_subtext": "Votre mot de passe a été changé avec succès. Vous pouvez vous connecter à Holman PartnerConnect maintenant.",
    "confirm_password_field_required": "Confirmer le mot de passe ne peut pas",
    "confirm_password_field_does_not_match": "Confirmer que le mot de passe ne correspond pas",
    "reset_password_title": "Réinitialisez votre mot de passe",
    "password_field_required": "Le mot de passe doit répondre aux critères de complexité ci-dessous",
    "new_password_field": "Nouveau mot de passe: ",
    "confirm_password_field": "Confirmez le mot de passe: ",
    "reset_password_button": "Réinitialiser le mot de passe",
    "reset_password_home_button": "Accueil",
    "federal_tax_id_last4digits": "Les 4 derniers chiffres de l'identifiant fiscal fédéral",
    "federal_tax_id_required": "Identifiant d'impôt fédéral invalide. Veuillez entrer le format suivant: (XXXX)",
    "recover_too_attempts_title": "Réinitialisez votre mot de passe",
    "recover_too_attempts_subtext": "Vous avez dépassé le nombre de tentatives réussies de réinitialisation de mot de passe qui peuvent être terminées en une heure et votre compte a été verrouillé.",
    "recover_too_attempts_subtext_2": "Si vous avez des questions, veuillez communiquer avec l'équipe des Relations avec les fournisseurs des États-Unis au 1-888-683-8327,  l'équipe des Relations avec les fournisseurs canadiens au 1-866-416-6542 ou avec le service à la clientèle d'ARIZA au 01-800-00-27492.",
    "return_button": "Retour",
    "recover_password_or_seperator": "ou",
    "recaptcha_title":"Cliquez sur la case à cocher ci-dessous, il s'agit d'une mesure de sécurité pour éviter le spam.",
    "password_policy_title" : "Le nouveau mot de passe doit répondre aux critères suivants:",
    "password_policy_8_chars" : "Contenir au moins 8 caractères",
    "password_policy_uppercase" : "Contient au moins 1 lettre majuscule",
    "password_policy_lowercase" : "Contient au moins 1 lettre minuscule",
    "password_policy_digit" : "Contenir au moins 1 chiffre",
    "password_policy_special_char" : "Contient au moins 1 symbole de cette liste !#$%&\'()*+,-./:;<=>?@[\]^_{|}~",
    "invalid_token_title": "Utilisateur Holman",
    "invalid_token_subtext": "Le jeton d'utilisateur Holman n'est pas valide.",
    "invalid_token_subtext_2": "Veuillez contacter HPCITsupport@holman.com pour obtenir de l'aide.",
    "vendor_search_label": "Entrez un identifiant de fournisseur pour accéder au compte.",
    "vendor_search_placeholder": "Entrer l'identifiant du vendeur",
    "vendor_search_invalid": "Conteneur de recherche de fournisseur non valide",
    "vendor_search_permission_error": "L'utilisateur Holman n'a pas la permission de voir ce vendeur",
    "vendor_search_empty": "L'identifiant du fournisseur ne peut pas être vide",
    "vendor_search_error": "Vendeur non trouvé",
    "vendor_search_suffix_missing": "Entrez le suffixe du pays à la fin de chaque ID de fournisseur (UA, MM, CC)",
    "change_password_title": "Changez votre mot de passe",
    "current_password_field": "Mot de passe actuel",
    "already_used_in_last_5_passwords": "Le mot de passe que vous avez entré n'est pas valide car il correspond à l'un des cinq derniers que vous avez utilisés. Veuillez créer un nouveau mot de passe.",
    "current_password_field_required": "Le mot de passe actuel ne peut pas être vide",
    "change_password_sub_title": "Nos enregistrements indiquent que votre mot de passe actuel ne répond pas aux exigences de complexité. Pour votre sécurité, veuillez créer un nouveau mot de passe.",
    "tls_warning_title": "Avertissement de sécurité du navigateur",
    "tls_warning_message_line1": "Ce site Web ne permet pas l'utilisation d'anciens navigateurs à des fins de sécurité. Pour accéder à ce site Web, vous devez effectuer une mise à niveau vers la dernière version de Microsoft Internet Explorer, Google Chrome, Apple Safari ou Mozilla Firefox.",
    "tls_warning_message_line3": "Si vous avez des questions, veuillez consulter votre service informatique ou votre fournisseur de sécurité Internet.",
    "tls_warning_message_line4": "Pour contacter Holman à propos de cette mise à jour de sécurité, contactez le centre d'assistance Holman PartnerConnect à l'adresse <a>partnerconnect@holman.com</a>",
    "tls_warning_learn_more_button": "Apprendre encore plus",
    "tls_learn_more_title": "Navigateur obsolète ou non pris en charge détecté",
    "tls_learn_more_message": "<p><p>Ce site Web ne permet pas l'utilisation d'anciens navigateurs à des fins de sécurité. Pour accéder à ce site Web, vous devez passer à la dernière version d'Internet Explorer (IE), Chrome, Safari ou Firefox.</p><p>Informations destinées à votre centre d’aide informatique: TLS 1.0 et TLS 1.1. Les protocoles sont désactivés sur ce site Web afin de respecter les normes de sécurité les plus recommandées en matière de protection des données.</p>Vous devrez installer un navigateur Web compatible avec le protocole TLS 1.2. Les versions de navigateur suivantes prennent en charge TLS 1.2:</p><ul><li>Google Chrome version 30 ou supérieure</li><li>Mozilla Firefox version 27 ou supérieure</li><li>Microsoft Internet Explorer 11 ou une version ultérieure</li><li>Microsoft Edge</li><li>Apple Safari 7 ou version ultérieure</li></ul><br />Si vous avez des questions, contactez Holman à l'adresse <a>partnerconnect@holman.com</a>.</ p>",
    
    // Crane inspection
    "crane_inspection__title": "Inspection de sécurité des grues mobiles",
    "crane_inspection__edit_form": 'Voir / Modifier le formulaire d\'inspection de la grue',
    "crane_inspection__save_continue": 'Sauvegarder et continuer',
    "crane_inspection__pass": 'PASS',
    "crane_inspection__fail": 'FAIL',
    "crane_inspection__inspection_date":"Date d'inspection",
    "crane_inspection__manufacturer": "Fabricant",
    "crane_inspection__manufacturer_required": "Fournir le fabricant",
    "crane_inspection__capacity": "Capacité",
    "crane_inspection__capacity_required": "Fournir une capacité",
    "crane_inspection_form__serial_number": "numéro de série",
    "crane_inspection_form__serial_number_required": "Indiquez le numéro de série",
    'crane_inspection__serial_number': "numéro de série",
    'crane_inspection__model_number': "Numéro de modèle",
    'crane_inspection__hoistSystemBrakesType': "Freins du système de levage",
    'crane_inspection__hoistSystemBrakesType_Hydraulic': "Hydraulique",
    'crane_inspection__hoistSystemBrakesType_Air': "Air",
    'crane_inspection__boomJibBrakesType': "Freins de flèche / flèche",
    'crane_inspection__boomJibBrakesType_Hydraulic': "Hydraulique",
    'crane_inspection__boomJibBrakesType_Air': "Air",
    'crane_inspection__interval': "Type",
    'crane_inspection__interval_annual': "annuel",
    'crane_inspection__interval_quadrennial': "Quadrennial",
    'crane_inspection__interval_other': "Autre",
    'crane_inspection_form__deficiencies': "Lacunes",
    'crane_inspection__serial_number_required': "Indiquez le numéro de série",
    'crane_inspection__model_number_required': "Indiquez le numéro de modèle",
    'crane_inspection__hoistSystemBrakesType_required': "Fournir des freins au système de levage",
    'crane_inspection__boomJibBrakesType_required': "Fournir des freins de flèche / flèche",
    'crane_inspection__interval_required': "Fournir type",
    'crane_inspection__interval_other_required': "Fournir un autre type",
    "crane_inspection__inspection_date_invalid":"Date invalide/mal formatée, veuillez saisir une date valide",
    "crane_inspection__inspection_date_disabled":"veuillez entrer une date valide",
    "crane_inspection__has_inspection_passed_required": 'Veuillez sélectionner PASS ou FAIL',
    "crane_inspection__has_inspection_passed_components_invalid": 'Tous les composants du véhicule doivent être marqués comme étant Sat. ou N / A pour passer l\'inspection ',
    "crane_inspection__has_inspection_passed_unsafe_conditions": "L\'inspection ne peut pas aboutir si les conditions dangereuses sont répertoriées",
    "crane_inspection__has_inspection_passed_no_failure_specified": "Veuillez spécifier les composants et les défauts du véhicule en panne",
    "crane_inspection__has_inspection_passed_no_failure_unsat_deficiencies": 'Composants du véhicule marqués comme Sat. ou N / A ne peut pas avoir des défauts énumérés',
    "crane_inspection__has_inspection_fail_deficiencies_without_unsat": 'S\'il vous plaît spécifier les lacunes pour Unsat. composants du véhicule',
    "crane_inspection__has_inspection_failed_all_components_required": 'Tous les composants doivent spécifier Sat./Unsat./NA.',
    "crane_inspection__inspector_attest": "J'atteste que je suis qualifié pour effectuer une inspection ANSI et / ou OSHA et que cette unité a satisfait à toutes les exigences d'inspection ANSI et / ou OSHA.",
    "crane_inspection__attest_required": "Veuillez attester de cette déclaration",
    "crane_inspection__inspector_signature": "signature de l'inspecteur",
    "crane_inspection__inspector_signature_note": "En tapant votre nom, vous acceptez que cela serve de représentation électronique de votre signature.",
    "crane_inspection__signature_required": "Veuillez entrer votre signature",
    "crane_inspection_confirmation__fail_confirm_title": 'Inspection mobile de grue ANSI',
    "crane_inspection_confirmation__fail_confirm_question": 'Allez-vous réparer les composants du véhicule en panne sur cette facture de réparation?',
    "crane_inspection_confirmation__fail_confirm_yes": 'Oui et modifier le bon de commande',
    "crane_inspection_confirmation__fail_confirm_no": 'Non et spécifiez les composants défaillants',
    "crane_inspection_form__title": "formulaire d'inspection de sécurité des grues mobiles",
    "crane_inspection_form__component_subtext_line1": '* Important de conserver une copie à jour du rapport d\'inspection dans ce véhicule.',
    "crane_inspection_form__component_subtext_line2": '* Les lois des États locaux peuvent demander des inspections plus fréquemment. Enquêter sur tous les États dans lesquels vous voyagez.',
    "crane_inspection_form__component_subtext_line3": 'Tout câble métallique non satisfaisant doit être remplacé conformément aux recommandations du fabricant d\'origine.',
    "crane_inspection_form__customer": 'Client',
    "crane_inspection_form__address": 'Adresse',
    "crane_inspection_form__numéro_unitaire": 'Numéro d\'unité',
    "crane_inspection_form__year": 'Année',
    "crane_inspection_form__make": 'Make',
    "crane_inspection_form__license_plate": 'plaque d\'immatriculation',
    "crane_inspection_form__date": 'Date',
    "crane_inspection_form__save": 'Sauvegarder et continuer',
    'crane_inspection_form__all_ok': 'Tout va bien',
    'crane_inspection_form__item': 'Actions',
    'crane_inspection_form__pass': 'Sat.',
    'crane_inspection_form__fail': 'Unsat.',
    'crane_inspection_form__na': 'N / A',
    'crane_inspection_form__fail_confirm_title': 'Réparer un bon de commande?',
    'crane_inspection_form__fail_confirm_question': 'Allez-vous réparer les composants du véhicule en panne sur cette facture de réparation?',
    'crane_inspection_form__fail_confirm_yes': 'Oui et éditer le bon de commande',
    'crane_inspection_form__fail_confirm_no': 'Non et continuer',
    'crane_inspection_form__rail_gear': "Engrenage de rail",
    'crane_inspection_form__rail_gear_model': "Modèle d'engrenage ferroviaire",
    'crane_inspection_form__rail_gear_front_serial_number': "Numéro de série avant",
    'crane_inspection_form__rail_gear_rear_serial_number': "Numéro de série arrière",
    'crane_inspection_form__component_300': "Publication en cours de certification",
    'crane_inspection_form__component_301': "* Instructions pour l'opérateur",
    'crane_inspection_form__component_302': "* Enregistrements de maintenance préventive",
    'crane_inspection_form__component_303': "Tableaux de signalisation manuelle",
    'crane_inspection_form__component_304': "* Tableaux de charge appropriés",
    'crane_inspection_form__component_305': "Commandes, niveaux et diagrammes de l'opérateur correctement marqués",
    'crane_inspection_form__component_306': "Décalcomanies d'information et d'avertissement requises",
    'crane_inspection_form__component_307': "Dispositif (s) d'avertissement / alarme de secours",
    'crane_inspection_form__component_308': "Sécurité et protections de la machine",
    'crane_inspection_form__component_309': "Dispositions relatives à l'accès (balustrades, etc.)",
    'crane_inspection_form__component_310': "Extincteurs d'incendie",
    'crane_inspection_form__component_311': "Anti-deux blocs",
    'crane_inspection_form__component_312': "Cellules de charge et indicateurs",
    'crane_inspection_form__component_313': "Composants / assemblages électriques",
    'crane_inspection_form__component_314': "Interrupteur d'arrêt d'urgence",
    'crane_inspection_form__component_315': "Commutateur de surcharge",
    'crane_inspection_form__component_316': "Interrupteurs principaux ou contrôleur de tambour",
    'crane_inspection_form__component_317': "Commutateurs, contacteurs, relais",
    'crane_inspection_form__component_318': "* Poste de commande principal",
    'crane_inspection_form__component_319': "Station de contrôle à distance et boutons",
    'crane_inspection_form__component_320': "Contrôles au godet / au sol",
    'crane_inspection_form__component_321': "Indicateurs: niveau, angle / longueur de la flèche, rotation du tambour, charge.",
    'crane_inspection_form__component_322': "Moteurs",
    'crane_inspection_form__component_323': "* Arbres, engrenages, accouplements, protecteurs",
    'crane_inspection_form__component_324': "* ensemble de roulement",
    'crane_inspection_form__component_325': "Scellés",
    'crane_inspection_form__component_326': "Assemblages de poulies",
    'crane_inspection_form__component_327': "* Réa et goupille d'égalisation",
    'crane_inspection_form__component_328': "Freins",
    'crane_inspection_form__component_329': "Interrupteur (s) de fin de course",
    'crane_inspection_form__component_330': "Dispositifs de verrouillage",
    'crane_inspection_form__component_331': "* Tambours et ancres",
    'crane_inspection_form__component_332': "* Charger bloc (s) bloc (s)",
    'crane_inspection_form__component_333': "Crochets et boulons de contrepoids",
    'crane_inspection_form__component_334': "* Crochets tram / gorge",
    'crane_inspection_form__component_335': "Crochet NDT",
    'crane_inspection_form__component_336': "* ensemble de roulement",
    'crane_inspection_form__component_337': "* Scellés",
    'crane_inspection_form__component_338': "Freins",
    'crane_inspection_form__component_339': "Ligne de transmission et batterie (s)",
    'crane_inspection_form__component_340': "* Embrayages à friction, accouplement et goupilles",
    'crane_inspection_form__component_341': "* Réducteur; tous les engrenages et tous les pignons",
    'crane_inspection_form__component_342': "* Bôme et pièces jointes",
    'crane_inspection_form__component_343': "* Système de rotation de la flèche, verrouillages et freins",
    'crane_inspection_form__component_344': "* Serrure ou cliquet de levage du bras",
    'crane_inspection_form__component_345': "* La flèche et le bras arrière s'arrêtent",
    'crane_inspection_form__component_346': "Barrières levantes",
    'crane_inspection_form__component_347': "* Retrait automatique de la flèche",
    'crane_inspection_form__component_348': "* assemblage (s) des stabilisateurs",
    'crane_inspection_form__component_349': "* Soudures, cordes, lacets",
    'crane_inspection_form__component_350': "* Supports à coin",
    'crane_inspection_form__component_351': "Batterie",
    'crane_inspection_form__component_352': "Systèmes de lubrification automatique",
    'crane_inspection_form__component_353': "Lubrification manuelle / niveaux de fluide",
    'crane_inspection_form__component_354': "Jauge (huile, etc.) et ports de jauge",
    'crane_inspection_form__component_355': "Superstructure (cadre principal, vitre)",
    'crane_inspection_form__component_356': "Boulons, écrous, rivets, fixations, supports",
    'crane_inspection_form__component_357': "* Système air / hydraulique: cylindres, tuyaux et raccords",
    'crane_inspection_form__component_358': "Silencieux / système d'échappement",
    'crane_inspection_form__component_359': "Entretien ménager",
    'crane_inspection_form__component_360': "Direction",
    'crane_inspection_form__component_361': "Soudures",
    'crane_inspection_form__component_362': "Boulons",
    'crane_inspection_form__component_363': "Traces",
    'crane_inspection_form__component_364': "Suivi de piste",
    'crane_inspection_form__component_365': "Palan principal",
    'crane_inspection_form__component_366': "Palan auxiliaire",
    'crane_inspection_form__component_367': "Palan auxiliaire (2)",
    'crane_inspection_form__component_368': "Pendentifs",
    'crane_inspection_form__component_369': "Pendentifs (2)",
    'crane_inspection_form__component_370': "Jib Stays",
    'crane_inspection_form__component_371': "Palan à flèche",
    'crane_inspection_form__component_group_18': "Enregistrements",
    'crane_inspection_form__component_group_19': "Equipement de sécurité",
    'crane_inspection_form__component_group_20': "Systèmes électriques et systèmes de contrôle",
    'crane_inspection_form__component_group_21': "Systèmes de levage",
    'crane_inspection_form__component_group_22': "Flèche / flèche",
    'crane_inspection_form__component_group_23': "Systèmes de lubrification",
    'crane_inspection_form__component_group_24': "Divers",
    'crane_inspection_form__component_group_25': "Carrosserie de véhicule sur chenilles",
    'crane_inspection_form__component_group_26': "Rapport sur l'état des câbles métalliques et des crochets",
    'crane_inspection__vehicle_history_title': "Enregistrement de l'historique du véhicule",
    'crane_inspection__vehicle_history_report_number': "Numéro du rapport",
    'crane_inspection__vehicle_history_unit_number': "Numéro d'unité de flotte",
    'crane_inspection__vehicle_history_inspection_date': "Date",

    //BIT
    "bit_inspection__title": 'inspection BIT',
    'bit_inspection__truck': 'camion',
    'bit_inspection__bus': 'bus',
    'bit_inspection__trailer': 'trailer',
    "bit_inspection__edit_form": "Voir / Modifier le formulaire d'inspection BIT",
    "bit_inspection__save_continue": 'Enregistrer et continuer',
    "bit_inspection__pass": 'PASS',
    "bit_inspection__fail": 'ECHEC',
    "bit_inspection__inspection_date":"Date d'inspection",
    "bit_inspection__inspection_date_invalid":"Date invalide/mal formatée, veuillez saisir une date valide",
    "bit_inspection__inspection_date_disabled":"veuillez entrer une date valide",
    "bit_inspection__has_inspection_passed_required": 'Veuillez sélectionner PASS ou FAIL',
    "bit_inspection__has_inspection_passed_components_invalid": "Tous les éléments doivent être marqués comme OK ou N / A pour réussir l'inspection",
    "bit_inspection__has_inspection_passed_unsafe_conditions": "L'inspection ne peut pas aboutir si les conditions dangereuses sont répertoriées",
    "bit_inspection__has_inspection_passed_no_failure_specified": 'Veuillez spécifier les éléments ayant échoué',
    "bit_inspection__has_inspection_failed_all_components_required": 'Tous les composants doivent spécifier OK / NA / Def.',
    "bit_inspection__has_inspection_other_option_comments_required": 'Des commentaires sont requis pour le composant "Autre" du véhicule.',
    "bit_inspection__inspector_attest": "J'atteste que je suis qualifié pour effectuer l'inspection demandée et que ce véhicule a réussi tous les éléments d'inspection conformément aux exigences du BIT.",
    "bit_inspection__attest_required": 'Veuillez attester de cette déclaration',
    "bit_inspection__inspector_signature": "signature de l'inspecteur",
    "bit_inspection__inspector_signature_note": "En tapant votre nom, vous acceptez que cela serve de représentation électronique de votre signature.",
    "bit_inspection__signature_required": "Veuillez entrer votre signature",
    "bit_inspection_confirmation__fail_confirm_title": 'Inspection BIT',
    "bit_inspection_confirmation__fail_confirm_question": 'Allez-vous réparer les éléments défaillants sur cette facture de réparation?',
    "bit_inspection_confirmation__fail_confirm_yes": 'Oui et éditer le bon de commande',
    "bit_inspection_confirmation__fail_confirm_no": 'Non et spécifiez les éléments ayant échoué',
    "bit_inspection_form__title": "formulaire d'inspection BIT",
    "bit_inspection_form__carrier_name": 'Nom du transporteur',
    "bit_inspection_form__numéro_unitaire": "Numéro d'unité",
    "bit_inspection_form__year": 'Année',
    "bit_inspection_form__make": 'Make',
    "bit_inspection_form__license_plate": "Plaque d'immatriculation",
    "bit_inspection_form__date": 'Date',
    "bit_inspection_form__save": 'Sauvegarder et continuer',
    'bit_inspection_form__all_ok': 'Tout va bien',
    'bit_inspection_form__item': 'Article',
    'bit_inspection_form__ok': 'OK',
    'bit_inspection_form__na': 'N / A',
    'bit_inspection_form__defective': 'Def',
    'bit_inspection_form__comments': 'Commentaires',

    // UN CAMION
    'bit_inspection_form__component_51': "1. Extincteurs et avertisseurs réfléchissants",
    'bit_inspection_form__component_52': '2. Klaxon, dégivreur, jauges, compteur kilométrique et indicateur de vitesse ',
    'bit_inspection_form__component_53': '3. Miroirs et supports ',
    'bit_inspection_form__component_54': '4. Essuie-glaces, fissures de vitres ',
    'bit_inspection_form__component_55': "5. Toutes les lumières, signaux, réflecteurs, bavettes ",
    'bit_inspection_form__component_56': '6. Câblage électrique-condition et protection ',
    'bit_inspection_form__component_57': "7. Batteries - niveau d'eau, bornes et câbles ",
    'bit_inspection_form__component_58': "8. Dispositifs d'avertissement: air, huile, température, antidérapants et / ou aspirants",
    'bit_inspection_form__component_59': "9. Radiateur et tuyaux d'eau - niveau, état et / ou fuite de liquide de refroidissement ",
    'bit_inspection_form__component_60': '10. Courroies compresseur, ventilateur, pompe à eau et / ou alternateur ',
    'bit_inspection_form__component_61': "11. Fuites, état et / ou protection des tuyaux d’air et des tubes",
    'bit_inspection_form__component_62': "12. Système de carburant - réservoir, tuyaux flexibles, tuyauterie et / ou pompe; fuites",
    'bit_inspection_form__component_63': "13. Système d'échappement, collecteurs, tuyauterie, silencieux; fuites et / ou condition",
    'bit_inspection_form__component_64': "14. Moteur, excès de graisse et / ou d'huile ",
    'bit_inspection_form__component_65': "15. Jeu sans ajustement de l'embrayage",
    'bit_inspection_form__component_66': "16. Filtre à air, tringlerie d'accélérateur",
    'bit_inspection_form__component_67': '17. Système de démarrage et de charge ',
    'bit_inspection_form__component_68': '18. Valve de protection du tracteur ',
    'bit_inspection_form__component_69': "19. Réglage du système de freinage hydraulique, composants et / ou état ",
    'bit_inspection_form__component_70': "20. Niveau du maître-cylindre hydraulique, fuites et / ou conditions ",
    'bit_inspection_form__component_71': '21. Tuyaux et tubes-condition et protection ',
    'bit_inspection_form__component_72': "22. Réglage du système de freinage pneumatique, composants et / ou état ",
    'bit_inspection_form__component_73': "23. Essai de perte d'air ou de vide d'une minute ",
    'bit_inspection_form__component_74': '24. Pressions d’arrêt et de déclenchement du régulateur de compresseur d’air (85-130) ',
    'bit_inspection_form__component_75': '25. Vidange du réservoir d’air primaire et clapet anti-retour d’essai ',
    'bit_inspection_form__component_76': '26. Autre réservoir d’air vider et vérifier la contamination; sécurisation ',
    'bit_inspection_form__component_77': "27. La profondeur, le gonflage et l'état des pneus, ",
    'bit_inspection_form__component_78': "28. Roues, écrous de roue et goujons - fissures, desserrement et / ou état ",
    'bit_inspection_form__component_79': "29. Frein de stationnement capable de retenir le véhicule ",
    'bit_inspection_form__component_80': "30. Système d'arrêt d'urgence étiqueté et opérationnel ",
    'bit_inspection_form__component_81': "31. Déblocage des freins après perte totale de l'air de service ",
    'bit_inspection_form__component_82': "32. Montage du système de direction, cils libres et composants ",
    'bit_inspection_form__component_83': '33. Bras de direction, bras de liaison et / ou embouts de biellette de direction ',
    'bit_inspection_form__component_84': "34. Dispositifs de connexion - sellette d'attelage, attelage à pivot et / ou dispositifs de sécurité",
    'bit_inspection_form__component_85': '35. Système de suspension - ressorts, manilles, boulons en U et / ou tiges de torsion ',
    'bit_inspection_form__component_86': "36. Cadres et traverses - fissures et / ou état ",
    'bit_inspection_form__component_87': "37. Arbre d'entraînement, joints universels et / ou protecteurs ",
    'bit_inspection_form__component_88': "38. Transmission et montage différentiel, fuites et / ou conditions ",
    'bit_inspection_form__component_89': "39. Joints de roue-fuites et / ou condition ",
    'bit_inspection_form__component_90': '40. Sous chariot-propre et sécurisé ',
    'bit_inspection_form__component_91': '41. Autre',
        
    // BANDE ANNONCE
    'bit_inspection_form__component_100': '1. Tous les feux, signaux, réflecteurs ',
    'bit_inspection_form__component_101': '2. Garde-boues',
    'bit_inspection_form__component_102': "3. Système de freinage des fuites d'air ",
    'bit_inspection_form__component_103': "4. Système de fuite d'air ",
    'bit_inspection_form__component_104': "5. Cadre, sous-cadre et corps-fissures ",
    'bit_inspection_form__component_105': '6. Réglage du frein ',
    'bit_inspection_form__component_106': '7. Système de freinage, tambours et état des composants ',
    'bit_inspection_form__component_107': '8. Système de suspension - ressorts, manilles, boulons en U et / ou tiges de torsion ',
    'bit_inspection_form__component_108': "9. Raccordement d'appareil-pivot, de barre d'attelage, de sécurité oculaire et / ou de sécurité ",
    'bit_inspection_form__component_109': "10. Sellette d'attelage sur remorque ",
    'bit_inspection_form__component_110': "11. Flexibles pneumatiques et tubes-fuites, condition et / ou protection ",
    'bit_inspection_form__component_111': "12. La profondeur, le gonflage et l'état des pneus, ",
    'bit_inspection_form__component_112': "13. Roues, écrous de roue et goujons - fissures, desserrement et / ou état ",
    'bit_inspection_form__component_113': '14. Tuyaux et tubes-condition, protection ',
    'bit_inspection_form__component_114': "15. Niveau du maître-cylindre hydraulique, fuites et / ou conditions ",
    'bit_inspection_form__component_115': "16. Frein de stationnement capable de retenir le véhicule ",
    'bit_inspection_form__component_116': "17. Système de freinage d'urgence détaché ",
    'bit_inspection_form__component_117': "18. Vannes relais d'air et montage sur réservoir ",
    'bit_inspection_form__component_118': "19. Déblocage des freins après perte totale de l'air de service ",
    'bit_inspection_form__component_119': "20. Joints de roue-fuites et / ou condition ",
    'bit_inspection_form__component_120': '21. Sous chariot-propre et sécurisé ',
    'bit_inspection_form__component_121': '22. Autre',
        
    // AUTOBUS
    'bit_inspection_form__component_150': '1. Extincteur, trousse de secours et avertisseurs réfléchissants ',
    'bit_inspection_form__component_151': '2. Klaxon, dégivreur, jauges, compteur kilométrique et indicateur de vitesse ',
    'bit_inspection_form__component_152': "3. Siège du conducteur, sièges des passagers, rembourrage, état intérieur et sol ",
    'bit_inspection_form__component_153': '4. Essuie-glaces, vitres, rétroviseurs et supports ',
    'bit_inspection_form__component_154': "5. Tous les éclairages intérieurs et extérieurs, signaux, réflecteurs ",
    'bit_inspection_form__component_155': '6. Câblage électrique-condition et protection ',
    'bit_inspection_form__component_156': "7. Batteries - niveau d'eau, bornes et câbles ",
    'bit_inspection_form__component_157': "8. Dispositifs d'avertissement - air, huile, température, sortie et / ou vide ",
    'bit_inspection_form__component_158': "9. Réchauffeurs, dégivreurs, interrupteurs et évents ",
    'bit_inspection_form__component_159': '10. Portes, extérieur, peinture et marquage ',
    'bit_inspection_form__component_160': "11. Radiateur et tuyaux d'eau - niveau, état et / ou fuite de liquide de refroidissement ",
    'bit_inspection_form__component_161': '12. Courroies-compresseur, ventilateur, eau et / ou alternateur ',
    'bit_inspection_form__component_162': '13. Flexibles pneumatiques et tubes-fuites, condition et / ou protection ',
    'bit_inspection_form__component_163': '14. Système de carburant - réservoir, tuyaux flexibles, tuyauterie et / ou fuite de pompe ',
    'bit_inspection_form__component_164': "15. Système d'échappement, collecteurs, tuyauterie, fuite et / ou état du silencieux ",
    'bit_inspection_form__component_165': "16. Moteur, excès de graisse et / ou d'huile ",
    'bit_inspection_form__component_166': "17. Jeu sans ajustement de l'embrayage ",
    'bit_inspection_form__component_167': "18. Filtre à air, tringlerie d'accélérateur ",
    'bit_inspection_form__component_168': '19. Système de démarrage et de charge ',
    'bit_inspection_form__component_169': "20. Réglage du système de freinage hydraulique, composants et / ou état ",
    'bit_inspection_form__component_170': "21. Niveau du maître-cylindre hydraulique, fuites et / ou conditions ",
    'bit_inspection_form__component_171': '22. Tuyaux et tubes-condition, protection ',
    'bit_inspection_form__component_172': "23. Réglage du système de freinage pneumatique, compartiments et / ou condition ",
    'bit_inspection_form__component_173': "24. Essai de perte d'air ou de vide d'une minute ",
    'bit_inspection_form__component_174': "25. Le compresseur d'air a coupé et coupé la pression (85-130) ",
    'bit_inspection_form__component_175': "26. Vidange du réservoir d'air primaire et fonction de test du clapet anti-retour ",
    'bit_inspection_form__component_176': "27. Autres réservoirs d'air: vidange et vérification de la contamination ",
    'bit_inspection_form__component_177': '28. Pneu - profondeur de la bande de roulement, gonflage, état ',
    'bit_inspection_form__component_178': '29. Roues, écrous de roue et goujons - fissures, desserrement et / ou état ',
    'bit_inspection_form__component_179': '30. Frein de stationnement capable de retenir le véhicule ',
    'bit_inspection_form__component_180': "31. Système d'arrêt d'urgence étiqueté, opérationnel ",
    'bit_inspection_form__component_181': '32. Les freins ne se relâchent pas après la perte totale de l’air de service ',
    'bit_inspection_form__component_182': '33. Montage du système de direction, cils libres et composants ',
    'bit_inspection_form__component_183': '34. Bras de direction, bras de liaison et / ou embouts de biellette de direction ',
    'bit_inspection_form__component_184': '35. Système de suspension - ressorts, manilles, boulons en U et / ou tiges de torsion ',
    'bit_inspection_form__component_185': '36. Cadres et traverses - fissures et / ou état ',
    'bit_inspection_form__component_186': "37. Arbre d'entraînement, joints universels et / ou protecteurs ",
    'bit_inspection_form__component_187': '38. Transmission et montage différentiel, fuites et / ou conditions ',
    'bit_inspection_form__component_188': '39. Joints de roue-fuites et / ou condition ',
    'bit_inspection_form__component_189': '40. Sous chariot-propre et sécurisé ',
    'bit_inspection_form__component_190': '41. Autre',
        
    'bit_inspection_form__fail_confirm_title': 'Réparer un bon de commande?',
    'bit_inspection_form__fail_confirm_question': 'Allez-vous réparer les éléments défaillants sur cette facture de réparation?',
    'bit_inspection_form__fail_confirm_yes': 'Oui et éditer le bon de commande',
    'bit_inspection_form__fail_confirm_no': 'Non et continuer',

    // inspection de la FRA
    "fra_inspection__title": 'inspection FRA',
    "fra_inspection__edit_form": "Voir / Modifier le formulaire d'inspection FRA",
    "fra_inspection__save_continue": 'Sauvegarder et continuer',
    "fra_inspection__pass": 'PASS',
    "fra_inspection__fail": 'ECHEC',
    "fra_inspection__inspection_date":"Date d'inspection",
    "fra_inspection__inspection_date_invalid":"Date invalide/mal formatée, veuillez saisir une date valide",
    "fra_inspection__inspection_date_disabled":"veuillez entrer une date valide",
    "fra_inspection__has_inspection_passed_required": 'Veuillez sélectionner PASS ou FAIL',
    "fra_inspection__has_inspection_passed_components_invalid": "Tous les éléments doivent être marqués comme OK pour réussir l'inspection",
    "fra_inspection__has_inspection_passed_unsafe_conditions": "L'inspection ne peut pas aboutir si les conditions dangereuses sont répertoriées",
    "fra_inspection__has_inspection_passed_no_failure_specified": 'Veuillez spécifier les éléments ayant échoué',
    "fra_inspection__inspector_attest": "Je certifie que je suis qualifié pour effectuer l'inspection demandée et que ce véhicule a réussi tous les éléments d'inspection conformément aux exigences de la FRA.",
    "fra_inspection__attest_required": "Veuillez attester de cette déclaration",
    "fra_inspection__inspector_signature": "signature de l'inspecteur",
    "fra_inspection__inspector_signature_note": "En tapant votre nom, vous acceptez que cela serve de représentation électronique de votre signature.",
    "fra_inspection__signature_required": "Veuillez entrer votre signature",
    "fra_inspection_confirmation__fail_confirm_title": 'inspection FRA',
    "fra_inspection_confirmation__fail_confirm_question": 'Allez-vous réparer les éléments défaillants sur cette facture de réparation?',
    "fra_inspection_confirmation__fail_confirm_yes": 'Oui et éditer le bon de commande',
    "fra_inspection_confirmation__fail_confirm_no": 'Non et spécifiez les éléments en échec',
    "fra_inspection_form__title": "formulaire d'inspection FRA",
    "fra_inspection_form__carrier_name": 'nom du transporteur',
    "fra_inspection_form__numéro_unitaire": "Numéro d'unité",
    "fra_inspection_form__year": 'Année',
    "fra_inspection_form__make": 'Make',
    "fra_inspection_form__license_plate": "Plaque d'immatriculation",
    "fra_inspection_form__date": 'Date',
    "fra_inspection_form__save": 'Sauvegarder et continuer',
    'fra_inspection_form__all_ok': 'Tout va bien',
    'fra_inspection_form__item': 'Actions',
    'fra_inspection_form__pass': 'PASS',
    'fra_inspection_form__fail': 'ECHEC',
    'fra_inspection_form__fail_confirm_title': 'Réparer un bon de commande?',
    'fra_inspection_form__fail_confirm_question': 'Allez-vous réparer les éléments défaillants sur cette facture de réparation?',
    'fra_inspection_form__fail_confirm_yes': 'Oui et éditer le bon de commande',
    'fra_inspection_form__fail_confirm_no': 'Non et continuer',
    'fra_inspection_form__rail_gear': "Engrenage de rail",
    'fra_inspection_form__rail_gear_model': "Modèle d'engrenage ferroviaire",
    'fra_inspection_form__rail_gear_front_serial_number': "Numéro de série avant",
    'fra_inspection_form__rail_gear_rear_serial_number': "Numéro de série arrière",
    'fra_inspection_form__guide_wheel_load_lf': "L / F",
    'fra_inspection_form__guide_wheel_load_rf': "R / F",
    'fra_inspection_form__guide_wheel_load_lr': "L / R",
    'fra_inspection_form__guide_wheel_load_rr': "R / R",
    'fra_inspection_form__component_201': "Inspectez visuellement les engrenages du rail pour vous assurer qu'ils ne sont pas endommagés et qu'il manque des composants. Vérifiez tout le matériel de montage. Serrez le matériel de montage desserré. Vérifiez l'usure des paliers de pivot.",
    'fra_inspection_form__component_202': "Vérifiez que le mécanisme de verrouillage du volant est correctement installé et fonctionnel.",
    'fra_inspection_form__component_203': "Inspectez les roues de guidage. Assurez-vous que l'usure des brides est dans la tolérance. Vérifiez les roulements de roue pour le jeu, l'usure et le bruit. Indiquez si les roulements de roue de guidage doivent être entretenus.",
    'fra_inspection_form__component_204': "Vérifiez la charge de la roue de guidage conformément aux recommandations du fabricant. Indiquez si les hauteurs et les poids nécessitent des ajustements. Vérifiez si les spécifications du fabricant d'origine le permettent.",
    'fra_inspection_form__component_205': "Vérifiez le fonctionnement des mécanismes de verrouillage des engrenages de rail et des goupilles de blocage",
    'fra_inspection_form__component_206': "Vérifiez que les pneus du camion sont gonflés correctement. Remplacez les bouchons d'obturateur manquants. Gonflez les pneus à la pression recommandée sur l'autocollant de montant de porte.",
    'fra_inspection_form__component_207': "Vérifiez les butées de volant sur les véhicules où elles sont installées. Inspectez : le câblage du capteur ABS avant, les bras de commande supérieurs, les flexibles de frein et les colliers de retenue des flexibles de frein pour détecter tout dommage. Signalez tout manquement.",
    'fra_inspection_form__component_208': "Inspectez visuellement les roues, goujons, écrous de roue et pneus modifiés, ainsi que les traces d'usure ou de détérioration. Inspectez visuellement les entretoises de roue. Ne retirez pas les roues du camion. Serrez les écrous de roue au couple correct.",
    'fra_inspection_form__component_209': "Inspecter le bon alignement des engrenages de rail. Peindre les brides des roues de guidage et tester la voie. Indiquer si le véhicule nécessite un alignement des engrenages.",
    'fra_inspection_form__component_210': "Inspectez le système de dérivation pour vous assurer qu'il ne soit pas endommagé et qu'il fonctionne correctement. Ajustez les brosses de dérivation selon les besoins.",
    'fra_inspection_form__component_211': "Vérifier le bon fonctionnement du feu stroboscopique. Requis sous 49CFR214.",
    'fra_inspection_form__component_212': "Vérifier le bon fonctionnement de l'alarme de secours. Requis sous 49CFR214.",
    'fra_inspection_form__component_213': "Lubrifiez tous les graisseurs des engrenages de rail. Lubrifiez les autres pièces mobiles avec de l'huile légère.",
    'fra_inspection_form__component_214': "Assurez-vous que le train est en conformité avec tous les bulletins de service et de sécurité du fabricant.",
    'fra_inspection_form__component_215': "Enregistrez tout dommage anormal au système de transmission ferroviaire qui indiquerait des dommages dus au déraillement ou à la collision.",
    'fra_inspection_form__component_216': "Vérifiez si les vannes, flexibles et cylindres hydrauliques sont équipés de tout dommage, fuite ou acheminement. Avisez-nous de toute anomalie.",
    "insurance_edit_check_message":"Si vous avez besoin de réviser une estimation pour des événements liés à une réclamation, veuillez envoyer un supplément et des documents justificatifs à <a>{emailAddress}</a>",
    "insurance_edit_check_ok":"D'accord",
    "work_completed_tax_deferred_header":"Le montant de la taxe sera ajouté plus tard.",
    "work_completed_tax_deferred_subtext":"Si vous pensez que vous voyez ce message par erreur, veuillez appeler le 1-866-274-1025 pour discuter avec un technicien Holman.",
    "work_completed_tax_deferred_subtext_can":"Si vous pensez que vous voyez ce message par erreur, veuillez appeler le 1-800-363-7676 pour discuter avec un technicien Holman.",

     /*User Account Menu */
     'user_account_menu_your_account':	'Votre Compte',
     'user_account_corporate_account_link':	'"Compte corporatif',
     'user_account_menu_manage_stores':	'Gérer les magasins',
     'user_account_menu_manage_users':	'Gérer les utilisateurs',
     'user_account_menu_access_stores':	'Accéder aux magasins',
     'user_account_menu_sign_out':	'Déconnexion',

     'all_stores__title':'Tous les magasins',
     'all_stores_ari_vendor_id_store_id':'ID Holman/ N ° de magasin',
     'all_stores_address':'Adresse du magasin',
     'all_stores__manage_stores':"Magasin daccès",
     'all_stores__pagination_results_caption':'Affichage des résultats {first}-{last} de {storeCount}',
     'all_stores__search_text_place_holder':'rechercher un magasin',
     'all_stores__search_previous':'précédent',
     'all_stores__search_next':'suivant',
     'all_stores__search_no_stores_text':'Aucun résultat trouvé pour ce critère de recherche.',

     'manage_stores__title':'Gérer les magasins',
     'manage_stores_ari_vendor_id_store_id':'ID Holman/ N ° de magasin',
     'manage_stores_address':'Adresse du magasin',
     'manage_stores__manage_stores':"Gérer le magasin",
     'manage_stores__pagination_results_caption':'Affichage des résultats {first}-{last} de {storeCount}',
     'manage_stores__search_text_place_holder':'rechercher un magasin',
     'manage_stores__search_previous':'précédent',
     'manage_stores__search_next':'suivant',
     'manage_stores__search_no_stores_text':'Aucun résultat trouvé pour ce critère de recherche.',
     
     'user_profile__title':"Profil de l'utilisateur",
     'user_profile_resend_invite_link':"Envoyer une invitation",
     'add_stores__title':'Ajouter des magasins',
     'user_profile__edit_permissions':'Modifier les autorisations',
     'user_profile__remove_access':"Supprimer l'accès",
     'user_profile__remove_access_all':"Supprimer l'accès à tous les magasins",
     'user_profile__back':'Retour à tous les utilisateurs',
     'user_profile_no_stores_text':"L'utilisateur n'a accès à aucun magasin.",
 
     'manage_users__title':'Gérer les utilisateurs',
     'manage_users_name':'Prénom',
     'manage_users_email':'Email',
     'manage_users_resend_invite_btn':"Envoyer une invitation",
     'manage_users_remove_all_user_access_btn':'Supprimer tout accès',
     'manage_users__no_search_results':'Aucun utilisateur ne correspond aux critères de recherche trouvés',
     'manage_users__pagination_results_caption':"Affichage des résultats {first}-{last} de {userCount}",
     'manage_users__pagination_results_search_caption':' pour le terme de recherche',
     'manage_users__clear_search_results_caption':'Effacer les résultats de la recherche',
     'manage_users__search_text_place_holder':'rechercher un utilisateur',
     'manage_users__search_previous':'précédent',
     'manage_users__search_next':'suivant',
     'add_user__title':' Ajouter un utilisateur',
     'manage_users__search_no_users_text':"Aucun résultat trouvé pour ce critère de recherche.",
     'manage_users__no_users_to_manage': "Vous ne gérez actuellement aucun utilisateur.",

     'remove_access__title':"Supprimer l'accès utilisateur",
     'remove_access_message':"Êtes-vous sûr de vouloir supprimer {firstName} {lastName} l'accès de {vendorId} à {adresse}? Cliquez sur oui pour continuer. Cliquez non pour annuler.",
     'remove_all_access_message':"Voulez-vous vraiment supprimer {firstName} {lastName} de tous leurs magasins? Cliquez sur oui pour continuer. Cliquez non pour annuler.",
     'remove_access_yes':'Oui',
     'remove_access_no':'Non',
     'delete_user':"Supprimer l'utilisateur",

     'recover_password_sub_heading'   :  'Veuillez cliquer sur le drapeau approprié',
     'recover_password_email_address' : "Identifiant de l'adresse e-mail ",  
     'recover_password_vendorId':"Holman a délivré une pièce d'identité ",
     'recover_password_ARI_access_error_message':"Veuillez sélectionner le mode d'accès à Holman Partner Connect.",

     'reset_password_persona_user_registration_mesg':'Entrez mot de passe pour enregistrer votre compte avec Holman Partner Connect',
     'reset_password_persona_user_registration_title':'Définir le mot de passe',

     'invalid_user_email_format':"S'il vous plaît, mettez une adresse email valide",
     'persona_header_text':'Holman PartnerConnect',
     'store_profile__title':'Profil de magasin',
     'email_confirmation_title':"confirmation de l'émail",
     'email_confirmation_message':"Un lien d'invitation a été envoyé à {firstName} {lastName}.",
     'email_confirmation_ok':"D'accord",

    'store_profile_name':'Prénom',
    'store_profile_email_address':'Adresse électronique',
    'store_profile__pagination_results_caption':'Affichage des résultats {first}-{last} de {userCount}',
    'store_profile__search_previous':'précédente',
    'store_profile__search_next':'suivante',
    'store_profile__admin':'Administrateur',
    'store_profile__remove_access':"Supprimer l'accès",
    'store_profile__back':'Retour à la gestion des magasins',
    'store_profile_no_users_text':"Il n'y a aucun utilisateur qui a accès à ce magasin.",

    // Holman Redirect Screen
    'holman_redirect__holman_button1': 'Accéder au nouveau site',
    'holman_redirect__ari_button1': "Rester sur l'ancien site",
    'holman_redirect__holman_redirect__holman_button2': 'Accéder au nouveau site Web Holman PartnerConnect',
    'holman_redirect__ari_button2': "Rester sur l'ancien site Web Holman Partner Connect",
    'holman_redirect__holman_redirect__holman_button3': 'Accéder au nouveau site Web Holman PartnerConnect',
    'holman_redirect__instructions_new_site': `<p>
    Veuillez cliquer sur le bouton ci-dessous pour accéder au nouveau site Web Holman PartnerConnect. Si vous rencontrez l’un des problèmes suivants après avoir navigué, suivez les instructions suivantes :
  </p>

  <ul>
    <li>
      Pare-feu d'entreprise bloquant le nouveau site Web: partnerconnect.holman.com
      <ul>
        <li>Cliquez sur le <b>bouton Retour</b> pour revenir à l’écran précédent, puis cliquez sur <b>Rester sur l’ancien site.</b></li>
        <li>Travaillez avec votre équipe de soutien informatique pour débloquer l’accès à partnerconnect.holman.com.</li>
      </ul>
    </li>
    <li>
      Impossible de vous connecter car votre nom d’utilisateur et votre mot de passe étaient mémorisés sur l’ancien site.
      <ul>
        <li>Utilisez le lien Mot de passe oublié sur le nouveau site pour réinitialiser votre mot de passe.</li>                  
      </ul>
    </li>
    <li>
      Si les solutions précédentes ne règlent pas vos problèmes...
      <ul>
        <li>Contactez Holman par courriel:  <Mailto>aripartnerconnect@arifleet.com</Mailto></li>
      </ul>
    </li>
  </ul>`,
    'holman_redirect__instructions_old_site': `<p>
    Vous pouvez continuer à utiliser l’ancien site jusqu’au <Date>30 avril</Date>.  Pendant ce temps, veuillez essayer d’utiliser le nouveau site Web et de résoudre tout problème. Voici les problèmes courants et leurs résolutions:
  </p>

  <ul>
    <li>
      Pare-feu d’entreprise bloquant le nouveau site Web : partnerconnect.holman.com
      <ul>
        <li>Cliquez sur le <b>bouton Retour</b> pour revenir à l’écran précédent, puis cliquez sur <b>Rester sur l’ancien site.</b></li>                    
        <li>Travaillez avec votre équipe de soutien informatique pour débloquer l’accès à partnerconnect.holman.com.</li>
      </ul>
    </li>
    <li>
      Impossible de vous connecter car votre nom d’utilisateur et votre mot de passe étaient mémorisés sur l’ancien site.
      <ul>
        <li>Utilisez le lien Mot de passe oublié sur le nouveau site pour réinitialiser votre mot de passe.</li>                  
      </ul>
    </li>
    <li>
      Si les solutions précédentes ne règlent pas vos problèmes...
      <ul>
        <li>Contactez Holman par courriel : <Mailto>aripartnerconnect@arifleet.com</Mailto></li>
      </ul>
    </li>
  </ul>`,

    'add_users_to_store_search_caption':'Rechercher des utilisateurs',
    'search_users_caption': "Rechercher et sélectionner les utilisateurs auxquels accorder l'accès",
    'search_users_add_user_text1':'Utilisateur non trouvé? Aucun problème.',
    'search_users_add_user_text2':'Vous pouvez configurer un nouvel utilisateur ici',
    'select_users_caption':'Utilisateurs sélectionnés',
    'select_users_none':'Aucun utilisateur sélectionné',
    'add_users_to_store_continue':'Terminez la sélection des utilisateurs et continuez',
    'add_users_to_store__no_user_found':'Aucun utilisateur trouvé',
    'add_users_to_store_grant_permissions_title':'Accorder des autorisations',
    'permissions_step__permissions_caption':'Accorder des autorisations à',
    'permissions_return':'Revenir',
    'permissions_save_changes':'Enregistre les modifications',
    'permissions__grant_delegate_access_title':'Accorder un accès délégué',
    'permissions__grant_delegate_access_instructions':"Les administrateurs peuvent créer de nouveaux utilisateurs, définir des autorisations et modifier des autorisations. Les administrateurs ne peuvent accorder ou modifier que les autorisations auxquelles ils ont déjà accès.",
    'permissions_yes':'Oui',
    'permissions_no':'Non',
    'permissions_features':"Fonctionnalités d'autorisations",
    'permissions_orders':'ACHETER EN LIGNE',
    'permissions_delivery_requests':'DEMANDES DE LIVRAISON DE COURTOISIE',
    'permissions_your_account_settings_contact_details':'CONFIGURATION ET DÉTAILS DE CONTACT',
    'permissions_bank':'DÉTAILS BANCAIRES',
    'permissions_tax':'DÉTAILS FISCAUX',
    'permissions_documents':'CHARGEMENT DE DOCUMENTS',
    'permissions_auth_email':"MISE A JOUR DE L'AUTORISATION CONFIGURATION E-MAIL",
    'permissions_services':'SERVICES',
    'permissions_view':'Vue',
    'permissions_search_view':'Rechercher / Voir',
    'permissions_create':'Créer',
    'permissions_edit':'modifier',
    'permissions_close_view':'Fermer / Voir',
    'permissions_void':'Annuler (annulé)',
    'permissions_upload':'Télécharger',
    'add_users_to_store__add_user_title':'Ajouter un utilisateur à ce magasin',
    'message_title': 'Mise à jour des détails de paiement en cours',
    'permissions__car_wash_vendor_po_note': 'Pour les vendeurs de lave-auto, toutes les autorisations de commande sont nécessaires',
    'payment_status_bulletin_body' : 'Les récentes modifications apportées aux informations de votre compte bancaire ont suspendu temporairement tous les paiements. Veuillez être prêt à recevoir un appel téléphonique de Holman pour vérifier ces changements afin de permettre la reprise des paiements.',
    'account_verify_sub_caption_for_payment_status':'Toute modification apportée aux informations de votre compte bancaire entraînera une suspension temporaire de tous les paiements. <alert>Pour des raisons de sécurité</alert>, soyez prêt à <b>recevoir</b> un appel téléphonique de Holman pour vérifier ces modifications.',
    'account_verify_sub_caption_for_payment_email':'Toutes les modifications apportées au champ Destinataire d’e-mail nécessitent la saisie de votre nom et de votre identifiant de taxe.',
    'edit_payment_email_caption':'Paiement Email Destinataire',
    'edit_payment_email_validation_error_message':'Veuillez entrer un email de paiement valide',
    'edit_payment_email_cancel__edit_caption':'Annuler',
    'edit_payment_email_save__caption':"Enregistrer le destinataire de l'e-mail de paiement",
 
    'payment_email_caption':'Paiement Email Destinataire',
    'edit_payment_email_information':"Modifier le destinataire de l'e-mail de paiement",

    'add_users_to_store_add_new_user_caption2':"Pour configurer un nouvel utilisateur, remplissez les champs ci-dessous et cliquez sur Créer un utilisateur. Une fois l'utilisateur créé, un courrier électronique contenant les instructions permettant d'accéder à Holman PartnerConnect sera envoyé.",
    'manage_stores_admin':'Administrateur',
    'manage_stores_store_information':'Informations de magasin',
    'store_profile__search_text_place_holder':"Recherche d'utilisateur",
    'store_profile__pagination_results_search_caption':' pour le terme de recherche',
    'add_users_to_store_add_new_users_caption':'Configurer un nouvel utilisateur',
    'add_new_user_first_name_placeHolder':'entrez votre prénom',
    'add_new_user_last_name_placeHolder':"Entrer le nom de famille",
    'add_new_user_email_placeHolder':'Entrer email',
    'add_new_user_create_user':'Créer un utilisateur',
    'add_new_user__email_invalid':"Format d'adresse email invalide",
    'add_new_user__email_invalid_required':'Adresse e-mail est nécessaire',
    'add_users_to_store_return':'Revenir',
    'add_users_to_store_add_new_user__continue':'Continuer',
    'add_users_to_store_add_new_user__dirty_warning':"Cet écran contient des modifications non enregistrées. Tous les nouveaux utilisateurs que vous avez créés seront enregistrés, mais les autorisations ne le seront pas. Cliquez sur 'Fermer' pour annuler les modifications.",
    'add_users__back':'Volver a todos los usuarios',

    'add_stores_to_store_search_caption':'Rechercher des magasins',
    'search_stores_caption':'Trouver et sélectionner des magasins',
    'select_stores_caption':'Boutiques sélectionnées',
    'add_stores_to_user__continue':'Terminer la sélection des magasins et continuer',
    'add_stores_to_user__no_store_found':'Aucun magasin trouvé',
    'add_stores_to_user__select':'Sélectionner',
    'add_stores_to_user__select_all_stores':'Sélectionner tous les magasins',
    'add_stores_to_user__ari_vendor_id_store_id':'Holman ID / numéro de magasin',
    'add_stores_to_user__address':'Adresse du magasin',
    'add_stores_to_user__manage_stores':'Adresse du magasin',
    'add_stores_to_user__pagination_results_caption':'Affichage des résultats {first} - {last} de {storeCount}',
    'add_stores_to_user__pagination_results_search_caption':' pour le terme de recherche',
    'add_stores_to_user__clear_search_results_caption':'. Effacer les résultats de la recherche',
    'add_stores_to_user__search_text_place_holder':'Rechercher un magasin',
    'add_stores_to_user__search_previous':'précédente',
    'add_stores_to_user__search_next':'suivante',
    'add_stores_to_user__search_no_stores_text':'Aucun résultat trouvé pour ce critère de recherche.',
    'select_stores_none':'Aucun magasin sélectionné',

    'modify_user_permissions__grant_permissions_title':'Modifier les autorisations',
    'modify_user_permissions__return':'Revenir',
    'modify_user_permissions__save_changes':'Sauvegarder les modifications',    
    'modify_user_permissions__store':'le magasin',

    'permissions__select_all':"Cliquez ici pour sélectionner toutes les fonctionnalités d'autorisation",
    'permissions__deselect_all':"Cliquez ici pour désélectionner toutes les fonctionnalités d'autorisation",
    'permissions__bank_details':'Coordonnées bancaires',
    'permissions__unable_to_edit_permission_level':"Vous ne pouvez pas modifier cette fonctionnalité à votre niveau d'autorisation actuel.",
    'permissions__unable_to_edit_minimum':"'L'autorisation PO Search / View ne peut pas être désélectionnée car elle est nécessaire pour afficher l'écran de la boutique PartnerConnect et rechercher les bons de commande.",
    'permissions__delivery_requests_us_only':'Disponible uniquement pour les utilisateurs américains',
    'permissions_close_view_edit':'Fermer / Voir / Modifier',
    'user_profile_search_text_place_holder':'Rechercher un magasin',

    'user_profile__search_no_users_text':'Aucun résultat trouvé pour ce critère de recherche.',
    'manage_stores__no_store_results':"L'utilisateur n'a accès à aucun magasin.",
    'all_stores__access_no_stores_text':"Vous n'avez accès à aucun magasin.",
    'store_profile_search_no_users_text':'Aucun résultat trouvé pour ce critère de recherche.',

    'user_profile':"Profil de l'utilisateur",
    'reset_password_result_subtext3':"Ce lien a expiré. Veuillez contacter la personne qui vous a invité et lui demander d'envoyer un nouveau lien.",
    'send_invite_result_title':'Invitation',
    'auto_negotiated_note_US':"Si vous nacceptez pas ces modifications, vous devez appeler Holman au \n1-866-274-1025",
    'auto_negotiated_note_CAN':'Si vous nacceptez pas ces modifications, vous devez appeler Holman au \n1-800-363-7676',
    'ari_issued_id':'ID délivré par Holman',
    'vendor_location':'Emplacement',
    'search_po_client_name_column_header':'Nom du client',
    'search_po_full_vin_column_header':'Vin complet. Non',
    'search_po_client_id_amount_column_header':'Identité du client',
    'client_po_title':'Client PO#',
    'client_po_label':'Client PO',
    'search_for_client_po':"Rechercher le (s) bon (s) d'achat par client PO# ...",
    'client_po_number_caption':'Client PO# (si disponible)',
    'client_po_subtitle':'(si disponible)',
    "existing_po_voided_link":"Il existe des bons de commande annulés par l'utilisateur pour cet actif. En conséquence, ce bon de commande sera soumis à un nouvel examen. {click}",
    "existing_po_voided_error_selection_continue":"Clique ici pour continuer",
    "existing_po_voided_error_selection_po":"PO",
    'recover_password_email_address_sub':"(E-mail en tant qu'ID utilisateur)",
    'recover_password_vendorId_sub':'(i.e. 123456UA ou 987654CC)',
    'recover_password_choice_sub':'Quelle méthode utilisez-vous pour vous connecter à Holman PartnerConnect?',
    'recover_password_footer_sub':"Veuillez noter: l'adresse e-mail que vous entrez DOIT correspondre à ce qui est dans le dossier avec Holman.",
    "dot_inspection_form__needs_repair":"a besoin <br></br> réparation",
    'dot_inspection_form__repaired_date': 'date <br></br> réparation',
    "closed_po_driver_reference_number_title": "<br></br> ta référence#",
    'coupon_header_services':'Calendrier des coupons',
    'pm_sheet_header':'Programme',
    'complaint_notes_readonly_your_note':'Votre note: ',
    'complaint_notes_readonly_ari_note':'Holman: ',
    'repair_notes_your_note':'Votre note: ',
    'repair_notes_client_note':'Client: ',
    "repair_start_date":"Date de début de réparation",
    'action_required_line_items_title':'Demande de VIN pour un bon de commande:',
    "contact_customer_directly": "Ce bon de commande est destiné à un client Holman Small Business Solutions. Pour cette transaction, vous traiterez directement avec le client plutôt qu'avec Holman. Si vous avez des questions sur ce bon de commande, veuillez appeler le client directement au numéro fourni une fois le bon de commande créé.",
    'find_part_for_unmapped_prod_code' : "Ce code produit est incorrect ou nécessite plus de détails. Si cela est correct, veuillez rechercher en utilisant la recherche de pièces Holman ci-dessus.",
    'search_product_code_caption': "Rechercher et sélectionner le code produit",
    'search_product_code_placeholder': "Recherche par code produit ou description",
    'product_code_search_continue': "continuer",
    'product_code_search_none_found': "Aucun résultat trouvé",
    
    'add_services_using_product_code_caption_note': "Veuillez ajouter la (les) ligne (s) suivante (s) au bon de commande en utilisant les codes produit appropriés.",

	'contract_pricing_cannot_be_edited_displayed_message': "Ce code produit a une tarification contractuelle. Le prix ne peut pas être modifié ou affiché.",
    'contract_pricing_cannot_be_edited_message' : "Ce code produit a une tarification contractuelle. Le prix ne peut pas être modifié.",
    
    'repair_labor_qty': "Qty",
    'repair_labor_cost': "Lab Cost",

    'adjust_labor_cost': "Lab Cost",
    'adjust_labor_qty': "Qty",
    
    'replace_labor_cost': "Lab Cost",
    'replace_labor_qty': "Qty",

    'price_exceeds_error_message': "Le champ en surbrillance ci-dessous a un coût trop élevé. Veuillez vérifier la pièce sélectionnée et le type de correction, modifiez le coût, puis cliquez sur confirmer. Si le coût est correct, veuillez cliquer sur confirmer. Le bouton 'Ajouter un élément de campagne' sera réactivé une fois que vous aurez confirmé votre coût.",
    'price_exceeds_continue_error_message':"Le champ en surbrillance ci-dessous a un coût trop élevé. Veuillez vérifier la pièce sélectionnée et le type de correction, modifiez le coût, puis cliquez sur confirmer. Si le coût est correct, veuillez cliquer sur confirmer. Le bouton 'Continuer' sera réactivé une fois que vous aurez confirmé votre coût.",
    'service_exceeds_error_message': 'Le ou les champs en surbrillance ci-dessous ont un coût trop élevé. Veuillez modifier le coût, puis cliquez sur confirmer. Si le coût est correct, veuillez cliquer sur confirmer. Les boutons de sauvegarde seront réactivés une fois que vous aurez confirmé votre coût.',
    'services_save_disabled_exceeds_tooltip_message': 'Les services dus ne peuvent pas être ajoutés tant que vous navez pas confirmé vos prix sur les lignes en surbrillance.',
    'save_disabled_exceeds_tooltip_message': 'La ligne ne peut pas être ajoutée tant que vous navez pas confirmé vos prix sur les lignes en surbrillance.',
    'save_disabled_exceeds_continue_tooltip_message': "Le bouton Continuer sera réactivé une fois que vous aurez confirmé les prix sur les lignes en surbrillance.",
    'validation_link_message': "Confirmer le {description} coût",
    'price_exceeds_confirmation': "Confirmer",

    'add_product_code_instruction': "Ces lignes ont été ajoutées par un technicien Holman, mais n'ont pas de codes produit. Veuillez suivre les étapes pour ajouter un code produit.",
    'missing_product_code_icon_title': 'Cliquez pour ajouter le code produit manquant',
    
    'delete_associated_line_confirmation_message': "Toutes les lignes de pièces ou de main-d'œuvre associées seront également supprimées. Êtes-vous sûr de vouloir supprimer cette ligne?",
    'missing_product_code_step_continue': "Continuer",
    'missing_product_code_step_return': "Supprimer la ligne",
    'missing_product_code_information_1': "Note d'Holman: L'élément de campagne pour <line_description> </line_description> a été ajouté sans code produit. Veuillez ajouter le bon code produit et le prix, le cas échéant.",
    'missing_product_code_information_2': "Cliquez sur Continuer pour fournir les informations de code produit manquantes.",
    'missing_product_code_information_3': "Cliquez sur Supprimer la ligne pour supprimer cet élément de ligne du bon de commande.",
    'missing_product_code':' pour <line_description></line_description>',
    'adjusted_line_window_tooltip':'Ce code produit a une tarification contractuelle. Le prix ne peut pas être modifié ou affiché.',
    'missing_product_code_disabled_reason':'Il manque des lignes de codes produit. Veuillez ajouter les codes produit et les prix corrects, le cas échéant.',
    'advanced_search_link':"RECHERCHE DE COMMANDES D'ACHAT",
    'advanced_search_link_2':'Ordres de Recherche',
    'adv_search_ata':'Rechercher des pièces',

    'reject_system_adjustment': "Négociation initiale rejetée par le fournisseur. Veuillez revoir le prix des pièces et le(s) temps(s) de main-d'œuvre en conséquence.",

    //Fleet Admin
    'fleet_admin_fallback_ari_contact_info': "Si vous n'acceptez pas ces modifications, vous pouvez transmettre ce bon de commande pour un examen plus approfondi \nou appeler un technicien Holman.",
    'fleet_admin_fallback_contact_info': "Si vous n'acceptez pas ces modifications, vous pouvez transmettre ce bon de commande pour un examen plus approfondi \nou appeler un technicien Holman.",
    'fleet_admin_contact_info' : "Si vous n'acceptez pas ces modifications, vous pouvez transmettre ce bon de commande pour un examen plus approfondi \nou appeler {fleetAdminName} à {fleetAdminPhone}.",
    'fleet_admin_contact_info_issue_note': "Si vous avez des questions concernant cette demande de client/notes, vous pouvez transmettre ce bon de commande pour un examen plus approfondi \nou appeler {fleetAdminName} à {fleetAdminPhone}.",

    'dealer_draft_reg_no_selection_label':'Aucune de ces réponses',
    'dealer_draft_reg_label':"Information d'inscription",
    'dealer_draft_temp_license_label':"# de plaque d'immatriculation temporaire",
    'dealer_draft_temp_exp_label':"Expiration de l'enregistrement temporaire",
    'dealer_draft_temp_state_label':"État d'enregistrement temporaire",
    'dealer_draft_initial_license_label':'Licence initiale délivrée',
    'dealer_draft_temp_label':'Tag temporaire émis',
    'dealer_draft_reg_details_label':"Détails d'inscription",
    'dealer_draft_reg_details_error_label':"Veuillez sélectionner l'un des champs ci-dessous",
    'dealer_draft_reg_details_select_label':'Sélectionnez-en un',
    'dealer_draft_no_selection_error_label':"Nos dossiers indiquent que votre concession doit terminer l'obtention de la licence initiale. Avant de continuer, veuillez nous contacter à delivery@holman.com ou au 800-257-7781 pour obtenir de l'aide.",
    'dealer_draft_delivery_date_error_label':'Veuillez sélectionner une date',

    //Aged POs
    'no_aged_pos_dialog':'Aucun pour le moment',
    'aged_po_title':'Votre attention nécessaire',
    'aged_po_messaging':'Votre boutique a des bons de commande ouverts datant de plus de 30 jours. Veuillez remplir et fermer ces bons de commande dès que possible. Holman ne traitera ni ne paiera les bons de commande datant de plus de 120 jours.',
    'shopview_link':'Aller à la vue de la boutique',
    'created_date_column':"Date de création",
    'vin_column':'VIN',
    'po_column':'PO no.',
    'ref_column':'Votre référence #',
    'amount_column':'Montante',
    'edit_link':'ÉDITER',
    'close_link':'FERMER',
    'aged_po_date':'quelques jours',
    'aged_po_link':'Afficher anciennes commandes',
    'aged_po_link2':'Afficher anciennes commandes',
    'holman_vendor':'HOLMAN VENDOR',

    'repair_type_change_step_continue':'Continuer',
    'repair_type_change_step_delete_line':'Supprimer la ligne',
    'repair_type_change_step_header':'Confirmer les détails du travail pour cette ligne',
    'repair_type_change_step_info_1':"Remarque de Holman : l'élément de ligne pour <line_description></line_description> a été ajouté par un technicien Holman, mais l'estimation doit être mise à jour.",
    'repair_type_change_step_info_2':"Cliquez sur Continuer pour mettre à jour l'estimation.",
    'repair_type_change_step_info_3':'Cliquez sur Supprimer la ligne pour supprimer cet article du bon de commande',

    'ie_warning_title':"Avertissement de sécurité du navigateur",
    'ie_warning_message_line1':"À compter du 31 octobre 2023, Holman PartnerConnect ne sera plus compatible avec Microsoft Internet Explorer (IE) car il n'est plus pris en charge par Microsoft. Cela signifie qu'IE ne reçoit plus de mises à jour de sécurité, ce qui rend son utilisation dangereuse pour Holman, nos clients ou nos partenaires.",
    'ie_warning_message_line2':"Si vous souhaitez en savoir plus, veuillez consulter {articleLink} de Microsoft: ",
    'ie_warning_message_line3':"Questions courantes:",
    'ie_warning_message_question1':"Que devrais-je faire ensuite?",
    'ie_warning_message_question2':"Que se passe-t-il si je ne connais pas mon nom d'utilisateur et mon mot de passe parce qu'ils sont enregistrés dans mon navigateur ?",
    'ie_warning_message_question3':"Que faire si je ne peux pas télécharger un nouveau navigateur ?",
    'ie_warning_message_answer1':"Vous devez télécharger un navigateur moderne tel que Microsoft Edge, Google Chrome ou Mozilla Firefox.",
    'ie_warning_message_answer2':"Téléchargez d'abord un nouveau navigateur.",
    'ie_warning_message_answer3':"Ensuite, vous pouvez {resetpasswordLink}.",
    'ie_warning_message_answer4':"Contactez votre équipe informatique.",
    'ie_warning_message_answer5':"Si vous n'avez pas d'informaticien dans votre entreprise, vous pouvez contacter Holman IT à {mailAddress}.",
    'ie_warning_article_text':"cet article",
    'ie_warning_reset_password_text':"réinitialisez votre mot de passe",
    'ie_warning_learn_more_button':"Apprendre encore plus",
    'ie_warning_signout_button':"Déconnectez-vous et utilisez un autre navigateur",

    //Labor rate update
    'labor_rate_update_title':"Mise à jour du taux de main-d'œuvre",
    'labor_rate_update_question_line1':"Vous avez modifié le taux de main-d'œuvre de ${currentRate} à ${newRate}. Est-ce votre nouveau taux de main-d'œuvre standard ?",
    'labor_rate_update_question_line2':"Est-ce votre nouveau taux de main-d'œuvre standard ?",
    'labor_rate_update_yes':"Oui, demander un nouveau tarif",
    'labor_rate_update_no':"Non",
    'labor_rate_update_error':"Veuillez sélectionner une option",

    'tire_width_required':"Veuillez entrer la largeur du pneu",
    'aspect_ratio_required':"Veuillez saisir le rapport hauteur/largeur",
    'rim_diameter_required':"Veuillez entrer le diamètre de la jante",
    'tire_manufacturer_required':"Veuillez sélectionner le fabricant de pneus",
    'tire_model_required':"Veuillez entrer le modèle de pneu",
    'tire_reason_required':"Veuillez sélectionner une raison pour le remplacement",
    'old_tire_tread_depth_required':"Veuillez saisir la profondeur de sculpture de l'ancien pneu pour le pneu remplacé.",
    'qty_must_match_with_tire_selection_counter_custom_message':"Le nombre de pneus sélectionnés pour le remplacement ne correspond pas à la quantité définie dans l'élément de campagne. Veuillez sélectionner ",
    'qty_must_match_end_message':" pneu(s).",
    'qty_must_match_with_tire_selection_counter':"Vous devez CLIQUER SUR LE(S) PNEU(S) du schéma qui correspond à la position que vous remplacez. Le pneu deviendra bleu une fois que vous aurez cliqué dessus. Appuyez sur RETOUR pour corriger votre entrée.",
    
    'wheel_required':"Requis",
    "account_submission_success": "Votre mise à jour bancaire a été soumise pour traitement. Veuillez prévoir un jour ouvrable complet pour finaliser le changement bancaire. N'oubliez pas : pour garantir le paiement des services, vérifiez toujours votre compte de paiement lors de la soumission de la facturation en ligne.",
    'account_submission_success_new_text': "Les récentes modifications apportées aux informations de votre compte bancaire ont suspendu temporairement tous les paiements. Veuillez être prêt à recevoir un appel téléphonique de Holman pour vérifier ces changements afin de permettre la reprise des paiements.",

  //Accident Management
  'images_and_estimates_requested': 'Images et estimations demandées',
  'repair_approved_eta_pending': 'Réparation approuvée, réparation ETA nécessaire',
  'repair_approved': 'Réparation approuvée',
  'payment_submitted': 'Paiement soumis',
  'pending_repair_approval': "En attente d'approbation pour {submission}",
  'original_estimate': 'estimation originale',
  'supplement': 'supplément {submissionNumber}',
  'show_all_waiting_claim': 'Afficher toutes les revendications {claimCount}',
  'hide_older_claim': 'Masquer les anciennes revendications',
  'accident_assignments_your_action_title': 'Votre action requise',
  'accident_assignments_approved_title': 'Approuvée',
  'accident_assignments_pending_approval_title': 'En attente de validation',
  'accident_assignments_payment_in_progress_title': 'Paiement en cours',
  'accident_assignments_vehicle_header': 'Véhicule',
  'accident_assignments_vin_header': 'Vin',
  'accident_assignments_claim_header': 'Réclamer #',
  'accident_assignments_status_header': 'Statut',
  'accident_assignments_view_caption': 'VOIR',
  'accident_assignments_no_claim': 'Aucun à ce moment',
  'repair_estimate_link': "Afficher tous les bons de commande d'accident ouverts",
  "accident_assignments_eta_title": "Mettre à jour l'ETA de réparation",
  "accident_assignments_body_1": "Entrez la date d'achèvement estimée pour toutes les réparations",
  "update_eta_link": "Mettre à jour l'ETA de réparation",
  "accident_assignments_eta_note_body": "Veuillez saisir la raison du changement d'ETA de réparation.",
  "accident_assignments_eta_date_error": "Veuillez saisir une date d'achèvement estimée valide",
  "accident_assignments_eta_note_error": "Veuillez saisir la raison du changement d'ETA de réparation.",
  "accident_assignments_submission_continue_validate": "Veuillez télécharger {fileType} avant de continuer",
  "accident_assignments_submission_submit_validate": "Veuillez saisir les prix avant de soumettre pour approbation",
  "accident_assignments_submission_images_drag_drop": "Faites glisser et déposez vos images ici ou cliquez sur l'icône pour télécharger un ou plusieurs",
  "accident_assignments_submission_document_drag_drop": "Faites glisser et déposez votre estimation de réparation ici ou cliquez sur l'icône pour en télécharger une",
  'submit_for_approval_link': 'Soumettre pour approbation',
  'submit_additional_images': 'Soumettre des images supplémentaires',
  'return_link': 'Retour',
  'browse_file': 'Parcourir le fichier',
  'files_uploaded': '{fileCount} fichier(s) téléchargé(s)',
  'accident_assignments_submission_rejected_image': "Certains fichiers n'ont pas pu être téléchargés en raison d'un type de fichier incorrect.Les types d'images acceptables incluent: jpg png jpeg jpe bmp gif jfif",
  'accident_assignments_submission_rejected_document': "Certains fichiers n'ont pas pu être téléchargés en raison d'un type de fichier incorrect.Les types de documents acceptables incluent: PDF",
  'accident_assignments_submission_rejected_max_files': "L'importation dépasse le nombre maximal de fichiers : {fileCount} fichier(s)",
  "accident_assignments_submission_images_title": "Importer des images",
  "accident_assignments_submission_estimate_title": "Télécharger la copie d'estimation de la réparation",
  "accident_assignments_submission_pricing_title": "Prix de réparation",
  "accident_assignments_submission_additional_images_title": "Télécharger des images supplémentaires",
  "accident_assignments_submission_images_body": "Soumettez des images des quatre coins du véhicule, de l'odomètre, du numéro de plaque, du vin et de toutes les zones de dommages.Ces images seront utilisées par l'équipe ACCMAN pour examiner l'estimation de réparation et seront accessibles au client et à leur compagnie d'assurance.",
  "accident_assignments_submission_estimate_body": "Pour accélérer l'examen de cette affectation d'accident, assurez-vous de télécharger l'estimation des images et de réparer, saisissez vos prix.Les trois éléments sont nécessaires pour soumettre votre estimation de réparation à l'équipe de gestion des accidents de Holman.\ n \ n Vous pouvez ajouter des images supplémentaires ici tout au long du processus de réparation si nécessaire.",
  "accident_assignments_submission_pricing_body": "Enfin, entrez le coût de la main-d'œuvre et des pièces dans les lignes ci-dessous.",
  "accident_assignments_submission_additional_images": "Cliquez ci-dessous pour sélectionner des images supplémentaires à ajouter à l'estimation originale.",
  "accident_assignments_pricing_description": "Description",
  "accident_assignments_pricing_cost": "Coût",
  "accident_assignments_pricing_subtotal": "TOTAL",
  "accident_assignments_view__claim_header": "Réclamez {claimId}",
  "accident_assignments_history_details": "Afficher les détails de l'histoire",
  "accident_assignments_decline_assignment": "Refuser une affectation d'accident",
  "accident_assignments_initial_submission_header": "Soumettre les images initiales, estimer et les prix",
  "accident_assignments_initial_submission_subheader_1": "Pour accélérer l'examen de cette affectation d'accident, assurez-vous de télécharger des images, de copier de l'estimation de réparation et de saisir vos prix.Les trois éléments sont nécessaires pour soumettre votre estimation de réparation à l'équipe de gestion des accidents de Holman.",
  "accident_assignments_initial_submission_subheader_2": "Une fois l'estimation de réparation initiale approuvée, vous pouvez télécharger des photos supplémentaires ici.",
  "accident_assignments_submit_initial_estimate": "Soumettre l'estimation initiale",
  "accident_assignments_additional_images_header": "Soumettre des images supplémentaires",
  "accident_assignments_submit_additional_images": "Soumettre des images",
  "accident_assignments_additional_images_subheader": "Télécharger des images ici",
  "accident_assignments_history_header": "Histoire",
  "accident_assignments_eta_header": "Mettre à jour l'ETA de réparation",
  "accident_assignments_eta_subheader": "Veuillez saisir la date d'achèvement estimée pour ces réparations.",
  "accident_assignments_supplement_header": "Soumettre une estimation de réparation supplémentaire",
  "accident_assignments_supplement_subheader": "Téléchargez votre estimation de réparation supplémentaire ici",
  "accident_assignments_supplement_button": "Soumettre une réparation supplémentaire",
  "accident_assignments_history_subheader": "Estimations / suppléments",
  "accident_assignments_driver_contact_title": "Contact avec le conducteur",
  "accident_assignments_driver_contact_caption": "contact",
  "accident_assignments_driver_phone_caption": "Téléphone",
  "accident_assignments_driver_email_caption": "E-mail",
  "accident_assignments_pending_estimate_title": "Images en attente et estimation",
  "asignacionesDeAccidentesPendientesTítuloDeAprobación": "En attente de l'approbation de Holman pour l'estimation de {submissionNumber}",
  "accident_assignments_pending_eta_title": "Réparation approuvée - Veuillez fournir l'ETA de réparation",
  "accident_assignments_approved_title": "Réparation approuvée",
  "accident_assignments_payment_in_progress_title": "Paiement en cours",
  "accident_assignments_payment_submitted_title": "Paiement soumis",
  "accident_assignments_work_completed_title": "Travaux achevés",
  "accident_assignments_vehicle_vin_caption": "Vin",
  "accident_assignments_vehicle_client_caption": "Cliente",
  "accident_assignments_vehicle_unit_number_caption": "Unité Holman no.",
  "accident_assignments_vehicle_engine_caption": "Type de moteur",
  "accident_assignments_vehicle_vehicle_caption": "Véhicule",
  "accident_assignments_vehicle_plate_caption": "Plaque",
  "accident_assignments_vehicle_color_caption": "Couleur",
  "accident_assignments_vehicle_mileage_caption": "Kilométrage",
  "accident_assignments_vehicle_other_caption": "Autre",
  'vehicle_documents': 'Documents de véhicule',
  'repair_history_of_vehicle': 'Réparation des antécédents du véhicule',
  "accident_assignments_unavailable_caption": "Indisponible",
  "accident_assignments_image_upload_caption": "Mis en ligne le {uploadDate}",
  "accident_assignments_image_title": "Images",
  "accident_assignments_images_submission": "Collecté pour {submissionNumber}",
  "accident_assignments_pricing_header": "Prix",
  "accident_assignments_pricing_description": "Description",
  "accident_assignments_pricing_qty": "Qté",
  "accident_assignments_pricing_cost": "Coût",
  "accident_assignments_pricing_subtotal": "TOTAL",
  "accident_assignments_pricing_damage_parts": "PIÈCES DE DÉGÂTS DE VÉHICULE",
  "accident_assignments_pricing_damage_labor": "DOMMAGE DES VÉHICULES LABOUR",
  "accident_assignments_pricing_paint_parts": "PEINTURE FOURNIT DES PIÈCES",
  "accident_assignments_pricing_paint_labor": "PEINTER LE TRAVAIL",
  "accident_assignments_pricing_mechanical": "TRAVAIL MÉCANIQUE",
  "accident_assignments_pricing_other": "AUTRE",
  "accident_assignments_close_header": "Fermer le bon de commande",
  "accident_assignments_close_subheader": "Une fois toutes les réparations terminées, y compris les réparations supplémentaires, vous pouvez clôturer la réparation",
  "accident_assignments_eta_note_length_error": "Veuillez saisir une raison entre 3 et 900 caractères",
  "claim_no_label": "Réclamation d'accident",
  'search_for_claim_no': "Rechercher une estimation d'accident par numéro de sinistre...",
  'search_claim_error': 'Numéro de réclamation non valide #{claimId}. Veuillez reconfirmer et réessayer.',
  "accident_assignments_submission_supplement_title": "Télécharger une copie supplémentaire",
  "accident_assignments_submission_supplement_pricing_body": "Soumettez les prix supplémentaires dans la ligne ci-dessous",
  "accident_assignments_decline_claim_confirmation_title": "Refuser l'affectation",
  'accident_assignments_decline_claim_confirmation_message': "Vous êtes sur le point de refuser cette mission de réparation après accident et de la supprimer de Holman PartnerConnect. Êtes-vous sûr de vouloir refuser cette mission?",
  "accident_assignments_declined_title": "Affectation refusée",
  'accident_assignments_close_po_disabled_message': "Veuillez mettre à jour l'ETA de réparation avant de fermer le bon de commande",
  "accident_assignments_locked_po_message": "Le bon de commande est actuellement en cours d'examen par un technicien Holman.",
  "accident_assignments_locked_po_2_message":"Le bon de commande est actuellement en cours d'examen par un autre technicien Holman.",
  "accident_po_link": "Afficher les ordonnances d'accident",
}
