import React from 'react';
import { defineMessages, FormattedMessage, FormattedNumber } from 'react-intl';
import { LINE_ITEM_TYPE } from 'constants/App';
import './LineItem.css';
import { IntlProvider } from 'react-intl';
import { useAppState } from 'shared/AppState/app-state';

const LineItem = (props) => {

    const [appState, dispatch] = useAppState();

    const messages = defineMessages({
        po_actions_line_item_view_quantity: {
            id: 'po_actions_line_item_view_quantity',
            description: 'Qty',
            defaultMessage: 'Qty'
        },
        po_actions_line_item_view_hour: {
            id: 'po_actions_line_item_view_hour',
            description: 'Hour',
            defaultMessage: 'Hour'
        },
        po_actions_line_item_view_cost: {
            id: 'po_actions_line_item_view_cost',
            description: 'Cost',
            defaultMessage: 'Cost'
        },
        po_actions_line_item_view_amount: {
            id: 'po_actions_line_item_view_amount',
            description: 'Amount',
            defaultMessage: 'Amount'
        }
    });

    return <div className='adjust_po_line_item table-responsive'>
        <table
            className='adjust_po_line_item__table'>
            <thead>
                <tr className="row">
                    <th className="col-md-6 adjust_po_line_item__header">

                    </th>
                    <th className="col-md-1 adjust_po_line_item__header  adjust_po_line_item__currency">

                        {
                            props.hideCostInformation == true ? false :
                                props.complaintCode != 'DG99'
                                    ? <FormattedMessage {...messages.po_actions_line_item_view_quantity} />
                                    : <FormattedMessage {...messages.po_actions_line_item_view_hour} />
                        }
                    </th>
                    <th className="col-md-2 adjust_po_line_item__header  adjust_po_line_item__currency">
                        {
                            props.hideCostInformation == true ? false :
                                <FormattedMessage {...messages.po_actions_line_item_view_cost} />
                        }
                    </th>
                    <th className="col-md-3 adjust_po_line_item__header adjust_po_line_item__currency">
                        {
                            props.hideCostInformation == true ? false :
                                <FormattedMessage {...messages.po_actions_line_item_view_amount} />
                        }
                    </th>
                </tr>
            </thead>
            <tbody>
                {
                    props.lineItems.valueSeq().map((lineItem) =>
                        <tr className="row" key={lineItem.get('lineItemId')} css={`margin-left: 0;`}>
                            <td className="col-md-6 adjust_po_line_item__item">
                                {
                                    appState.isProductCodeFeatureEnabled && lineItem.get('vendorProductCode') !== undefined && lineItem.get('vendorProductCode') !== null ? lineItem.get('vendorProductCode') + ' - ' + lineItem.get('productDescription') + ', ' + (lineItem.get('lineItemType') == LINE_ITEM_TYPE.PM ? LINE_ITEM_TYPE.PM_TEXT : lineItem.get('lineItemType').toUpperCase())
                                        : lineItem.get('productDescription') + ', ' + (lineItem.get('lineItemType') == LINE_ITEM_TYPE.PM ? LINE_ITEM_TYPE.PM_TEXT : lineItem.get('lineItemType').toUpperCase())
                                }
                            </td>

                            <td className="col-md-1 adjust_po_line_item__item  adjust_po_line_item__currency">
                                {
                                    props.hideCostInformation == true ? false :
                                        lineItem.get('quantity')
                                }
                            </td>
                            <td className="col-md-2 adjust_po_line_item__item  adjust_po_line_item__currency">
                                {
                                      appState.isHideClientPricingFeatureEnabled && lineItem.get('pricingSource') == "client_contract_pricing" ?
                                     <div>N/A</div> :
                                        <IntlProvider locale="en">
                                            <FormattedNumber value={lineItem.get('unitPrice')} style="currency" currency={props.selectedCurrency} />
                                        </IntlProvider>
                                }
                            </td>
                            <td className="col-md-3 adjust_po_line_item__item adjust_po_line_item__currency">
                                {
                                     appState.isHideClientPricingFeatureEnabled && lineItem.get('pricingSource') == "client_contract_pricing" ?
                                    <div>N/A</div> :
                                        <IntlProvider locale="en">
                                            <FormattedNumber value={lineItem.get('totalAmount')} style="currency" currency={props.selectedCurrency} />
                                        </IntlProvider>
                                }
                            </td>

                        </tr>
                    )
                }
            </tbody>
        </table>
    </div>
}

export default LineItem;