var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
//#region Imports
import React, { useState } from "react";
import { CloseButton, Col, Container, Modal, OverlayTrigger, ProgressBar, Row, Spinner, Tooltip } from "react-bootstrap";
import { FormattedMessage, defineMessages, useIntl } from "react-intl";
import styled from 'styled-components';
import 'AccidentAssignmentsSubmissionModal.css';
import { ModalClose, ModalMainHeader, ModalBody, ButtonStyled } from "shared/DesignSpecs/components/FormComponents";
import { useDropzone } from "react-dropzone";
import { uploadRepairEstimateDocument, uploadRepairEstimateImage } from "shared/utils/Api";
import { useAppState } from "shared/AppState/app-state";
import { GetRepairEstimateImagesDocument, useCreateEstimateLinesMutation, useDeleteRepairEstimateImageMutation, useGetRepairEstimateImagesQuery, useRequestApprovalForRepairEstimateSubmissionMutation, useSubmitAdditionalImagesMutation } from "shared/types/__generated__/graphql-type";
import FormWrapper from "shared/components/FormWrapper";
import SpinnerComponent from "shared/components/Spinner";
import DynamicNumber from 'react-dynamic-number';
import { AccidentAssignmentAtaCodes } from "AccidentManagement/common/AccidentAssignmentAtaCodes";
import * as Bluebird from "bluebird";
import configuration from "shared/configuration";
//#endregion
// #region Styled Components
const ModalStyled = styled(Modal).attrs({
    dialogClassName: "childStyle"
}) `
.childStyle {
  width: 900px;
  max-width: 900px;
  min-height: 600px;
}`;
//#endregion
//#region Component
const AccidentAssignmentsSubmissionModal = ({ showModal, toggleShowModal, submissionCount, repairEstimateId, viewRefetch, isSupplementSubmission = false }) => {
    // #region State
    const [isLoading, setIsLoading] = useState(false);
    const [isCleaningUp, setIsCleaningUp] = useState(false);
    const [dropZoneErrors, setDropZoneErrors] = useState(null);
    const [dropZoneFiles, setDropZoneFiles] = useState([]);
    const [currentImages, setCurrentImages] = useState([]);
    const [queryErrors, setQueryErrors] = useState(null);
    const [uploadErrors, setUploadErrors] = useState([]);
    const [wizardStep, setWizardStep] = useState(1);
    const [deleteImagesQueue, setDeleteImagesQueue] = useState([]);
    const [repairEstimateDocument, setRepairEstimateDocument] = useState();
    const [repairEstimatePricing, setRepairEstimatePricing] = useState(initalizePricingList);
    const [uploadImagesProgress, setUploadImagesProgress] = useState(0);
    // #endregion
    // #region Ref
    // #endregion
    const getAcceptedTypes = () => {
        if (wizardStep == 1) {
            return { "image/*": [".JPG", ".JPEG", ".JPE", ".BMP", ".GIF", ".PNG", ".JFIF"] };
        }
        else if (wizardStep == 2) {
            return {
                "application/pdf": [".PDF"]
            };
        }
        else {
            return null;
        }
    };
    const getMaxFiles = () => {
        if (wizardStep == 1) {
            return 100;
        }
        else if (wizardStep == 2) {
            return 1;
        }
    };
    // #region Hooks
    const intl = useIntl();
    const [appState, dispatch] = useAppState();
    const { getRootProps, getInputProps, open } = useDropzone({
        noClick: true,
        accept: getAcceptedTypes(),
        onDropAccepted: acceptedFiles => {
            onDropAccepted(acceptedFiles);
        },
        onDropRejected(fileRejections, event) {
            onDropRejected(fileRejections);
        },
        onDrop(acceptedFiles, fileRejections, event) {
            setDropZoneErrors(null);
            setUploadErrors([]);
        },
        maxFiles: getMaxFiles(),
    });
    const { data: imagesData, loading: imagesLoading, error: imagesError, refetch: imagesRefresh } = useGetRepairEstimateImagesQuery({
        variables: {
            repairEstimateId: repairEstimateId,
            submissionNumber: submissionCount + 1
        },
    });
    const [deleteRepairEstimateImageMutation, { loading: deleteLoading, error: deleteError }] = useDeleteRepairEstimateImageMutation({
        refetchQueries: [
            GetRepairEstimateImagesDocument,
            'GetRepairEstimateImages'
        ]
    });
    const [submitAdditionalImagesMutation, { data: additionalImagesData, loading: additionalImagesLoading, error: additionalImagesError }] = useSubmitAdditionalImagesMutation({
        variables: {
            repairEstimateId: repairEstimateId,
            submissionNumber: submissionCount
        },
    });
    const [requestApprovalForRepairEstimateSubmissionMutation, { data: approvalRequestData, loading: approvalRequestLoading, error: approvalRequestError }] = useRequestApprovalForRepairEstimateSubmissionMutation({
        variables: {
            repairEstimateId: repairEstimateId,
            submissionNumber: submissionCount + 1
        },
    });
    const [createEstimateLinesMutation, { data, loading, error }] = useCreateEstimateLinesMutation();
    // #endregion
    // #region Effects
    // #endregion
    // #region Event handlers
    const onCloseModal = (cleanUp) => __awaiter(void 0, void 0, void 0, function* () {
        if (cleanUp && _images.length !== 0) {
            yield deleteCanceledUploadedImages();
            setRepairEstimatePricing(initalizePricingList);
        }
        clearErrors();
        setWizardStep(1);
        setCurrentImages([]);
        viewRefetch().finally(() => {
            toggleShowModal();
        });
    });
    const onClickDeleteImage = (repairEstimateId, imageId, fileName, e) => {
        //Disable delete button
        e.currentTarget.classList.add("disabled");
        //Add image to deletion queue
        setDeleteImagesQueue(newDeletedImagesQueue => [...deleteImagesQueue, fileName]);
        deleteRepairEstimateImageMutation({
            variables: {
                repairEstimateId: repairEstimateId,
                imageId: imageId
            }
        }).finally(() => {
            let target = e.target;
            //renable delete button
            target === null || target === void 0 ? void 0 : target.classList.remove("disabled");
            //remove image from deletion queue
            setDeleteImagesQueue(resolvedImagesQueue => resolvedImagesQueue.filter(i => { return i !== fileName; }));
            //remove image from any remaining state
            setCurrentImages(currentImages.filter(image => { return image.fileName !== fileName; }));
            setDropZoneFiles(dropZoneFiles.filter(f => { return f.name !== fileName; }));
        });
    };
    //Delete all currently uploaded images
    const deleteCanceledUploadedImages = () => __awaiter(void 0, void 0, void 0, function* () {
        setIsCleaningUp(true);
        yield Promise.all(_images.map(i => {
            return deleteRepairEstimateImageMutation({
                variables: {
                    repairEstimateId: repairEstimateId,
                    imageId: i.imageUrl
                }
            });
        })).finally(() => setIsCleaningUp(false));
    });
    const onDropAccepted = (acceptedFiles) => {
        const uploadImages = () => __awaiter(void 0, void 0, void 0, function* () {
            let uploadedFileCount = 0;
            let submissionNumber = submissionCount + 1;
            Bluebird.map(acceptedFiles, (file) => {
                return Promise.resolve(uploadRepairEstimateImage(file.name, submissionNumber, file, repairEstimateId, appState.token))
                    .then((id) => {
                    return id;
                }, (error) => {
                    setDropZoneFiles(dropZoneFiles => dropZoneFiles.filter(f => { return f.name !== file.name; }));
                    setUploadErrors(uploadErrors => [...uploadErrors, { message: error.Message ? error.Message : error.message }]);
                })
                    .finally(() => {
                    uploadedFileCount++;
                    let progress = Math.floor(uploadedFileCount / acceptedFiles.length * 100);
                    setUploadImagesProgress(progress);
                });
            }, { concurrency: configuration.accmanConcurentImageUpload }).finally(() => {
                setIsLoading(false);
                imagesRefresh().then((result) => {
                    setCurrentImages(currentImages.concat(result.data.repairEstimateImages.filter(image => {
                        return acceptedFiles.some(f => {
                            return f.name === image.fileName;
                        });
                    })));
                });
            });
        });
        setDropZoneFiles(acceptedFiles);
        if (wizardStep === 1) {
            setUploadImagesProgress(0);
            setIsLoading(true);
            uploadImages();
        }
        else
            setRepairEstimateDocument(acceptedFiles[0]);
    };
    const onDropRejected = (fileRejections) => {
        if (fileRejections.some(r => r.errors.some(e => e.code == "too-many-files"))) {
            setDropZoneErrors([{ message: intl.formatMessage(messages.accident_assignments_submission_rejected_max_files, { fileCount: getMaxFiles().toString() }) }]);
        }
        else {
            let errorMessage = wizardStep === 1 ? { message: intl.formatMessage(messages.accident_assignments_submission_rejected_image) } : { message: intl.formatMessage(messages.accident_assignments_submission_rejected_document) };
            setDropZoneErrors([errorMessage]);
        }
    };
    const onContinueClicked = () => {
        const messages = defineMessages({
            accident_assignments_submission_continue_validate: {
                id: "accident_assignments_submission_continue_validate",
                description: "Please upload {fileType} before continuing",
                defaultMessage: "Please upload {fileType} before continuing"
            },
            accident_assignments_submission_submit_validate: {
                id: "accident_assignments_submission_submit_validate",
                description: "Please enter pricing before submitting for approval",
                defaultMessage: "Please enter pricing before submitting for approval"
            },
            accident_assignments_submission_supplement_doc_validate: {
                id: "accident_assignments_submission_supplement_doc_validate",
                description: "Please upload supplemental repair estimate PDF before continuing",
                defaultMessage: "Please upload supplemental repair estimate PDF before continuing"
            },
            accident_assignments_locked_po_2_message: {
                id: "accident_assignments_locked_po_message",
                description: "PO is currently being reviewed by another Holman Technician.",
                defaultMessage: "PO is currently being reviewed by another Holman Technician."
            },
            accident_assignments_locked_po_message: {
                id: "accident_assignments_locked_po_message",
                description: "PO is currently being reviewed by a Holman Technician.",
                defaultMessage: "PO is currently being reviewed by a Holman Technician."
            },
        });
        const uploadDocument = (document) => __awaiter(void 0, void 0, void 0, function* () {
            let submissionNumber = submissionCount + 1;
            let uploadResponse = Promise.resolve(uploadRepairEstimateDocument(document.name, submissionNumber, document, repairEstimateId, appState.token)).then(() => {
                return true;
            }, (error) => {
                setUploadErrors(uploadErrors => [...uploadErrors, { message: error.Message ? error.Message : error.message }]);
            });
        });
        clearErrors();
        switch (wizardStep) {
            case 1:
                if (_images.length === 0) {
                    setDropZoneErrors([{ message: intl.formatMessage(messages.accident_assignments_submission_continue_validate, { fileType: "images" }) }]);
                }
                else {
                    setDropZoneFiles([]);
                    setWizardStep(2);
                }
                break;
            case 2:
                if (repairEstimateDocument === undefined) {
                    if (isSupplementSubmission) {
                        setDropZoneErrors([{ message: intl.formatMessage(messages.accident_assignments_submission_supplement_doc_validate) }]);
                    }
                    else {
                        setDropZoneErrors([{ message: intl.formatMessage(messages.accident_assignments_submission_continue_validate, { fileType: "document" }) }]);
                    }
                }
                else {
                    setWizardStep(3);
                }
                break;
            case 3:
                if (repairEstimatePricing.every(p => p.unitPrice === null || Number.parseFloat(p.unitPrice) == 0)) {
                    setDropZoneErrors([{ message: intl.formatMessage(messages.accident_assignments_submission_submit_validate) }]);
                }
                else {
                    setIsCleaningUp(true);
                    clearErrors();
                    uploadDocument(repairEstimateDocument).then(() => {
                        createEstimateLinesMutation({
                            variables: {
                                repairEstimateId: repairEstimateId,
                                lines: repairEstimatePricing.reduce((result, p) => {
                                    if (p.unitPrice != null && Number.parseFloat(p.unitPrice) != 0) {
                                        let type;
                                        let formattedCost = p.unitPrice.replace(',', '');
                                        switch (p.ataCode) {
                                            case AccidentAssignmentAtaCodes.DamageParts:
                                                type = 'P';
                                                break;
                                            case AccidentAssignmentAtaCodes.DamageLabor:
                                                type = 'L';
                                                break;
                                            case AccidentAssignmentAtaCodes.PaintParts:
                                                type = 'P';
                                                break;
                                            case AccidentAssignmentAtaCodes.PaintLabor:
                                                type = 'L';
                                                break;
                                            case AccidentAssignmentAtaCodes.MechLabor:
                                                type = 'L';
                                                break;
                                            case AccidentAssignmentAtaCodes.Other:
                                                type = 'O';
                                                break;
                                        }
                                        let lineItemInput = {
                                            productCode: p.ataCode,
                                            ataDescription: p.description,
                                            cost: Number.parseFloat(formattedCost),
                                            quantity: 1,
                                            extendedEstimate: Number.parseFloat(formattedCost),
                                            repairType: type
                                        };
                                        result.push(lineItemInput);
                                    }
                                    return result;
                                }, [])
                            }
                        }).then(() => {
                            requestApprovalForRepairEstimateSubmissionMutation().then(() => {
                                onCloseModal(false);
                            }, (error) => {
                                setUploadErrors(uploadErrors => [...uploadErrors, { message: error.message }]);
                            }).finally(() => {
                                setIsCleaningUp(false);
                            });
                        }, (error) => {
                            var _a;
                            if (error.networkError.name == "ServerError") {
                                let serverError = error.networkError;
                                let serverErrorRecord = serverError.result;
                                let serverErrorMessage = (_a = serverErrorRecord.errors[0]) === null || _a === void 0 ? void 0 : _a.message;
                                if (serverErrorMessage == messages.accident_assignments_locked_po_message.defaultMessage || serverErrorMessage == messages.accident_assignments_locked_po_2_message.defaultMessage)
                                    setUploadErrors(uploadErrors => [...uploadErrors, { message: intl.formatMessage(messages.accident_assignments_locked_po_message) }]);
                                else
                                    setUploadErrors(uploadErrors => [...uploadErrors, { message: error.message }]);
                            }
                            else
                                setUploadErrors(uploadErrors => [...uploadErrors, { message: error.message }]);
                            setIsCleaningUp(false);
                        });
                    }, (error) => {
                        setUploadErrors(uploadErrors => [...uploadErrors, { message: error.Message ? error.Message : error.message }]);
                        setIsCleaningUp(false);
                    });
                }
                break;
        }
    };
    const onBackClicked = () => {
        clearErrors();
        switch (wizardStep) {
            case 2:
                setDropZoneFiles([]);
                setWizardStep(1);
                break;
            case 3:
                setWizardStep(2);
                break;
        }
    };
    const clearErrors = () => {
        setDropZoneErrors(null);
        setQueryErrors(null);
        setUploadErrors([]);
        setDropZoneFiles([]);
        setDeleteImagesQueue([]);
    };
    function initalizePricingList() {
        let result = [
            { ataCode: AccidentAssignmentAtaCodes.DamageParts, description: "Vehicle Damage Parts", unitPrice: null },
            { ataCode: AccidentAssignmentAtaCodes.DamageLabor, description: "Vehicle Damage Labor", unitPrice: null },
            { ataCode: AccidentAssignmentAtaCodes.PaintParts, description: "Paint Supplies Parts", unitPrice: null },
            { ataCode: AccidentAssignmentAtaCodes.PaintLabor, description: "Paint Labor", unitPrice: null },
            { ataCode: AccidentAssignmentAtaCodes.MechLabor, description: "Mechanical Labor", unitPrice: null },
            { ataCode: AccidentAssignmentAtaCodes.Other, description: "Other - Accident Repair", unitPrice: null },
        ];
        return result;
    }
    const onCostChange = (index, newValue) => {
        let newPricing = repairEstimatePricing.map((p, i) => {
            if (index === i) {
                return Object.assign(Object.assign({}, p), { unitPrice: newValue });
            }
            else {
                return p;
            }
        });
        setRepairEstimatePricing(newPricing);
    };
    // #endregion
    // #region International messages
    const messages = defineMessages({
        cancel: {
            id: "cancel",
            description: "Cancel",
            defaultMessage: "Cancel"
        },
        accident_assignments_submission_images_drag_drop: {
            id: "accident_assignments_submission_images_drag_drop",
            description: "Drag and drop your images here or click the icon to upload one or more",
            defaultMessage: "Drag and drop your images here or click the icon to upload one or more"
        },
        accident_assignments_submission_document_drag_drop: {
            id: "accident_assignments_submission_document_drag_drop",
            description: "Drag and drop your repair estimate here or click the icon to upload one",
            defaultMessage: "Drag and drop your repair estimate here or click the icon to upload one"
        },
        continue_link: {
            id: 'continue_link',
            description: 'Continue',
            defaultMessage: 'Continue'
        },
        submit_for_approval_link: {
            id: 'submit_for_approval_link',
            description: 'Submit for approval',
            defaultMessage: 'Submit for approval'
        },
        submit_additional_images: {
            id: 'submit_additional_images',
            description: 'Submit additional images',
            defaultMessage: 'Submit additional images'
        },
        return_link: {
            id: 'return_link',
            description: 'Return',
            defaultMessage: 'Return'
        },
        browse_file: {
            id: 'browse_file',
            description: 'Browse file',
            defaultMessage: 'Browse file'
        },
        files_uploaded: {
            id: 'files_uploaded',
            description: '{fileCount} file(s) uploaded',
            defaultMessage: '{fileCount} file(s) uploaded'
        },
        accident_assignments_submission_rejected_image: {
            id: 'accident_assignments_submission_rejected_image',
            description: 'Some file(s) could not be uploaded because of incorrect file type. Acceptable image types include: JPG PNG JPEG JPE BMP GIF JFIF',
            defaultMessage: 'Some file(s) could not be uploaded because of incorrect file type. Acceptable image types include: JPG PNG JPEG JPE BMP GIF JFIF'
        },
        accident_assignments_submission_rejected_document: {
            id: 'accident_assignments_submission_rejected_document',
            description: 'Some file(s) could not be uploaded because of incorrect file type. Acceptable document types include: PDF',
            defaultMessage: 'Some file(s) could not be uploaded because of incorrect file type. Acceptable document types include: PDF'
        },
        accident_assignments_submission_rejected_max_files: {
            id: 'accident_assignments_submission_rejected_max_files',
            description: 'Upload exceeds maximum file count: {fileCount} file(s)',
            defaultMessage: 'Upload exceeds maximum file count: {fileCount} file(s)'
        },
        accident_assignments_pricing_description: {
            id: "accident_assignments_pricing_description",
            description: "Description",
            defaultMessage: "Description"
        },
        accident_assignments_pricing_cost: {
            id: "accident_assignments_pricing_cost",
            description: "Cost",
            defaultMessage: "Cost"
        },
        accident_assignments_pricing_subtotal: {
            id: "accident_assignments_pricing_subtotal",
            description: "SUBTOTAL",
            defaultMessage: "SUBTOTAL"
        },
        accident_assignments_pricing_damage_parts: {
            id: "accident_assignments_pricing_damage_parts",
            description: "VEHICLE DAMAGE PARTS",
            defaultMessage: "VEHICLE DAMAGE PARTS"
        },
        accident_assignments_pricing_damage_labor: {
            id: "accident_assignments_pricing_damage_labor",
            description: "VEHICLE DAMAGE LABOR",
            defaultMessage: "VEHICLE DAMAGE LABOR"
        },
        accident_assignments_pricing_paint_parts: {
            id: "accident_assignments_pricing_paint_parts",
            description: "PAINT SUPPLIES PARTS",
            defaultMessage: "PAINT SUPPLIES PARTS"
        },
        accident_assignments_pricing_paint_labor: {
            id: "accident_assignments_pricing_paint_labor",
            description: "PAINT LABOR",
            defaultMessage: "PAINT LABOR"
        },
        accident_assignments_pricing_mechanical: {
            id: "accident_assignments_pricing_mechanical",
            description: "MECHANICAL LABOR",
            defaultMessage: "MECHANICAL LABOR"
        },
        accident_assignments_pricing_other: {
            id: "accident_assignments_pricing_other",
            description: "OTHER",
            defaultMessage: "OTHER"
        },
    });
    // #endregion
    // #region Render helpers
    const renderCloseButton = () => (React.createElement(ModalClose, { role: 'button', id: "close_btn", onClick: () => onCloseModal(true), style: { width: "fit-content", marginBottom: "0.5rem", cursor: isCleaningUp ? "default" : '', opacity: isCleaningUp ? "50%" : '' }, disabled: isCleaningUp },
        React.createElement("img", { src: "/assets/VMS_33button_kill_blue.png" }),
        "  ",
        " ",
        React.createElement(FormattedMessage, Object.assign({}, messages.cancel))));
    const getContinueButtonMessage = () => {
        if (wizardStep === 3) {
            return (React.createElement(FormattedMessage, Object.assign({}, messages.submit_for_approval_link)));
        }
        else {
            return (React.createElement(FormattedMessage, Object.assign({}, messages.continue_link)));
        }
    };
    const getWizardHeader = () => {
        const messages = defineMessages({
            accident_assignments_submission_images_title: {
                id: "accident_assignments_submission_images_title",
                description: "Upload images",
                defaultMessage: "Upload images"
            },
            accident_assignments_submission_estimate_title: {
                id: "accident_assignments_submission_estimate_title",
                description: "Upload repair estimate copy",
                defaultMessage: "Upload repair estimate copy"
            },
            accident_assignments_submission_pricing_title: {
                id: "accident_assignments_submission_pricing_title",
                description: "Repair pricing",
                defaultMessage: "Repair pricing"
            },
            accident_assignments_submission_additional_images_title: {
                id: "accident_assignments_submission_additional_images_title",
                description: "Upload additional images",
                defaultMessage: "Upload additional images"
            },
            accident_assignments_submission_supplement_title: {
                id: "accident_assignments_submission_supplement_title",
                description: "Upload supplemental copy",
                defaultMessage: "Upload supplemental copy"
            },
        });
        if (isSupplementSubmission) {
            return (React.createElement(FormattedMessage, Object.assign({}, messages.accident_assignments_submission_supplement_title)));
        }
        else {
            switch (wizardStep) {
                case 1:
                    return (React.createElement(FormattedMessage, Object.assign({}, messages.accident_assignments_submission_images_title)));
                case 2:
                    return (React.createElement(FormattedMessage, Object.assign({}, messages.accident_assignments_submission_estimate_title)));
                case 3:
                    return (React.createElement(FormattedMessage, Object.assign({}, messages.accident_assignments_submission_pricing_title)));
            }
        }
    };
    const getWizardBody = () => {
        const messages = defineMessages({
            accident_assignments_submission_images_body: {
                id: "accident_assignments_submission_images_body",
                description: "Submit images of the four corners of the vehicle, odometer, plate number, VIN, and any areas of damage. These images will be used by the AccMan team to review the repair estimate, and will be accessible to the client and their insurance company.",
                defaultMessage: "Submit images of the four corners of the vehicle, odometer, plate number, VIN, and any areas of damage. These images will be used by the AccMan team to review the repair estimate, and will be accessible to the client and their insurance company."
            },
            accident_assignments_submission_estimate_body: {
                id: "accident_assignments_submission_estimate_body",
                description: "To expedite the review of this accident assignment, please be sure to upload the images and repair estimate, input your pricing. All three items are needed to submit your repair estimate to Holman's Accident Management team. \n\n You can add additional images here throughout the repair process if needed.",
                defaultMessage: "To expedite the review of this accident assignment, please be sure to upload the images and repair estimate, input your pricing. All three items are needed to submit your repair estimate to Holman's Accident Management team. \n\n You can add additional images here throughout the repair process if needed."
            },
            accident_assignments_submission_pricing_body: {
                id: "accident_assignments_submission_pricing_body",
                description: "Lastly, enter the cost of the labor and parts in the lines below.",
                defaultMessage: "Lastly, enter the cost of the labor and parts in the lines below."
            },
            accident_assignments_submission_additional_images: {
                id: "accident_assignments_submission_additional_images",
                description: "Click below to select additional images to add to the original estimate.",
                defaultMessage: "Click below to select additional images to add to the original estimate."
            },
            accident_assignments_submission_supplement_pricing_body: {
                id: "accident_assignments_submission_supplement_pricing_body",
                description: "Submit supplemental pricing in the line below",
                defaultMessage: "Submit supplemental pricing in the line below"
            },
        });
        switch (wizardStep) {
            case 1:
                return (React.createElement(FormattedMessage, Object.assign({}, messages.accident_assignments_submission_images_body)));
            case 2:
                return (React.createElement(FormattedMessage, Object.assign({}, messages.accident_assignments_submission_estimate_body)));
            case 3:
                return (isSupplementSubmission ? React.createElement(FormattedMessage, Object.assign({}, messages.accident_assignments_submission_supplement_pricing_body)) : React.createElement(FormattedMessage, Object.assign({}, messages.accident_assignments_submission_pricing_body)));
        }
    };
    const getFileName = (fileName) => {
        return (React.createElement(OverlayTrigger, { placement: "right", key: fileName, overlay: React.createElement(Tooltip, { id: "tooltop" }, fileName) },
            React.createElement("div", { key: fileName }, fileName.substring(0, 25) + "...")));
    };
    const getInternationalDescription = (lineItem) => {
        switch (lineItem.ataCode) {
            case AccidentAssignmentAtaCodes.DamageParts:
                return intl.formatMessage(messages.accident_assignments_pricing_damage_parts);
            case AccidentAssignmentAtaCodes.DamageLabor:
                return intl.formatMessage(messages.accident_assignments_pricing_damage_labor);
            case AccidentAssignmentAtaCodes.PaintParts:
                return intl.formatMessage(messages.accident_assignments_pricing_paint_parts);
            case AccidentAssignmentAtaCodes.PaintLabor:
                return intl.formatMessage(messages.accident_assignments_pricing_paint_labor);
            case AccidentAssignmentAtaCodes.MechLabor:
                return intl.formatMessage(messages.accident_assignments_pricing_mechanical);
            case AccidentAssignmentAtaCodes.Other:
                return intl.formatMessage(messages.accident_assignments_pricing_other);
        }
    };
    // #endregion  
    let _images = imagesData === null || imagesData === void 0 ? void 0 : imagesData.repairEstimateImages;
    // #region Render
    return (React.createElement(ModalStyled, { show: showModal, backdrop: "static", keyboard: false, style: { overflow: "auto" } },
        React.createElement(ModalBody, null,
            renderCloseButton(),
            dropZoneErrors ? React.createElement(FormWrapper, { id: "dropzoneErrorMessages", key: "dropzoneErrorMessages", formErrors: dropZoneErrors })
                : null,
            (imagesError === null || imagesError === void 0 ? void 0 : imagesError.message) ? React.createElement(FormWrapper, { id: "queryErrorMessages", key: "queryErrorMessages", formErrors: [{ message: imagesError === null || imagesError === void 0 ? void 0 : imagesError.message }] })
                : null,
            (deleteError === null || deleteError === void 0 ? void 0 : deleteError.message) ? React.createElement(FormWrapper, { id: "deleteErrorMessages", key: "deleteErrorMessages", formErrors: [{ message: deleteError === null || deleteError === void 0 ? void 0 : deleteError.message }] })
                : null,
            uploadErrors.length > 0 ? React.createElement(FormWrapper, { id: "uploadErrorMessages", key: "uploadErrorMessages", formErrors: uploadErrors })
                : null,
            React.createElement(Container, { className: "accident_assignments__body_container" }, isCleaningUp ? React.createElement(SpinnerComponent, { spinnerType: "blue" }) :
                React.createElement(Row, null,
                    React.createElement(Col, { xs: 1, className: "accident_assignments__step_col" },
                        React.createElement(Row, { className: "accident_assignments__step_count" }, wizardStep),
                        React.createElement(Row, { className: "accident_assignments__step_border" })),
                    React.createElement(Col, null,
                        React.createElement(ModalMainHeader, null, getWizardHeader()),
                        React.createElement(Row, { className: "accident_assignments__body_text", style: { padding: "2rem 0 3.5rem 0" } }, getWizardBody()),
                        wizardStep == 1 ?
                            React.createElement(Container, { style: { paddingLeft: "0", paddingRight: "0", minHeight: "200px" } },
                                dropZoneFiles.length === 0 && (_images === undefined || (_images === null || _images === void 0 ? void 0 : _images.length) === 0) ?
                                    React.createElement("div", Object.assign({}, getRootProps({ className: "accident_assignments__upload_container" })),
                                        React.createElement("input", Object.assign({}, getInputProps(), { type: "file" })),
                                        React.createElement(Row, { className: "justify-content-center accident_assignments__body_text" },
                                            React.createElement(FormattedMessage, Object.assign({}, messages.accident_assignments_submission_images_drag_drop))),
                                        React.createElement(Row, { className: "justify-content-center" },
                                            React.createElement("button", { type: "button", onClick: open, className: "accident_assignments__upload_file_icon", id: "upload_btn" },
                                                React.createElement("img", { src: "/assets/Imageupload.jpg", style: { width: "30px", height: "29px" } }))))
                                    : isLoading || imagesLoading ? React.createElement(ProgressBar, { animated: true, now: uploadImagesProgress })
                                        : React.createElement(Container, { fluid: true, className: "accident_assignments__estimate_submission_subsection" },
                                            React.createElement(Row, { className: "accident_assignments_image_container" }, _images.map((image, count) => (React.createElement("div", { className: "accident_assignments_image_item", key: image.fileName },
                                                React.createElement(CloseButton, { className: "accident_assignments__image_close", id: `delete_image_${count + 1}_btn`, onClick: (e) => onClickDeleteImage(repairEstimateId, image.imageUrl, image.fileName, e) }),
                                                React.createElement("div", null,
                                                    deleteImagesQueue.includes(image.fileName) ? React.createElement(Spinner, { className: "accident_assignments__submission_image_spinner" }) : null,
                                                    React.createElement("img", { className: "accident_assignments_submission_image", src: image.thumbnailUrl })),
                                                React.createElement("div", { className: "accident_assignments__submission_image_text" },
                                                    React.createElement("div", { style: { fontStyle: "italic" } }, image.fileName.length > 25 ? getFileName(image.fileName) : image.fileName),
                                                    React.createElement("div", null, `${count + 1} of ${_images === null || _images === void 0 ? void 0 : _images.length}`))))))),
                                (!isLoading && !imagesLoading) && (dropZoneFiles.length !== 0 || (_images === null || _images === void 0 ? void 0 : _images.length) !== 0) ?
                                    React.createElement(Row, null,
                                        React.createElement(Col, { className: "justify-content-start align-self-center" },
                                            React.createElement(FormattedMessage, Object.assign({}, messages.files_uploaded, { values: { fileCount: _images === null || _images === void 0 ? void 0 : _images.length } }))),
                                        React.createElement(Col, { className: "justify-content-end d-flex" },
                                            React.createElement(ButtonStyled, { role: "button", onClick: open, disable: false, style: { width: "fit-content", paddingRight: "0", paddingTop: "0" }, id: "additional_upload_btn" },
                                                React.createElement(FormattedMessage, Object.assign({}, messages.browse_file)),
                                                " ",
                                                React.createElement("img", { src: "/assets/VMS_28button_edit_clear.png" }))))
                                    : null)
                            : null,
                        wizardStep == 2 ?
                            React.createElement(Container, { style: { paddingLeft: "0", paddingRight: "0", minHeight: "200px" } },
                                dropZoneFiles.length === 0 && repairEstimateDocument === undefined ?
                                    React.createElement("div", Object.assign({}, getRootProps({ className: "accident_assignments__upload_container" })),
                                        React.createElement("input", Object.assign({}, getInputProps(), { type: "file", id: "upload_btn" })),
                                        React.createElement(Row, { className: "justify-content-center accident_assignments__body_text" },
                                            React.createElement(FormattedMessage, Object.assign({}, messages.accident_assignments_submission_document_drag_drop))),
                                        React.createElement(Row, { className: "justify-content-center" },
                                            React.createElement("button", { type: "button", onClick: open, className: "accident_assignments__upload_file_icon", id: "upload_btn" },
                                                React.createElement("img", { src: "/assets/Imageupload.jpg", style: { width: "30px", height: "29px" } }))))
                                    : React.createElement(Container, { fluid: true, className: "accident_assignments__estimate_submission_subsection" },
                                        React.createElement(Row, { className: "accident_assignments_document_container" },
                                            React.createElement(Col, { className: "justify-content-start align-items-center d-flex" },
                                                React.createElement("i", { className: "fa fa-file-o fa-2x", style: { width: "fit-content", paddingRight: "1rem" } }),
                                                repairEstimateDocument.name),
                                            React.createElement(Col, { className: "justify-content-end d-flex" },
                                                React.createElement(ButtonStyled, { role: "button", onClick: () => {
                                                        setRepairEstimateDocument(undefined);
                                                        setDropZoneFiles([]);
                                                    }, disable: false, style: { width: "fit-content", padding: 0 }, id: "remove_file_btn" },
                                                    React.createElement("i", { className: "fa fa-trash-o fa-2x" }))))),
                                dropZoneFiles.length !== 0 ?
                                    React.createElement(Row, null,
                                        React.createElement(Col, { className: "justify-content-end d-flex" },
                                            React.createElement(ButtonStyled, { role: "button", onClick: open, disable: false, style: { width: "fit-content", paddingRight: "0", paddingTop: "0" }, id: "additional_upload_btn" },
                                                React.createElement(FormattedMessage, Object.assign({}, messages.browse_file)),
                                                " ",
                                                React.createElement("img", { src: "/assets/VMS_28button_edit_clear.png" }))))
                                    : null)
                            : null,
                        wizardStep === 3 ?
                            React.createElement(Row, null,
                                React.createElement("table", { className: "accident_assignments_line_item__table" },
                                    React.createElement("thead", null,
                                        React.createElement("tr", { className: "container" },
                                            React.createElement("th", { className: "col-md-5 accident_assignments_line_item__header", style: { paddingLeft: "2rem" } },
                                                React.createElement(FormattedMessage, Object.assign({}, messages.accident_assignments_pricing_description))),
                                            React.createElement("th", { className: "col-md-2 accident_assignments_line_item__header accident_assignments_line_item__currency", style: { paddingRight: "2rem" } },
                                                React.createElement(FormattedMessage, Object.assign({}, messages.accident_assignments_pricing_cost))))),
                                    React.createElement("tbody", null, repairEstimatePricing.map((lineitem, i) => (React.createElement("tr", { className: "container", key: i },
                                        React.createElement("td", { className: "col-md-6 accident_assignments_line_item__item", style: { paddingLeft: "2rem" } }, getInternationalDescription(lineitem)),
                                        React.createElement("td", { className: "col-md-1 accident_assignments_line_item__item accident_assignments_line_item__currency", style: { paddingRight: "2rem" } },
                                            React.createElement(DynamicNumber, { className: "accident_assignments__lineitem-input", onChange: (evt, modelValue, viewValue) => onCostChange(i, viewValue), separator: '.', integer: 6, fraction: 2, positive: true, negative: false, thousand: true, placeholder: "0", value: lineitem.unitPrice === undefined || lineitem.unitPrice === null ? "" : lineitem.unitPrice, id: `input_${i + 1}` }))))))))
                            : null,
                        React.createElement(Row, null,
                            wizardStep !== 1 ?
                                React.createElement(Col, { className: "justify-content-start d-flex" },
                                    React.createElement(ButtonStyled, { role: "button", onClick: () => { onBackClicked(); }, disable: false, style: { width: "fit-content", paddingRight: "0", paddingTop: "3.5rem" }, id: "return_btn" },
                                        React.createElement("img", { src: "/assets/VMS_33button_back_blue.png" }),
                                        " ",
                                        React.createElement(FormattedMessage, Object.assign({}, messages.return_link))))
                                : null,
                            React.createElement(Col, { className: "justify-content-end d-flex" },
                                React.createElement(ButtonStyled, { role: "button", onClick: () => { onContinueClicked(); }, style: { width: "fit-content", paddingRight: "0", paddingTop: "3.5rem", opacity: isLoading || imagesLoading ? "50%" : "" }, disable: isLoading || imagesLoading, id: "continue_btn" },
                                    getContinueButtonMessage(),
                                    " ",
                                    React.createElement("img", { src: "/assets/VMS_33button_go_bblue.png" }))))))))));
    // #endregion
};
// #endregion
export default (AccidentAssignmentsSubmissionModal);
