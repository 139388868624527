const tireProductCodes = [
    '17001001',
    '17001002',
    '17001004',
    '17001005',
    '17001010',
    '17001011',
    '17001012',
    '17001014',
    '17001015',
    '17001016',
    '17001017',
    '17001018',
    '17001026',
    '17001A06',
    '17999A58',
    '17999A61'
]
export default tireProductCodes